import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export const EmailOpened = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      color="primary"
      {...props}
    >
      <path
        fill="none"
        opacity="0.4"
        d="M28.6641 25.3351L19.807 17.3351M12.1878 17.3351L3.33073 25.3351M18.3227 3.51323L28.36 10.0375C28.7147 10.268 28.892 10.3833 29.0205 10.5371C29.1343 10.6732 29.2197 10.8306 29.2718 11.0001C29.3307 11.1917 29.3307 11.4032 29.3307 11.8262V21.6018C29.3307 23.842 29.3307 24.9621 28.8948 25.8178C28.5113 26.5704 27.8993 27.1823 27.1467 27.5658C26.291 28.0018 25.1709 28.0018 22.9307 28.0018H9.06406C6.82385 28.0018 5.70375 28.0018 4.8481 27.5658C4.09545 27.1823 3.48353 26.5704 3.10004 25.8178C2.66406 24.9621 2.66406 23.842 2.66406 21.6018V11.8262C2.66406 11.4032 2.66406 11.1917 2.72296 11.0001C2.77509 10.8306 2.86052 10.6732 2.97426 10.5371C3.10274 10.3833 3.28008 10.268 3.63475 10.0375L13.6721 3.51323C14.5138 2.96615 14.9346 2.69261 15.388 2.58618C15.7888 2.49211 16.206 2.49211 16.6068 2.58618C17.0602 2.69261 17.481 2.96615 18.3227 3.51323Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M13.678 17.8237L4.04587 11.5628C3.5873 11.2647 3.35802 11.1157 3.27861 10.9267C3.20921 10.7615 3.20921 10.5754 3.27861 10.4102C3.35802 10.2212 3.5873 10.0722 4.04587 9.77412L13.678 3.51323C14.5197 2.96615 14.9405 2.69261 15.3939 2.58618C15.7947 2.49211 16.2119 2.49211 16.6127 2.58618C17.0661 2.69261 17.4869 2.96615 18.3286 3.51323L27.9607 9.77412C28.4193 10.0722 28.6486 10.2212 28.728 10.4102C28.7974 10.5754 28.7974 10.7615 28.728 10.9267C28.6486 11.1157 28.4193 11.2647 27.9607 11.5628L18.3286 17.8237C17.4869 18.3708 17.0661 18.6443 16.6127 18.7507C16.2119 18.8448 15.7947 18.8448 15.3939 18.7507C14.9405 18.6443 14.5197 18.3708 13.678 17.8237Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
