import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from "@mui/material";

import { useLearningResource } from "@/utils/ResourceGet";

const ENDPOINT = "company/send_onboarding_new_users_status";
const SEND_SINGLE_ONBOARDING_ENDPOINT = "admin/send_onboarding_email";
export const SendOnboardings = () => {
  const snackbar = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const [{ enabled: optionEnabled }, readError] = useLearningResource(
    ENDPOINT,
    false,
  );

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(optionEnabled);
  }, [optionEnabled]);

  async function onChange(): Promise<void> {
    const initialCheckValue = !!checked;
    const targetCheckValue = !checked;
    try {
      setChecked(targetCheckValue);
      await axios.put(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/${ENDPOINT}`,
        { enabled: targetCheckValue },
      );
      if (targetCheckValue) {
        snackbar.showMessage(t("New users will receive onboarding emails."));
      } else {
        snackbar.showMessage(
          t("New users will not receive onboarding emails."),
        );
      }
    } catch {
      snackbar.showMessage("Could not update value.");
      setChecked(initialCheckValue);
    }
  }

  async function sendExampleOnboardingEmail(): Promise<void> {
    try {
      setButtonLoading(true);
      await axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT_LEARNING
        }/${SEND_SINGLE_ONBOARDING_ENDPOINT}`,
      );
      snackbar.showMessage(t("Email sent."));
    } catch (e) {
      snackbar.showMessage(t("Error: email could not be sent."));
    }
    setButtonLoading(false);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Typography variant="h6">
              {t("Send onboarding emails to new users")} &nbsp;
            </Typography>
            <Box sx={{ paddingLeft: ".5em" }}>
              <Switch
                size="small"
                checked={checked}
                onChange={onChange}
                disabled={readError}
              />
            </Box>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <Typography>
            {t(
              "When activated, new employees will receive an email that explains how Mantra Awareness courses work.",
            )}
          </Typography>
          {readError !== null && (
            <Typography style={{ color: "red" }}>
              {t("Could not retrieve data.")}
            </Typography>
          )}
          <Button
            variant="contained"
            sx={{ marginTop: "1rem" }}
            onClick={sendExampleOnboardingEmail}
            disabled={buttonLoading}
          >
            {t("Send example onboarding email to myself")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
