import { createApi } from "@reduxjs/toolkit/query/react";

import { DateRange } from "@/components/controls/DateRangePicker/DateRange";
import { simulationBaseQuery } from "@/utils/rtkQuery";

type LeaderboardRequest = {
  dateRange: DateRange;
};

export const leaderboardApi = createApi({
  reducerPath: "leaderboardApi",
  baseQuery: simulationBaseQuery(),
  endpoints: (builder) => ({
    downloadLeaderboard: builder.query<string, LeaderboardRequest>({
      query: (requestParams) => {
        const params = new URLSearchParams();
        params.set("date_from", requestParams.dateRange.from.toISOString());
        params.set("date_to", requestParams.dateRange.to.toISOString());
        return {
          url: `users/leaderboard/export?${params}`,
          responseHandler: "text",
        };
      },
      transformResponse: (responseStr: string, responseObj, args) => {
        if (responseObj.response.status !== 200) {
          return undefined;
        }
        return responseStr;
      },
    }),
  }),
});

export const { useLazyDownloadLeaderboardQuery } = leaderboardApi;
