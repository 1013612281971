import React from "react";

import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon color="primary">
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.8873 18.3237L9.44016 19.5671C9.6045 19.9373 9.87272 20.2517 10.2123 20.4724C10.5518 20.6931 10.9481 20.8106 11.3531 20.8105C11.758 20.8106 12.1543 20.6931 12.4939 20.4724C12.8334 20.2517 13.1016 19.9373 13.266 19.5671L13.8188 18.3237C14.0156 17.8825 14.3467 17.5147 14.7648 17.2727C15.1855 17.03 15.6723 16.9266 16.1553 16.9773L17.508 17.1213C17.9107 17.1639 18.3171 17.0888 18.6779 16.905C19.0387 16.7213 19.3384 16.4368 19.5408 16.086C19.7434 15.7355 19.8399 15.3338 19.8186 14.9295C19.7973 14.5252 19.6592 14.1357 19.4209 13.8084L18.62 12.708C18.3349 12.3132 18.1825 11.838 18.1849 11.3511C18.1848 10.8654 18.3386 10.3922 18.6242 9.99941L19.4251 8.89896C19.6634 8.57164 19.8015 8.18221 19.8228 7.77791C19.8441 7.37362 19.7476 6.97184 19.545 6.62133C19.3426 6.27061 19.0429 5.98612 18.6821 5.80236C18.3213 5.6186 17.9149 5.54345 17.5122 5.58605L16.1595 5.73004C15.6765 5.78077 15.1897 5.67739 14.769 5.43469C14.35 5.1913 14.0189 4.82153 13.823 4.37839L13.266 3.135C13.1016 2.76487 12.8334 2.45039 12.4939 2.22968C12.1543 2.00898 11.758 1.89154 11.3531 1.8916C10.9481 1.89154 10.5518 2.00898 10.2123 2.22968C9.87272 2.45039 9.6045 2.76487 9.44016 3.135L8.8873 4.37839C8.69142 4.82153 8.36029 5.1913 7.94136 5.43469C7.52061 5.67739 7.03388 5.78077 6.55082 5.73004L5.19391 5.58605C4.79125 5.54345 4.38488 5.6186 4.02407 5.80236C3.66327 5.98612 3.36352 6.27061 3.16118 6.62133C2.95857 6.97184 2.86205 7.37362 2.88332 7.77791C2.90459 8.18221 3.04274 8.57164 3.281 8.89896L4.0819 9.99941C4.36752 10.3922 4.52132 10.8654 4.52124 11.3511C4.52132 11.8367 4.36752 12.3099 4.0819 12.7027L3.281 13.8032C3.04274 14.1305 2.90459 14.5199 2.88332 14.9242C2.86205 15.3285 2.95857 15.7303 3.16118 16.0808C3.36372 16.4313 3.6635 16.7157 4.02426 16.8994C4.38501 17.0831 4.79128 17.1584 5.19391 17.1161L6.54661 16.9721C7.02968 16.9213 7.51641 17.0247 7.93715 17.2674C8.35765 17.5101 8.69032 17.88 8.8873 18.3237Z"
          stroke="currentColor"
          strokeWidth="1.89189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3514 14.1889C12.9187 14.1889 14.1892 12.9184 14.1892 11.3511C14.1892 9.78377 12.9187 8.51322 11.3514 8.51322C9.78408 8.51322 8.51354 9.78377 8.51354 11.3511C8.51354 12.9184 9.78408 14.1889 11.3514 14.1889Z"
          stroke="currentColor"
          strokeWidth="1.89189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
