import React from "react";
import { useSearchParams } from "react-router-dom";

import SplashScreen from "@/components/SplashScreen";
import { useMarkAsSafe } from "@/pages/landing-pages/api";
import css from "@/pages/landing-pages/components/LandingPage.module.css";
import LandingPageLayout, {
  SupportedLanguage,
} from "@/pages/landing-pages/components/LandingPageLayout";
import { LANDING_PAGE_MESSAGES } from "@/pages/landing-pages/translations";
import {
  browserLanguage,
  useOnlyOnceEffect,
} from "@/pages/landing-pages/utils";

type MarkAsSafePageProps = {
  lang: SupportedLanguage;
  isLoading: boolean;
  isError: boolean;
  error: any;
  senderAddress: string;
};

export const MarkAsSafePage = ({
  lang,
  isError,
  isLoading,
  error,
  senderAddress,
}: MarkAsSafePageProps) => {
  const translate = LANDING_PAGE_MESSAGES[lang];
  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <LandingPageLayout lang={lang}>
      {isError ? (
        <>
          {error?.response?.status === 403 ? (
            <div>{translate.action_not_allowed}</div>
          ) : (
            <div>{translate.an_error_has_occurred}</div>
          )}
          <div>{translate.sender_has_not_been_added}</div>
        </>
      ) : (
        <>
          <p className={css.sender_email}>{senderAddress}</p>
          <p className={css.medium_message}>
            {translate.mark_as_safe_add_to_safe}
          </p>
          <p className={css.small_message}>
            {translate.mark_as_safe_not_display_again}
          </p>
        </>
      )}
    </LandingPageLayout>
  );
};

export function MarkAsSafe() {
  const [searchParams] = useSearchParams();
  const { markAsSafe, isLoading, isError, error } = useMarkAsSafe();

  const browser_language = browserLanguage();

  const senderAddress = searchParams.get("sender_address");

  useOnlyOnceEffect(() => {
    markAsSafe({
      sender_address: senderAddress,
      recipient_address: searchParams.get("recipient_address"),
    });
  });

  return (
    <MarkAsSafePage
      lang={browser_language}
      isLoading={isLoading}
      isError={isError}
      error={error}
      senderAddress={senderAddress}
    />
  );
}
