import { appDispatch, appSelector } from "@/store";
import {
  selectBannersDashboardPeriod,
  selectSimulationDashboardPeriod,
  setBannersDashboardPeriod,
  setSimulationDashboardPeriod,
} from "@/utils/userPreferencesSlice";

export const useUserPreferences = () => {
  const dispatch = appDispatch();
  return {
    simulationDashboardPeriod: appSelector(selectSimulationDashboardPeriod),
    setSimulationDashboardPeriod: (new_period: string) =>
      dispatch(setSimulationDashboardPeriod(new_period)),
    bannersDashboardPeriod: appSelector(selectBannersDashboardPeriod),
    setBannersDashboardPeriod: (new_period: string) =>
      dispatch(setBannersDashboardPeriod(new_period)),
  };
};
