import React from "react";
import { useTranslation } from "react-i18next";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import Box from "@mui/material/Box";

import { BannerLevel } from "@/pages/threats/service/domain";

type WarningBannerProps = {
  bannerLevel: BannerLevel;
};

const WarningBanner = ({ bannerLevel }: WarningBannerProps) => {
  let textColor: string;
  let backgroundColor: string;
  let text: string;
  const { t } = useTranslation();

  switch (bannerLevel) {
    case "very-suspicious":
      textColor = "#EE0E00";
      backgroundColor = "#F8E9E8";
      text = t(
        "Mantra has analyzed this email and thinks it is very suspicious",
      );
      break;
    case "suspicious":
      textColor = "#FF9800";
      backgroundColor = "#FDF4E6";
      text = t("Mantra has analyzed this email and thinks it is suspicious");
      break;
    default:
      return null;
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: backgroundColor,
          color: textColor,
          padding: "20px",
          fontWeight: "bold",
          marginTop: "15px",
          marginBottom: "15px",
          borderRadius: "5px",
        }}
      >
        <ReportProblemOutlinedIcon sx={{ marginRight: "10px" }} />
        {text}
      </Box>
    </>
  );
};

export default WarningBanner;
