import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { FullPageSpinner } from "@/components/Spinner";
import { Layout } from "@/components/layout/Layout";
import { CourseSharing } from "@/pages/awareness/courses/corporate-course-sharing/CourseSharing";
import { CourseInfo } from "@/pages/awareness/courses/course-editor/CourseInfo";
import { CoursesCatalog } from "@/pages/awareness/courses/courses-catalog/CoursesCatalog";
import { AwarenessDashboard } from "@/pages/awareness/dashboard/AwarenessDashboard";
import { AwarenessUsersConfig } from "@/pages/awareness/dashboard/awareness-users-config/AwarenessUsersConfig";
import { Push } from "@/pages/awareness/push/Push";
import { Setup as AwarenessSetup } from "@/pages/awareness/setup/Setup";
import BannersLayout from "@/pages/banners/BannersLayout";
import { BannerDashboard } from "@/pages/banners/dashboard/BannerDashboard";
import { Setup as BannerSetup } from "@/pages/banners/setup/Setup";
import { Users as BannerUsers } from "@/pages/banners/users/Users";
import { DangerousBrowsing } from "@/pages/browser-defender/DangerousBrowsing";
import DangerousDownloadsPage from "@/pages/browser-defender/DangerousDownloads";
import { Dashboard as BrowserDefenderDashboard } from "@/pages/browser-defender/Dashboard";
import { ExtensionGovernance } from "@/pages/browser-defender/ExtensionGovernance";
import PasswordMisusePage from "@/pages/browser-defender/PasswordMisuse";
import { Setup as BrowserDefenderSetup } from "@/pages/browser-defender/Setup";
import { UserDetails as BrowserDefenderUserDetails } from "@/pages/browser-defender/UserDetails";
import { Users as BrowserDefenderUsers } from "@/pages/browser-defender/Users";
import { WeakPasswords } from "@/pages/browser-defender/WeakPasswords";
import { SaasGovernance } from "@/pages/browser-defender/demo/saasGovernance/SaasGovernance";
import DownloadsPolicies from "@/pages/browser-defender/setup/DownloadsPolicies";
import { ErrorPage404 } from "@/pages/errors/ErrorPage404";
import GeneralSettings from "@/pages/general-settings/GeneralSettings";
import { User } from "@/pages/general-settings/UsersManagement/User";
import { ImportWizard } from "@/pages/general-settings/UsersManagement/csvImport/ImportWizard";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";
import { DisableThreatNotifications } from "@/pages/landing-pages/DisableThreatNotifications";
import { MarkAsSafe } from "@/pages/landing-pages/MarkAsSafe";
import { ReportAsThreat } from "@/pages/landing-pages/ReportAsThreat";
import { MyProgress } from "@/pages/my-progress/MyProgress";
import CompanyScan from "@/pages/phishing-simulation/CompanyScan";
import CustomCampaign from "@/pages/phishing-simulation/CustomCampaign/CustomCampaign";
import PanelCustomCampaignDetails from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignDetails/PanelCustomCampaignDetails";
import Audit from "@/pages/phishing-simulation/audit/Audit";
import Dashboard from "@/pages/phishing-simulation/dashboard/Dashboard";
import EmailMessage from "@/pages/phishing-simulation/dashboard/emailmessage/EmailMessage";
import { Setup as PhishingSimulationSetup } from "@/pages/phishing-simulation/setup/Setup";
import { Support } from "@/pages/support/Support";
import { TemplateCatalog } from "@/pages/templates/TemplateCatalog";
import { ThreatsFeatureGate } from "@/pages/threats/ThreatsFeatureGate";
import AuthenticatedComponent from "@/router/AuthenticatedComponent";
import { HomeRedirect } from "@/router/HomeRedirect";

const PanelCreateCustomCampaign = React.lazy(
  () =>
    import(
      "@/pages/phishing-simulation/CustomCampaign/CustomCampaignCreate/PanelCreateCustomCampaign"
    ),
);

const EnterpriseWebchat = React.lazy(
  () => import("@/pages/webchat/EnterpriseWebchat"),
);
const UnauthenticatedEnterpriseWebchat = React.lazy(
  () => import("@/pages/webchat/UnauthenticatedEnterpriseWebchat"),
);

const PublicWebchat = React.lazy(() => import("@/pages/webchat/PublicWebchat"));

export type RouteType = {
  path: string;
  element: JSX.Element;
  permissions?: Permission[];
  disablePadding?: boolean;
  disableMenu?: boolean;
};

const AUTHENTICATED_ROUTES: RouteType[] = [
  {
    path: "/phishing-simulation/dashboard",
    element: <Dashboard isCorporateView={false} />,
    permissions: [Permission.SIMULATION_DASHBOARD],
  },
  {
    path: "/phishing-simulation/custom-campaign",
    element: <CustomCampaign />,
    permissions: [Permission.SIMULATION_CUSTOM_CAMPAIGN],
  },
  {
    path: "/phishing-simulation/custom-campaign/create",
    element: (
      <React.Suspense fallback={<FullPageSpinner />}>
        <PanelCreateCustomCampaign />
      </React.Suspense>
    ),
    permissions: [Permission.SIMULATION_CUSTOM_CAMPAIGN],
  },
  {
    path: "/phishing-simulation/custom-campaign/details",
    element: <PanelCustomCampaignDetails />,
    permissions: [Permission.SIMULATION_CUSTOM_CAMPAIGN],
  },
  {
    path: "/phishing-simulation/templates",
    element: <TemplateCatalog />,
    permissions: [Permission.SIMULATION_TEMPLATE_CATALOG],
  },
  {
    path: "/phishing-simulation/company",
    element: <CompanyScan />,
    permissions: [Permission.SETTINGS_COMPANY_SCAN],
  },
  {
    path: "/phishing-simulation/audit",
    element: <Audit />,
    permissions: [Permission.SIMULATION_AUDIT],
  },
  {
    path: "/phishing-simulation/setup",
    element: <PhishingSimulationSetup />,
    permissions: [Permission.SIMULATION_SETUP],
  },

  {
    path: "/corporate/dashboard",
    element: <Dashboard isCorporateView={true} />,
    permissions: [Permission.CORPORATE_ADMIN],
  },

  {
    path: "/corporate/course-sharing",
    element: <CourseSharing />,
    permissions: [Permission.CORPORATE_ADMIN],
  },

  {
    path: "/awareness/dashboard",
    element: <AwarenessDashboard />,
    permissions: [Permission.AWARENESS_DASHBOARD],
  },
  {
    path: "/awareness/courses",
    element: <CoursesCatalog isPublicWebchat={false} />,
    permissions: [
      Permission.AWARENESS_COURSE_CATALOG_USER,
      Permission.AWARENESS_COURSE_CATALOG_ADMIN,
    ],
  },
  {
    path: "/awareness/push",
    element: <Push />,
  },
  {
    path: "/awareness/courses/:id",
    element: <CourseInfo />,
    permissions: [Permission.AWARENESS_COURSE_CATALOG_ADMIN],
  },
  {
    path: "/awareness/setup",
    element: <AwarenessSetup />,
    permissions: [Permission.AWARENESS_SETUP],
  },
  {
    path: "/awareness/users",
    element: <AwarenessUsersConfig />,
    permissions: [Permission.AWARENESS_SETUP],
  },
  {
    path: "/banners/dashboard",
    element: (
      <BannersLayout>
        <BannerDashboard />
      </BannersLayout>
    ),
    permissions: [Permission.BANNERS],
  },
  {
    path: "/banners/setup",
    element: (
      <BannersLayout>
        <BannerSetup />
      </BannersLayout>
    ),
    permissions: [Permission.BANNERS],
  },
  {
    path: "/banners/users",
    element: (
      <BannersLayout>
        <BannerUsers />
      </BannersLayout>
    ),
    permissions: [Permission.BANNERS],
  },
  {
    path: "/banners/report-as-threat",
    element: <ReportAsThreat />,
    permissions: [Permission.BASIC_USER_PERMISSIONS],
    disableMenu: true,
  },
  {
    path: "/banners/mark-as-safe",
    element: <MarkAsSafe />,
    permissions: [Permission.BASIC_USER_PERMISSIONS],
    disableMenu: true,
  },

  {
    path: "/browser-defender/dashboard",
    element: <BrowserDefenderDashboard />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/password-misuse",
    element: <PasswordMisusePage />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/dangerous-downloads",
    element: <DangerousDownloadsPage />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/dangerous-browsing",
    element: <DangerousBrowsing />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/extension-governance",
    element: <ExtensionGovernance />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/saas-governance",
    element: <SaasGovernance />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/weak-passwords",
    element: <WeakPasswords />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/users",
    element: <BrowserDefenderUsers />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/user-details",

    element: <BrowserDefenderUserDetails />,
  },
  {
    path: "/browser-defender/setup",
    element: <BrowserDefenderSetup />,
    permissions: [Permission.BROWSER_DEFENDER],
  },
  {
    path: "/browser-defender/downloads-policies",
    element: <DownloadsPolicies />,
    permissions: [Permission.BROWSER_DEFENDER],
  },

  {
    path: "/threats",
    element: <ThreatsFeatureGate />,
    permissions: [Permission.THREATS],
    disablePadding: true,
  },
  {
    path: "/threats/disable-notification",
    element: <DisableThreatNotifications />,
    permissions: [Permission.BASIC_USER_PERMISSIONS],
    disableMenu: true,
  },
  {
    path: "/my-progress",
    element: <MyProgress />,
    permissions: [Permission.MY_PROGRESS],
  },

  {
    path: "/general-settings",
    element: <GeneralSettings />,
    permissions: [Permission.SETTINGS_GENERAL],
  },
  {
    path: "/users/import",
    element: <ImportWizard />,
    permissions: [Permission.SETTINGS_GENERAL],
  },

  {
    path: "/users/:userId",
    element: <User />,
    permissions: [Permission.BASIC_ADMIN_PERMISSIONS],
  },

  {
    path: "/dashboard/emailmessage",
    element: <EmailMessage />,
    permissions: [Permission.SIMULATION_DASHBOARD],
  },
  {
    path: "/webchat",
    element: (
      <React.Suspense fallback={<FullPageSpinner />}>
        <EnterpriseWebchat />
      </React.Suspense>
    ),
    disablePadding: true,
    permissions: [Permission.BASIC_USER_PERMISSIONS],
  },

  {
    path: "/support",
    element: <Support />,
    permissions: [Permission.SUPPORT],
  },

  { path: "/", element: <HomeRedirect /> },
  { path: "/*", element: <ErrorPage404 /> },
];

const PUBLIC_ROUTES: RouteType[] = [
  {
    path: "public/webchat/:userTempId",
    element: (
      <React.Suspense fallback={<FullPageSpinner />}>
        <UnauthenticatedEnterpriseWebchat />
      </React.Suspense>
    ),
    disablePadding: true,
  },
  {
    path: "demo/awareness/courses/",
    element: <CoursesCatalog isPublicWebchat={true} />,
  },
  {
    path: "demo/webchat/:userId",
    element: (
      <React.Suspense fallback={<FullPageSpinner />}>
        <PublicWebchat />
      </React.Suspense>
    ),
    disablePadding: true,
  },
  {
    path: "demo/webchat/all/:userId",
    element: (
      <React.Suspense fallback={<FullPageSpinner />}>
        <PublicWebchat all={true} />
      </React.Suspense>
    ),
    disablePadding: true,
  },
];

const REDIRECTIONS = [
  { from: "/dashboard", to: "/phishing-simulation/dashboard" },
  { from: "/phishing-simulation", to: "/phishing-simulation/dashboard" },

  { from: "/awareness", to: "/awareness/dashboard" },
  { from: "/demo/awareness", to: "/demo/awareness/courses" },

  { from: "/banners", to: "/banners/dashboard" },

  { from: "/browser-defender", to: "/browser-defender/dashboard" },

  { from: "/settings", to: "/general-settings" },
];

export const AppRoutes = () => {
  return (
    <Routes>
      {AUTHENTICATED_ROUTES.map(
        ({ path, element, permissions, disablePadding, disableMenu }) => (
          <Route
            path={path}
            element={
              <Layout disablePadding={disablePadding} disableMenu={disableMenu}>
                <AuthenticatedComponent permissions={permissions}>
                  {element}
                </AuthenticatedComponent>
              </Layout>
            }
            key={path}
          />
        ),
      )}
      {REDIRECTIONS.map((redirection) => (
        <Route
          path={redirection.from}
          element={<Navigate to={redirection.to} />}
          key={`from_${redirection.from}_to_${redirection.to}`}
        />
      ))}
      {PUBLIC_ROUTES.map(({ path, element, disablePadding, disableMenu }) => (
        <Route
          path={path}
          element={
            <Layout disablePadding={disablePadding} disableMenu={disableMenu}>
              {element}
            </Layout>
          }
          key={path}
        />
      ))}
    </Routes>
  );
};
