import React from "react";

import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3514 19.8649L11.2567 19.7229C10.5996 18.7373 10.2711 18.2445 9.837 17.8877C9.45272 17.5719 9.00993 17.3349 8.53397 17.1904C7.99633 17.0271 7.40404 17.0271 6.21944 17.0271H4.91893C3.85937 17.0271 3.3296 17.0271 2.9249 16.8209C2.56892 16.6395 2.27949 16.3501 2.09811 15.9941C1.89191 15.5894 1.89191 15.0596 1.89191 14.0001V5.86492C1.89191 4.80536 1.89191 4.27558 2.09811 3.87088C2.27949 3.5149 2.56892 3.22548 2.9249 3.04409C3.3296 2.83789 3.85937 2.83789 4.91893 2.83789H5.29731C7.41643 2.83789 8.47599 2.83789 9.28538 3.2503C9.99735 3.61306 10.5762 4.19191 10.939 4.90387C11.3514 5.71327 11.3514 6.77283 11.3514 8.89194M11.3514 19.8649V8.89194M11.3514 19.8649L11.446 19.7229C12.1031 18.7373 12.4317 18.2445 12.8657 17.8877C13.25 17.5719 13.6928 17.3349 14.1688 17.1904C14.7064 17.0271 15.2987 17.0271 16.4833 17.0271H17.7838C18.8434 17.0271 19.3731 17.0271 19.7778 16.8209C20.1338 16.6395 20.4232 16.3501 20.6046 15.9941C20.8108 15.5894 20.8108 15.0596 20.8108 14.0001V5.86492C20.8108 4.80536 20.8108 4.27558 20.6046 3.87088C20.4232 3.5149 20.1338 3.22548 19.7778 3.04409C19.3731 2.83789 18.8434 2.83789 17.7838 2.83789H17.4054C15.2863 2.83789 14.2267 2.83789 13.4173 3.2503C12.7054 3.61306 12.1265 4.19191 11.7638 4.90387C11.3514 5.71327 11.3514 6.77283 11.3514 8.89194"
          stroke="#DDE3EF"
          strokeWidth="1.89189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
