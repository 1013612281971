import Papa from "papaparse";

/**
 * Programmatic file download.
 *
 * Create a blob with a mimtype with
 *
 *    new Blob([data], {type: "text/csv"});
 */
export function downloadFile(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  let element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", filename);
  element.setAttribute("visibility", "hidden");

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function downloadAsCSV(data: any, filename: string) {
  downloadFile(new Blob([data], { type: "text/csv" }), filename);
}

export function dataToCSV(data: object[]) {
  return Papa.unparse(data);
}
