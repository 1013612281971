import React from "react";

import SplashScreen from "@/components/SplashScreen";
import { useDisableThreatNotifications } from "@/pages/landing-pages/api";
import css from "@/pages/landing-pages/components/LandingPage.module.css";
import LandingPageLayout, {
  SupportedLanguage,
} from "@/pages/landing-pages/components/LandingPageLayout";
import { LANDING_PAGE_MESSAGES } from "@/pages/landing-pages/translations";
import {
  browserLanguage,
  useOnlyOnceEffect,
} from "@/pages/landing-pages/utils";

type DisableThreatNotificationPageProps = {
  lang: SupportedLanguage;
  isLoading: boolean;
  isError: boolean;
};

export const DisableThreatNotificationPage = ({
  lang,
  isLoading,
  isError,
}: DisableThreatNotificationPageProps) => {
  const translate = LANDING_PAGE_MESSAGES[lang];

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <LandingPageLayout lang={lang}>
      {isError ? (
        <div>{translate.an_error_has_occurred}</div>
      ) : (
        <p className={css.message}>{translate.threat_notification_disabled}</p>
      )}
    </LandingPageLayout>
  );
};

export function DisableThreatNotifications() {
  const browser_language = browserLanguage();
  const { disableThreatNotifications, isError, isLoading } =
    useDisableThreatNotifications();

  useOnlyOnceEffect(() => {
    disableThreatNotifications();
  });

  return (
    <DisableThreatNotificationPage
      lang={browser_language}
      isError={isError}
      isLoading={isLoading}
    />
  );
}
