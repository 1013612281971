import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FullPageSpinner } from "@/components/Spinner";
import Threats from "@/pages/threats/Threats";
import { useIsNewThreatsUIEnabledQuery } from "@/pages/threats/api";
import { selectAuth0Token } from "@/utils/contexts/AuthSlice";
import { useUserContext } from "@/utils/contexts/UserContext";
import { getEmailJwt } from "@/utils/misc";

type ThreatsFeatureFlag = "new" | "withBanners";

export const ThreatsFeatureGate = () => {
  const [feature, setFeature] = useState<ThreatsFeatureFlag | null>(null);
  const { data: isNewThreatsEnabled } = useIsNewThreatsUIEnabledQuery();

  const userContext = useUserContext();

  const token = useSelector(selectAuth0Token);

  useEffect(() => {
    (async () => {
      if (isNewThreatsEnabled?.enabled == null) {
        return;
      }

      const nonImpersonatedEmail = getEmailJwt(token);

      const urlParams = new URLSearchParams(window.location.search);
      const uiParam = urlParams.get("threats-ui");

      if (uiParam === "with-banners") {
        setFeature("withBanners");
      } else if (isNewThreatsEnabled?.enabled) {
        setFeature("withBanners");
      } else if (
        nonImpersonatedEmail === "alina@mantra.ms" ||
        nonImpersonatedEmail === "bruno@mantra.ms" ||
        nonImpersonatedEmail === "guillaume@mantra.ms" ||
        nonImpersonatedEmail === "lucas@mantra.ms"
      ) {
        setFeature("withBanners");
      } else if (userContext.is_staff) {
        setFeature("withBanners");
      } else {
        setFeature("new");
      }
    })();
  }, [token, isNewThreatsEnabled, userContext.is_staff]);

  if (feature === null) {
    return <FullPageSpinner />;
  }

  return <Threats disableThreatsInBanners={feature !== "withBanners"} />;
};
