import React, { useState } from "react";

import { ThreatCategorisationButtons } from "@/pages/threats/components/FeedbackButtons";
import ThreatDeleteConfirmationDialog from "@/pages/threats/components/ThreatDeleteConfirmationDialog";
import ThreatFeedbackDialog from "@/pages/threats/components/ThreatFeedbackDialog";
import { FeedbackType } from "@/pages/threats/service/domain";
import { Threat } from "@/pages/threats/service/types";

type DetailPaneHeaderProps = {
  selectedThreats: Threat[];
  areThreatsArchived: boolean;

  onDelete?: () => void;
  onUnarchive?: () => void;
  onSendFeedback?: (
    feedbackType: FeedbackType,
    feedbackComment: string,
  ) => void;
};

const DetailPaneHeader = ({
  selectedThreats = [],
  areThreatsArchived = false,
  onDelete = () => {},
  onUnarchive = () => {},
  onSendFeedback = () => {},
}: DetailPaneHeaderProps) => {
  const [threatsToDelete, setThreatsToDelete] = useState<Threat[]>([]);
  const [confirmFeedbackType, setConfirmFeedbackType] =
    useState<FeedbackType | null>(null);

  const showDeleteDialog = () => setThreatsToDelete(selectedThreats);

  const handleDeleteThreatsConfirmation = () => {
    onDelete();
    setThreatsToDelete([]);
  };

  const disabled = selectedThreats.length === 0;

  return (
    <>
      <ThreatCategorisationButtons
        selectedCount={selectedThreats.length}
        archivePane={areThreatsArchived}
        unarchive={onUnarchive}
        sendFeedback={setConfirmFeedbackType}
        deleteThreats={showDeleteDialog}
        disabled={disabled}
      />
      <ThreatDeleteConfirmationDialog
        onDelete={handleDeleteThreatsConfirmation}
        onCancel={() => setThreatsToDelete([])}
        threats={threatsToDelete}
      />
      <ThreatFeedbackDialog
        open={confirmFeedbackType !== null}
        onConfirm={(comment?: string) => {
          onSendFeedback(confirmFeedbackType, comment);
          setConfirmFeedbackType(null);
        }}
        onCancel={() => setConfirmFeedbackType(null)}
        action={confirmFeedbackType}
        threatCount={selectedThreats.length}
      />
    </>
  );
};

export default DetailPaneHeader;
