import React from "react";

import { Stack, Tooltip, Typography } from "@mui/material";

import { StyledBox } from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/DataPerUsersRow";

export default function CompromisingAction({
  data,
  icon,
  iconFirst = false,
  tooltipValue = "",
}: {
  data: number;
  icon: JSX.Element;
  iconFirst?: boolean;
  tooltipValue?: string;
}) {
  let style;
  if (iconFirst) {
    style = { paddingLeft: "5px" };
  } else {
    style = { paddingRight: "5px" };
  }
  return (
    <Tooltip
      title={tooltipValue}
      disableHoverListener={tooltipValue.length === 0}
      arrow
    >
      <StyledBox>
        <Stack direction="row" alignItems="center" gap="1px">
          {iconFirst && icon}

          <Typography sx={style} variant="body_small" color="text.secondary">
            {data}
          </Typography>
          {!iconFirst && icon}
        </Stack>
      </StyledBox>
    </Tooltip>
  );
}
