import dayjs from "dayjs";

import { Threat } from "@/pages/threats/service/types";
import { dataToCSV, downloadAsCSV } from "@/utils/downloadFile";

type CSVThreatState = "unknown" | "unsafe" | "safe" | "spam" | "suspicious";

type CSVThreatEntry = {
  subject: string;
  from_email: string;
  from_name: string;
  report_date: Date;
  feedback_giver_email: string;
  reporter_email: string;
  feedback_type: CSVThreatState;
  feedback_date: Date | null;
  count_attachment: number;
};

function fromThreat(threat: Threat): CSVThreatEntry {
  return {
    subject: threat.subject,
    from_email: threat.sender.email,
    from_name: threat.sender.name,
    report_date: threat.reportDate,
    reporter_email: threat?.reporter?.email,
    feedback_type: threat.feedback === null ? "unknown" : threat.feedback.type,
    count_attachment: threat.hasAttachments ? 1 : 0,
    feedback_giver_email: threat.feedback === null ? "" : threat.feedback.email,
    feedback_date:
      threat.feedback?.date === null
        ? null
        : new Date(Date.parse(threat?.feedback?.date)),
  };
}

export function downloadThreatCSV(threats: Threat[]) {
  downloadAsCSV(
    dataToCSV(threats.map(fromThreat)),
    `threats-${dayjs().format("DD-MM-YYYY")}.csv`,
  );
}
