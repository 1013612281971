import React from "react";
import { useTranslation } from "react-i18next";

import { Card, CardContent, CardHeader } from "@mui/material";

import { SignatureManager } from "@/pages/phishing-simulation/company/companysignature/SignatureManager";

export function CompanySignature() {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("Company Signature")} />
      <CardContent>
        <SignatureManager />
      </CardContent>
    </Card>
  );
}
