import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import BookIcon from "@mui/icons-material/Book";
import PeopleIcon from "@mui/icons-material/People";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import {
  selectCourses,
  selectDepartments,
  selectFilterDepartments,
  selectFilterSharingPolicy,
  setFilterDepartments,
  setFilterSharingPolicy,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { FilterAssignment } from "@/pages/awareness/courses/utils/filterAssignmentEnum";
import { AllowedSharingPolicy } from "@/pages/awareness/courses/utils/sharingPolicies";
import { useUserContext } from "@/utils/contexts/UserContext";

export const CourseFilterBar = () => {
  const user = useUserContext();
  const dispatch = useDispatch();

  const selectedAssignmentFilter = useSelector(selectFilterDepartments);
  const selectedSharingPolicy = useSelector(selectFilterSharingPolicy);
  const allDepartments = useSelector(selectDepartments);
  const allCourses = useSelector(selectCourses);
  const { t } = useTranslation();

  const onChangeFilterAssignment = (e) => {
    dispatch(setFilterDepartments(e.target.value));
  };

  const onChangeSelectSharingPolicy = (e) => {
    dispatch(setFilterSharingPolicy(e.target.value));
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "flex-start", paddingTop: "15px" }}
    >
      <FormControl sx={{ minWidth: "270px" }}>
        <InputLabel id="assignment-filters-select-label">
          {t("Assignment filter")}
        </InputLabel>
        <Select
          startAdornment={<PeopleIcon sx={{ marginRight: "10px" }} />}
          labelId="assignment-filter-label"
          id="assignment-filter-simple-select"
          label="Assignment filter"
          value={selectedAssignmentFilter}
          size={"small"}
          onChange={onChangeFilterAssignment}
          sx={{ borderRadius: "10px", width: "250px" }}
          renderValue={(selected) => {
            if (selected === FilterAssignment.NoFilter) {
              return "";
            }
            const selectedItem = [
              { id: FilterAssignment.NotAssigned, name: "Not assigned" },
              { id: FilterAssignment.Assigned, name: "Assigned" },
              { id: FilterAssignment.AllDepartments, name: "All departments" },
              { id: FilterAssignment.Everyone, name: "Everyone" },
              ...allDepartments,
            ].find((item) => item.id === selected);
            return selectedItem ? selectedItem.name : "";
          }}
        >
          <MenuItem
            key={"no-assignment-filter"}
            id={"select-no-assignment-filter"}
            value={FilterAssignment.NoFilter}
          >
            {t("No filter")}
          </MenuItem>

          <MenuItem
            key={"not-assigned"}
            id={"select-not-assigned"}
            value={FilterAssignment.NotAssigned}
          >
            {t("Not assigned")}
          </MenuItem>

          <MenuItem
            key={"assigned"}
            id={"select-assigned"}
            value={FilterAssignment.Assigned}
          >
            {t("Assigned")}
          </MenuItem>

          <MenuItem
            key={"all-departments"}
            id={"select-all-departments"}
            value={FilterAssignment.AllDepartments}
          >
            {t("All departments")}
          </MenuItem>

          <MenuItem
            key={"everyone"}
            id={"select-everyone"}
            value={FilterAssignment.Everyone}
          >
            {t("Everyone")}
          </MenuItem>

          {allDepartments.map((department) => (
            <MenuItem key={department.id} value={department.id}>
              {department.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="course-owner-select-label">
          {t("Type of Course")}
        </InputLabel>
        <Select
          startAdornment={<BookIcon sx={{ marginRight: "10px" }} />}
          labelId="course_select-label"
          id="course-simple-select"
          value={selectedSharingPolicy}
          label={t("Type of Course")}
          size={"small"}
          onChange={onChangeSelectSharingPolicy}
          sx={{ borderRadius: "10px", width: "20rem" }}
        >
          <MenuItem
            id={"select-all-courses"}
            value={AllowedSharingPolicy.NO_POLICY}
          >
            {t("All Courses")}
          </MenuItem>
          <MenuItem id={"select-mantra"} value={AllowedSharingPolicy.ALL}>
            {t("Provided by Mantra")}
          </MenuItem>
          {allCourses.some(
            (c) =>
              c.sharing_policy === AllowedSharingPolicy.COMPANIES_OF_CORPORATE,
          ) && (
            <MenuItem
              id={"select-corporate"}
              value={AllowedSharingPolicy.COMPANIES_OF_CORPORATE}
            >
              {t("Corporate Courses")}
            </MenuItem>
          )}
          <MenuItem
            id={"select-company"}
            value={AllowedSharingPolicy.COMPANY_ONLY}
          >
            {t("{{companyName}} Courses", {
              companyName: user.current_company?.name ?? t("Custom"),
            })}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
