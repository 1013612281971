import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import { Box, Typography } from "@mui/material";

const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

const rgbToHex = (color) => {
  return (
    "#" +
    componentToHex(color.r) +
    componentToHex(color.g) +
    componentToHex(color.b)
  );
};

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function randomColors(number) {
  let startRGB = hexToRgb("#6495ED");
  return Array.from({ length: number }, (x, i) => {
    if (i === 0) {
      return rgbToHex(startRGB);
    }

    const tmp = {
      r: Math.floor((i * startRGB.r) / number),
      g: Math.floor((i * startRGB.g) / number),
      b: Math.floor((i * startRGB.b) / number),
    };

    return rgbToHex(tmp);
  });
}

const renderTooltipContent = (props) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    const dataPiece = payload[0].payload;
    return (
      <div
        style={{
          background: "white",
          padding: "8px",
          border: "1px solid #ccc",
        }}
      >
        <Typography>{dataPiece.name}</Typography>
        <Box display="flex" gap={1} sx={{ alignItems: "center" }}>
          <Box
            sx={{
              aspectRatio: "1/1",
              height: "11px",
              borderRadius: "50%",
              border: "1px solid white",
              boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.75)",
              backgroundColor: dataPiece.color,
            }}
          />
          <Typography variant="h5">{dataPiece.value}</Typography>
        </Box>
      </div>
    );
  }

  return null;
};

export default function DoughnutGraph({
  data,
  labelMapping = {},
  excluded = [],
  onClickCallbackAction = null,
}) {
  let colors = randomColors(Object.keys(data).length);

  const transformedData = Object.keys(data)
    .filter((key) => !excluded.includes(key) && data[key] > 0)
    .map((key, index) => {
      return {
        name: labelMapping[key] ? labelMapping[key].label : key,
        label: key,
        color: labelMapping[key] ? labelMapping[key].color : colors[index],
        value: data[key],
      };
    });

  function customAction(value) {
    if (!onClickCallbackAction) {
      return;
    }

    onClickCallbackAction(value);
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie
            data={transformedData}
            nameKey="name"
            dataKey="value"
            label={renderCustomizedLabel}
            labelLine={false}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={100}
          >
            {transformedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color ? entry.color : "inherit"}
              />
            ))}
          </Pie>
          <Tooltip content={renderTooltipContent} />
        </PieChart>
      </ResponsiveContainer>
      <Box display="flex" sx={{ justifyContent: "space-around" }}>
        {transformedData.map((dataPiece, index) => (
          <div
            key={index}
            onClick={() => customAction(dataPiece.label)}
            style={{ cursor: "pointer" }}
          >
            <Typography>{dataPiece.name}</Typography>
            <Box display="flex" gap={1} sx={{ alignItems: "center" }}>
              <Box
                sx={{
                  aspectRatio: "1/1",
                  height: "11px",
                  borderRadius: "50%",
                  border: "1px solid white",
                  boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.75)",
                  backgroundColor: dataPiece.color,
                }}
              />
              <Typography variant="h5">{dataPiece.value}</Typography>
            </Box>
          </div>
        ))}
      </Box>
    </>
  );
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
