import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";

import { useImportJsonMutation } from "@/api";
import {
  selectCourseId,
  selectCurrentLanguage,
  selectCurrentLocalizedCourse,
  selectHasInteractions,
  setLocalizedCourseDraftForLanguage,
  toggleEditMode,
} from "@/pages/awareness/courses/course-editor/courseSlice";
import { languageDisplayName } from "@/utils/misc";

const ToolbarImportJsonButton = () => {
  const currentLanguage = useSelector(selectCurrentLanguage);
  const courseId = useSelector(selectCourseId);
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  const hasInteractions = useSelector(selectHasInteractions);
  const { t } = useTranslation();
  const canDeployChange =
    hasInteractions &&
    localizedCourse?.name?.length > 0 &&
    localizedCourse?.description?.length > 0;

  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [importJson] = useImportJsonMutation();
  const [openUploadJsonDialog, setOpenUploadJsonDialog] = useState(false);
  const [jsonFile, setJsonFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const maxSizeBytes = 5 * 1024 * 1024; // 5MB in bytes

    if (file && file.type === "application/json") {
      if (file.size <= maxSizeBytes) {
        setJsonFile(file);
        setOpenUploadJsonDialog(true);
        event.target.value = null;
      } else {
        snackbar.showMessage("File size exceeds the limit of 5MB.");
      }
    } else {
      snackbar.showMessage("Please upload a valid JSON file.");
    }
  };
  const sendImportedJson = async (file) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const fileContent = e.target.result;
          const jsonData = JSON.parse(String(fileContent));
          jsonData.language = currentLanguage;

          const accessToken = await getAccessTokenSilently();
          const response = await importJson({
            jsonData,
            courseId,
            accessToken,
          });

          // @ts-ignore
          if (response.error) {
            snackbar.showMessage("Failed to upload file.");
          } else {
            // @ts-ignore
            const data = response.data;
            dispatch(
              setLocalizedCourseDraftForLanguage({
                id: data.id,
                language: data.language,
                name: data.name,
                description: data.description,
                course_id: courseId,
                published: false,
                version: 2,
              }),
            );
            dispatch(toggleEditMode());
            snackbar.showMessage("File uploaded successfully!");
          }
        } catch (error) {
          snackbar.showMessage(
            "Error parsing or uploading file, please check the format of your file",
          );
        }
      };
      reader.readAsText(file);
    } catch (error) {
      snackbar.showMessage("Error reading file: " + error.message);
    }
  };

  return (
    <>
      <Tooltip
        title={`Import a JSON file (up to 5MB) to create a draft for this course in ${languageDisplayName(
          currentLanguage,
          false,
        )}`}
        arrow
      >
        <span style={{ height: "30px" }}>
          <input
            accept="application/json"
            style={{ display: "none" }}
            id="upload-json-file"
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="upload-json-file">
            <Button
              size="small"
              variant="outlined"
              color="neutral"
              component="span"
              disabled={!canDeployChange}
              startIcon={<FileDownloadOutlinedIcon />}
            >
              {t("Import JSON")}
            </Button>
          </label>
        </span>
      </Tooltip>
      <Dialog
        open={openUploadJsonDialog}
        onClose={() => setOpenUploadJsonDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span style={{ fontSize: "medium" }}>
            {t("Uploaded files")} &nbsp;
          </span>
          <span style={{ color: "red", fontSize: "small" }}>
            {t("This will create a draft on the {{lang}} course", {
              lang: languageDisplayName(currentLanguage, false),
            })}
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                border: "1px solid #F4F4F6",
                borderRadius: "5px",
                padding: "10px 15px 10px 15px",
                fontSize: "small",
              }}
            >
              <span style={{ color: "black" }}>{jsonFile?.name}</span>{" "}
              <span style={{ color: "#7F8995" }}>
                {formatFileSize(jsonFile?.size)}
              </span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpenUploadJsonDialog(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (jsonFile) {
                sendImportedJson(jsonFile);
                setOpenUploadJsonDialog(false);
              } else {
                snackbar.showMessage(
                  "An unexpected error occured, please try again.",
                );
              }
            }}
            autoFocus
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToolbarImportJsonButton;

const formatFileSize = (size) => {
  if (size < 1024) return `${size} B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
  return `${(size / (1024 * 1024)).toFixed(2)} MB`;
};
