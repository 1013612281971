import React from "react";
import { useTranslation } from "react-i18next";

import { useUsersSimulationLevelsQuery } from "@/apis/usersApi";
import { StatsCard } from "@/components/dashboard/StatsCard";
import UserPerLevelChart from "@/pages/phishing-simulation/dashboard/UserPerLevelChart";

export default function UserPerLevel({ department, activeUsersOnly }) {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useUsersSimulationLevelsQuery({
    department: department,
    active_only: activeUsersOnly,
  });

  return (
    <StatsCard
      title={t("Users per level (last 6 months)")}
      isLoading={isLoading}
      isError={isError}
    >
      <UserPerLevelChart levels={data} />
    </StatsCard>
  );
}
