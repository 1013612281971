import React from "react";
import { useTranslation } from "react-i18next";

import InventoryIcon from "@mui/icons-material/Inventory";
import { Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

import LabelItem from "@/pages/browser-defender/passwordMisuse/domain-label/LabelItem";
import { passwordMisuseDomainLabelButtonOptions } from "@/pages/browser-defender/passwordMisuse/domain-label/configuration";
import { capitalize } from "@/utils/strings";

type OpenState = {
  reuse?: boolean;
  safe?: boolean;
  blocked?: boolean;
  archive?: boolean;
};

type OpenStateKeys = keyof OpenState;

export default function ButtonWithDropdown({
  archivePossible,
  labelActionPossible,
  labelDomains,
  handleAlerts,
  currentTab,
  selectionCounts,
}) {
  const { t } = useTranslation();
  const labellingOptions = {
    noAction: {
      title: t("Don't %ACTION_LOWER% any alert"),
      description: t(
        "Label all events related to this domain and do not %ACTION_LOWER% any of them",
      ),
      apiAttribute: "no_action",
    },
    archiveSelected: {
      title: t("%ACTION_UPPER% selection"),
      description: t(
        "Label all events related to this domain and %ACTION_LOWER% %SELECTED_COUNT% selected alerts",
      ),
      apiAttribute: "%ACTION_LOWER%_selected",
    },
    archiveSimilarByUserAndDomain: {
      title: t("%ACTION_UPPER% related alerts for this user and domain"),
      description: t(
        "Label all events related to this domain and %ACTION_LOWER% %RELATED_COMBINED_COUNT% related domain and user alerts",
      ),
      apiAttribute: "%ACTION_LOWER%_similar_by_user_and_domain",
    },
    archiveSimilarByDomain: {
      title: t("%ACTION_UPPER% related alerts for domain"),
      description: t(
        "Label all events related to this domain and %ACTION_LOWER% %RELATED_DOMAIN_COUNT% related domain alerts",
      ),
      apiAttribute: "%ACTION_LOWER%_similar_by_domain",
    },
  };

  const [open, setOpen] = React.useState<OpenState>({
    reuse: false,
    safe: false,
    blocked: false,
    archive: false,
  });

  const anchorRef = React.useRef(null);
  const anchorRefArchive = React.useRef(null);

  const currentTabAction =
    currentTab === "archived" ? t("unarchive") : t("archive");
  const currentTabActionUppercase = capitalize(currentTabAction);

  const handleToggle = (key: OpenStateKeys) => {
    setOpen((prevOpen) =>
      Object.keys(prevOpen).reduce((acc, k) => {
        acc[k] = k === key ? !prevOpen[k] : false;
        return acc;
      }, {}),
    );
  };

  const handleClose = (key: OpenStateKeys) => {
    setOpen((prevOpen) =>
      Object.keys(prevOpen).reduce((acc, k) => {
        acc[k] = k === key ? false : prevOpen[k];
        return acc;
      }, {}),
    );
  };

  function applyAction(label, action) {
    labelDomains(label, action);
    handleClose(label);
  }

  return (
    <>
      <Tooltip
        title={
          labelActionPossible ? "" : t("You must select at least one event")
        }
      >
        <ButtonGroup
          variant="outlined"
          ref={anchorRef}
          disabled={!labelActionPossible}
        >
          {passwordMisuseDomainLabelButtonOptions.map((item) => {
            const key = item.key;
            const options = item.value;
            return (
              <React.Fragment key={key}>
                <Button
                  size="small"
                  aria-controls={open[key] ? "split-button-menu" : undefined}
                  aria-expanded={open[key] ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={() => handleToggle(key)}
                >
                  <LabelItem
                    icon={options.icon}
                    title={options.title}
                    description={options.description}
                    disabled={!labelActionPossible}
                  />
                </Button>
                <Popper
                  open={open[key]}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper
                        style={{
                          minWidth: anchorRef.current
                            ? anchorRef.current.clientWidth
                            : undefined,
                        }}
                      >
                        <ClickAwayListener onClickAway={() => handleClose(key)}>
                          <div>
                            <Typography
                              style={{
                                padding: "8px 16px",
                                fontWeight: "bold",
                                fontSize: "1.1rem",
                                textAlign: "left",
                              }}
                            >
                              {options.description}
                            </Typography>
                            <Divider />
                            <MenuList id="split-button-menu" autoFocusItem>
                              {Object.keys(labellingOptions).map((k) => {
                                let description = labellingOptions[
                                  k
                                ].description.replace(
                                  "%ACTION_LOWER%",
                                  currentTabAction,
                                );
                                let title = labellingOptions[k].title
                                  .replace(
                                    "%ACTION_UPPER%",
                                    currentTabActionUppercase,
                                  )
                                  .replace("%ACTION_LOWER%", currentTabAction);
                                let apiAttribute = labellingOptions[
                                  k
                                ].apiAttribute.replace(
                                  "%ACTION_LOWER%",
                                  currentTabAction,
                                );

                                if (k === "archiveSelected") {
                                  description = description.replace(
                                    "%SELECTED_COUNT%",
                                    selectionCounts.selectedRows,
                                  );
                                } else if (k === "archiveSimilarByDomain") {
                                  description = description.replace(
                                    "%RELATED_DOMAIN_COUNT%",
                                    selectionCounts.similarByDomain,
                                  );
                                } else if (
                                  k === "archiveSimilarByUserAndDomain"
                                ) {
                                  description = description.replace(
                                    "%RELATED_COMBINED_COUNT%",
                                    selectionCounts.similarByDomainAndUser,
                                  );
                                }

                                return (
                                  <MenuItem
                                    key={`${key}-${k}`}
                                    value={`${key}-${k}`}
                                    onClick={(event) =>
                                      applyAction(key, apiAttribute)
                                    }
                                  >
                                    <div>
                                      <Typography>{title}</Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {description}
                                      </Typography>
                                    </div>
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </React.Fragment>
            );
          })}
        </ButtonGroup>
      </Tooltip>
      <Tooltip
        title={!archivePossible && t("All selected domains must be labeled")}
      >
        <>
          <Button
            variant="outlined"
            size="small"
            aria-controls={open["archive"] ? "split-button-menu" : undefined}
            aria-expanded={open["archive"] ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            ref={anchorRefArchive}
            disabled={!archivePossible}
            onClick={() => handleToggle("archive")}
          >
            <LabelItem
              icon={<InventoryIcon style={{ color: "#4445F4" }} />}
              title={
                currentTab === "unarchived" ? t("Archive") : t("Unarchive")
              }
              description={t("Archive alerts")}
              disabled={!archivePossible}
            />
          </Button>
          <Popper
            open={open["archive"]}
            anchorEl={anchorRefArchive.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  style={{
                    minWidth: anchorRefArchive.current
                      ? anchorRefArchive.current.clientWidth
                      : undefined,
                  }}
                >
                  <ClickAwayListener onClickAway={() => handleClose("archive")}>
                    <div>
                      <MenuList autoFocusItem>
                        <MenuItem
                          key="archiveSelection"
                          value="archiveSelection"
                          onClick={(event) =>
                            handleAlerts(
                              currentTab === "unarchived"
                                ? "archive"
                                : "unarchive",
                              "archive_selected",
                            )
                          }
                        >
                          <div>
                            <Typography>
                              {(currentTab === "unarchived"
                                ? t("Archive") + " "
                                : t("Unarchive") + " ") +
                                "%SELECTED_COUNT% selected alerts".replace(
                                  "%SELECTED_COUNT%",
                                  selectionCounts.selectedRows,
                                )}
                            </Typography>
                          </div>
                        </MenuItem>
                        <MenuItem
                          key="archiveSimilarByUserAndDomain"
                          value="archiveSimilarByUserAndDomain"
                          onClick={(event) =>
                            handleAlerts(
                              currentTab === "unarchived"
                                ? "archive"
                                : "unarchive",
                              "archive_similar_by_user_and_domain",
                            )
                          }
                        >
                          <div>
                            <Typography>
                              {(currentTab === "unarchived"
                                ? t("Archive") + " "
                                : t("Unarchive") + " ") +
                                "%RELATED_COMBINED_COUNT% related domain and user alerts".replace(
                                  "%RELATED_COMBINED_COUNT%",
                                  selectionCounts.similarByDomainAndUser,
                                )}
                            </Typography>
                          </div>
                        </MenuItem>
                        <MenuItem
                          key="archiveSimilarByDomain"
                          value="archiveSimilarByDomain"
                          onClick={(event) =>
                            handleAlerts(
                              currentTab === "unarchived"
                                ? "archive"
                                : "unarchive",
                              "archive_similar_by_domain",
                            )
                          }
                        >
                          <div>
                            <Typography>
                              {(currentTab === "unarchived"
                                ? t("Archive") + " "
                                : t("Unarchive") + " ") +
                                "%RELATED_DOMAIN_COUNT% related domain alerts".replace(
                                  "%RELATED_DOMAIN_COUNT%",
                                  selectionCounts.similarByDomain,
                                )}
                            </Typography>
                          </div>
                        </MenuItem>
                      </MenuList>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      </Tooltip>
    </>
  );
}
