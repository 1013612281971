import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { threatsApi } from "@/pages/threats/api";
import { ThreatDetails } from "@/pages/threats/service/domain";
import { ThreatId } from "@/pages/threats/service/types";
import { RootState } from "@/store";

type ThreatState = {
  detail: {
    id: ThreatId;
    details: ThreatDetails;
  } | null;
  threatDetailIsLoading: boolean;
  expandedThreats: ThreatId[];
  selectedThreats: ThreatId[];
};

const initialState: ThreatState = {
  threatDetailIsLoading: false,
  expandedThreats: [],
  selectedThreats: [],
  detail: null,
};

const threatSlice = createSlice({
  name: "threats",
  initialState: initialState,
  reducers: {
    expandThreat: (state, action: PayloadAction<ThreatId>) => {
      state.expandedThreats.push(action.payload);
    },

    addThreatToSelection: (state, action: PayloadAction<ThreatId>) => {
      state.selectedThreats.push(action.payload);
    },
    removeThreatFromSelection: (state, action: PayloadAction<ThreatId>) => {
      const index = state.selectedThreats.findIndex(
        (id) => id === action.payload,
      );
      if (index !== -1) state.selectedThreats.splice(index, 1);
    },
    resetThreatSelection: (state) => {
      state.selectedThreats = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(displayThreatDetails.fulfilled, (state, action) => {
        state.detail = action.payload;
      })
      .addMatcher(
        threatsApi.endpoints.threatDetails.matchPending,
        (state, action) => {
          state.threatDetailIsLoading = true;
        },
      )
      .addMatcher(
        threatsApi.endpoints.threatDetails.matchFulfilled,
        (state, action) => {
          state.threatDetailIsLoading = false;
        },
      );
  },
});

export const selectDetails = (state: RootState) => state.threats.detail;

export const selectDetailIsLoading = (state: RootState) =>
  state.threats.threatDetailIsLoading;

export const selectSelectedThreatsIds = createSelector(
  [
    (state: RootState) => state.threats.detail,
    (state: RootState) => state.threats.selectedThreats,
  ],
  (details, selectedIds: ThreatId[]): ThreatId[] => {
    if (details)
      if (!selectedIds.includes(details.id))
        return [details.id, ...selectedIds];
    return selectedIds;
  },
);

export const selectIsThreatSelected =
  (threatId: number) => (state: RootState) =>
    state.threats.selectedThreats.includes(threatId);

export const selectIsThreatExpanded =
  (threatId: number) => (state: RootState) =>
    state.threats.expandedThreats.includes(threatId);

export const {
  removeThreatFromSelection,
  addThreatToSelection,
  resetThreatSelection,
  expandThreat,
} = threatSlice.actions;
export const threatReducer = threatSlice.reducer;

export const displayThreatDetails = createAsyncThunk(
  "threats/displayThreatDetails",
  async (threatId: ThreatId | null, thunkAPI) => {
    if (!threatId) {
      return null;
    }

    const promise = thunkAPI.dispatch(
      threatsApi.endpoints.threatDetails.initiate(threatId),
    );

    const { data } = await promise;
    return { id: threatId, details: data };
  },
);
