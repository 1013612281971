import React from "react";

import DoughnutGraph from "@/pages/browser-defender/components/DoughnutGraph";
import GenericCard from "@/pages/browser-defender/components/GenericCard";

export function AlertsStatsCardDemo({ user_email }) {
  const data = {
    period_in_weeks: 2,
    email: user_email,
    total_alerts: 12,
    stats: [
      {
        value: "dangerous_downloads",
        label: "Dangerous download",
        count: 4,
        percentage: 33,
      },
      {
        value: "unsafe_navigation",
        label: "Unsafe navigation",
        count: 2,
        percentage: 17,
      },
      {
        value: "password_misuse",
        label: "Password alerts",
        count: 6,
        percentage: 50,
      },
    ],
  };
  return (
    <GenericCard title={"Types of alerts (last 2 weeks)"} isLoading={false}>
      <DoughnutGraph
        data={
          Object.keys(data.stats).length > 0 &&
          data.stats.reduce((acc, item) => {
            acc[item.value] = item.count;
            return acc;
          }, {})
        }
        labelMapping={{
          password_misuse: { label: "Credentials", color: "#f57040" },
          dangerous_downloads: { label: "Downloads", color: "#ee97a7" },
          unsafe_navigation: { label: "Browsing", color: "#e76b82" },
        }}
      />
    </GenericCard>
  );
}
