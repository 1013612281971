import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { ButtonSpinner } from "@/components/Spinner";
import { selectIsMantraUser } from "@/utils/contexts/AuthSlice";
import { languageDisplayName } from "@/utils/misc";

import {
  Modal,
  ModalContent,
  ModalTitle,
} from "../../../../components/modals/Modal";
import DeleteCourseModalStyle from "./DeleteCourseModal.module.css";
import {
  selectCurrentLocalizedCourse,
  selectDraftLocalizedCourses,
} from "./courseSlice";

export function PublishLocalizedCourseModal({
  show,
  close,
  action,
  isLoading,
}) {
  const { t } = useTranslation();
  const draftLocalizedCourses = useSelector(selectDraftLocalizedCourses);
  const currentLocalizedCourse = useSelector(selectCurrentLocalizedCourse);
  const [value, setValue] = useState("all");
  const isMantraUser = useSelector(selectIsMantraUser);

  const languagesNames = (Object.keys(draftLocalizedCourses) ?? [])
    .map((language) => {
      return languageDisplayName(language, isMantraUser);
    })
    .join(", ");

  return (
    <Modal show={show} close={close}>
      <ModalTitle>
        <h3>{t("What do you want to do?")}</h3>
      </ModalTitle>
      <ModalContent className={DeleteCourseModalStyle.content}>
        <Box sx={{ marginLeft: "1rem" }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={t("Publish all existing drafts ({{languagesNames}})", {
                  languagesNames: languagesNames,
                })}
              />
              <FormControlLabel
                value="some"
                control={<Radio />}
                label={t("Publish {{display_language}} only", {
                  display_language: languageDisplayName(
                    currentLocalizedCourse?.language,
                    isMantraUser,
                  ),
                })}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <div className={DeleteCourseModalStyle.buttonsPanel}>
          <Button variant="contained" color="primary" onClick={close}>
            {t("Cancel")}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => action(value === "all")}
            disabled={isLoading}
          >
            <div className="d-flex align-items-center">
              {isLoading ? (
                <ButtonSpinner className="mr-2" color="error" />
              ) : (
                ""
              )}
              {t("Publish")}
            </div>
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}
