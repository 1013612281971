import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { useSaveCompanySafeSendersMutation } from "@/pages/banners/remote-apis/bannerUsersApi";
import { ContactType } from "@/pages/banners/remote-apis/contact_types";
import { useUserContext } from "@/utils/contexts/UserContext";
import { validateDomainName, validateEmail } from "@/utils/misc";

function ErrorMessage({ display, content }) {
  return (
    <i
      style={{
        color: "red",
        visibility: display === false ? "hidden" : "visible", // Change visibility based on the value of display
      }}
    >
      {" "}
      {content}
    </i>
  );
}

const success = () => {
  enqueueSnackbar("Item(s) added to safe lists.", {
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
    autoHideDuration: 7000,
    variant: "success",
  });
};

export default function ModalAddSafeList({
  confirmationDialogOpen,
  setConfirmationDialogOpen,
  contactType,
}) {
  const { t } = useTranslation();
  const user = useUserContext();

  const [saveCompanySafeSenders] = useSaveCompanySafeSendersMutation();

  const [contentSafeSenders, setContentSafeSenders] = useState("");
  const [displayMessageError, setDisplayMessageError] =
    useState<boolean>(false);

  const handleClose = () => {
    setConfirmationDialogOpen(false);
  };

  const onSaveEmailAddresses = async () => {
    const emailAddresses = contentSafeSenders
      .split("\n")
      .map((e) => e.trim())
      .filter((e) => e !== "");

    const cleanList = emailAddresses.filter(validateEmail);
    if (emailAddresses.length > cleanList.length) {
      setDisplayMessageError(true);
    } else {
      await saveCompanySafeSenders({
        companyId: user.current_company.id,
        items: emailAddresses,
        type: ContactType.email_address,
      });
      success();
      handleClose();
    }
  };
  const onSaveDomains = async () => {
    const domainsList = contentSafeSenders
      .split("\n")
      .map((e) => e.trim())
      .map((e) => e.toLowerCase())
      .filter((e) => e !== "");

    const cleanList = domainsList.filter(validateDomainName);
    if (domainsList.length > cleanList.length) {
      setDisplayMessageError(true);
    } else {
      await saveCompanySafeSenders({
        companyId: user.current_company.id,
        items: cleanList,
        type: ContactType.domain,
      });
      success();
      handleClose();
    }
  };

  const config = {
    [ContactType.email_address]: {
      dialogTypeTitle: t("email addresses"),
      dialogTypeTitleSingular: t("email address"),
      exampleContent:
        "bob@mozilla.org\njohn@safe-company.com\nhello@mail.example.com",
      action: onSaveEmailAddresses,
    },
    [ContactType.domain]: {
      dialogTypeTitlePlural: t("domains"),
      dialogTypeTitleSingular: t("domain"),
      exampleContent: "mozilla.org\neff.org",
      action: onSaveDomains,
    },
  };

  const onSave: () => void = config[contactType].action;
  const dialogTypeTitlePlural: string =
    config[contactType].dialogTypeTitlePlural;
  const dialogTypeTitleSingular = config[contactType].dialogTypeTitleSingular;
  const exampleContent: string = config[contactType].exampleContent;

  return (
    <Dialog
      open={confirmationDialogOpen}
      onClose={handleClose}
      PaperProps={{ sx: { width: "40%", maxHeight: 300 } }}
    >
      <DialogTitle>
        {t("Add {{dialogTypeTitlePlural}} to safe list", {
          dialogTypeTitlePlural: dialogTypeTitlePlural,
        })}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <i style={{ color: "grey", paddingBottom: "10px" }}>
            {t("Put each {{dialogTypeTitleSingular}} on a line.", {
              dialogTypeTitleSingular: dialogTypeTitleSingular,
            })}
          </i>
          <textarea
            style={{ width: "100%", height: "150px" }}
            onChange={(e) => setContentSafeSenders(e.target.value)}
            placeholder={exampleContent}
          ></textarea>

          <ErrorMessage
            display={displayMessageError}
            content={t("Items must be {{dialogTypeTitlePlural}}", {
              dialogTypeTitlePlural: dialogTypeTitlePlural,
            })}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="warning"
          autoFocus
        >
          {t("Cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={() => onSave()}>
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
