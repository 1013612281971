import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExtensionOffIcon from "@mui/icons-material/ExtensionOff";
import { Grid, TableContainer, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import InstallTypeChip from "@/pages/browser-defender/extensionGovernance/InstallTypeChip";

export default function UsersTable({ users, riskColors }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = useTranslation();

  useEffect(() => {
    setPage(0);
  }, [users]);

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t("Email")}</TableCell>
            <TableCell align="center">{t("Version")}</TableCell>
            <TableCell align="center">{t("Install mode")}</TableCell>
            <TableCell align="center">{t("Status")}</TableCell>
            <TableCell align="center">{t("Browser")}</TableCell>
            <TableCell>{t("Last detected")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : users
          ).map((user, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {user.email}
              </TableCell>
              <TableCell align="center">{user.version}</TableCell>
              <TableCell align="center">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <InstallTypeChip user={user} riskColors={riskColors} />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                {user.uninstalled ? (
                  <Tooltip title={t("Uninstalled")}>
                    <DeleteForeverIcon
                      sx={{ color: "#F6285F" }}
                      style={{ cursor: "help" }}
                    />
                  </Tooltip>
                ) : user.enabled ? (
                  <Tooltip title={t("Enabled")}>
                    <CheckIcon
                      sx={{ color: "#65c58b" }}
                      style={{ cursor: "help" }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={t("Disabled")}>
                    <ExtensionOffIcon
                      sx={{ color: "#D3D3D3" }}
                      style={{ cursor: "help" }}
                    />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>{user.browser}</TableCell>
              <TableCell>{user.latest_detection}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        labelRowsPerPage={t("Rows per page") + " :"}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={users.length <= rowsPerPage ? 0 : page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={(_, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </TableContainer>
  );
}
