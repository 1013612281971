import React from "react";

import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import Box from "@mui/material/Box";

import { Attachment } from "@/pages/threats/service/domain";

type AttachmentsProps = {
  attachments: Attachment[];
};

const Attachments = ({ attachments }: AttachmentsProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {attachments.map((attachment, index) => (
        <Box
          key={index}
          sx={{
            margin: "4px",
            padding: "3px",
            borderRadius: "3px",
            backgroundColor: "#f7f7f7",
            color: "#737373",
          }}
        >
          <AttachmentOutlinedIcon sx={{ marginRight: "2px" }} />
          {attachment.name}
        </Box>
      ))}
    </Box>
  );
};

export default Attachments;
