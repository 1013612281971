import React from "react";
import { useTranslation } from "react-i18next";

import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";

import { capitalize } from "@/utils/strings";

import { MessagingPlatform } from "../../SetupChecklistTypes";

type SelectMessagingPlatformProps = {
  messagingPlatform: MessagingPlatform | "";
  setMessagingPlatform: (m: MessagingPlatform | "") => void;
};

const SelectMessagingPlatform = ({
  messagingPlatform,
  setMessagingPlatform,
}: SelectMessagingPlatformProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography>
        {t("Please select a messaging platform in the list below")}
      </Typography>
      <Box width="15rem">
        <Select
          sx={{ width: "100%" }}
          value={messagingPlatform}
          onChange={(e) =>
            setMessagingPlatform(e.target.value as MessagingPlatform)
          }
        >
          <MenuItem value=""></MenuItem>
          {Object.values(MessagingPlatform).map((messagingPlatform) => (
            <MenuItem key={messagingPlatform} value={messagingPlatform}>
              {capitalize(messagingPlatform)}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Stack>
  );
};

export default SelectMessagingPlatform;
