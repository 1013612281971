import React from "react";
import { useTranslation } from "react-i18next";

import { Tab, Tabs } from "@mui/material";

export type DetailsTabType = "body" | "headers";

const DetailsTabs = ({
  onChange,
  activeTab,
}: {
  onChange: (tab: DetailsTabType) => void;
  activeTab: DetailsTabType;
}) => {
  const { t } = useTranslation();

  return (
    <Tabs
      textColor="inherit"
      value={activeTab}
      onChange={(_, nextTab) => onChange(nextTab)}
      sx={{ minHeight: "45px", height: "45px" }}
    >
      <Tab disableRipple label={t("Body")} value="body" />
      <Tab disableRipple label={t("Headers")} value="headers" />
    </Tabs>
  );
};

export default DetailsTabs;
