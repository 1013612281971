import clsx from "clsx";
import React, { ReactNode } from "react";

import { CancelIcon } from "./icons/CancelIcon";
import { CompletedIcon } from "./icons/CompletedIcon";
import {
  DoubleCircleIcon,
  GreyDoubleCircleIcon,
} from "./icons/DoubleCircleIcon";
import { WarningIcon } from "./icons/WarningIcon";
import "./style.css";

export type StepStatusType =
  | "active"
  | "uncompleted"
  | "succeeded"
  | "warning"
  | "error";

export type StepType = {
  label: ReactNode;
  status: StepStatusType;
  disablePrevNextButtonPane?: boolean;
};

export type StepperType = {
  activeIndex: number;
  steps: StepType[];
  disableChangeStepOnClick: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>, index: number) => void;
};

export type SetupStep = StepType & { mandatory: boolean };

const stepIcon = (
  step: Partial<StepType>,
  index: number,
  activeIndex: number,
) => {
  if (activeIndex === index && step.status === "uncompleted") {
    return <DoubleCircleIcon style={{ zIndex: 2, position: "relative" }} />;
  }
  switch (step.status) {
    case "active":
      return <DoubleCircleIcon style={{ zIndex: 2, position: "relative" }} />;
    case "uncompleted":
      return (
        <GreyDoubleCircleIcon style={{ zIndex: 2, position: "relative" }} />
      );
    case "succeeded":
      return (
        <CompletedIcon
          style={{ zIndex: 2, position: "relative", background: "#fff" }}
        />
      );
    case "warning":
      return (
        <WarningIcon
          style={{ zIndex: 2, position: "relative", background: "#fff" }}
        />
      );
    case "error":
      return (
        <CancelIcon color="error" style={{ zIndex: 2, position: "relative" }} />
      );
  }
};

const stepperStatusClassName = (
  step: StepType,
  index: number,
  activeIndex: number,
) => {
  let className = "";
  if (activeIndex === index) {
    className += "stepper-status-active";
  }
  className += step.status ? ` stepper-status-${step.status}` : "";
  return className;
};

const Stepper = ({
  steps,
  activeIndex,
  onClick,
  disableChangeStepOnClick,
}: StepperType) => {
  return (
    <div className="stepper">
      {steps.map((step, index) => {
        return (
          <div key={index} className="stepper-step">
            <div>
              {index > 0 ? (
                <div className="stepper-bar-wrapper">
                  <div
                    className={clsx(
                      "stepper-bar",
                      activeIndex >= index ? "stepper-bar-completed" : false,
                    )}
                  />
                </div>
              ) : null}
              {stepIcon(step, index, activeIndex)}
            </div>
            <div
              className={clsx(
                "stepper-label-wrapper",
                stepperStatusClassName(step, index, activeIndex),
              )}
            >
              <div
                className={
                  disableChangeStepOnClick ? "" : "stepper-label-pointer"
                }
                onClick={
                  onClick != null ? (e) => onClick(e, index) : () => undefined
                }
              >
                {step.label}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
