import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { WarningAmberOutlined } from "@mui/icons-material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { ChecklistPane } from "@/components/stepper/ChecklistPane";
import { useStatusQuery } from "@/pages/banners/setup/BannerSetupChecklist";
import { useUserContext } from "@/utils/contexts/UserContext";

type CompanySetupProps = {
  title: ReactNode;
};

export const CompanySetup: React.FC<CompanySetupProps> = ({
  title,
}: CompanySetupProps) => {
  const { t } = useTranslation();
  const { current_company } = useUserContext();
  const validController = current_company.banners_controller !== null;

  const { data, isLoading, isError } = useStatusQuery();

  if (isError) return <>{t("Error")}</>;

  if (isLoading) return <>{t("Loading")}</>;
  return (
    <ChecklistPane title={title}>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Typography>{t("Controller")}</Typography>
        <Typography>
          {validController ? (
            <>
              {current_company.banners_controller}{" "}
              <CheckCircleOutlinedIcon color="success" />
            </>
          ) : (
            <WarningAmberOutlinedIcon sx={{ fill: "#fe9534" }} />
          )}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap="2.5rem">
        {data.company_in_catalogue ? (
          <>
            <Typography>{t("Company is setup for Banners")}</Typography>
            <CheckCircleOutlinedIcon color="success" />
          </>
        ) : (
          <>
            <Typography>{t("Company is setup for Banners")}</Typography>
            <WarningAmberOutlined sx={{ fill: "#fe9534" }} />
          </>
        )}
      </Box>
    </ChecklistPane>
  );
};
