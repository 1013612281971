import React from "react";

import { Box } from "@mui/material";

import { setIFrameHeight } from "@/utils/misc";

const EmailBody = ({ body }: { body: string }) => {
  return (
    <>
      <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
        <iframe
          sandbox="allow-same-origin"
          width="100%"
          title="Threat view"
          onLoad={(e) => setIFrameHeight(e.target as HTMLIFrameElement)}
          onChange={(e) => setIFrameHeight(e.target as HTMLIFrameElement)}
          srcDoc={body}
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            outline: 0,
          }}
        />
      </Box>
    </>
  );
};
export default EmailBody;
