import React, { Children, PropsWithChildren, ReactNode } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";

function findSlotOfType(children: ReactNode, slotType) {
  return Children.toArray(children).find((child) => {
    // @ts-ignore
    return child?.type?.name === slotType.name;
  });
}

export const ThreatsTitlePane = (props: PropsWithChildren) => {
  return <>{props.children}</>;
};

export const ThreatsDetailsPane = (props: PropsWithChildren) => {
  return <>{props.children}</>;
};

export const ThreatsListPane = (props: PropsWithChildren) => {
  return <>{props.children}</>;
};

export const ThreatsToolPane = (props: PropsWithChildren) => {
  return <>{props.children}</>;
};

export const ThreatsPaginationPane = (props: PropsWithChildren) => {
  return <>{props.children}</>;
};

const ListPane = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  flexGrow: 0.8,
});

const DetailsPane = styled(Box)({
  flex: 1,
  padding: ".5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  borderLeft: 1,
});

const Toolbar = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: "0.7rem",
  margin: "1rem",
  flex: "0 1 auto",
});

export const ThreatsLayout = ({ children }: PropsWithChildren) => {
  const TitlePaneContent = findSlotOfType(children, ThreatsTitlePane);
  const ListPaneContent = findSlotOfType(children, ThreatsListPane);
  const DetailsPaneContent = findSlotOfType(children, ThreatsDetailsPane);
  const ToolPaneContent = findSlotOfType(children, ThreatsToolPane);
  const PaginationPaneContent = findSlotOfType(children, ThreatsPaginationPane);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 0,
        height: "100%",
      }}
    >
      <Box
        sx={{
          gap: "1rem",
          display: "flex",
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "space-between",
          padding: ".5rem",
          paddingBottom: 0,
        }}
      >
        {TitlePaneContent}
      </Box>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          overflow: "auto",
        }}
      >
        <ListPane sx={{ borderRight: 1, borderColor: "divider" }}>
          <Toolbar>{ToolPaneContent}</Toolbar>
          <Box
            sx={{
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
            }}
          >
            {ListPaneContent}
          </Box>
          <Box>{PaginationPaneContent}</Box>
        </ListPane>
        <DetailsPane>{DetailsPaneContent}</DetailsPane>
      </Box>
    </Paper>
  );
};
