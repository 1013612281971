import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { debounce } from "lodash";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserWithPermissions } from "@/pages/general-settings/permissions-attribution/permissions";
import { extractErrorMessage } from "@/utils/misc";

export function useAdminOptions(admins: Array<UserWithPermissions>) {
  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchOption = useCallback(
    async (email) => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        let urlParams = new URLSearchParams();
        urlParams.append("email", email);

        const endpoint_url =
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
          "/users/search_by_email?" +
          urlParams.toString();
        const result = await axios.get(endpoint_url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const adminIds = admins.map((admin) => admin.id);
        const users = result.data.filter((user) => !adminIds.includes(user.id));
        setOptions(users);
        setLoading(false);
      } catch (error) {
        snackbar.showMessage(
          extractErrorMessage(error) || t("An error occurred"),
        );
      }
    },
    [admins, getAccessTokenSilently, snackbar, t],
  );

  const debouncedUpdate = useMemo(() => {
    return debounce(fetchOption, 1000);
  }, [fetchOption]);

  const onSearchChange = (email) => {
    setLoading(true);
    debouncedUpdate(email);
  };

  return {
    selectedOption,
    setSelectedOption,
    options,
    isLoading,
    onSearchChange,
  };
}
