import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Typography } from "@mui/material";

import { FullPageSpinner } from "@/components/Spinner";
import StickyHeader from "@/components/StickyHeader";
import Tabs, {
  TabObject,
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { PleaseActivateAwarenessPopup } from "@/pages/awareness/setup/PleaseActivateAwarenessPopup";
import { Preferences } from "@/pages/awareness/setup/Preferences";
import { selectMessagingPlatform } from "@/pages/awareness/setup/SetupChecklistSlice";
import { Slack } from "@/pages/awareness/setup/Slack";
import { Teams } from "@/pages/awareness/setup/Teams/Teams";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useBackend } from "@/utils/misc";
import { Blur } from "@/utils/styled-components";

import AwarenessSetupChecklist from "./Checklist/Setup";

const useAwarenessSetup = () => {
  const {
    data: awarenessConfig,
    error: isError,
    isPending,
  } = useBackend(`${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/company/`);
  const messagingPlatform = useSelector(selectMessagingPlatform);
  const [tabs, setTabs] = useState<TabObject[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (awarenessConfig == null) {
      return;
    }
    const tabs = [
      {
        label: t("Setup Checklist"),
        anchor: "setup-checklist",
        component: <AwarenessSetupChecklist />,
      },
      {
        label: t("Preferences"),
        anchor: "preferences",
        component: (
          <Preferences
            days_between_courses={awarenessConfig.days_between_courses}
            messaging_platform={messagingPlatform}
          />
        ),
      },
    ];
    switch (messagingPlatform) {
      case "teams":
        tabs.push({
          label: t("Chatbot Configuration"),
          anchor: "chatbot-configuration",
          component: <Teams />,
        });
        break;
      case "slack":
        tabs.push({
          label: t("Chatbot Configuration"),
          anchor: "chatbot-configuration",
          component: <Slack />,
        });
        break;
      default:
        break;
    }
    setTabs(tabs);
  }, [
    awarenessConfig?.days_between_courses,
    setTabs,
    awarenessConfig,
    t,
    messagingPlatform,
  ]);
  return {
    isError,
    isPending,
    tabs,
  };
};

export const Setup: React.FC = () => {
  const { isError, isPending, tabs } = useAwarenessSetup();
  const { t } = useTranslation();

  if (isError) {
    return t(
      "Something went wrong when loading the page, please try again later.",
    );
  }

  if (isPending) {
    return <FullPageSpinner />;
  }

  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <SetupInner />
    </TabsContextProvider>
  );
};

const SetupInner: React.FC = () => {
  const { current_company } = useUserContext();
  const { currentTabComponent } = useTabsContext();
  const { t } = useTranslation();

  return (
    <>
      <Blur
        blur={current_company.awareness_activated ? 0 : 5}
        onClickCapture={(e) =>
          !current_company.awareness_activated ? e.stopPropagation() : null
        }
      >
        <StickyHeader height="120px">
          <Typography variant="h2">{t("Awareness Setup")}</Typography>
          <Tabs />
        </StickyHeader>
        {currentTabComponent}
      </Blur>
      {!current_company.awareness_activated ? (
        <PleaseActivateAwarenessWrapper />
      ) : null}
    </>
  );
};

const PleaseActivateAwarenessWrapper: React.FC = () => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        top: "-100px",
      }}
    >
      <PleaseActivateAwarenessPopup />
    </div>
  );
};
