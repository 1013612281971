import React from "react";
import { useTranslation } from "react-i18next";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useTheme } from "@mui/material";

interface BannerMailsProcessedChartData {
  date: string;
  mailsProcessed: number;
}

const ActiveDot = (props: any) => {
  const theme = useTheme();

  const { cx, cy } = props;
  return (
    <svg
      x={cx - 6.5}
      y={cy - 6}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.28125"
        cy="6"
        r="5"
        fill="white"
        stroke={theme.palette.charts.primary.light}
        strokeWidth="2"
      />
      <ellipse
        cx="6.28125"
        cy="6"
        rx="2"
        ry="2"
        fill={theme.palette.charts.primary.light}
      />
    </svg>
  );
};

export const BannerMailsProcessedAreaChart = ({
  dataset,
}: {
  dataset: Array<BannerMailsProcessedChartData>;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart
        data={dataset}
        margin={{ left: 0, top: 10, right: 10, bottom: 0 }}
      >
        <defs>
          <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="20%"
              stopColor={theme.palette.charts.primary.light}
              stopOpacity={1}
            />
            <stop
              offset="97%"
              stopColor={theme.palette.charts.primary.light}
              stopOpacity={0.05}
            />
            <stop
              offset="100%"
              stopColor={theme.palette.charts.primary.light}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray={5} horizontal={false} />
        <Tooltip
          cursor={{
            color: theme.palette.charts.primary.light,
            stroke: theme.palette.charts.primary.light,
            strokeDasharray: 5,
            strokeWidth: 1,
          }}
        />
        <Area
          name={t("Emails processed")}
          type="monotone"
          dataKey={"mailsProcessed"}
          fill="url(#blueGradient)"
          hide={false}
          activeDot={<ActiveDot />}
        />
        <YAxis width={50} axisLine={false} tickLine={false} />
        <XAxis dataKey={"date"} axisLine={false} tickLine={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};
