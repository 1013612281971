import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Box, Button, Typography } from "@mui/material";

import { selectSetupChecklistState } from "../SetupChecklistSlice";

const CompanyScan = () => {
  const { company_scan: companyScan } = useSelector(selectSetupChecklistState);
  const { t } = useTranslation();

  const categories = [
    { label: t("Key People"), isCompleted: companyScan.has_key_people },
    {
      label: t("Email Signature"),
      isCompleted: companyScan.has_email_signature,
    },
    { label: t("Key Customers"), isCompleted: companyScan.has_key_customers },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        {t("To enable spear phishing, fill in key information in Company Scan")}
      </Typography>
      {categories.map((category) => (
        <Box
          key={category.label}
          display="grid"
          gridTemplateColumns="2fr 1fr"
          gap="1.5rem"
          width="30%"
        >
          <Typography color="warning">{category.label}</Typography>
          {category.isCompleted ? (
            <CheckCircleOutlinedIcon color="success" />
          ) : (
            <WarningAmberOutlinedIcon sx={{ fill: "#fe9534" }} />
          )}
        </Box>
      ))}
      <Box display="flex" alignItems="center" gap="1rem">
        <Typography>
          {t("You can setup company informations here") + " "}:
        </Typography>
        <Link to="/phishing-simulation/company#general-informations">
          <Button variant="contained">{t("Go to Company Scan")}</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default CompanyScan;
