import React from "react";
import { useTranslation } from "react-i18next";

import { Card, CardContent, CardHeader } from "@mui/material";

import { Spinner } from "@/components/Spinner";
import ClicksPerDimensionChart from "@/pages/phishing-simulation/dashboard/ClicksPerDimensionChart";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useBackend } from "@/utils/misc";

export default function CompletedRatePerDepartments() {
  const { t } = useTranslation();
  const user = useUserContext();
  const endpoint_url =
    import.meta.env.VITE_APP_ENDPOINT_LEARNING +
    `/stats/course_stats_per_departments?company_id=${
      user?.current_company?.id ?? ""
    }`;

  const { data, error, isPending } = useBackend(endpoint_url);

  let body;
  if (isPending || !data) body = <Spinner />;
  if (error) body = <div>{t("An error occurred")}</div>;
  if (data && !isPending) {
    if (data?.length === 0) {
      body = <div>{t("No data available")}</div>;
    } else {
      body = (
        <ClicksPerDimensionChart
          labels={Object.keys(data)}
          clickRates={Object.values(data)}
          displayXTitle={false}
        />
      );
    }
  }

  return (
    <Card>
      <CardHeader title={t("Average completion rate per department")} />
      <CardContent>{body}</CardContent>
    </Card>
  );
}
