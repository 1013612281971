const adminScriptContents: string = `
  function Get-ActiveUserProfileEntra {
    try {
        $userProfiles = Get-CimInstance -ClassName Win32_UserProfile | 
            Where-Object { -not $_.Special -and $_.Loaded }

        if ($userProfiles) {
            # If multiple profiles are loaded, prefer the one with the most recent LastUseTime
            $activeProfile = $userProfiles | Sort-Object -Property LastUseTime -Descending | Select-Object -First 1

            $sid = $activeProfile.SID
            $username = (New-Object System.Security.Principal.SecurityIdentifier($sid)).Translate([System.Security.Principal.NTAccount]).Value

            return @{
                Username = $username
                SID = $sid
            }
        }
    }
    catch {
        Write-Host "Error getting active user profile: $_"
    }

    return $null
}


function Get-ActiveUserProfileLocal {
    $currentUserSID = [System.Security.Principal.WindowsIdentity]::GetCurrent().User.Value
    Write-Host "Current process user SID: $currentUserSID"

    $currentUserName = [System.Security.Principal.WindowsIdentity]::GetCurrent().Name
    Write-Host "Current process user name: $currentUserName"

    # Try to get the logged-in user using multiple methods
    $loggedInUser = $null
    $loggedInUserSID = $null

    # Method 1: Using Win32_Process
    $explorerProcesses = Get-WmiObject Win32_Process -Filter "Name='explorer.exe'"
    if ($explorerProcesses) {
        $loggedInUser = ($explorerProcesses | ForEach-Object { $_.GetOwner() } | Select-Object -Unique -Expand User)
        Write-Host "Logged-in user from explorer.exe: $loggedInUser"
    }

    # Method 2: Using Win32_ComputerSystem
    if (-not $loggedInUser) {
        $loggedInUser = Get-WmiObject -Class Win32_ComputerSystem | Select-Object -ExpandProperty UserName
        Write-Host "Logged-in user from Win32_ComputerSystem: $loggedInUser"
    }

    # Method 3: Using environment variable (last resort)
    if (-not $loggedInUser) {
        $loggedInUser = $env:USERNAME
        Write-Host "Logged-in user from environment variable: $loggedInUser"
    }

    if ($loggedInUser) {
        try {
            $objUser = New-Object System.Security.Principal.NTAccount($loggedInUser)
            $strSID = $objUser.Translate([System.Security.Principal.SecurityIdentifier])
            $loggedInUserSID = $strSID.Value
            Write-Host "Logged-in user SID: $loggedInUserSID"

            return @{
                Username = $loggedInUser
                SID = $loggedInUserSID
            }
        } catch {
            Write-Host "Error translating user to SID: $_"
        }
    } else {
        Write-Host "No logged-in user found."
        exit
    }
}

function Refresh-HKUHive {
  param (
      [string]$SID
  )
  try {
      # Unload the hive
      [Microsoft.Win32.Registry]::Users.Close()
      [Microsoft.Win32.Registry]::Users.Flush()

      # Small delay to ensure the unload is complete
      Start-Sleep -Milliseconds 500

      # Reload the hive
      $null = [Microsoft.Win32.Registry]::Users.OpenSubKey($SID, $true)
      [Microsoft.Win32.Registry]::Users.Flush()

      Write-Host "HKU hive refreshed for SID: $SID"
  }
  catch {
      Write-Host "Error refreshing HKU hive: $_"
  }
}

function Process-UserSpecificPath {
  param(
    [string]$userRegPath,
    [string]$currentUser,
    [string]$loggedInUserSID
  )

  Write-Host "Checking registry path: $userRegPath"

  $retryCount = 0
  $maxRetries = 5
  $retryDelay = 5 # seconds

  if (-not (Test-Path $userRegPath)) {
      Write-Host "Registry key not found. Attempting to create: $userRegPath"
      try {
          New-Item -Path $userRegPath -Force | Out-Null
          Refresh-HKUHive -SID $loggedInUserSID
          Write-Host "Registry key creation attempt successful."
      } catch {
          Write-Host "Error creating registry key: $_"
      }
  }

  while (-not (Test-Path $userRegPath) -and $retryCount -lt $maxRetries) {
      Write-Host "Waiting for registry key to be available. Attempt $($retryCount + 1) of $maxRetries"
      Start-Sleep -Seconds $retryDelay
      $retryCount++
  }

  if (Test-Path $userRegPath) {
      Write-Host "Registry key found: $userRegPath"
      
      $acl = Get-Acl $userRegPath

      # Remove any existing Deny rules for the user
      $existingRules = $acl.Access | Where-Object { $_.IdentityReference.Value -eq $currentUser }
      foreach ($rule in $existingRules) {
          Write-Host "Existing rule - Identity: $($rule.IdentityReference), Type: $($rule.AccessControlType), Rights: $($rule.RegistryRights)"
      
          if ($rule.AccessControlType -eq 'Deny') {
              $acl.RemoveAccessRule($rule)
              Write-Host "Removed Deny rule for $currentUser"
          }
      }

      $allowRule = New-Object System.Security.AccessControl.RegistryAccessRule(
          $currentUser,
          "FullControl",
          "ContainerInherit,ObjectInherit",
          "None",
          "Allow"
      )

      $acl.AddAccessRule($allowRule)
      Set-Acl -Path $userRegPath -AclObject $acl

      Write-Host "Exception added for the logged-in user ($currentUser) on the registry key: $userRegPath"
  } else {
      Write-Host "Registry key not found for the logged-in user: $userRegPath"

      # Check if the parent keys exist
      $parentPath = Split-Path $userRegPath -Parent
      while (-not (Test-Path $parentPath) -and $parentPath -ne "HKU:\\$loggedInUserSID") {
          $parentPath = Split-Path $parentPath -Parent
      }
      Write-Host "Closest existing parent path: $parentPath"
      
      # List contents of the closest existing parent path
      Get-ChildItem $parentPath -Recurse | ForEach-Object {
          Write-Host "Found item: $($_.Name)"
      }
  }
}

# Check for admin rights
if (-NOT ([Security.Principal.WindowsPrincipal][Security.Principal.WindowsIdentity]::GetCurrent()).IsInRole([Security.Principal.WindowsBuiltInRole] "Administrator")) {
    Write-Warning "You do not have Administrator rights to run this script! Please re-run this script as an Administrator!"
    exit
}

$activeUser = Get-ActiveUserProfileEntra

try {
  if ($activeUser) {
      $loggedInUser = $activeUser.Username
      $loggedInUserSID = $activeUser.SID
      Write-Host "Active user detected Entra/AD - Username: $loggedInUser, SID: $loggedInUserSID"
  } else {
      # Fallback to local accounts
      $activeUser = Get-ActiveUserProfileLocal
      if ($activeUser) {
          $loggedInUser = $activeUser.Username
          $loggedInUserSID = $activeUser.SID
          Write-Host "Active user detected local - Username: $loggedInUser, SID: $loggedInUserSID"
      }
  } 

  $currentUser = $loggedInUser

  # Create a new PSDrive for the user's hive
  New-PSDrive -Name HKU -PSProvider Registry -Root HKEY_USERS | Out-Null
  
  # Construct the full path to the user's registry key
  $extensionID = "hpdgaogjloaccgpbbncjmedlakljfegb"
  
  # Replace START
  # Dynamical update of list of targetted browsers
  # Replace END
  
  # Process the paths
foreach ($browser in $userRegPaths.Keys) {
  $path = $userRegPaths[$browser]
  Write-Host "Processing $browser path: $path"
  Process-UserSpecificPath -userRegPath $path -currentUser $currentUser -loggedInUserSID $loggedInUserSID
}
}
catch {
    Write-Host "An error occurred: $_"
}
finally {
    # Clean up: Remove the HKU PSDrive
    Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
    Write-Host "Script execution completed."
}
`;

const userScriptLegacyContents = `
    # Ensure the registry path exists
    New-Item -Path $regPath -Force

    # Write the EnterpriseToken to the registry
    Set-ItemProperty -Path $regPath -Name "EnterpriseToken" -Value $enterpriseToken
`;

const userScriptEnterpriseContents = `    
    # Ensure the registry path exists
    New-Item -Path $regPath -Force

    # Write the EnterpriseToken to the registry
    Set-ItemProperty -Path $regPath -Name "EnterpriseToken" -Value $enterpriseToken
    
    # Get the user email or UPN via whoami
    # If this does not work for you, please modify the script so that the $userDomainInfo variable contains the email address of the current user
    $userDomainInfo = whoami /upn
          
    
    # Write the UPN to the registry
    Set-ItemProperty -Path $regPath -Name "EnterpriseUserId" -Value $userDomainInfo
`;

const userScriptFirefoxContents = `
    # Get the user's UPN (email) using whoami
    $userUPN = whoami /upn

    # Please update this to a location the user account can write to
    $destinationDirectory = "C:\\Temp\\mantra-browser-defender"

    # Ensure the destinationDirectory exists
    if (-not (Test-Path -Path $destinationDirectory)) {
        New-Item -ItemType Directory -Force -Path $destinationDirectory
    }

    # Create a file with the user's UPN (email)
        $userUPN | Out-File -FilePath "$destinationDirectory\\user_email.txt"

    Write-Host "User's email has been saved to $destinationDirectory\\user_email.txt"
`;

const adminScriptFirefoxContents = (enterpriseToken: string): string => {
  return `
        # Read the content of the file containing the user's email
        # If you have stored the email in a different location, please modify the script accordingly
        $destinationDirectory = "C:\\Temp\\mantra-browser-defender"
        $userIdentity = (Get-Content -Path "$destinationDirectory\\user_email.txt" -Raw).Trim()

        $extensionID = "browser-defender@mantra.ms"

        $policyDirectory = "C:\\Program Files\\Mozilla Firefox\\distribution"
        $policyFilePath = Join-Path $policyDirectory "policies.json"


        if (!(Test-Path -Path $policyDirectory)) {
            New-Item -ItemType Directory -Path $policyDirectory -Force
        }

        $browserDefenderConfig = @{
            "EnterpriseToken" = "${enterpriseToken}"
            "EnterpriseUserId" = $userIdentity
        }

        if (Test-Path $policyFilePath) {
            # File exists, read and update it
            $existingPolicy = Get-Content $policyFilePath -Raw | ConvertFrom-Json

            if (-not $existingPolicy.policies.'3rdparty') {
                $existingPolicy.policies | Add-Member -NotePropertyName '3rdparty' -NotePropertyValue @{}
            }
            if (-not $existingPolicy.policies.'3rdparty'.Extensions) {
                $existingPolicy.policies.'3rdparty' | Add-Member -NotePropertyName 'Extensions' -NotePropertyValue @{}
            }

            $existingPolicy.policies.'3rdparty'.Extensions | Add-Member -NotePropertyName $extensionID -NotePropertyValue $browserDefenderConfig -Force

            $updatedPolicy = $existingPolicy | ConvertTo-Json -Depth 10
        } else {
            # File doesn't exist, create new policy
            $newPolicy = @{
                policies = @{
                    '3rdparty' = @{
                        Extensions = @{
                            $extensionID = $browserDefenderConfig
                        }
                    }
                }
            }
            $updatedPolicy = $newPolicy | ConvertTo-Json -Depth 10
        }

        # Write the policy to the file
        $updatedPolicy | Out-File -FilePath $policyFilePath -Encoding utf8 -Force
    `;
};

const browserPaths: { [key: string]: string } = {
  brave: "BraveSoftware\\Brave",
  chrome: "Google\\Chrome",
  edge: "Microsoft\\Edge",
  vivaldi: "Vivaldi",
};

function generateBrowserPath(browser: string, extensionID: string): string {
  const browserDependantPath = browserPaths[browser.toLowerCase()];

  return `HKCU:\\Software\\Policies\\${browserDependantPath}\\3rdparty\\extensions\\${extensionID}\\policy`;
}

export function generateAdminPowerShellScript(
  newBrowserPaths: string[],
  extensionID: string,
): string {
  // Define the markers for the section to replace
  const startMarker = "# Replace START";
  const endMarker = "# Replace END";

  // Find the start and end indices of the section to replace
  const startIndex = adminScriptContents.indexOf(startMarker);
  const endIndex =
    adminScriptContents.indexOf(endMarker, startIndex) + endMarker.length;

  // Generate the new content
  let newContent = `$userRegPaths = @{\n`;
  newBrowserPaths.forEach((browser) => {
    const specificContent = generateBrowserPath(browser, extensionID);
    newContent += `${browser} = "${specificContent}"\n`;
  });
  newContent += `}\n`;

  // Replace the old content with the new content
  const updatedScript =
    adminScriptContents.substring(0, startIndex) +
    newContent +
    adminScriptContents.substring(endIndex);

  return updatedScript;
}

export function generateUserPowerShellScript(
  browsers: string[],
  extensionID: string,
  enterpriseToken: string,
  extensionGeneration: string,
): string {
  let scriptContent = "";
  let scriptContentSuffix = "";

  if (extensionGeneration === "legacy") {
    scriptContentSuffix = userScriptLegacyContents;
  } else {
    scriptContentSuffix = userScriptEnterpriseContents;
  }

  scriptContent += '$enterpriseToken = "' + enterpriseToken + '"\n\n';

  browsers.forEach((browser) => {
    const browserPath = browserPaths[browser.toLowerCase()];
    scriptContent +=
      '$regPath = "' +
      `HKCU:\\Software\\Policies\\${browserPath}\\3rdparty\\extensions\\${extensionID}\\policy` +
      '"';
    scriptContent += "\n\n";
    scriptContent += scriptContentSuffix;
  });

  return scriptContent;
}

export function generateUserPowershellScriptFirefox(): string {
  return userScriptFirefoxContents;
}

export function generateAdminPowershellScriptFirefox(
  enterpriseToken: string,
): string {
  return adminScriptFirefoxContents(enterpriseToken);
}
