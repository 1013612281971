import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  useTheme,
} from "@mui/material";

import { TimeFrameSelector } from "@/components/controls/TimeFrameSelector";
import { TitleBar } from "@/components/dashboard/TitleBar";
import BasicStats from "@/pages/phishing-simulation/dashboard/BasicStats/BasicStats";
import ClicksPerDimension from "@/pages/phishing-simulation/dashboard/ClicksPerDimension";
import DepartmentSelector from "@/pages/phishing-simulation/dashboard/DepartmentSelector";
import Stats from "@/pages/phishing-simulation/dashboard/Stats";
import UserPerLevel from "@/pages/phishing-simulation/dashboard/UserPerLevel";
import UsersTable from "@/pages/phishing-simulation/dashboard/UsersTable";
import { useBackend } from "@/utils/misc";
import { useUserPreferences } from "@/utils/useUserPreferencesService";

export default function Dashboard({
  isCorporateView,
}: {
  isCorporateView: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  // selector if clicked on a specific week/month
  const [selectedPeriod, setSelectedPeriod] = useState<{
    unit: dayjs.ManipulateType;
    value: dayjs.Dayjs | null;
  }>({
    unit: "month",
    value: null,
  });

  const resetPeriodValue = () =>
    setSelectedPeriod((current) => ({ ...current, value: null }));

  const [department, setDepartment] = useState("");

  const {
    simulationDashboardPeriod: selectedTimeFrame,
    setSimulationDashboardPeriod: setSelectedTimeFrame,
  } = useUserPreferences();
  const [activeUsersOnly, setActiveUsersOnly] = useState(true);

  const dateFrom = useMemo(() => {
    if (selectedPeriod.value) {
      return selectedPeriod.value;
    }
    const dateFromSelector = dayjs()
      .subtract(dayjs.duration(selectedTimeFrame))
      .startOf("day");

    if (selectedPeriod.unit === "week") {
      return dateFromSelector.startOf("week");
    }
    return dateFromSelector;
  }, [selectedTimeFrame, selectedPeriod.unit, selectedPeriod.value]);

  const dateTo = useMemo(() => {
    if (selectedPeriod.value) {
      return selectedPeriod.value.add(1, selectedPeriod.unit);
    }
    return dayjs().endOf("day");
  }, [selectedPeriod.unit, selectedPeriod.value]);

  const HighestClickRateReq = (dimension) => {
    let endpoint_url =
      import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
      "/stats/click_rate_per_" +
      dimension;
    let urlParams = new URLSearchParams();

    urlParams.append("date_from", dateFrom.toISOString());
    urlParams.append("date_to", dateTo.toISOString());
    urlParams.append("active_only", String(activeUsersOnly));

    if (department) {
      urlParams.append("department", department);
    }

    if (urlParams.toString() !== "") {
      endpoint_url = endpoint_url + "?" + urlParams.toString();
    }
    return useBackend(endpoint_url);
  };

  const HighestClickRateReqDepartment = HighestClickRateReq("department");
  const HighestClickRateReqLocation = HighestClickRateReq("location");
  let dimensionSize = 4;

  if (HighestClickRateReqLocation && HighestClickRateReqLocation.data) {
    if (
      Object.keys(HighestClickRateReqLocation.data).length === 1 &&
      Object.keys(HighestClickRateReqLocation.data)[0] === "UNKNOW"
    ) {
      dimensionSize = 6;
    } else {
      dimensionSize = 4;
    }
  }

  return (
    <>
      <TitleBar
        title={
          isCorporateView
            ? t("Aggregated Phishing Simulation Dashboard")
            : t("Phishing Simulation Dashboard")
        }
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={activeUsersOnly}
                onChange={(event) => setActiveUsersOnly(event.target.checked)}
              />
            }
            label={t("Show active users only")}
          />
        </FormGroup>
        {selectedPeriod?.value && (
          <Chip
            style={{ width: "200px" }}
            label={`
              Selected ${selectedPeriod.unit}:
              ${dayjs(selectedPeriod.value).format(
                selectedPeriod.unit === "week" ? "DD/MM/YYYY" : "MM/YYYY",
              )}
              `}
            onDelete={resetPeriodValue}
            variant="outlined"
            color="primary"
          />
        )}

        {!isCorporateView && (
          <DepartmentSelector
            value={department}
            onChange={(department) => {
              setDepartment(department);
              resetPeriodValue();
            }}
          />
        )}
        <TimeFrameSelector
          value={selectedTimeFrame}
          onChange={setSelectedTimeFrame}
        />
      </TitleBar>

      <Grid container>
        <Grid item xs={12}>
          <Stats
            department={department}
            timeframe={dayjs.duration(selectedTimeFrame).asMonths()}
            setSelectedPeriod={setSelectedPeriod}
            isCorporateView={isCorporateView}
            activeUsersOnly={activeUsersOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicStats
            dateFrom={dateFrom}
            dateTo={dateTo}
            department={department}
            isCorporateView={isCorporateView}
            activeUsersOnly={activeUsersOnly}
          />
        </Grid>

        {!isCorporateView && (
          <>
            <Grid item md={dimensionSize} xs={12}>
              <UserPerLevel
                department={department}
                activeUsersOnly={activeUsersOnly}
              />
            </Grid>
            <Grid item md={dimensionSize} xs={12}>
              <ClicksPerDimension
                title={t("Highest click rate departments")}
                backgroundColor={theme.palette.charts.primary.main}
                dataErrorPending={HighestClickRateReqDepartment}
              />
            </Grid>
            {dimensionSize === 4 && (
              <Grid item md={4} xs={12}>
                <ClicksPerDimension
                  title={t("Highest click rate locations")}
                  backgroundColor={theme.palette.charts.primary.dark}
                  dataErrorPending={HighestClickRateReqLocation}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <UsersTable
                dateFrom={dateFrom}
                dateTo={dateTo}
                department={department}
                fetchActiveUsersOnly={activeUsersOnly}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
