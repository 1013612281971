import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Stack } from "@mui/system";

import { FullPageSpinner } from "@/components/Spinner";
import CompletedRatePerDepartments from "@/pages/awareness/dashboard/CompletedRatePerDepartments";
import { CourseRundown } from "@/pages/awareness/dashboard/CourseRundown";
import { UserRepartitionStats } from "@/pages/awareness/dashboard/UserRepartitionStats";
import { CoursesProgress } from "@/pages/awareness/progress/CoursesProgress";
import { PleaseActivateAwarenessPopup } from "@/pages/awareness/setup/PleaseActivateAwarenessPopup";
import { useSimulationResource } from "@/utils/ResourceGet";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useBackend } from "@/utils/misc";
import { Blur } from "@/utils/styled-components";

import { CourseCompletedCard } from "./CourseCompletedCard";
import { NbEngagedUserCard } from "./NbEngagedUsersCard";
import { NbUserCard } from "./NbUsersCard";

export const AwarenessDashboard = () => {
  const [company, error] = useSimulationResource("company", null);
  const [isMonthly, setIsMonthly] = useState(false);
  const user = useUserContext();

  const company_id = user?.current_company?.id;
  const rundown_base_url = isMonthly
    ? "/stats/rundown/monthly"
    : "/stats/rundown/weekly";
  const courseRundownUrl =
    import.meta.env.VITE_APP_ENDPOINT_LEARNING +
    `${rundown_base_url}?company_id=${company_id}`;
  const repartitionUrl =
    import.meta.env.VITE_APP_ENDPOINT_LEARNING +
    `/stats/repartition?company_id=${user?.current_company?.id}`;
  const {
    data: repartitionData,
    error: repartitionError,
    isPending: repartitionIsPending,
  } = useBackend(repartitionUrl);
  const { data, error: dataError, isPending } = useBackend(courseRundownUrl);
  const { t } = useTranslation();

  const changeRundownChartMode = useCallback(
    (event, value) => {
      if (value === null) {
        return;
      }
      setIsMonthly(value);
    },
    [setIsMonthly],
  );

  function checkEvent(e) {
    if (!company.awareness_activated) {
      e.stopPropagation();
    }
  }

  const mainContent = useMemo(
    () => (
      <Stack gap={2}>
        <UserRepartitionStats
          data={repartitionData}
          error={repartitionError}
          isPending={repartitionIsPending}
        />
        <Stack direction="row" gap={2} useFlexGap>
          <NbUserCard
            usersCount={repartitionData?.user_count}
            error={repartitionError}
            isPending={repartitionIsPending}
          />
          <NbEngagedUserCard
            engagedUsersPercentage={repartitionData?.engaged_user_percentage}
            error={repartitionError}
            isPending={repartitionIsPending}
          />
          <Box flexGrow={1}>
            <CourseCompletedCard
              data={data}
              error={dataError}
              isPending={isPending}
            />
          </Box>
        </Stack>
        <CourseRundown
          changeRundownChartMode={changeRundownChartMode}
          isMonthly={isMonthly}
          data={data}
          error={dataError}
          isPending={isPending}
        />
        <CompletedRatePerDepartments />
        <CoursesProgress />
      </Stack>
    ),
    [
      isMonthly,
      data,
      dataError,
      isPending,
      changeRundownChartMode,
      repartitionData,
      repartitionError,
      repartitionIsPending,
    ],
  );

  if (error) {
    return t(
      "Something went wrong when loading the page, please try again later.",
    );
  } else if (!company) {
    return <FullPageSpinner />;
  }
  if (company.awareness_activated) {
    return mainContent;
  }

  return (
    <div style={{ overflow: "hidden", position: "relative", top: 0, left: 0 }}>
      <Blur style={{ maxHeight: "90vh" }} onClickCapture={checkEvent}>
        {mainContent}
      </Blur>
      <div style={{ position: "absolute", top: "50%", left: "25%" }}>
        <PleaseActivateAwarenessPopup />
      </div>
    </div>
  );
};
