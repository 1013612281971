import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MailIcon from "@mui/icons-material/Mail";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const overrideTheme = createTheme({
  palette: {
    primary: {
      main: "#061123",
    },
  },
});

export function DropdownButtons(props) {
  const [spinnerDisplay, setSpinnerDisplay] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const theme = useTheme();

  const [latestSent, setLatestSent] = useState(props.data);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const clickAwayHandle = () => {
    setAnchorEl(null);
  };

  const id = anchorEl ? "simple-popper" : undefined;

  const normalColor = theme.palette.primary.main;
  const selectionColor = anchorEl ? normalColor : alpha(normalColor, 0.75);
  const hasReaction =
    latestSent.request_password_reset || latestSent.send_warning_email;
  const buttonColor = hasReaction ? selectionColor : "rgba(127, 137, 149, 0.5)";

  return (
    <ThemeProvider theme={overrideTheme}>
      <Button
        sx={{ pr: "0", mr: "0" }}
        style={{ borderRight: "0px", color: buttonColor }}
        onClick={handleClick}
      >
        <MailIcon />
        <ArrowDropDownIcon />
      </Button>

      {anchorEl && (
        <ClickAwayListener onClickAway={clickAwayHandle}>
          <Popper
            id={id}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="bottom-end"
            sx={{ w: "100%" }}
          >
            <Paper elevation={3}>
              <ButtonGroup orientation="vertical" variant="text">
                {spinnerDisplay === "send_warning_email" ? (
                  <CircularProgress
                    size="1.1rem"
                    sx={{ m: "0.95rem" }}
                    style={{
                      display: "block",
                      margin: "3px auto 0 auto",
                      color: `${normalColor}`,
                    }}
                  />
                ) : (
                  <Box>
                    <Button
                      color="primary"
                      style={{
                        flex: "1",
                        textTransform: "none",
                        display: "inline-block",
                        fontWeight: "400",
                        paddingBottom: "0",
                      }}
                      onClick={() => {
                        setSpinnerDisplay("send_warning_email");
                        setTimeout(() => {
                          setLatestSent({
                            ...latestSent,
                            send_warning_email:
                              dayjs().format("DD-MM-yyyy HH:mm"),
                          });
                          setSpinnerDisplay("");
                        }, 1000);
                      }}
                    >
                      {t("Send email warning to user")}
                    </Button>

                    {latestSent["send_warning_email"] && (
                      <Typography
                        style={{
                          fontSize: "0.7rem",
                          fontStyle: "italic",
                          color: "rgba(92, 87, 106, 0.75)",
                          float: "right",
                          padding: "10px 6px 0 0",
                        }}
                      >
                        {`${t("Last sent")} ${
                          latestSent["send_warning_email"]
                        }`}
                      </Typography>
                    )}
                  </Box>
                )}

                {spinnerDisplay === "request_password_reset" ? (
                  <CircularProgress
                    size="1.1rem"
                    sx={{ m: "0.95rem" }}
                    style={{
                      display: "block",
                      margin: "0 auto 3px auto",
                      color: `${normalColor}`,
                    }}
                  />
                ) : (
                  <Box>
                    <Button
                      color="primary"
                      style={{
                        textTransform: "none",
                        display: "inline-block",
                        fontWeight: "400",
                      }}
                      onClick={() => {
                        setSpinnerDisplay("request_password_reset");
                        setTimeout(() => {
                          setLatestSent({
                            ...latestSent,
                            request_password_reset:
                              dayjs().format("DD-MM-yyyy HH:mm"),
                          });
                          setSpinnerDisplay("");
                        }, 1000);
                      }}
                    >
                      {t("Request password change")}
                    </Button>

                    {latestSent["request_password_reset"] && (
                      <Typography
                        style={{
                          fontSize: "0.7rem",
                          fontStyle: "italic",
                          color: "rgba(92, 87, 106, 0.75)",
                          float: "right",
                          padding: "10px 6px 0 0",
                        }}
                      >
                        {`${t("Last sent")} ${
                          latestSent["request_password_reset"]
                        }`}
                      </Typography>
                    )}
                  </Box>
                )}
              </ButtonGroup>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </ThemeProvider>
  );
}
