import styled from "@emotion/styled";
import React from "react";

import { Box, Button } from "@mui/material";

const MACROS = [
  "{{first_name}}",
  "{{last_name}}",
  "{{company_name}}",
  "{{ceo_first_name}}",
  "{{ceo_last_name}}",
  "{{hr_director_first_name}}",
  "{{hr_director_last_name}}",
  "{{company_domain}}",
];

const MacroButton = styled(Button)(
  () => `
    margin-right: 0.5rem;
    border-radius: 16px;
    flex-shrink: 0;
    background: #f5f4ff;

    &:hover {
      background: #EDEBFF;
    }
  `,
);

const MacroSelector = ({ onClick, sx }) => {
  return (
    <Box
      sx={sx}
      maxHeight="2.5rem"
      overflow="scroll"
      display="flex"
      flexDirection="row"
    >
      {MACROS.map((macro) => (
        <MacroButton
          key={macro}
          variant="outlined"
          size="small"
          onClick={() => onClick(macro)}
        >
          {macro}
        </MacroButton>
      ))}
    </Box>
  );
};

export default MacroSelector;
