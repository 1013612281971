import { nanoid } from "@reduxjs/toolkit";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

import {
  useCreateInteractionMutation,
  usePatchInteractionContentMutation,
  useRemoveInteractionMutation,
} from "@/api";
import { T } from "@/components/LocalizedString";
import TrashIcon from "@/components/icons/TrashIcon";

import Answer from "./Answer";
import MacroSelector from "./MacroSelector";
import {
  addAnswer,
  deleteInteraction,
  selectCurrentLocalizedCourse,
  selectEditMode,
  selectInteractions,
} from "./courseSlice";
import { useDebounceRequest } from "./hooks";

const StyledTextareaAutosize = styled(TextareaAutosize)(
  () => `
    background: #fff;
    padding: 8.5px 14px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    font-family: Inter,Arial,sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: #061123;
    &:disabled {
      color: rgba(0, 0, 0, 0.38);
    }
    &.error {
      border: 1px solid #d32f2f;
    }
    &.error:focus {
      border: 1px solid #d32f2f;
      outline: 1px solid #d32f2f;
    }
    &.error:focus-visible {
      border: 1px solid #d32f2f;
      outline: 1px solid #d32f2f;
    }
  `,
);

const ContentBox = styled(Box)(
  () => `
    background: #fff;
    min-height: calc(1.4375em * 5);
    padding: 8.5px 14px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    font-family: Inter,Arial,sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: #061123;
  `,
);

type InteractionValidationError = {
  status?: string;
  data?: {
    error: string;
    content: string[];
  };
};

const validationErrorToMessage = (error: InteractionValidationError) => {
  if (!error) {
    return "";
  }
  if (error.status === "PARSING_ERROR") {
    // Assume Pydantic validation error...
    return T("Unable to save an empty content");
  } else if (error?.data?.error === "validation error") {
    return error?.data?.content[0];
  }
  return T("Unknown error");
};

export function Interaction({ index, interaction }) {
  const textAreaRef = useRef(null);
  const { t } = useTranslation();

  const editMode = useSelector(selectEditMode);
  const interactions = useSelector(selectInteractions);
  const [patch, { isError: isErrorPatch, error: errorPatch }] =
    usePatchInteractionContentMutation();
  const [removeInteraction] = useRemoveInteractionMutation();
  const [createInteraction, { isError: isErrorCreate, error: errorCreate }] =
    useCreateInteractionMutation();
  const dispatch = useDispatch();
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  const [contentValue, setContentValue] = useState(interaction.content);
  const [typeValue, setTypeValue] = useState(interaction.type);
  const debouncedPatch = useDebounceRequest(patch, {
    successMessage: t("Interaction successfully updated"),
    errorMessage: t("Error while updating the interaction"),
  });
  const debouncedCreate = useDebounceRequest(createInteraction, {
    successMessage: t("Interaction successfully created"),
    errorMessage: t("Error while creating the interaction"),
  });

  const [shouldShowErrorStatus, setShouldShowErrorStatus] = useState(false);
  const error = errorPatch || errorCreate;

  useEffect(() => {
    if (isErrorPatch || isErrorCreate) {
      setShouldShowErrorStatus(true);
    }
  }, [isErrorPatch, isErrorCreate]);

  const onUpdateContent = (index: null | number) => (e) => {
    setShouldShowErrorStatus(false);
    const value = e.target.value;
    setContentValue(value);
    if (interaction.id) {
      debouncedPatch({
        localizedCourseId: localizedCourse.id,
        interactionId: interaction.id,
        interaction_type: typeValue ?? "txt",
        content: value,
      });
    } else {
      const previous_interaction_id = interactions[index - 1]?.id;
      debouncedCreate({
        localizedCourseId: localizedCourse.id,
        placeholderId: interaction.placeholderId,
        interaction_type: typeValue,
        content: value,
        previous_interaction_id,
      });
    }
  };

  const onUpdateType = (index: null | number) => (e) => {
    setShouldShowErrorStatus(false);
    const value = e.target.value;
    setTypeValue(value);
    if (interaction.id) {
      patch({
        localizedCourseId: localizedCourse.id,
        interactionId: interaction.id,
        interaction_type: value ?? "txt",
        content: contentValue,
      });
    } else {
      const previous_interaction_id = interactions[index - 1]?.id;
      createInteraction({
        localizedCourseId: localizedCourse.id,
        placeholderId: interaction.placeholderId,
        interaction_type: value,
        content: contentValue,
        previous_interaction_id,
      });
    }
  };

  const onAddAnswer = () => {
    dispatch(
      addAnswer({
        placeholderId: nanoid(),
        localizedCourseId: localizedCourse.id,
        interactionId: interaction.id,
      }),
    );
  };

  const onDeleteInteraction = () => {
    if (interaction.id) {
      removeInteraction({
        localizedCourseId: localizedCourse.id,
        interactionId: interaction.id,
      });
    } else {
      dispatch(
        deleteInteraction({
          placeholderId: interaction.placeholderId,
        }),
      );
    }
  };

  const onMacroSelect = (macro: string) => {
    textAreaRef.current.value += macro;
    textAreaRef.current.dispatchEvent(new Event("input", { bubbles: true }));
  };

  return (
    <Box>
      <Paper
        variant="outlined"
        sx={{
          padding: "20px",
          marginBottom: "1rem",
          background: "#f4f4f6",
        }}
      >
        <Stack gap={2} sx={{ marginBottom: "10px" }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="h5">
              {t("Interaction")} {index + 1}
            </Typography>
            {editMode ? (
              <Select
                value={typeValue}
                size="small"
                disabled={!editMode}
                sx={{ background: "#fff" }}
                onChange={onUpdateType(index)}
              >
                <MenuItem value="txt">{t("Text")}</MenuItem>
                <MenuItem value="img">{t("Image")}</MenuItem>
              </Select>
            ) : (
              <Typography variant="body">{interaction.type}</Typography>
            )}
            {editMode ? (
              <Button
                startIcon={<TrashIcon color="#fd397a" />}
                variant="outlined"
                color="warning"
                size="small"
                sx={{ marginLeft: "auto", background: "#fff" }}
                onClick={onDeleteInteraction}
              >
                {t("Delete Interaction")}
              </Button>
            ) : (
              ""
            )}
          </Stack>
          {editMode ? (
            <Stack spacing={0}>
              <StyledTextareaAutosize
                ref={textAreaRef}
                value={contentValue}
                placeholder={t("Write your content here...")}
                minRows={4}
                disabled={!editMode}
                className={shouldShowErrorStatus ? "error" : ""}
                onInput={onUpdateContent(index)}
              />
              {editMode ? (
                <MacroSelector
                  onClick={onMacroSelect}
                  sx={{ marginTop: "16px" }}
                />
              ) : (
                ""
              )}
              <Typography
                variant="body_small"
                sx={{
                  padding: "4px 14px",
                  fontSize: "0.75em",
                  fontWeight: 400,
                  color: "#d32f2f",
                }}
              >
                {shouldShowErrorStatus
                  ? validationErrorToMessage(
                      error as InteractionValidationError,
                    )
                  : ""}
              </Typography>
            </Stack>
          ) : (
            <ContentBox>{interaction.content}</ContentBox>
          )}
          {interaction.answers.map((answer, answerIndex) => (
            <Answer
              key={answer.keyId}
              interaction={interaction}
              answer={answer}
              index={answerIndex}
            />
          ))}
          {index === 0 && interaction.answers.length === 0 ? (
            <Typography color="red">
              {t(
                "To avoid spamming users at the beginning of the course, we recommend you add at least one answer to the first interaction.",
              )}
            </Typography>
          ) : (
            ""
          )}
          <Box>
            {editMode ? (
              <Button
                variant="outlined"
                sx={{ background: "#fff" }}
                size="small"
                startIcon={<AddIcon />}
                onClick={onAddAnswer}
              >
                {t("Add answer")}
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
}
