import * as React from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import { Checkbox, Chip, TextField, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Stack } from "@mui/system";

import TableSort from "@/components/sort/TableSort";

export const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-head": {
    fontSize: "1rem",
    fontWeight: "600",
    color: "#000000",
  },
}));

export default function CollapsibleTable({
  headers,
  sortingColumns,
  rows,
  setSorting,
  setSearchTerm,
  customFilter = "",
  clearCustomFilter = null,
  defaultOrder = [],
  checkboxSelection = false,
  selectAll = null,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectAllStatus, setSelectAllStatus] = React.useState([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    setPage(0);
  }, [customFilter]);

  const onChange = (e) => {
    if (e.keyCode !== 13) {
      return;
    }
    const value = e.target.value;
    setSearchTerm(value);
    setPage(0);
  };

  function toggleMainCheckbox(event) {
    let newStatus = [...selectAllStatus];
    if (!selectAllStatus.includes(page) && event.target.checked) {
      newStatus.push(page);
    } else if (selectAllStatus.indexOf(page) > -1 && !event.target.checked) {
      newStatus = selectAllStatus.filter((e) => e !== page);
    }
    setSelectAllStatus(newStatus);
    selectAll(event, page, rowsPerPage);
  }

  return (
    <Box>
      <Stack direction="row" gap=".5rem" marginBottom="10px">
        <TextField
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          size={"small"}
          label={t("Search")}
          onKeyDown={onChange}
        />
        <TableSort
          setOrderBy={setSorting}
          columns={sortingColumns}
          defaultOrder={defaultOrder}
        />
        {customFilter && (
          <Chip
            label={customFilter}
            variant="outlined"
            onDelete={clearCustomFilter}
            style={{ marginLeft: "1rem" }}
          />
        )}
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
          {headers && (
            <TableHead>
              <StyledTableHeaderRow>
                {checkboxSelection && (
                  <TableCell>
                    <Checkbox
                      checked={selectAllStatus.indexOf(page) > -1}
                      onClick={toggleMainCheckbox}
                    />
                  </TableCell>
                )}
                {headers.map((header) => (
                  <TableCell align={header.alignment} key={header.label}>
                    {header.label}
                  </TableCell>
                ))}
              </StyledTableHeaderRow>
            </TableHead>
          )}
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => row)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        labelRowsPerPage={t("Rows per page") + " :"}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={(_, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Box>
  );
}
