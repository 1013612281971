import React from "react";

import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon color="primary">
      <svg
        width="27"
        height="24"
        viewBox="0 0 27 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1245 0.958515C18.4246 1.00722 18.683 1.19724 18.819 1.46921L19.6239 3.07907L21.2338 3.884C21.5058 4.01999 21.6958 4.27842 21.7445 4.57856C21.7932 4.8787 21.6947 5.18396 21.4797 5.39897L18.6418 8.23681C18.4644 8.41421 18.2238 8.51387 17.9729 8.51387H15.527L12.0203 12.0206C11.6508 12.39 11.0519 12.39 10.6825 12.0206C10.3131 11.6512 10.3131 11.0522 10.6825 10.6828L14.1891 7.17615V4.73008C14.1891 4.4792 14.2888 4.2386 14.4662 4.0612L17.304 1.22336C17.5191 1.00835 17.8243 0.909809 18.1245 0.958515Z"
          fill="currentColor"
        />
        <path
          d="M2.83785 11.3517C2.83785 6.64981 6.64948 2.83818 11.3514 2.83818C11.8738 2.83818 12.2973 2.41467 12.2973 1.89224C12.2973 1.3698 11.8738 0.946289 11.3514 0.946289C5.60462 0.946289 0.945961 5.60495 0.945961 11.3517C0.945961 17.0984 5.60462 21.7571 11.3514 21.7571C17.0981 21.7571 21.7568 17.0984 21.7568 11.3517C21.7568 10.8293 21.3333 10.4057 20.8108 10.4057C20.2884 10.4057 19.8649 10.8293 19.8649 11.3517C19.8649 16.0536 16.0533 19.8652 11.3514 19.8652C6.64948 19.8652 2.83785 16.0536 2.83785 11.3517Z"
          fill="currentColor"
        />
        <path
          d="M7.56758 11.3517C7.56758 9.26197 9.26164 7.56791 11.3514 7.56791C11.8738 7.56791 12.2973 7.1444 12.2973 6.62196C12.2973 6.09953 11.8738 5.67602 11.3514 5.67602C8.21678 5.67602 5.67569 8.21711 5.67569 11.3517C5.67569 14.4863 8.21678 17.0274 11.3514 17.0274C14.486 17.0274 17.027 14.4863 17.027 11.3517C17.027 10.8293 16.6035 10.4057 16.0811 10.4057C15.5587 10.4057 15.1352 10.8293 15.1352 11.3517C15.1352 13.4414 13.4411 15.1355 11.3514 15.1355C9.26164 15.1355 7.56758 13.4414 7.56758 11.3517Z"
          fill="currentColor"
        />
        <circle cx="20.4324" cy="18.1619" r="5.67561" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4324 12.4862C17.2978 12.4862 14.7567 15.0273 14.7567 18.1619C14.7567 21.2965 17.2978 23.8376 20.4324 23.8376C23.567 23.8376 26.1081 21.2965 26.1081 18.1619C26.1081 15.0273 23.567 12.4862 20.4324 12.4862ZM23.1191 16.9788C23.3206 16.7773 23.3206 16.4506 23.1191 16.2491C22.9176 16.0476 22.5909 16.0476 22.3894 16.2491L19.6584 18.9801L18.4754 17.797C18.2739 17.5955 17.9472 17.5955 17.7457 17.797C17.5442 17.9985 17.5442 18.3252 17.7457 18.5267L19.2936 20.0746C19.4951 20.2761 19.8218 20.2761 20.0233 20.0746L23.1191 16.9788Z"
          fill="#65C58B"
        />
      </svg>
    </SvgIcon>
  );
}
