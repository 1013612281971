import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { ButtonSpinner } from "@/components/Spinner";
import { ChecklistPane } from "@/components/stepper/ChecklistPane";
import {
  useStatusQuery,
  useToggleAutoEngageMutation,
} from "@/pages/banners/setup/BannerSetupChecklist";
import DoughnutGraph from "@/pages/browser-defender/components/DoughnutGraph";

type EngageCompanyStatusProps = { title: ReactNode };

type LoadableBoolean = "loading" | "enabled" | "disabled";

export const EngageCompanyStatus: React.FC<EngageCompanyStatusProps> = ({
  title,
}: EngageCompanyStatusProps) => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useStatusQuery();
  const [toggleAutoEngage, { isLoading: isToggleLoading }] =
    useToggleAutoEngageMutation();

  const [status, setStatus] = useState({
    engaged: 0,
    disengaged: 0,
    disabled: 0,
  });

  const autoEngageEnabled: LoadableBoolean = useMemo(
    () =>
      isFetching || isToggleLoading
        ? "loading"
        : data.auto_engage
          ? "enabled"
          : "disabled",
    [isFetching, data, isToggleLoading],
  );

  useEffect(() => {
    if (isLoading) return;

    setStatus({
      engaged: data.engaged_users,
      disengaged:
        data.active_users - (data.engaged_users + data.disabled_users),
      disabled: data.disabled_users,
    });
  }, [data, isLoading, setStatus]);

  return (
    <ChecklistPane title={title}>
      <Typography>{t("The whole company can now be engaged.")}</Typography>

      <FormGroup>
        <FormControlLabel
          control={
            <Box
              sx={{ display: "flex", padding: ".5rem", alignItems: "center" }}
            >
              <Switch
                size="small"
                checked={autoEngageEnabled === "enabled"}
                disabled={isToggleLoading || isFetching}
                onChange={(e) => {
                  toggleAutoEngage(e.target.checked);
                }}
              />
              {autoEngageEnabled === "loading" && (
                <ButtonSpinner size="1rem" color="primary" thickness={8} />
              )}
            </Box>
          }
          label={t("Enable Auto Engage")}
        />
      </FormGroup>
      <Typography variant="helper_text">
        {t(
          "Smart Banner will be progressively enabled on company mailboxes, starting tomorrow morning.",
        )}
      </Typography>
      <Typography variant="helper_text">
        <Trans i18nKey="EngageCompanyStatusBanners">
          Users that are disengaged in the{" "}
          <Link to="/banners/users">Users</Link> page will never be engaged
          automatically.
        </Trans>
      </Typography>
      <Box>
        <DoughnutGraph
          data={status}
          labelMapping={{
            engaged: { color: "#1dc9b7", label: t("Engaged") },
            disengaged: { color: "#e76b82", label: t("Disengaged") },
            disabled: { color: "#dce5ec", label: t("Disabled") },
          }}
        />
      </Box>
    </ChecklistPane>
  );
};
