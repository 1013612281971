import React from "react";
import { useTranslation } from "react-i18next";

import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";

import LabelItem from "@/pages/browser-defender/passwordMisuse/domain-label/LabelItem";

export default function ExtensionActions({
  selectedExtension,
  currentTab,
  onAllow,
  onBlock,
}) {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleActionClick = (action) => {
    if (action === "allow") {
      onAllow();
    } else if (action === "block") {
      setConfirmOpen(true);
    } else {
      console.error("Unknown action");
    }
  };

  const isBlocked = currentTab === "blocked";
  const blockExplained = t("extensionBlockExplained");
  const tooltipText = !selectedExtension
    ? t("Select an extension first")
    : blockExplained;

  const getDialogText = () => {
    if (!selectedExtension) return "";
    return t(
      "Are you sure you want to block {{extension_name}} for all users? {{blockExplained}}",
      {
        extension_name: selectedExtension.name,
        blockExplained: blockExplained,
      },
    );
  };

  return (
    <>
      <Tooltip title={tooltipText}>
        <span>
          <ButtonGroup
            variant="outlined"
            disabled={!selectedExtension}
            sx={{
              "& > button": { mx: 1 },
              "& > button:first-of-type": { ml: 0 },
              "& > button:last-of-type": { mr: 0 },
            }}
          >
            {!isBlocked && (
              <Button size="small" onClick={() => handleActionClick("block")}>
                <LabelItem
                  icon={<BlockIcon style={{ color: "#F19949" }} />}
                  title={t("Block")}
                  description="Block extension for all users."
                  disabled={!selectedExtension}
                />
              </Button>
            )}
            {isBlocked && (
              <Button size="small" onClick={() => handleActionClick("allow")}>
                <LabelItem
                  icon={<CheckCircleIcon style={{ color: "#35D9C0" }} />}
                  title={t("Allow")}
                  description="Allow this extension to be installed and enabled."
                  disabled={!selectedExtension}
                />
              </Button>
            )}
          </ButtonGroup>
        </span>
      </Tooltip>

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>{t("Confirm Block")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{getDialogText()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>{t("Cancel")}</Button>
          <Button
            onClick={() => {
              onBlock();
              setConfirmOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
