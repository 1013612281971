import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Box, Button, Typography } from "@mui/material";

import ConsentGrantDocumentationLink from "@/components/ConsentGrantDocumentationLink";
import ServiceAccountStatus from "@/pages/phishing-simulation/setup/ServiceAccountStatus";
import { SIMULATION_SCRIBEHOW_SETUP_GOOGLE_SSO_API_SYNC } from "@/utils/constants";

import { selectSetupChecklistState } from "../SetupChecklistSlice";
import { MailHost } from "../SetupChecklistTypes";

const ProvisioningPermissions = () => {
  const { t } = useTranslation();

  const {
    has_provisioning_permissions: hasProvisioningPermissions,
    mail_host: mailHost,
    service_account_status,
  } = useSelector(selectSetupChecklistState);

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        {t("Allow Mantra to provision users using") + " "}
        {mailHost === MailHost.OFFICE
          ? "Microsoft Entra ID"
          : "Google Workspace"}
      </Typography>
      <Typography>
        {t(
          "You need to provide Mantra with the user access permissions to import the proper users.",
        )}
      </Typography>
      {mailHost === MailHost.GMAIL ? (
        <>
          <ServiceAccountStatus
            is_account_set_up={service_account_status.is_set_up}
            account_id={service_account_status.unique_id}
          />
          <ConsentGrantDocumentationLink
            documentation_url={SIMULATION_SCRIBEHOW_SETUP_GOOGLE_SSO_API_SYNC}
          />
        </>
      ) : (
        <Box display="flex" alignItems="center" gap="2.5rem">
          <Link
            to={
              hasProvisioningPermissions
                ? "#"
                : "/general-settings#users-provisioning"
            }
            style={
              hasProvisioningPermissions
                ? {
                    pointerEvents: "none",
                    cursor: "auto",
                  }
                : {}
            }
          >
            <Button variant="contained" disabled={hasProvisioningPermissions}>
              {t("Grant Provisioning Permissions")}
            </Button>
          </Link>
          <Box display="flex" gap="0.5rem">
            {hasProvisioningPermissions ? (
              <>
                <CheckCircleOutlinedIcon color="success" />
                <Typography variant="body_small" color="success.main">
                  {t("It works!")}
                </Typography>
              </>
            ) : (
              <>
                <CancelOutlinedIcon color="error" />
                <Typography color="error">{t("Not connected")}</Typography>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProvisioningPermissions;
