import React, { PropsWithChildren } from "react";

import css from "@/pages/landing-pages/components/LandingPage.module.css";
import { LANDING_PAGE_LAYOUT } from "@/pages/landing-pages/translations";

export type SupportedLanguage = "en" | "fr";

type LandingPageLayoutProps = {
  lang: SupportedLanguage;
} & PropsWithChildren;

const LandingPageLayout = (props: LandingPageLayoutProps): JSX.Element => {
  const translate = LANDING_PAGE_LAYOUT[props.lang];

  return (
    <div className={css.container}>
      <div className={css.message_container}>
        <img
          src="/media/logos/logo_mantra_loading_screen.png"
          alt="Mantra logo"
        />
        {props.children}
        <p className={css.small_message}>{translate.close}</p>
      </div>
      <div className={css.footer}>
        <p>
          {translate.footer}{" "}
          <a href="mailto:team@mantra.ms">{"team@mantra.ms"}</a>
        </p>
      </div>
    </div>
  );
};

export default LandingPageLayout;
