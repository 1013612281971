import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardContent, CardHeader } from "@mui/material";

import { FullPageSpinner } from "@/components/Spinner";
import { OnlyVisibleByMantra } from "@/components/layout/OnlyVisibleByMantra";
import {
  SetupChecklist,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";
import { ApiAccessSetup } from "@/pages/banners/setup/ApiAccessSetup";
import {
  selectBannersSetupChecklistStatus,
  setStepperState,
  useStatusQuery,
} from "@/pages/banners/setup/BannerSetupChecklist";
import { StepperStateKey } from "@/pages/banners/setup/BannersChecklistTypes";
import { CompanySetup } from "@/pages/banners/setup/CompanySetup";
import { DeploymentTest } from "@/pages/banners/setup/DeploymentTest";
import { EngageCompanyStatus } from "@/pages/banners/setup/EngageCompanyStatus";
import { HistoricalAnalysis } from "@/pages/banners/setup/HistoricalAnalysis";
import { SafeListsSetup } from "@/pages/banners/setup/SafeListsSetup";
import { SampleEmails } from "@/pages/banners/setup/SampleEmails";
import {
  BANNERS_SCRIBEHOW_ON_GOOGLE_URL,
  BANNERS_SCRIBEHOW_ON_OFFICE_URL,
} from "@/utils/constants";

const BannerCurrentComponent: React.FC = () => {
  const checklist = useContext(SetupChecklistContext);

  const steps = useSelector(selectBannersSetupChecklistStatus);

  const title = steps[checklist.activeIndex].label;

  switch (checklist.activeIndex) {
    case StepperStateKey.COMPANY_SETUP:
      return <CompanySetup title={title} />;
    case StepperStateKey.API_ACCESS:
      return <ApiAccessSetup title={title} />;
    case StepperStateKey.DEPLOYMENT_TEST:
      return <DeploymentTest title={title} />;
    case StepperStateKey.HISTORICAL_ANALYSIS:
      return <HistoricalAnalysis title={title} />;
    case StepperStateKey.SAFE_LIST_SETUP:
      return <SafeListsSetup title={title} />;
    case StepperStateKey.SAMPLE_EMAILS:
      return <SampleEmails title={title} />;
    case StepperStateKey.ENGAGE_COMPANY:
      return <EngageCompanyStatus title={title} />;
  }
};

export const BannersSetupChecklist: React.FC = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useStatusQuery();
  const steps = useSelector(selectBannersSetupChecklistStatus);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading) dispatch(setStepperState(data));
  }, [isLoading, data, dispatch]);

  return (
    <>
      {isLoading && !isError ? (
        <FullPageSpinner />
      ) : (
        <>
          <SetupChecklist steps={steps}>
            <BannerCurrentComponent />
          </SetupChecklist>
          <OnlyVisibleByMantra>
            <Card>
              <CardHeader title={"Documentation Links"} />
              <CardContent>
                <ul>
                  <li>
                    <Link to={BANNERS_SCRIBEHOW_ON_GOOGLE_URL}>
                      {t("Google Scribe Documentation")}
                    </Link>
                  </li>
                  <li>
                    <Link to={BANNERS_SCRIBEHOW_ON_OFFICE_URL}>
                      {t("Office Scribe Documentation")}
                    </Link>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </OnlyVisibleByMantra>
        </>
      )}
      {isError ? t("An error occurred") : null}
    </>
  );
};
