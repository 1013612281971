import React from "react";

import { Stack } from "@mui/material";

import { TemplateFooter } from "./TemplateFooter";
import { TemplateList } from "./TemplateList";

export const TemplateListSection = () => {
  return (
    <Stack spacing={3}>
      <TemplateList />
      <TemplateFooter />
    </Stack>
  );
};
