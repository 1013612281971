import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

import { useUserContext } from "@/utils/contexts/UserContext";

export function useStartSimulations() {
  const { getAccessTokenSilently } = useAuth0();
  const user = useUserContext();
  const startSimulations = async (setLoading, refetchState) => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    try {
      await axios
        .post(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/company/simulation/start",
          {},
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(() => {
          enqueueSnackbar(
            `Activation complete, ${user.current_company.name} employees will receive their first simulations tomorrow`,
            { variant: "success" },
          );
        });
    } catch (error) {
      enqueueSnackbar("Failed to activate automatic simulations", {
        variant: "error",
      });
    }
    refetchState();
    setLoading(false);
  };
  return { startSimulations };
}
