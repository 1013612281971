import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { useFirstSimulationQuery } from "@/utils/contexts/userApi";

interface TimeFrame {
  label: string;
  period: string; // iso string of duration
}

interface TimeFrameInputProps {
  value: string;
  onChange: (period: string) => void;
  timeFrames: TimeFrame[];
}

const TimeFrameInput: React.FC<TimeFrameInputProps> = ({
  value,
  onChange,
  timeFrames,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <FormControl sx={{ width: "150px", background: "#ebefff" }}>
      <InputLabel size="small" htmlFor="timeframe-select">
        {t("Timeframe")}
      </InputLabel>
      <Select
        label={t("Timeframe")}
        id="timeframe-select"
        size="small"
        value={value}
        onChange={handleChange}
      >
        {timeFrames.map((timeFrame) => (
          <MenuItem value={timeFrame.period} key={timeFrame.label}>
            {timeFrame.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

type TimeFrameSelectorProps = {
  value: string;
  onChange?: (period: string) => void;
  withLastWeek?: boolean;
};

export const TimeFrameSelector: React.FC<TimeFrameSelectorProps> = ({
  value,
  onChange = () => {},
  withLastWeek = false,
}: TimeFrameSelectorProps) => {
  const { data, isLoading } = useFirstSimulationQuery();
  const { t } = useTranslation();

  const timeFrames: TimeFrame[] = useMemo(() => {
    const baseDurations = [
      { label: `1 ${t("Month")}`, period: "P1M" },
      { label: `3 ${t("Months")}`, period: "P3M" },
      { label: `6 ${t("Months")}`, period: "P6M" },
      { label: `1 ${t("Year")}`, period: "P1Y" },
    ];

    let max: TimeFrame[] = [];
    if (!isLoading && data?.nb_months_since > 12) {
      max = [
        {
          label: t("Max"),
          period: dayjs.duration(data.nb_months_since, "months").toISOString(),
        },
      ];
    }

    if (withLastWeek) {
      return [
        { label: `7 ${t("Days")}`, period: "P7D" },
        ...baseDurations,
        ...max,
      ];
    }
    return [...baseDurations, ...max];
  }, [withLastWeek, isLoading, data, t]);

  return (
    <TimeFrameInput value={value} onChange={onChange} timeFrames={timeFrames} />
  );
};

export default TimeFrameSelector;
