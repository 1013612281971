import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export const AttachmentsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ fontSize: 12 }} color="neutral" {...props}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.339 6.35804L7.07985 11.6171C5.88387 12.8131 3.9448 12.8131 2.74882 11.6171C1.55284 10.4212 1.55284 8.48209 2.74882 7.28611L8.00793 2.02701C8.80525 1.22969 10.098 1.22969 10.8953 2.02701C11.6926 2.82433 11.6926 4.11704 10.8953 4.91436L5.84241 9.96723C5.44375 10.3659 4.7974 10.3659 4.39874 9.96723C4.00008 9.56857 4.00008 8.92221 4.39874 8.52355L8.83289 4.0894"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
