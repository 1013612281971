import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

import DoughnutGraph from "@/pages/browser-defender/components/DoughnutGraph";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { useUserContext } from "@/utils/contexts/UserContext";
import { sentryCaptureException } from "@/utils/sentry";

export function AlertsStatsCard({ user_email }) {
  const [stats, setStats] = useState({ data: [], loading: true });

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchAlertStats = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const path = "/stats/user/alerts";

      const url = `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}${path}`;

      let resource;
      try {
        resource = await axios.post(
          url,
          { email: user_email, period_in_weeks: 2 },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (e) {
        if (e.response.status === 400) {
          return;
        }
      }

      setStats({
        data: resource.data.stats,
        loading: false,
      });
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently, user_email]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchAlertStats();
  }, [fetchAlertStats, info_user]);

  return (
    <GenericCard
      title={"Types of alerts (last 2 weeks)"}
      isLoading={stats.loading}
    >
      <DoughnutGraph
        data={
          Object.keys(stats.data).length > 0 &&
          stats.data.reduce((acc, item) => {
            acc[item.value] = item.count;
            return acc;
          }, {})
        }
        labelMapping={{
          password_misuse: { label: "Credentials", color: "#f57040" },
          dangerous_downloads: { label: "Downloads", color: "#ee97a7" },
          unsafe_navigation: { label: "Browsing", color: "#e76b82" },
        }}
      />
    </GenericCard>
  );
}
