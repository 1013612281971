import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, Typography } from "@mui/material";

import { extractErrorMessage } from "@/utils/misc";

export function SelectedDepartment({ departments, group, onSelect }) {
  const [selectedDepartment, setSelectedDepartment] = useState(
    group.department,
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();

  const onChange = async (event) => {
    // change selector value
    let dept = departments.find((dept) => {
      return dept.id === parseInt(event.target.value);
    });

    if (dept === undefined) {
      dept = null;
    }
    setSelectedDepartment(dept);

    // change selection on server side
    setLoading(true);

    const accessToken = await getAccessTokenSilently();
    try {
      const aGroup = {
        department: dept,
      };

      await axios.put(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/groups/" + group.id,
        aGroup,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      setErrorMessage(null);
      snackbar.showMessage("Group updated");
      onSelect();
    } catch (error) {
      setErrorMessage(extractErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <select
        name="departments"
        value={selectedDepartment ? selectedDepartment.id : "default"}
        onChange={(event) => onChange(event)}
      >
        <option value="default">--{t("Select department")}--</option>
        {departments
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((department) => (
            <option key={department.id} value={department.id}>
              {department.name}
            </option>
          ))}
      </select>
      <span
        style={loading ? { visibility: "visible" } : { visibility: "hidden" }}
      >
        &nbsp;
        <CircularProgress size={10} />
      </span>

      {errorMessage !== null && (
        <div className="row">
          <Typography style={{ color: "red" }}>{errorMessage}</Typography>
        </div>
      )}
    </div>
  );
}
