import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@mui/material";

import { useLearningResource } from "@/utils/ResourceGet";
import { useUserContext } from "@/utils/contexts/UserContext";
import { extractErrorMessage, getConfig } from "@/utils/misc";

export function LearningLanguage() {
  const [languageList] = useLearningResource("languages/", []);
  const [userLanguage] = useLearningResource("languages/current/", null);
  const [language, setLanguage] = useState({});
  const { uses_formal_tone } = useUserContext();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  const displayedLanguageList = languageList.filter((language) =>
    uses_formal_tone
      ? language.code !== "fr"
      : language.code !== "fr_FR@formal",
  );

  useEffect(() => {
    setLanguage(userLanguage);
  }, [userLanguage]);

  const changeLanguage = async (event) => {
    const newLanguage = { language: event };
    try {
      const result = await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/languages/current/",
        newLanguage,
        await getConfig(getAccessTokenSilently),
      );
      setLanguage(result.data);
      snackbar.showMessage(t("My Learning language updated successfully."));
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  };

  const getOptionLabel = (language) => {
    if (language.code === "fr_FR@formal") {
      return `fr | French`;
    }
    return `${language?.code} | ${language?.value}`;
  };

  return (
    <>
      {userLanguage && (
        <Card>
          <CardHeader title={t("My language")} />
          <CardContent>
            <Autocomplete
              options={displayedLanguageList}
              value={language}
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={(option) =>
                displayedLanguageList.length &&
                displayedLanguageList.findIndex((o) => o === option) >= 0
              }
              className="mt-3"
              onChange={(e, value) => {
                changeLanguage(value?.code);
              }}
              renderInput={(params) => (
                <TextField {...params} label={t("Learning Language")} />
              )}
              loadingText={t("Loading") + "..."}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
}
