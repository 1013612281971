import { createApi } from "@reduxjs/toolkit/query/react";

import {
  checkEditMode,
  createAnswerFromPlaceholder,
  createInteractionFromPlaceholder,
  deleteAnswer,
  deleteInteraction,
  publishAllLocalizedCoursesForCourse,
  publishLocalizedCourse,
  setAnswerContent,
  setCourseState,
  setInteractionContent,
  setInteractions,
  setLocalizedCourseDraftForLanguage,
  setLocalizedCourseField,
  toggleEditMode,
} from "@/pages/awareness/courses/course-editor/courseSlice";
import { learningBaseQuery } from "@/utils/rtkQuery";

export const courseEditorApi = createApi({
  reducerPath: "courseEditorApi",
  refetchOnMountOrArgChange: true,
  baseQuery: learningBaseQuery(),
  endpoints: (builder) => ({
    fetchCourseById: builder.query({
      query: (id) => `courses/${id}`,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const response = await queryFulfilled;
        dispatch(setCourseState(response.data));
        dispatch(checkEditMode());
      },
    }),
    deleteCourseById: builder.mutation({
      query: (id) => ({
        url: `courses/${id}`,
        method: "DELETE",
      }),
    }),
    cloneCourse: builder.mutation({
      query: (id) => ({
        url: `courses/${id}/clone/`,
        method: "POST",
      }),
    }),
    getLocalizedCourseInteractions: builder.query({
      query: (id) => `localized-courses/${id}/interactions/`,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const response = await queryFulfilled;
        dispatch(setInteractions(response.data));
      },
    }),
    patchLocalizedCourse: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `localized-courses/${id}/`,
        method: "PATCH",
        body: {
          description: patch.description,
          name: patch.name,
        },
      }),
      onQueryStarted: async (queryData, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          setLocalizedCourseField({
            id: queryData.id,
            name: queryData.name,
            description: queryData.description,
          }),
        );
      },
    }),
    publishLocalizedCourse: builder.mutation({
      query: ({ id }) => ({
        url: `localized-courses/${id}/publish`,
        method: "PATCH",
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(publishLocalizedCourse(arg.language));
        dispatch(toggleEditMode());
      },
    }),
    publishAllLocalizedCourses: builder.mutation({
      query: ({ id }) => ({
        url: `courses/${id}/publish`,
        method: "PATCH",
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(publishAllLocalizedCoursesForCourse(arg));
        dispatch(toggleEditMode());
      },
    }),
    createInteraction: builder.mutation({
      query: (queryData) => ({
        url: `localized-courses/${queryData.localizedCourseId}/interactions`,
        method: "POST",
        body: {
          content: queryData.content,
          interaction_type: queryData.interaction_type,
          previous_interaction_id: queryData.previous_interaction_id,
        },
      }),
      onQueryStarted: async (queryData, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          createInteractionFromPlaceholder({
            placeholderId: queryData.placeholderId,
            id: data.id,
            localizedCourseId: queryData.interactionId,
            content: data.content,
            interaction_type: data.type,
          }),
        );
      },
    }),
    patchInteractionContent: builder.mutation({
      query: (queryData) => ({
        url: `localized-courses/${queryData.localizedCourseId}/interactions/${queryData.interactionId}/`,
        method: "PATCH",
        body: {
          interaction_type: queryData.interaction_type,
          content: queryData.content,
        },
      }),
      onQueryStarted: async (queryData, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          setInteractionContent({
            id: queryData.interactionId,
            interaction_type: queryData.interaction_type,
            content: queryData.content,
          }),
        );
      },
    }),
    removeInteraction: builder.mutation({
      query: (queryData) => ({
        url: `localized-courses/${queryData.localizedCourseId}/interactions/${queryData.interactionId}/`,
        method: "DELETE",
      }),
      onQueryStarted: async (queryData, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          deleteInteraction({
            id: queryData.interactionId,
          }),
        );
      },
    }),
    patchAnswerContent: builder.mutation({
      query: (queryData) => ({
        url: `interactions/${queryData.interactionId}/answers/${queryData.answerId}/`,
        method: "PATCH",
        body: {
          content: queryData.content,
          reaction: queryData.reaction,
          is_correct: queryData.is_correct,
        },
      }),
      onQueryStarted: async (queryData, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          setAnswerContent({
            interactionId: queryData.interactionId,
            answerId: queryData.answerId,
            content: queryData.content,
            reaction: queryData.reaction,
            is_correct: queryData.is_correct,
          }),
        );
      },
    }),
    deleteAnswer: builder.mutation({
      query: (queryData) => ({
        url: `interactions/${queryData.interactionId}/answers/${queryData.answerId}/`,
        method: "DELETE",
      }),
      onQueryStarted: async (queryData, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          deleteAnswer({
            answerId: queryData.answerId,
            interactionId: queryData.interactionId,
          }),
        );
      },
    }),
    createAnswer: builder.mutation({
      query: (queryData) => ({
        url: `interactions/${queryData.interactionId}/answers/`,
        method: "POST",
        body: {
          content: queryData.content,
          reaction: queryData.reaction,
          is_correct: queryData.is_correct,
        },
      }),
      onQueryStarted: async (queryData, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          createAnswerFromPlaceholder({
            placeholderId: queryData.placeholderId,
            interactionId: queryData.interactionId,
            answerId: data.id,
            content: data.content,
            reaction: data.reaction,
            is_correct: data.is_correct,
          }),
        );
      },
    }),
    addLanguageFromLocalizedCourse: builder.mutation({
      query: ({ id, language }) => ({
        url: `localized-courses/${id}/add_language`,
        method: "POST",
        body: {
          language,
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          setLocalizedCourseDraftForLanguage({
            ...data,
          }),
        );
      },
    }),
    createDraftFromLocalizedCourse: builder.mutation({
      query: ({ id }) => ({
        url: `localized-courses/${id}/draft`,
        method: "POST",
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          setLocalizedCourseDraftForLanguage({
            ...data,
          }),
        );
      },
    }),
    importJson: builder.mutation({
      query: ({ jsonData, courseId, accessToken }) => ({
        url: `admin/courses/${courseId}/import_draft`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonData),
      }),
    }),
  }),
});

export const {
  useFetchCourseByIdQuery,
  useDeleteCourseByIdMutation,
  useCloneCourseMutation,
  usePatchLocalizedCourseMutation,
  useGetLocalizedCourseInteractionsQuery,
  usePatchInteractionContentMutation,
  useCreateInteractionMutation,
  useRemoveInteractionMutation,
  usePatchAnswerContentMutation,
  useDeleteAnswerMutation,
  useCreateAnswerMutation,
  usePublishLocalizedCourseMutation,
  useAddLanguageFromLocalizedCourseMutation,
  useCreateDraftFromLocalizedCourseMutation,
  usePublishAllLocalizedCoursesMutation,
  useImportJsonMutation,
} = courseEditorApi;

export const { addLanguageFromLocalizedCourse } = courseEditorApi.endpoints;
