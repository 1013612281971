import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";

import DetailsTabs, {
  DetailsTabType,
} from "@/pages/threats/components/details/DetailsTabs";
import DownloadEmlButton from "@/pages/threats/components/details/DownloadEmlButton";
import EmailHeaders from "@/pages/threats/components/details/EmailHeaders";
import WarningBanner from "@/pages/threats/components/details/WarningBanner";
import {
  useIsThreatDisplayed,
  useShowThreat,
} from "@/pages/threats/service/service";
import { DetailedThreat, Threat } from "@/pages/threats/service/types";

import EmailBody from "./EmailBody";

type NonVisibleMessageProps = {
  threat: Threat;
  onExpand: () => void;
};

const NonVisibleMessage = ({ threat, onExpand }: NonVisibleMessageProps) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ flex: 1, overflowY: "auto" }}>
      <WarningBanner bannerLevel={threat.bannerLevel} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          variant="outlined"
          color="neutral"
          sx={{ flex: "0 0 auto" }}
          onClick={onExpand}
          startIcon={<VisibilityIcon />}
        >
          {t("View body")}
        </Button>
      </Box>
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation={false} />
    </Box>
  );
};

type ContentProps = {
  threat: DetailedThreat;
};

const Content = ({ threat }: ContentProps) => {
  const [activeTab, setActiveTab] = useState<DetailsTabType>("body");

  const showContent = threat.reporter != null;
  const isExpanded = useIsThreatDisplayed(threat.id);
  const showThreat = useShowThreat();

  if (!isExpanded && !showContent) {
    return (
      <NonVisibleMessage
        threat={threat}
        onExpand={() => {
          showThreat(threat.id);
        }}
      />
    );
  }

  const DetailsTab = ({ activeTab }: { activeTab: DetailsTabType }) => {
    switch (activeTab) {
      case "headers":
        return <EmailHeaders headers={threat.details.headers} />;
      default:
        return <EmailBody body={threat.details.body} />;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <DetailsTabs onChange={setActiveTab} activeTab={activeTab} />
        <DownloadEmlButton threat={threat} />
      </Box>
      <Box sx={{ flex: 1, overflowY: "auto" }}>
        <DetailsTab activeTab={activeTab} />
      </Box>
    </>
  );
};

export default Content;
