import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FullPageSpinner } from "@/components/Spinner";
import StickyHeader from "@/components/StickyHeader";
import { CourseCardsContainerList } from "@/pages/awareness/courses/courses-catalog/CourseCardsContainerList";
import { CourseFilterBar } from "@/pages/awareness/courses/courses-catalog/CourseFilterBar";
import { ErrorCatalogMessage } from "@/pages/awareness/courses/courses-catalog/ErrorCatalogMessage";
import { ResearchBar } from "@/pages/awareness/courses/courses-catalog/ResearchBar";
import { SubscribeAllModal } from "@/pages/awareness/courses/courses-catalog/SubscribeAllModal";
import {
  selectCourseBySearch,
  selectIsDemoAcme,
  selectUserInfos,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { CourseCardsContainerListDraggable } from "@/pages/awareness/courses/courses-catalog/demo-course-catalog/CourseCardsContainerListDraggable";
import { EMPTY_COURSE_CATALOG_TRANSLATIONS } from "@/pages/awareness/courses/utils/translations";
import { useHasCourseCatalogAdminRights } from "@/utils/misc";

import { useCourseCatalog } from "./hooks";

type CourseCatalogProps = {
  isPublicWebchat: boolean;
};

export const CoursesCatalog = ({ isPublicWebchat }: CourseCatalogProps) => {
  const isCourseCatalogAdmin = useHasCourseCatalogAdminRights();
  const { isLoading, error } = useCourseCatalog(isPublicWebchat);
  const userInfos = useSelector(selectUserInfos);
  const [openModal, setOpenModal] = useState(false);
  const filteredCourse = useSelector(selectCourseBySearch);
  const isDemoAcme: boolean = useSelector(selectIsDemoAcme);
  const { t } = useTranslation();
  const empty_catalog_translation = useMemo(
    () =>
      EMPTY_COURSE_CATALOG_TRANSLATIONS[userInfos.language] ||
      EMPTY_COURSE_CATALOG_TRANSLATIONS["en"],
    [userInfos.language],
  );

  const onClickSubscribeAll = async () => {
    setOpenModal(true);
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (error) {
    return <div>{t("Error: Unable to fetch courses")}</div>;
  }
  return (
    <>
      {isCourseCatalogAdmin && !isPublicWebchat && (
        <StickyHeader height="120px">
          <ResearchBar onClickSubscribeAll={onClickSubscribeAll} />
          <CourseFilterBar />
        </StickyHeader>
      )}
      {filteredCourse.length > 0 && isCourseCatalogAdmin !== null ? (
        <>
          {isDemoAcme ? (
            <CourseCardsContainerListDraggable
              isCourseCatalogAdmin={isCourseCatalogAdmin}
              isPublicWebchat={isPublicWebchat}
              courses={filteredCourse}
              learningUserInfos={userInfos}
            />
          ) : (
            <CourseCardsContainerList
              isCourseCatalogAdmin={isCourseCatalogAdmin}
              isPublicWebchat={isPublicWebchat}
              courses={filteredCourse}
              learningUserInfos={userInfos}
            />
          )}
        </>
      ) : (
        !isCourseCatalogAdmin && (
          <ErrorCatalogMessage
            error_catalog_message_text={empty_catalog_translation}
          />
        )
      )}
      <SubscribeAllModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        filteredCourse={filteredCourse}
      />
    </>
  );
};
