import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

export function Level({ level, starsToNextLevel, starsPerLevel }) {
  const barPercentage = Math.floor(
    (1 - starsToNextLevel / starsPerLevel) * 100,
  );
  const { t } = useTranslation();

  return (
    <div>
      <Typography component={"span"} style={{ fontSize: 20, color: "black" }}>
        {t("Level")} {level} 🏆
      </Typography>
      <Typography component={"div"} style={{ color: "black" }}>
        {t("progressStarCount", { count: starsToNextLevel })}
      </Typography>

      <div
        className="progress"
        style={{ height: "5px", fontSize: "16px", marginBottom: "1rem" }}
      >
        <div
          className="progress-bar bg-danger"
          role="progressbar"
          style={{ width: barPercentage + "%" }}
          aria-valuenow={barPercentage}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
    </div>
  );
}
