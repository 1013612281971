import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export function useCompanyReminderStatus() {
  const [autoEngageEnabled, setAutoEngageEnabled] = useState(false);
  const [numScheduledReminders, setNumScheduledReminders] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    const token = await getAccessTokenSilently();

    try {
      setLoading(true);
      const response = await axios.get(
        import.meta.env.VITE_APP_ENDPOINT_LEARNING +
          "/reminders/company_status",
        { headers: { Authorization: `Bearer ${token}` } },
      );
      setAutoEngageEnabled(response.data.auto_engage);
      setNumScheduledReminders(
        response.data.number_of_scheduled_new_course_reminders,
      );
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const isError = !!error;

  return {
    autoEngageEnabled,
    numScheduledReminders,
    isError,
    loading,
    refetch: fetch,
  };
}
