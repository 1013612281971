import { createApi } from "@reduxjs/toolkit/query/react";

import { learningBaseQuery } from "@/utils/rtkQuery";

type DeploymentMode = "company" | "departments" | "users";

interface UserToDeploy {
  id: number;
  email: string;
}
interface DepartementToDeploy {
  id: number;
  name: string;
}
interface TeamsInstallation {
  users_to_deploy: UserToDeploy[];
  deployed_departments: DepartementToDeploy[];
  deploy_bot_consent: boolean;
  mode: DeploymentMode;
  users_deployed: number[];
  bot_name: string;
  nonce: string;
}

interface DeployUsersParams {
  company_id: number;
  user_ids: number[];
}

interface DeployDepartments {
  company_id: number;
  department_ids: number[];
}

export const teamsInstallationApi = createApi({
  reducerPath: "teams-installation-api",
  baseQuery: learningBaseQuery(),
  endpoints: (builder) => ({
    installation: builder.query<TeamsInstallation, number>({
      query: (company_id) => ({
        url: "/teams/installation/" + company_id,
        method: "GET",
      }),
    }),
    deployCompanyWide: builder.mutation<void, number>({
      query: (company_id) => ({
        url: "/teams/deploy-all",
        method: "POST",
        body: { company_id: company_id },
      }),
    }),
    deployUsers: builder.mutation<void, DeployUsersParams>({
      query: (params) => ({
        url: "teams/deploy",
        method: "POST",
        body: params,
      }),
    }),
    deployDepartments: builder.mutation<void, DeployDepartments>({
      query: (params) => ({
        url: "teams/deploy-department",
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const {
  useInstallationQuery,
  useDeployCompanyWideMutation,
  useDeployUsersMutation,
  useDeployDepartmentsMutation,
} = teamsInstallationApi;
