import React from "react";
import { useTranslation } from "react-i18next";

import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton, Stack, Typography } from "@mui/material";

const DocumentationItem = ({ title, link, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <Typography color="textSecondary">{t(title)}</Typography>
      <IconButton
        onClick={() => window.open(link, "_blank")}
        disabled={isDisabled}
      >
        <LaunchIcon />
      </IconButton>
    </Stack>
  );
};

export default DocumentationItem;
