import React from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import { BrowserIcon } from "@/components/icons/BrowserIcon";
import { FolderDownloadIcon } from "@/components/icons/FolderDownloadIcon";
import { PasswordExposedIcon } from "@/components/icons/PasswordExposedIcon";
import GenericCard from "@/pages/browser-defender/components/GenericCard";

export function LatestActivityCardDemo() {
  const { t } = useTranslation();

  const latestAlerts = [
    {
      event_type: "Password alert",
      target: "not-risky-domain.io",
      event_date: "11-03-2024 09:45",
    },
    {
      event_type: "Password alert",
      target: "phishy-domain.io",
      event_date: "10-03-2024 10:59",
    },
    {
      event_type: "Dangerous download",
      target: "dangerous_site.fr",
      event_date: "10-03-2024 09:33",
    },
    {
      event_type: "Unsafe navigation",
      target: "domain-update.xyz",
      event_date: "07-03-2024 17:25",
    },
    {
      event_type: "Password alert",
      target: "evil-domain.io",
      event_date: "04-03-2024 09:25",
    },
  ];

  return (
    <GenericCard title={"Latest activity"} isLoading={false}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          marginTop: "1rem",
        }}
      >
        {latestAlerts &&
          latestAlerts.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: "1rem",
              }}
            >
              <Box sx={{ marginRight: "1rem" }}>
                {item.event_type === "Password alert" && (
                  <PasswordExposedIcon fontSize="18px" color="primary" />
                )}
                {item.event_type === "Dangerous download" && (
                  <FolderDownloadIcon fontSize="18px" color="primary" />
                )}
                {item.event_type === "Unsafe navigation" && (
                  <BrowserIcon fontSize="18px" color="primary" />
                )}
              </Box>
              <Box>
                <b>{item.event_type}</b> {t("on")} <b>{item.target}</b>{" "}
                {t("on")} <i>{item.event_date}</i>
              </Box>
            </Box>
          ))}
      </Box>
    </GenericCard>
  );
}
