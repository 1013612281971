import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useTheme } from "@mui/material";

export default function BarChartStacked({ data, labels }) {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height={200} style={{ padding: 0 }}>
      <BarChart
        data={data}
        barGap={-22}
        margin={{ left: 0, top: 10, right: 40, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray={5} vertical={false} />
        <XAxis dataKey="interval" />
        <YAxis />
        <Tooltip />
        <Legend
          formatter={(value) => (
            <span style={{ color: theme.palette.text.secondary }}>{value}</span>
          )}
        />
        {labels.map((label, index) => {
          return (
            <Bar
              barSize={22}
              key={index}
              name={label.name}
              dataKey={label.key}
              stackId={index}
              fill={label.color}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}
