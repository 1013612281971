import React from "react";

import { TemplateHeader } from "./TemplateHeader";
import { TemplateListSection } from "./TemplateListSection";

export const TemplateCatalog = () => {
  return (
    <div>
      <TemplateHeader />
      <TemplateListSection />
    </div>
  );
};
