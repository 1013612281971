import React from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { BasicStatCard } from "@/components/dashboard/BasicStatCard";
import { DownloadComplete } from "@/components/icons/DownloadComplete";
import { FolderDownloadIcon } from "@/components/icons/FolderDownloadIcon";
import { UserIcon } from "@/components/icons/UserIcon";

export default function StatCards({ data }) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item md={4} sm={6} xs={12}>
        <BasicStatCard
          title={t("Dangerous Browsing Alerts")}
          icon={<FolderDownloadIcon fontSize="32px" color="primary" />}
          data={data.values && data.values.events_count}
          isLoading={data.loading}
          isError={data.error}
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <BasicStatCard
          title={t("Users Triggering Dangerous Browsing Alerts")}
          icon={<UserIcon fontSize="32px" color="primary" />}
          data={data.values && data.values.users_count}
          isLoading={data.loading}
          isError={data.error}
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <BasicStatCard
          title={t("Dangerous Browsing Completed")}
          icon={<DownloadComplete fontSize="32px" color="primary" />}
          data={data.values && data.values.completed_navigation_count}
          isLoading={data.loading}
          isError={data.error}
        />
      </Grid>
    </>
  );
}
