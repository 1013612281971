import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { ThemeProvider } from "@mui/material/styles";

import CustomCampaignList from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignList";
import { CustomCampaignTheme } from "@/pages/phishing-simulation/CustomCampaign/theme";
import { SimulationAccess } from "@/types/user";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useBackend } from "@/utils/misc";

export default function CustomCampaign() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { data, isPending } = useBackend(
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
      "/custom_campaign/list_custom_campaigns",
  );

  const onClickCreate = () => {
    navigate(location.pathname + "/create");
  };

  const user = useUserContext();
  const simulationAccess = user.current_company?.simulation_access;

  if (
    simulationAccess === SimulationAccess.NO_ACCESS ||
    simulationAccess === SimulationAccess.TRIAL_MODE
  ) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h5" align="center">
          {simulationAccess === SimulationAccess.NO_ACCESS
            ? t(
                "Your company doesn't have access to phishing simulations. Ask Mantra to provide full access to display this page.",
              )
            : t(
                "This page is only accessible with a full subscription, once your audit has been completed.",
              )}
        </Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={CustomCampaignTheme}>
      <Card>
        <CardHeader
          title={t("Custom Campaign")}
          action={
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={onClickCreate}
            >
              {t("Create new campaign")}
            </Button>
          }
        />
        <Divider />
        <CardContent style={{ height: "100%" }}>
          {data?.campaigns?.length ? (
            <>
              <Box height={"10px"}>{isPending && <LinearProgress />}</Box>
              <CustomCampaignList listCampaigns={data.campaigns} />
            </>
          ) : (
            <Box
              display={"flex"}
              height={"80%"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              {isPending ? (
                <CircularProgress />
              ) : (
                <>
                  <IconButton
                    aria-label="create-new-campaign"
                    color="primary"
                    onClick={onClickCreate}
                  >
                    <NoteAddOutlinedIcon sx={{ fontSize: "5rem" }} />
                  </IconButton>
                  <Typography sx={{ fontWeight: "unset", fontSize: "16px" }}>
                    {t("Click on")}{" "}
                    <strong>“{t("Create a new campaign")}”</strong>{" "}
                    {t("to create your first phishing campaign")}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}
