import React from "react";

import { Typography } from "@mui/material";

import { Mailbox } from "@/pages/threats/service/domain";

type MailboxTextProps = {
  label: string;
  mailbox: Mailbox;
};

const MailboxText = ({ label, mailbox }: MailboxTextProps) => {
  if (mailbox.name) {
    return (
      <Typography style={{ overflowWrap: "anywhere" }}>
        {label}: <strong>{mailbox.name}</strong> ({mailbox.email})
      </Typography>
    );
  } else {
    return (
      <Typography style={{ overflowWrap: "anywhere" }}>
        {label}: <strong>{mailbox.email}</strong>
      </Typography>
    );
  }
};

export default MailboxText;
