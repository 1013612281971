import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, Fade, useTheme } from "@mui/material";

export function ButtonSpinner({ size = "1.5rem", ...props }) {
  return <CircularProgress size={size} {...props} />;
}

type SpinnerProps = {
  size?: string;
};

export const Spinner = ({ size = "50px" }: SpinnerProps) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "auto",
      }}
    >
      <CircularProgress
        size={size}
        sx={{ color: theme.palette.primary.main }}
      />
    </div>
  );
};

type BigSpinnerProps = {
  withText?: boolean;
  text?: string;
  center?: boolean;
};

export function BigSpinner({
  withText = false,
  text = null,
  center = false,
}: BigSpinnerProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [textVisible, setTextVisible] = useState(false);

  if (!text) {
    text = t("This may take a while...");
  }

  setTimeout(() => {
    setTextVisible(true);
  }, 3000);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flex: center ? 1 : 0,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {withText && (
          <Fade in={textVisible}>
            <div style={{ height: "1em" }}>{text}</div>
          </Fade>
        )}
        <CircularProgress
          size="100px"
          sx={{ color: theme.palette.primary.main }}
        />
      </div>
    </div>
  );
}

export const FullPageSpinner = () => <BigSpinner center withText />;
