import React from "react";

import { Chip } from "@mui/material";

interface ExtensionGenerationBadgeProps {
  generation: string;
}

export const ExtensionGenerationBadge = ({
  generation,
}: ExtensionGenerationBadgeProps) => {
  const label =
    generation === "enterprise"
      ? "Enterprise"
      : generation === "legacy"
        ? "Legacy"
        : "...";
  const color = generation === "enterprise" ? "primary" : "default";

  return <Chip label={label} color={color} size="small" variant="outlined" />;
};
