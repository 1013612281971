import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import TableCell from "@mui/material/TableCell";

import { T } from "@/components/LocalizedString";
import ActionChip from "@/pages/browser-defender/components/ActionChip";
import CollapsibleTable from "@/pages/browser-defender/components/CollapsibleTable";
import RiskChip from "@/pages/browser-defender/components/RiskChip";
import StyledTableRow from "@/pages/browser-defender/styles/StyledTableRow";

const riskColors = {
  high: {
    bgColor: "#FEE9EF8C",
    textColor: "#F6285F",
    icon: <WarningAmberIcon color="#FEE9EF" />,
    label: T("High"),
  },
  medium: {
    bgColor: "#FEF5ED8C",
    textColor: "#F2994A",
    icon: <ErrorOutlineOutlinedIcon color="#FEF5ED" />,
    label: T("Medium"),
  },
  default: {
    bgColor: "#90EE907D",
    textColor: "#65C58B",
    icon: <CheckCircleOutlineOutlinedIcon color="#65c58b" />,
    label: null,
  },
};

const actionColors = {
  cancel: { bgColor: "rgba(255, 175, 44, 0.25)", textColor: "#BE7A0C" },
  navigate_to: { bgColor: "rgba(255, 175, 44, 0.5)", textColor: "#BE7A0C" },
  default: { bgColor: "rgba(255, 175, 44, 0.25)", textColor: "#B50A3E" },
};

const filterColumns = [
  "email",
  "url",
  "user_action",
  "danger_level",
  "browser",
];

export default function DataTable({ data, setData }) {
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const actionMapping = {
    cancel: t("Cancelled"),
    navigate_to: t("Visited"),
  };

  const headers = [
    { label: t("Time (UTC)"), alignment: "left" },
    { label: t("Email"), alignment: "left" },
    { label: t("Url"), alignment: "left" },
    { label: t("Risk level"), alignment: "center" },
    { label: t("Browser"), alignment: "center" },
    { label: t("User action"), alignment: "center" },
  ];

  const sortingColumns = [
    { label: t("Email"), name: "email" },
    { label: t("Url"), name: "url" },
    { label: t("User action"), name: "user_action" },
    { label: t("Browser"), name: "browser" },
    { label: t("Risk level"), name: "danger_level" },
  ];

  useEffect(() => {
    let filteredValues = data.values;

    if (searchTerm) {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          let value;

          try {
            value = row[attribute].toLowerCase();
          } catch {
            value = row[attribute];
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    function parseDates(stringDate) {
      let [dateComp, timeComp] = stringDate.split(" ");
      let [day, month, year] = dateComp.split("-");
      let [hours, minutes] = timeComp.split(":");
      return new Date(+year, +month - 1, +day, +hours, +minutes);
    }

    if (sorting) {
      filteredValues.sort((p1, p2) => {
        let condition = 1;
        for (let sortRule of sorting) {
          if (sortRule.column === "event_date") {
            const parsedDate1 = parseDates(p1[sortRule.column]);
            const parsedDate2 = parseDates(p2[sortRule.column]);

            condition *=
              parsedDate1 < parsedDate2
                ? 1 * sortRule.direction
                : parsedDate1 > parsedDate2
                  ? -1 * sortRule.direction
                  : 0;
            continue;
          }
          condition *=
            p1[sortRule.column] < p2[sortRule.column]
              ? -1 * sortRule.direction
              : p1[sortRule.column] > p2[sortRule.column]
                ? 1 * sortRule.direction
                : 0;
        }
        return condition;
      });
    }

    setData({
      values: data.values,
      filteredValues: filteredValues,
      loading: false,
    });
  }, [searchTerm, sorting, data.values, setData]);

  return (
    <CollapsibleTable
      headers={headers}
      sortingColumns={sortingColumns}
      setSorting={setSorting}
      setSearchTerm={setSearchTerm}
      defaultOrder={[{ column: "event_date", direction: 1 }]}
      rows={data.filteredValues.map((row, index) => {
        return (
          <StyledTableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row" align={headers[0].alignment}>
              {row.event_date}
            </TableCell>
            <TableCell align={headers[1].alignment}>
              <Link to="#">{row.email}</Link>
            </TableCell>
            <TableCell align={headers[2].alignment}>{row.url}</TableCell>
            <TableCell align={headers[3].alignment}>
              <RiskChip danger_level={row.danger_level} colors={riskColors} />
            </TableCell>
            <TableCell align={headers[4].alignment}>{row.browser}</TableCell>
            <TableCell align={headers[5].alignment}>
              <ActionChip
                data={row}
                actionMapping={actionMapping}
                colors={actionColors}
              />
            </TableCell>
          </StyledTableRow>
        );
      })}
    />
  );
}
