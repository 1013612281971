import React from "react";

import { Tooltip } from "@mui/material";

import css from "@/pages/my-progress/badges/Badge.module.css";

export interface BadgeProps {
  icon: string;
  title: string;
  tooltip: string;
  enabled: boolean;
  inverted?: boolean;
}

export const Badge: React.FC<BadgeProps> = ({
  icon,
  title,
  tooltip,
  enabled,
  inverted,
}) => (
  <div
    className={`${enabled ? "" : css.grayout} ${
      inverted ? css.badgeInverted : css.badge
    }`}
  >
    <div className={css.icon}>
      <Tooltip disableInteractive title={tooltip} placement="top" arrow>
        <img src={icon} alt={title} />
      </Tooltip>
    </div>
    <div className={css.title}>
      <div>{title}</div>
    </div>
  </div>
);
