import * as React from "react";
import { useTranslation } from "react-i18next";

import { Card, CardContent, Grid } from "@mui/material";
import Box from "@mui/material/Box";

import { BigSpinner } from "@/components/Spinner";
import ExtensionDetailsCard from "@/pages/browser-defender/extensionGovernance/ExtensionDetailsCard";
import ExtensionsTable from "@/pages/browser-defender/extensionGovernance/ExtensionsTable";
import BasicTabs from "@/pages/browser-defender/extensionGovernance/TabsComponent";

const LoadingContentSpinner = ({ loading, children }) => {
  if (loading) {
    return <BigSpinner />;
  }
  return children;
};

export default function DataPerCompany({
  content,
  onExtensionSelect,
  onTabChange,
  selectedExtension,
  loading,
}) {
  const [activeTab, setActiveTab] = React.useState(0);
  const { t } = useTranslation();

  const allowedExtensions = content.filter((ext) => !ext.blocked);
  const blockedExtensions = content.filter((ext) => ext.blocked);

  React.useEffect(() => {
    onTabChange(activeTab === 0 ? "allowed" : "blocked");
  }, [activeTab, onTabChange]);

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
    onExtensionSelect(null);
  };

  return (
    <Box style={{ height: "100%" }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={5} style={{ height: "100%" }}>
          <Card>
            <CardContent>
              <BasicTabs
                tabsLabels={[
                  t("Allowed.female_plural"),
                  t("Blocked.female_plural"),
                ]}
                tabsContent={[
                  <LoadingContentSpinner loading={loading} key="allowed">
                    <ExtensionsTable
                      values={allowedExtensions}
                      setCurrentExtension={onExtensionSelect}
                      selectedExtension={selectedExtension}
                    />
                  </LoadingContentSpinner>,
                  <LoadingContentSpinner loading={loading} key="blocked">
                    <ExtensionsTable
                      values={blockedExtensions}
                      setCurrentExtension={onExtensionSelect}
                      selectedExtension={selectedExtension}
                    />
                  </LoadingContentSpinner>,
                ]}
                value={activeTab}
                onChange={handleTabChange}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7} style={{ height: "100%" }}>
          {selectedExtension ? (
            <Box style={{ height: "100%" }}>
              <ExtensionDetailsCard row={selectedExtension} />
            </Box>
          ) : (
            <Card sx={{ height: "100%" }} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
