import React from "react";

import { createSvgIcon } from "@mui/material";

export const DoubleCircleIcon = createSvgIcon(
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.5125"
      transform="rotate(90 11 11)"
      fill="#fff"
    />
    <circle
      cx="11"
      cy="11"
      r="10.3125"
      transform="rotate(90 11 11)"
      stroke="#4F46E5"
      strokeWidth="1.375"
    />
    <circle
      cx="11"
      cy="11"
      r="3.4375"
      transform="rotate(90 11 11)"
      fill="#4F46E5"
    />
  </svg>,
  "DoubleCircleIcon",
);

export const GreyDoubleCircleIcon = createSvgIcon(
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.5125"
      transform="rotate(90 11 11)"
      fill="#fff"
    />
    <circle
      cx="11"
      cy="11"
      r="10.3125"
      transform="rotate(90 11 11)"
      stroke="#9CA3AF"
      strokeWidth="1.375"
    />
    <circle
      cx="11"
      cy="11"
      r="3.4375"
      transform="rotate(90 11 11)"
      fill="#D1D5DB"
    />
  </svg>,
  "GreyDoubleCircleIcon",
);
