import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Grid, Typography } from "@mui/material";

import { StatsCard } from "@/components/dashboard/StatsCard";
import { BrowserIcon } from "@/components/icons/BrowserIcon";
import { FolderDownloadIcon } from "@/components/icons/FolderDownloadIcon";
import { PasswordExposedIcon } from "@/components/icons/PasswordExposedIcon";
import { ShieldIcon } from "@/components/icons/ShieldIcon";

export default function StatCards({ error, eventsData }) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <StatsCard
          title={t("Users with active extensions")}
          icon={<ShieldIcon fontSize="32px" color="primary" />}
          link={"/browser-defender/users"}
          isLoading={eventsData.loading}
          isError={error.events}
        >
          <Typography variant="h3" sx={{ marginBottom: "15px" }}>
            {eventsData.values.extensions &&
              eventsData.values.extensions.active}
          </Typography>

          <Box
            sx={{
              fontSize: "2rem",
              textAlign: "left",
              fontWeight: "500",
              lineHeight: "1.2",
              color: "#646c9a",
              marginTop: "1rem",
            }}
          >
            <Typography>
              {t("Active w/o password")}:&nbsp;
              {eventsData.values.extensions &&
                eventsData.values.extensions.with_partial_defense}
            </Typography>
            <Typography>
              {t("Disabled by user")}:&nbsp;
              {eventsData.values.extensions &&
                eventsData.values.extensions.disabled}
            </Typography>
            <Typography>
              {t("Last seen +30 days ago")}:&nbsp;
              {eventsData.values.extensions &&
                eventsData.values.extensions.inactive}
            </Typography>
          </Box>
        </StatsCard>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <StatsCard
          title={t("Password alerts")}
          icon={<PasswordExposedIcon fontSize="32px" color="primary" />}
          link={"/browser-defender/password-misuse"}
          isLoading={eventsData.loading}
          isError={error.events}
        >
          <Typography variant="h3" sx={{ marginBottom: "15px" }}>
            {eventsData.values.password_misuse &&
              eventsData.values.password_misuse.count}
          </Typography>

          <Box
            sx={{
              fontSize: "2rem",
              textAlign: "left",
              fontWeight: "500",
              lineHeight: "1.2",
              color: "#646c9a",
              marginTop: "1rem",
            }}
          >
            <Typography>
              {t("With domain set as trusted")}:&nbsp;
              {
                eventsData.values.password_misuse &&
                  // <Link
                  //   style={{ fontWeight: "bold" }}
                  //   to={`/browser-defender/password-misuse?filter=mark_as_safe`}
                  // >
                  // {
                  eventsData.values.password_misuse.with_domain_set_as_safe
                // }
                // </Link>
              }
            </Typography>
          </Box>
        </StatsCard>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <StatsCard
          title={t("Dangerous download alerts")}
          icon={<FolderDownloadIcon fontSize="32px" color="primary" />}
          link="/browser-defender/dangerous-downloads"
          isLoading={eventsData.loading}
          isError={error.events}
        >
          <Typography variant="h3" sx={{ marginBottom: "15px" }}>
            {eventsData.values.dangerous_downloads &&
              eventsData.values.dangerous_downloads.count}
          </Typography>

          <Box
            sx={{
              fontSize: "2rem",
              textAlign: "left",
              fontWeight: "500",
              lineHeight: "1.2",
              color: "#646c9a",
              marginTop: "1rem",
            }}
          >
            <Typography>
              {t("With file downloaded")}:&nbsp;
              {eventsData.values.dangerous_downloads &&
                eventsData.values.dangerous_downloads.completed}
            </Typography>
          </Box>
        </StatsCard>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <StatsCard
          title={t("Dangerous browsing alerts")}
          icon={<BrowserIcon fontSize="32px" color="primary" />}
          link="/browser-defender/dangerous-browsing"
          isLoading={eventsData.loading}
          isError={error.events}
        >
          <Typography variant="h3" sx={{ marginBottom: "15px" }}>
            {eventsData.values.unsafe_navigation &&
              eventsData.values.unsafe_navigation.count}
          </Typography>

          <Box
            sx={{
              fontSize: "2rem",
              textAlign: "left",
              fontWeight: "500",
              lineHeight: "1.2",
              color: "#646c9a",
              marginTop: "1rem",
            }}
          >
            <Typography>
              {t("With page visited")}:&nbsp;
              {eventsData.values.unsafe_navigation &&
                eventsData.values.unsafe_navigation.completed}
            </Typography>
          </Box>
        </StatsCard>
      </Grid>
    </>
  );
}
