import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "@mui/material";

export function ImportButton() {
  const { t } = useTranslation();
  return (
    <Button variant="contained">
      <Link to="/users/import" style={{ color: "white" }}>
        {t("Import User")}
      </Link>
    </Button>
  );
}
