import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Card, CardContent, CardHeader } from "@mui/material";

export function Confirmation() {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader title="Import Users - Step 4/4: Confirmation" />
      <CardContent>
        <p>
          {t("Your users have been successfully imported.")}
          <br />
        </p>
        <div>
          <Button variant="contained">
            <Link to="/settings" style={{ color: "white" }}>
              {t("Back to Settings")}
            </Link>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
