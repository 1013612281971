import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import { Spinner } from "@/components/Spinner";
import { CopyToClipboard } from "@/pages/browser-defender/components/CopyToClipboard";
import { BrowserDefenderGenerations } from "@/pages/browser-defender/config";
import { useUserContext } from "@/utils/contexts/UserContext";
import { sentryCaptureException } from "@/utils/sentry";

export function ManualDeployment() {
  const { t } = useTranslation();
  const [config, setConfig] = useState({
    enterpriseToken: null,
    idProvider: null,
    extensionGeneration: null,
    extensionID: null,
  });
  const [loading, setLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchConfig = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = `${
        import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
      }/setup/company`;

      let resource;

      try {
        resource = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          setConfig({
            enterpriseToken: null,
            idProvider: null,
            extensionGeneration: null,
            extensionID: null,
          });
          setLoading(false);
          return;
        }
      }

      setConfig({
        enterpriseToken: resource.data.enterprise_token,
        idProvider: resource.data.id_provider_configuration,
        extensionGeneration: resource.data.extension_generation,
        extensionID:
          resource.data.extension_generation === "legacy"
            ? BrowserDefenderGenerations.legacy
            : BrowserDefenderGenerations.enterprise,
      });
      setLoading(false);
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchConfig();
  }, [fetchConfig, info_user]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <Box>
          <Stack spacing={2} style={{ marginBottom: "3rem" }}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={4}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Container>
                <Typography>
                  <Trans i18nKey="manualDeploymentBrowserDefender.Chromium">
                    <b>Chromium</b> browsers (<b>Chrome</b>, <b>Edge</b>,{" "}
                    <b>Brave</b>, <b>Vivaldi</b>, <b>Opera</b>, etc)
                  </Trans>
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="2.5rem"
                  sx={{ mt: "1rem" }}
                >
                  <Button
                    variant="contained"
                    onClick={() =>
                      window.open(
                        `https://chrome.google.com/webstore/detail/${config.extensionID}`,
                        "_blank",
                      )
                    }
                  >
                    {t("Take me to the Chrome Web Store page")}
                  </Button>
                </Box>
              </Container>
              {config.extensionGeneration === "enterprise" && (
                <Container>
                  <Typography>
                    <Trans i18nKey="manualDeploymentBrowserDefender.Firefox">
                      <strong>Mozilla Firefox</strong> browsers
                    </Trans>
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="2.5rem"
                    sx={{ mt: "1rem" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() =>
                        window.open(
                          `https://addons.mozilla.org/en-US/firefox/addon/mantra-browser-defender-e/`,
                          "_blank",
                        )
                      }
                    >
                      {t("Take me to the Firefox Add-ons store page")}
                    </Button>
                  </Box>
                </Container>
              )}
            </Stack>
            <Divider
              textAlign="left"
              style={{ marginBottom: "1rem", marginTop: "3rem" }}
            >
              <Typography style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
                {t("Documentation & Resources")}
              </Typography>
            </Divider>

            <CopyToClipboard
              callToAction={t("Copy Enterprise Token")}
              content={config.enterpriseToken}
            />
          </Stack>
        </Box>
      )}
    </>
  );
}
