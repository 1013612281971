import React from "react";
import { useTranslation } from "react-i18next";

import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import MailboxText from "@/pages/threats/components/MailboxText";
import { ReporterMetadata } from "@/pages/threats/components/ReporterMetadata";
import { Threat } from "@/pages/threats/service/types";

type MetadataProps = {
  threat: Threat;
};

const Metadata = ({ threat }: MetadataProps) => {
  const receivedDate = threat.receivedDate.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const { t } = useTranslation();

  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Typography variant="h3">{threat.subject}</Typography>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={{ paddingTop: "10px" }}
      >
        <Stack spacing={1}>
          <MailboxText label={t("From")} mailbox={threat.sender} />
          <MailboxText label={t("To")} mailbox={threat.receiver} />
          <Typography>
            {t("Received date")}: {receivedDate}
          </Typography>
        </Stack>
        {threat.reporter && <ReporterMetadata threat={threat} />}
      </Stack>
    </Box>
  );
};

export default Metadata;
