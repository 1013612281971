import dayjs from "dayjs";

// Simulation constants
export const SIMULATION_SCRIBEHOW_REPORT_BUTTON_DEPLOYMENT_OFFICE: string =
  "https://scribehow.com/shared/Deploy_the_Oulook_Mantra_report_button__GfOn5w14RjWNdDHPjjrg-Q";
export const SIMULATION_SCRIBEHOW_REPORT_BUTTON_DEPLOYMENT_GMAIL: string =
  "https://scribehow.com/shared/Install_Mantra_Phishing_Report_Button_in_Google_Workspace__kY_H3dhESJGbY2T4CY8cCg";
export const SIMULATION_SCRIBEHOW_SETUP_MICROSOFT_SSO_API_SYNC: string =
  "https://scribehow.com/page-embed/Setup_For_Microsoft_Simulation_SSO_API_Sync__YYb0p6qZTPObuQnhE2gj8w";
export const SIMULATION_SCRIBEHOW_SETUP_GOOGLE_SSO_API_SYNC: string =
  "https://scribehow.com/page/Setup_for_Google_Simulation_SSO_API_Sync__xYsiB6buQRu_ZFuN9eF1Ug";
export const SIMULATION_SCRIBEHOW_ADD_COMPANY_SIGNATURE: string =
  "https://scribehow.com/shared/How_to_Add_a_Company_Signature__6dp_4Yd8SAeODm5lZacO8w";

// Awareness constants
export const AWARENESS_SCRIBEHOW_INSTALL_ALEX_BOT_TEAMS: string =
  "https://scribehow.com/shared/Install_Alex_Bot_on_Teams__PJCVKUY8SNmipPlbb0zv5Q";
export const AWARENESS_SCRIBEHOW_SETUP_TEAMS: string =
  "https://scribehow.com/page-embed/Awareness_Setup_Teams__bEY7pwTITLubuarJG74nlg";
export const AWARENESS_SCRIBEHOW_SETUP_SLACK: string =
  "https://scribehow.com/page-embed/Awareness_Setup_Slack__ODwVnGP6QVmqlMuSu0uAAg";
export const AWARENESS_SCRIBEHOW_TROUBLESHOOTING_TEAMS: string =
  "https://scribehow.com/shared/Troubleshooting_Alex_store_version_deployment_on_Teams__SfZDAuazSeCd6cfSwOj-gg";

// Banners constants
export const BANNERS_SCRIBEHOW_ON_GOOGLE_URL: string =
  "https://scribehow.com/shared/Google_Allow_Mantra_to_display_Banners_to_your_users__9n8BRGLuRDiNPc-6GHzLtA";
export const BANNERS_SCRIBEHOW_ON_GOOGLE_GRANT_CONSENT_URL: string =
  "https://scribehow.com/shared/Google_Allow_Mantra_to_display_Banners_to_your_users__9n8BRGLuRDiNPc-6GHzLtA#155c4c59";
export const BANNERS_SCRIBEHOW_ON_OFFICE_URL: string =
  "https://scribehow.com/shared/Office_Allow_Mantra_to_display_Banners_to_your_users__5F1dEVMVQ6e2a9dNM6vmQA";

// Browser constants
type DeploymentBrowsers = {
  BRAVE: string;
  CHROME: string;
  EDGE: string;
  FIREFOX: string;
  VIVALDI: string;
};

type DeploymentMode = {
  INTUNE: DeploymentBrowsers;
  GPO: DeploymentBrowsers;
  GWS: string;
  MANUAL: string;
};

type BrowserExtensionGeneration = {
  legacy: DeploymentMode;
  enterprise: DeploymentMode;
};

export const BROWSER_SCRIBEHOW_DEPLOYMENT_POLICIES: BrowserExtensionGeneration =
  {
    legacy: {
      INTUNE: {
        CHROME:
          "https://scribehow.com/shared/ChromeIntune_Create_Browser_Defender_Deployment_Policy__ai2j6lxRRKKighdM3mgfkw",
        EDGE: "https://scribehow.com/shared/EdgeIntune_Create_Browser_Defender_Deployment_Policy__O5hWvdbRTaO_6fgrzi-Hhw",
        BRAVE: "",
        VIVALDI: "",
        FIREFOX: "",
      },
      GPO: {
        CHROME:
          "https://scribehow.com/shared/ChromeGPO_Create_Browser_Defender_Deployment_Policy__GZhyz-pjQPmWymNt6sJxxQ",
        EDGE: "https://scribehow.com/shared/EdgeGPO_Create_Browser_Defender_Deployment_Policy__XsudHyJZSLqGuMIZPL7kxw",
        BRAVE: "",
        VIVALDI: "",
        FIREFOX: "",
      },
      GWS: "https://scribehow.com/shared/Create_Browser_Defender_Deployment_Policy__HY4yFajMTQmmGz68fM0Jdw",
      MANUAL:
        "https://scribehow.com/shared/Create_Browser_Defender_Deployment_Policy__HY4yFajMTQmmGz68fM0Jdw",
    },
    enterprise: {
      INTUNE: {
        CHROME:
          "https://scribehow.com/shared/IntuneChromeEnterprise_Create_Browser_Defender_Deployment_Policy__xh0Q-WvjTf6VSYtlUDh12w",
        EDGE: "https://scribehow.com/shared/IntuneEdgeEnterprise_Create_Browser_Defender_Deployment_Policy__Ln6IuQ3bRF2RBex0C6sS2Q",
        BRAVE:
          "https://scribehow.com/shared/IntuneBraveEnterprise_Create_Browser_Defender_Deployment_Policy__JqTv5FoLQkGwmYbBrqjo8g",
        VIVALDI:
          "https://scribehow.com/shared/IntuneVivaldi_Create_Browser_Defender_Deployment_Policy__l-337E4KShG0R_HWGLSshA",
        FIREFOX:
          "https://scribehow.com/shared/IntuneFirefoxEnterprise_Create_Browser_Defender_Deployment_Policy__FXOdAAHgRC-P2vC3I7HfTA",
      },
      GPO: {
        CHROME:
          "https://scribehow.com/shared/GPOChromeEnterprise_Create_Browser_Defender_Deployment_Policy__VLA93g5fQPKuFvF3AF-wzg",
        EDGE: "https://scribehow.com/shared/GPOEdgeEnterprise_Create_Browser_Defender_Deployment_Policy__ZakeiFPxR9aWoId3_8-P8A",
        BRAVE:
          "https://scribehow.com/shared/GPOBraveEnterprise_Create_Browser_Defender_Deployment_Policy__QXuJyx_aTouaeRgI4Q_WEQ",
        VIVALDI:
          "https://scribehow.com/shared/GPOVivaldiEnterprise_Create_Browser_Defender_Deployment_Policy__GKuDjcxQTiKMG9GrlgdhAw",
        FIREFOX:
          "https://scribehow.com/shared/GPOFirefoxEnterprise_Create_Browser_Defender_Deployment_Policy__BfMAYKhXRU6qIUQKLCmiZA",
      },
      GWS: "https://scribehow.com/shared/Enterprise_Create_Browser_Defender_Deployment_Policy__wK2PB1wdTP-P1u_3E0xXtg",
      MANUAL:
        "https://scribehow.com/shared/Create_Browser_Defender_Deployment_Policy__HY4yFajMTQmmGz68fM0Jdw",
    },
  };

// Mantra constants
export const MANTRA_WEBSITE_URL: string = "https://mantra.ms";
export const MANTRA_CREATION_DATE: dayjs.Dayjs = dayjs("2020-12-17");
export const MANTRA_DOMAINS: Array<string> = [
  "mantra.ms",
  "getplexx.com",
  "mantrams.onmicrosoft.com",
  "getplexx.onmicrosoft.com",
];
export const MANTRA_SCRIBEHOW_COMMON_ACTIONS: string =
  "https://scribehow.com/page-embed/Common_Actions__0rQKj4gjRQOASQ45d4FPDQ";
