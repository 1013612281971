import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "@mui/material";
import { Typography } from "@mui/material";

import { extractErrorMessage } from "@/utils/misc";

const AdditionalText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-left: 1rem;
`;

export function AddDepartment({ departments, setDepartments }) {
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const add = async () => {
    const accessToken = await getAccessTokenSilently();
    if (name !== "") {
      try {
        const department = {
          name: name,
          saas: [],
        };

        const result = await axios.post(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/departments",
          department,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        setDepartments([...departments, result.data]);
        setName("");
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    }
  };
  const { t } = useTranslation();
  const nameAlreadyExists = departments.map((dpt) => dpt.name).includes(name);

  const submitOnEnter = (e) => {
    // the enter key according to: https://stackoverflow.com/a/47873678
    if (e.which === 13 && !nameAlreadyExists) {
      add();
    }
  };

  return (
    <>
      <div className="col-md-2">
        <input
          type="text"
          className="form-control"
          placeholder=""
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyPress={(e) => submitOnEnter(e)}
        />
      </div>

      <Button
        variant="outlined"
        color="neutral"
        onClick={add}
        disabled={nameAlreadyExists}
      >
        {t("Add Department")}
      </Button>

      <AdditionalText>
        {nameAlreadyExists && (
          <Typography style={{ color: "orange" }}>
            {t("This department already exists.")}
          </Typography>
        )}
        {errorMessage !== null && (
          <Typography style={{ color: "red" }}>{errorMessage}</Typography>
        )}
      </AdditionalText>
    </>
  );
}
