import React from "react";
import { useTranslation } from "react-i18next";

import CreateIcon from "@mui/icons-material/Create";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";

import {
  CoursesType,
  SubscriptionsType,
} from "@/pages/awareness/courses/utils/courseTypes";
import { LearningUserType } from "@/types/awareness";
import { useUserContext } from "@/utils/contexts/UserContext";

const CourseAssignmentButtons = ({ course, onIconClick }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isAssigned =
    course.subscription_type !== "not_subscribed" &&
    course.subscriptions.length > 0;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "4px",
          borderRadius: "4px",
        }}
        onClick={onIconClick}
      >
        {isAssigned &&
          course.subscriptions.map((subscription: SubscriptionsType) => (
            <Chip
              label={
                course.subscription_type === "company"
                  ? t("Everyone")
                  : subscription.label
              }
              key={"chip" + subscription.id}
              color={"primary"}
              variant={"outlined"}
              sx={{
                "& .MuiChip-label": {
                  paddingLeft: "2px",
                  paddingRight: "2px",
                },
                minWidth: "30px",
                marginRight: "2px",
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                width: "auto",
              }}
            />
          ))}
        <IconButton
          sx={{
            border: 1,
            borderColor: "primary.main",
            borderRadius: "16px",
            padding: "4px",
            marginRight: isAssigned ? "2px" : "0",
            display: "flex",
            alignItems: "center",
          }}
          size={"small"}
        >
          <CreateIcon color={"primary"} />
          {!isAssigned && (
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: "0.875rem",
                marginLeft: "4px",
              }}
            >
              {t("Edit assignment")}
            </Typography>
          )}
        </IconButton>
      </Box>
    </>
  );
};

export const CourseCardFooter = ({
  learningUserInfos,
  course,
  isPublicWebchat,
  isCourseCatalogAdmin,
  onClickAssignment,
}: {
  learningUserInfos: LearningUserType;
  course: CoursesType;
  isPublicWebchat: boolean;
  isCourseCatalogAdmin: boolean;
  onClickAssignment: (x: CoursesType) => void;
}) => {
  const user = useUserContext();
  const { t } = useTranslation("translation", {
    lng: learningUserInfos.language,
  });

  const getDraftLabel = () => {
    if (!course.published) {
      return `- ${t("Deploy changes to assign course")}`;
    } else if (course.published && course.draft_label_name != null) {
      return `- ${t("This course has unsaved changes")}`;
    }
    return "";
  };

  const TypeOfCourseSubtitles = {
    company: (
      <>
        <HistoryEduIcon sx={{ marginLeft: "-3px", marginRight: "1px" }} />
        <span>
          {t("{{companyName}} Courses", {
            companyName: user.current_company?.name ?? t("Custom"),
          })}
        </span>
      </>
    ),
    all: (
      <>
        <img
          src="/media/logos/logo_mantra_textless.png"
          alt="Mantra Logo"
          height={16}
          style={{ marginRight: 4 }}
        />
        <span>{t("Mantra Course")}</span>
      </>
    ),
    corporate: (
      <>
        <img
          src="/media/icons/svg/menu/company.svg"
          alt="Corporate Logo"
          style={{
            marginRight: 4,
            filter:
              "invert(13%) sepia(11%) saturate(5288%) hue-rotate(185deg) brightness(95%) contrast(94%)",
          }}
        />
        <span>{t("Corporate Course")}</span>
      </>
    ),
  };

  const onIconClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClickAssignment(course);
  };

  if (isCourseCatalogAdmin && !isPublicWebchat) {
    return (
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          paddingLeft: "5px",
          paddingRight: "15px",
          paddingTop: "10px",
        }}
      >
        <Grid item>
          <Box
            fontWeight="500"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
              fontStyle: "normal",
              color: "#142947",
              paddingLeft: "15px",
            }}
          >
            {TypeOfCourseSubtitles[course.sharing_policy] || " "}
            <Typography
              sx={{
                fontStyle: "italic",
                fontSize: "12px",
                color: "#94A3B8",
              }}
            >
              {getDraftLabel()}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <CourseAssignmentButtons course={course} onIconClick={onIconClick} />
        </Grid>
      </Grid>
    );
  }

  return <></>;
};

export default CourseCardFooter;
