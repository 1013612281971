import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  Popover,
  SelectChangeEvent,
} from "@mui/material";

import SelectOrderByComponent, {
  Column,
  OrderBy,
} from "./SelectOrderByComponent";

type SortComponentProps = {
  setOrderBy: (orderBy: OrderBy[]) => void;
  orderChoices: Column[];
};

export default function SortComponent({
  setOrderBy,
  orderChoices,
}: SortComponentProps) {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [listOrderBy, setListOrderBy] = useState<OrderBy[]>([]); // should be the same as setOrderBy parameter

  const applyFilter = useCallback(() => {
    setOrderBy(listOrderBy);
  }, [listOrderBy, setOrderBy]);

  const openComponent = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    setAnchorEl(event?.currentTarget);
    setIsModalOpen(true);
  };

  const closeComponent = () => {
    setAnchorEl(null);
    setIsModalOpen(false);
    applyFilter();
  };

  const handleAddSort = () => {
    setListOrderBy((prev) => [
      ...prev,
      { id: prev.length, label: "", order: "asc", column: null },
    ]);
  };

  const handleRemoveItem = (index) => {
    setListOrderBy((prev) => prev.filter((_, i) => i !== index));
  };

  const handleOrderChange = (
    index,
    event: SelectChangeEvent<"asc" | "desc">,
  ) => {
    const order = event.target.value === "asc" ? "asc" : "desc";
    setListOrderBy((prev) =>
      prev.map(
        (_, i): OrderBy =>
          i === index ? { ...prev[i], order: order } : prev[i],
      ),
    );
  };

  const handleOrderLabelChange = (index, value) => {
    setListOrderBy((prev) =>
      prev.map((_, i) =>
        i === index
          ? { ...prev[i], column: value, label: value ? value.name : "" }
          : prev[i],
      ),
    );
  };

  return (
    <>
      <Button
        startIcon={<ImportExportIcon />}
        endIcon={isModalOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        size="uppercase"
        variant="outlined"
        color="darkBlue"
        onClick={openComponent}
      >
        {t("Sort")}
      </Button>
      <Popover
        open={isModalOpen}
        anchorEl={anchorEl}
        onClose={closeComponent}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Card sx={{ width: "350px", paddingBottom: 0 }} elevation={1}>
          <CardContent sx={{ padding: 0 }}>
            <List dense>
              {listOrderBy.map((orderItem, index) => {
                return (
                  <SelectOrderByComponent
                    key={index + orderItem.toString()}
                    index={index}
                    orderChoice={orderChoices}
                    handleOrderChange={handleOrderChange}
                    handleOrderLabelChange={handleOrderLabelChange}
                    orderItem={orderItem}
                    handleRemoveItem={handleRemoveItem}
                  />
                );
              })}
              <ListItem>
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddSort}
                  size="small"
                >
                  {t("Add Sort")}
                </Button>
              </ListItem>
            </List>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <Button size="small" variant="contained" onClick={closeComponent}>
              {t("Apply")}
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
}
