import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { useUserContext } from "@/utils/contexts/UserContext";

/**
 *  Block that is displayed only for staff (Mantra) users
 */
export const OnlyVisibleByMantra = (
  props: PropsWithChildren,
): React.JSX.Element => {
  const { t } = useTranslation();
  const { is_staff } = useUserContext();

  if (!is_staff) {
    return <></>;
  }

  return (
    <div
      style={{
        color: "#ffdbdb",
        background:
          "repeating-linear-gradient(-45deg, transparent, transparent 10px, currentColor 10px, currentColor calc(2 * 10px))",
        padding: "1rem",
        borderRadius: "1rem",
        margin: "1em",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <div style={{ color: "black", fontWeight: "bold" }}>
          {t("Only visible by Mantra admins")}
        </div>
      </div>
      {props.children}
    </div>
  );
};
