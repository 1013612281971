import { SnackbarProvider as MuiSnackbarProvider } from "material-ui-snackbar-provider";
import { SnackbarProvider as NotisnackProvider } from "notistack";
import React, { PropsWithChildren, ReactNode } from "react";

const SnackbarProvider = (props: PropsWithChildren): ReactNode => {
  return (
    <MuiSnackbarProvider SnackbarProps={{ autoHideDuration: 1500 }}>
      <NotisnackProvider autoHideDuration={3000}>
        {props.children}
      </NotisnackProvider>
    </MuiSnackbarProvider>
  );
};

export default SnackbarProvider;
