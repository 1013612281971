import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import InfoIcon from "@mui/icons-material/Info";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";

import { useStartSimulations } from "@/pages/phishing-simulation/setup/startSimulations";
import { useStopSimulations } from "@/pages/phishing-simulation/setup/stopSimulations";
import { useCompanySimulationStatus } from "@/pages/phishing-simulation/setup/useCompanySimulationStatus";

export const SimulationScheduling = () => {
  const [autoEngageToggleLoading, setAutoEngageToggleLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { isSimulationEnabled, error, loading, refetch } =
    useCompanySimulationStatus();
  const { stopSimulations } = useStopSimulations();
  const { startSimulations } = useStartSimulations();
  const { t } = useTranslation();

  const isError = Boolean(error);

  const closeConfirmation = () => {
    setShowConfirmation(false);
    return true;
  };

  const handleToggleChange = (event) => {
    if (event.target.checked) {
      startSimulations(setAutoEngageToggleLoading, refetch);
    } else {
      setShowConfirmation(true);
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h6">
              {t("Automatic simulation delivery")}
            </Typography>
            <Tooltip
              title={t(
                "Simulations are sent on business days between 9:30 - 12:00 and 14:00 - 17:30.",
              )}
              sx={{ marginLeft: "15px" }}
            >
              <InfoIcon />
            </Tooltip>
            <Box sx={{ paddingLeft: ".5em" }}>
              <Switch
                size="small"
                checked={isSimulationEnabled}
                onChange={handleToggleChange}
                disabled={isError || autoEngageToggleLoading || loading}
              />
            </Box>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Stack spacing={2}>
          <Box>
            <Typography>
              {t(
                "When activated, employees will receive simulations automatically based on the assignment you made in the",
              )}{" "}
              <Link to={"/phishing-simulation/templates"}>
                {t("Template Catalog")}.
              </Link>
            </Typography>
            <Typography>
              {t(
                "You can choose the minimum number of days between simulations below.",
              )}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
      <Dialog open={showConfirmation} onClose={closeConfirmation}>
        <DialogTitle>
          {t("Deactivate automatic simulation delivery")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              "Employees won't receive any simulations automatically until you reactivate the automatic simulation delivery.",
            )}
          </DialogContentText>
          <DialogActions>
            <Button onClick={closeConfirmation}>{t("Cancel")}</Button>
            <LoadingButton
              variant={"contained"}
              onClick={() =>
                stopSimulations(
                  setAutoEngageToggleLoading,
                  closeConfirmation,
                  refetch,
                )
              }
              loading={autoEngageToggleLoading || loading}
              disabled={isError}
            >
              {t("Confirm")}
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
