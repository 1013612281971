import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";

import { BigSpinner } from "@/components/Spinner";

import { TemplateCard } from "./TemplateCard";
import { useFetchTemplateListQuery } from "./templateApi";
import {
  selectActiveStatus,
  selectBestPerformersOnly,
  selectPage,
  selectSaasSearch,
  selectSearch,
  selectTemplateTypeSearch,
  selectTemplatesPerPage,
  setCount,
} from "./templateSlice";
import { TemplateType } from "./templateUtils";

export const TemplateList = () => {
  const dispatch = useDispatch();
  const page = useSelector(selectPage);
  const templatesPerPage = useSelector(selectTemplatesPerPage);
  const templateSearchValue = useSelector(selectSearch);
  const templatesTypeValue = useSelector(selectTemplateTypeSearch);
  const saasSearchValue = useSelector(selectSaasSearch);
  const activationStatus = useSelector(selectActiveStatus);
  const best_performers_only = useSelector(selectBestPerformersOnly);

  const { data, isFetching, isSuccess, isError } = useFetchTemplateListQuery({
    page,
    templatesPerPage,
    search: templateSearchValue,
    saas: saasSearchValue,
    type: templatesTypeValue,
    activation_status: activationStatus,
    best_performers: best_performers_only,
  });

  useEffect(() => {
    dispatch(setCount({ count: data?.count ?? 0 }));
  }, [data?.count, dispatch]);

  return (
    <Box>
      {isFetching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5rem",
          }}
        >
          <BigSpinner />
        </div>
      ) : (
        ""
      )}
      {isSuccess && !isFetching && !isError ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "1rem",
          }}
        >
          {data.templates.map((template: TemplateType) => (
            <TemplateCard key={template.name} template={template} />
          ))}
        </div>
      ) : (
        ""
      )}
      {isError ? "an error occured" : ""}
    </Box>
  );
};
