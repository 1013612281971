import DOMPurify from "dompurify";
import React, { useMemo } from "react";

import { setIFrameHeight } from "@/utils/misc";

const setIFrameSize = (target: HTMLIFrameElement) => {
  if (target == null || target.contentDocument == null) {
    return;
  }
  setIFrameHeight(target);
  target.contentDocument.body.style.margin = "0";
  target.contentDocument.body.style.padding = "0";
  const width = target.contentDocument.body.getBoundingClientRect().width;
  const scrollWidth = target.contentDocument.body.scrollWidth;
  const widthScale = width / scrollWidth;
  target.contentDocument.body.style.transformOrigin = "top left";
  target.contentDocument.body.style.transform = `scale(${widthScale})`;
};

export const TemplateContent = ({
  content,
  minHeight = null,
  scrolling = true,
}: {
  content: string;
  minHeight?: string | null;
  scrolling?: boolean;
}) => {
  const cleanedContent = useMemo(
    () =>
      DOMPurify.sanitize(content, {
        FORBID_TAGS: ["script"],
        FORBID_ATTR: ["href"],
      })
        .replaceAll('<img src="{{ pixel_url }}">', "")
        .replaceAll('<img src="{{pixel_url}}">', ""),
    [content],
  );

  return (
    <iframe
      scrolling={scrolling ? "yes" : "no"}
      height="100%"
      width="100%"
      srcDoc={cleanedContent}
      onLoad={(e) => setIFrameSize(e.target as HTMLIFrameElement)}
      onChange={(e) => setIFrameSize(e.target as HTMLIFrameElement)}
      title="Template"
      style={{
        border: 0,
        outline: 0,
        minHeight: minHeight ? minHeight : "auto",
        overflow: scrolling ? "auto" : "hidden",
        display: "block",
      }}
    />
  );
};
