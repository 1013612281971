import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";

import { useAddLanguageFromLocalizedCourseMutation } from "@/api";
import { ButtonSpinner } from "@/components/Spinner";
import {
  selectIsMantraUser,
  selectUsesFormalTone,
} from "@/utils/contexts/AuthSlice";
import { languageDisplayName } from "@/utils/misc";

import {
  changeLanguage,
  selectCurrentLocalizedCourse,
  selectMissingLanguages,
} from "./courseSlice";

const useMissingVisibleLanguages = () => {
  const missingLanguages = useSelector(selectMissingLanguages);
  const useFormalTone = useSelector(selectUsesFormalTone);
  const isMantraUser = useSelector(selectIsMantraUser);
  let language_filter: undefined | ((language: string) => boolean) = undefined;
  if (isMantraUser) {
    language_filter = (language: string) => true;
  } else if (useFormalTone) {
    language_filter = (language: string) => language !== "fr";
  } else {
    language_filter = (language: string) => language !== "fr_FR@formal";
  }
  return {
    missingLanguages: missingLanguages.filter(language_filter),
  };
};

const NewLanguageSelector = () => {
  const { missingLanguages } = useMissingVisibleLanguages();
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  const [anchorDropdownEl, setAnchorDropdownEl] = useState(null);
  const handleCloseDropdown = () => {
    setAnchorDropdownEl(null);
  };
  const [addLanguage, { isLoading }] =
    useAddLanguageFromLocalizedCourseMutation();
  const dispatch = useDispatch();
  const isMantraUser = useSelector(selectIsMantraUser);

  const handleNewLanguage = async (language: string) => {
    handleCloseDropdown();
    await addLanguage({
      id: localizedCourse.id,
      language,
    });
    dispatch(changeLanguage(language));
  };

  return (
    <Box>
      {missingLanguages.length > 0 ? (
        <>
          <IconButton
            size="small"
            sx={{ width: "30px", height: "30px" }}
            onClick={(e) => setAnchorDropdownEl(e.currentTarget)}
          >
            <AddIcon />
          </IconButton>
          {isLoading ? (
            <ButtonSpinner
              sx={{ marginLeft: "1.5em", color: "rgb(89, 93, 110)" }}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <Menu
        open={Boolean(anchorDropdownEl)}
        anchorEl={anchorDropdownEl}
        onClose={() => handleCloseDropdown()}
        anchorOrigin={{
          horizontal: "right",
          vertical: "center",
        }}
      >
        {missingLanguages.map((lang: string) => (
          <MenuItem
            key={lang}
            onClick={() => {
              handleNewLanguage(lang);
            }}
            disabled={isLoading}
          >
            {languageDisplayName(lang, isMantraUser)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default NewLanguageSelector;
