import React from "react";
import { useTranslation } from "react-i18next";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Button, Typography } from "@mui/material";

import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import { StyledTypo } from "@/pages/awareness/setup/Teams/StyledTypo";

const CONSENT_URL =
  "https://login.microsoftonline.com/organizations/v2.0/adminconsent";

export const GrantPermissionComponent = ({ nonce, deployBotConsent }) => {
  const { t } = useTranslation();

  const consentParams = {
    client_id: import.meta.env.VITE_APP_AZURE_AWARENESS_CLIENT_ID,
    scope: "https://graph.microsoft.com/.default",
    redirect_uri: `${
      import.meta.env.VITE_APP_ENDPOINT_LEARNING
    }/office/record_consent`,
    state: nonce,
  };

  const urlParams = Object.keys(consentParams)
    .map((key) => `${key}=${consentParams[key]}`)
    .join("&");
  return (
    <React.Fragment>
      <StyledTypo variant={"h2"}>{t("Grant API Permissions")}</StyledTypo>
      <StyledBox>
        <StyledBox sx={{ justifyContent: "unset", width: "600px" }}>
          <Button
            sx={{ width: "11rem" }}
            variant={"outlined"}
            onClick={() => window.open(CONSENT_URL + "?" + urlParams, "_blank")}
          >
            {t("Grant Consent")}
          </Button>
          <Typography
            sx={{ paddingLeft: "3rem", paddingRight: "2rem" }}
            color={"text.secondary"}
          >
            {t("Allow Mantra to manage Teams apps")}
          </Typography>
          {deployBotConsent ? (
            <CheckCircleOutlineOutlinedIcon color={"success"} />
          ) : (
            <CancelOutlinedIcon color={"error"} />
          )}
        </StyledBox>
      </StyledBox>
    </React.Fragment>
  );
};
