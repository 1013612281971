import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const BlockingText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  z-index: 100;
  font-size: 32px;
  color: black;
  font-weight: 400;

  color: #140040;

  height: 100%;
  padding: 5% 20%;

  & > h1 {
    font-size: 46px;
    font-weight: 600;

    margin-bottom: 5%;
  }

  & > p {
    font-size: 30px;
    font-weight: 400;

    margin-bottom: 10%;
  }

  & > #subtext {
    font-style: italic;
    font-weight: 300;
    font-size: 20px;

    color: #999999;
  }
`;

const RequestCallbackButton = styled.div`
  background: #4007ed;
  border-radius: 100px;
  padding: 10px 40px;

  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #ffffff;

  &:hover {
    background-color: #3c00d1;
    cursor: pointer;
  }
`;

export function ActivateBrowserDefender() {
  const [salesNotified, setSalesNotified] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const handleClick = async (event) => {
    event.preventDefault();

    if (!salesNotified) {
      setSalesNotified(true);

      // Make a call to simulation to send an email to sales team
      const accessToken = await getAccessTokenSilently();
      try {
        await axios.post(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/browser_defender/feature_interest",
          null,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (error) {
        snackbar.showMessage("Request could not be sent due to an error");
        setSalesNotified(false);
      }
    }
  };

  return (
    <div>
      {!salesNotified ? (
        <BlockingText>
          <h1>{t("Prevent credentials phishing")}</h1>
          <p>{t("Make your teams safer online with our browser extension")}</p>
          <span id="subtext">{t("Want to learn more?")}</span>
          <RequestCallbackButton onClick={handleClick}>
            {t("Request a callback")}
          </RequestCallbackButton>
        </BlockingText>
      ) : (
        <BlockingText>
          <p>{t("Callback request sent")} &#10004;</p>
          <p>{t("We will get back to you shortly")}</p>
        </BlockingText>
      )}
    </div>
  );
}
