import React from "react";

import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { Checkbox, IconButton, TableCell } from "@mui/material";

import StyledTableRow from "@/pages/browser-defender/styles/StyledTableRow";

export default function TableRowWithMoreButton({
  row,
  seeMore,
  selectCallback = null,
  isSelected = false,
  children,
}) {
  function toggleMore(e) {
    e.stopPropagation();
    seeMore(row);
  }

  return (
    <>
      <StyledTableRow
        hover
        key={row.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        style={{
          backgroundColor:
            row.impersonated_brand && !row.company_domain_label
              ? "rgb(253, 237, 237)"
              : "transparent",
        }}
        onClick={() => selectCallback(row.id)}
      >
        {selectCallback && (
          <TableCell>
            <Checkbox
              color="primary"
              checked={isSelected}
              inputProps={{
                "aria-labelledby": "1",
              }}
              onClick={() => {
                selectCallback(row.id);
              }}
            />
          </TableCell>
        )}
        {children}

        {row.impersonated_brand && (
          <TableCell width={"20px"}>
            <IconButton size="small" onClick={toggleMore}>
              <ContentPasteSearchIcon />
            </IconButton>
          </TableCell>
        )}
      </StyledTableRow>
    </>
  );
}
