import React from "react";

import { Stack } from "@mui/material";

import { DaysBetweenCourses } from "@/pages/awareness/setup/DaysBetweenCourses";
import { Reminders } from "@/pages/awareness/setup/Reminders";
import { SendOnboardings } from "@/pages/awareness/setup/SendOnboardings";

import FreechatPreferences from "./FreechatPreferences";

export function Preferences({
  days_between_courses,
  messaging_platform,
}: {
  days_between_courses: number;
  messaging_platform: string;
}) {
  return (
    <Stack spacing={3}>
      <SendOnboardings />
      <Reminders />
      <DaysBetweenCourses days_between_courses={days_between_courses} />
      {messaging_platform !== "webchat" && <FreechatPreferences />}
    </Stack>
  );
}
