import React from "react";
import { useTranslation } from "react-i18next";

import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import {
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { BackendSuspense } from "./BackendSuspense";

export const NbUserCard = ({ usersCount, error, isPending }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("Total number of users")}>
      <Card>
        <CardHeader
          title={t("User count")}
          avatar={<PeopleOutlinedIcon fontSize="large" color="primary" />}
        />
        <CardContent>
          <Box display="flex" justifyContent="center">
            <BackendSuspense
              hasData={usersCount > 0}
              error={error}
              isPending={isPending}
            >
              <Typography variant="h3">{usersCount}</Typography>
            </BackendSuspense>
          </Box>
        </CardContent>
      </Card>
    </Tooltip>
  );
};
