import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { debounce } from "lodash";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  AccountBox,
  AddPhotoAlternate,
  Business,
  Http,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import { ButtonSpinner } from "@/components/Spinner";
import { CompanySignature } from "@/pages/phishing-simulation/company/companysignature/CompanySignature";
import { KeyCustomers } from "@/pages/phishing-simulation/company/keycustomers/KeyCustomers";
import { KeyPeople } from "@/pages/phishing-simulation/company/keypeople/KeyPeople";
import { extractErrorMessage, getConfig, useBackend } from "@/utils/misc";

const BasicInfoListItem = ({ icon, value }) => (
  <ListItem button>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={value} />
  </ListItem>
);

const ListItemTextField = styled(TextField)`
  & input {
    padding: 10px;
    color: rgb(100, 108, 154);
  }
`;

const CompanyGeneralInformations = () => {
  const { data, error, isPending } = useBackend(
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/company",
  );
  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const [domain, setDomain] = useState();
  useEffect(() => {
    if (data) {
      setDomain(data?.user_defined_domain ?? data.domain);
    }
  }, [data]);

  const handleDomainChange = (event) => {
    const newDomain = event.target.value;
    if (newDomain.length > 80) return;

    setDomain(newDomain);
    updateUserDefinedDomain(newDomain);
  };

  const onFileSelected = async (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    if (file == null) {
      console.error("no file selected");
      return;
    }
    if (file.size > 2097152) {
      return snackbar.showMessage("File is too large.");
    }

    formData.append("logo", file);
    const accessToken = await getAccessTokenSilently();
    try {
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/company/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      snackbar.showMessage(
        "Logo updated successfully. It might take a few minutes to take effect.",
      );
    } catch (error) {
      formData.delete("logo");
      // reset the input element
      event.target.value = "";
      event.target.type = "";
      event.target.type = "file";
      snackbar.showMessage(extractErrorMessage(error));
    }
  };

  const handlePreviewLogo = async () => {
    const newWindow = window.open("", "_blank");
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/company/preview_logo`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      newWindow.location = response.data;
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  };

  const updateUserDefinedDomain = useMemo(
    () =>
      debounce(async (user_defined_domain) => {
        try {
          await axios.put(
            `${
              import.meta.env.VITE_APP_ENDPOINT_SIMULATION
            }/company/change_domain`,
            { company: { user_defined_domain } },
            await getConfig(getAccessTokenSilently),
          );
          snackbar.showMessage("Domain saved");
        } catch (error) {
          snackbar.showMessage(extractErrorMessage(error));
        }
      }, 1000),
    [getAccessTokenSilently, snackbar],
  );

  if (isPending || !data)
    return (
      <ButtonSpinner
        size="2.5rem"
        thickness={4}
        color="neutral"
        sx={{ marginTop: "2rem" }}
      >
        {t("Loading")}...
      </ButtonSpinner>
    );
  if (error) return <div>{t("An error occurred")}</div>;
  if (data) {
    const company = data;
    return (
      <Stack gap={2}>
        <Card>
          <CardHeader title={t("Company Info")} />
          <CardContent>
            <h5>{t("Basic Info")}</h5>
            <div>
              <List component="nav">
                <BasicInfoListItem icon={<AccountBox />} value={company.name} />
                <ListItem button>
                  <ListItemIcon>
                    <AddPhotoAlternate />
                  </ListItemIcon>
                  <a
                    onClick={handlePreviewLogo}
                    style={{ marginRight: 10 }}
                    title="Might take a few minutes to update"
                  >
                    {t("Preview logo")}
                  </a>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    accept="image/*"
                    onChange={onFileSelected}
                  />
                  <Typography
                    variant="body_small"
                    color="error"
                    marginLeft="1rem"
                  >
                    ({t("2MB Max")})
                  </Typography>
                </ListItem>
                <BasicInfoListItem icon={<Http />} value={company.website} />
                <ListItem button>
                  <ListItemIcon>
                    <Business />
                  </ListItemIcon>
                  <ListItemTextField
                    variant="outlined"
                    value={domain || ""}
                    onChange={handleDomainChange}
                  />
                </ListItem>
              </List>
            </div>
          </CardContent>
        </Card>

        <KeyPeople />
        <CompanySignature />
        <KeyCustomers />
      </Stack>
    );
  }
};

export default CompanyGeneralInformations;
