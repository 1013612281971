import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router";

import config from "../../auth_config.json";

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) =>
    navigate(appState?.returnTo || window.location.pathname);

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin + "/callback",
        audience: config.audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
