import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Divider, Typography } from "@mui/material";

import { ButtonSpinner } from "@/components/Spinner";
import { usePermissionService } from "@/pages/general-settings/permissions-attribution/permissionService";
import { UserWithPermissions } from "@/pages/general-settings/permissions-attribution/permissions";

function RemovePermissionFooter({
  cancel,
  confirm,
  loading,
}: {
  cancel: () => void;
  confirm: () => void;
  loading: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button onClick={cancel}>{t("Back")}</Button>
      <Button
        variant="contained"
        onClick={confirm}
        startIcon={loading ? <ButtonSpinner /> : null}
        disabled={loading}
      >
        {t("Confirm")}
      </Button>
    </Box>
  );
}

export default function RemovePermission({
  user,
  closeDialog,
}: {
  user: UserWithPermissions;
  closeDialog: () => void;
}) {
  const { removeUserWithPermissions, removeIsLoading, removeIsSuccess } =
    usePermissionService();

  function removePermissionsFromUser() {
    removeUserWithPermissions(user);
  }

  useEffect(() => {
    if (removeIsSuccess) {
      closeDialog();
    }
  }, [removeIsSuccess, closeDialog]);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: 600,
        minHeight: 250,
        "& > *": { padding: "0 1rem" },
        padding: "1rem 0",
      }}
    >
      <h4 style={{ padding: "0.5rem 1.5rem 0" }}>{t("Revoke permissions")}</h4>
      <Divider sx={{ margin: "1rem 0" }} />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>
          {t("You're about to revoke all permissions granted to {{email}}", {
            email: user.email,
          })}
        </Typography>
      </Box>
      <Divider sx={{ margin: "1rem 0" }} />
      <RemovePermissionFooter
        cancel={closeDialog}
        confirm={removePermissionsFromUser}
        loading={removeIsLoading}
      />
    </Box>
  );
}
