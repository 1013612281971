import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/store";

export interface AwarenessUserSettings {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  departments: string;
  use_webchat: boolean;
}

export interface ListAwarenessUserSettings {
  total_users: number;
  total_pages: number;
  page_size: number;
  current_page: number;
  company_messaging_platform: string;
  users: Array<AwarenessUserSettings>;
}

const initialState: ListAwarenessUserSettings = {
  total_users: 0,
  total_pages: 0,
  page_size: 25,
  current_page: 0,
  company_messaging_platform: "teams",
  users: [],
};

export const AwarenessUsersSettingsSlice = createSlice({
  name: "awarenessUsersSettings",
  initialState,
  reducers: {
    setListAwarenessUserSettings: (
      state,
      action: PayloadAction<ListAwarenessUserSettings>,
    ) => {
      return {
        total_users: action.payload.total_users,
        total_pages: action.payload.total_pages,
        page_size: state.page_size,
        company_messaging_platform: action.payload.company_messaging_platform,
        current_page: state.current_page,
        users: action.payload.users,
      };
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.current_page = action.payload;
    },
    updateUseWebchat: (state, action) => {
      state.users.forEach((user) => {
        if (action.payload.users_id.includes(user.id)) {
          user.use_webchat = action.payload.use_webchat;
        }
      });
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.page_size = action.payload;
    },
  },
});

export const {
  setListAwarenessUserSettings,
  setCurrentPage,
  updateUseWebchat,
  setPageSize,
} = AwarenessUsersSettingsSlice.actions;

export const selectPageSize = (state: any) => state.awarenessUser.page_size;
export const selectUsers = (state: RootState) => state.awarenessUser.users;
export const selectTotalUsers = (state: any) => state.awarenessUser.total_users;
export const selectCompanyMessagingPlatform = (state: any) =>
  state.awarenessUser.company_messaging_platform;

export const selectCurrentPage = (state: any) =>
  state.awarenessUser.current_page;

export default AwarenessUsersSettingsSlice.reducer;
