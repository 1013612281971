import { createApi } from "@reduxjs/toolkit/query/react";

import { AllowedSharingPolicy } from "@/pages/awareness/courses/utils/sharingPolicies";
import { CourseList, LearningUserType } from "@/types/awareness";
import { learningBaseQuery } from "@/utils/rtkQuery";

export const courseSharingApi = createApi({
  reducerPath: "courseSharingApi",
  baseQuery: learningBaseQuery(),
  tagTypes: ["Courses"],
  endpoints: (builder) => ({
    updateCourseSharingPolicy: builder.mutation<
      void,
      { course_id: number; new_policy: AllowedSharingPolicy }
    >({
      query: (payload) => ({
        url: "courses/update_sharing_policy",
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(
        { course_id, new_policy },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          courseSharingApi.util.updateQueryData(
            "getCompanyCourses",
            undefined,
            (draft) => {
              const course = draft.courses_info.find((c) => c.id === course_id);
              if (course) {
                course.sharing_policy = new_policy;
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ["Courses"],
    }),
    shareAllCourses: builder.mutation<void, void>({
      query: () => ({
        url: "courses/share_all",
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          courseSharingApi.util.updateQueryData(
            "getCompanyCourses",
            undefined,
            (draft) => {
              draft.courses_info.forEach((course) => {
                course.sharing_policy =
                  AllowedSharingPolicy.COMPANIES_OF_CORPORATE;
              });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ["Courses"],
    }),
    getCompanyCourses: builder.query<CourseList, number>({
      query: () => `courses/`,
      providesTags: ["Courses"],
    }),
    getUserInfo: builder.query<LearningUserType, void>({
      query: () => "user",
    }),
  }),
});

export const {
  useUpdateCourseSharingPolicyMutation,
  useShareAllCoursesMutation,
  useGetCompanyCoursesQuery,
  useGetUserInfoQuery,
} = courseSharingApi;
