import {
  ChartData,
  Chart as ChartJS,
  Filler,
  LineElement,
  PointElement,
} from "chart.js";
import { color } from "chart.js/helpers";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import React from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardContent,
  CardHeader,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Box } from "@mui/system";

import { BackendSuspense } from "@/pages/awareness/dashboard/BackendSuspense";
import { HeaderBox } from "@/utils/styled-components";

ChartJS.register(PointElement, LineElement, Filler);

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

type CourseCompletionRundownType = {
  date: string;
  completed_courses: number;
};

type DataType = {
  course_count: number;
  course_completion_rundown: Array<CourseCompletionRundownType>;
};

const formatData = (data: DataType, isMonthly: boolean) => {
  if (!data) {
    return [];
  }
  const format = isMonthly ? "MM/YYYY" : "DD/MM/YYYY";
  const courseCount = data.course_count;
  const chartFormattedData = [];
  for (let el of data.course_completion_rundown) {
    chartFormattedData.push({
      x: dayjs(el.date).format(format),
      y:
        courseCount > 0
          ? Math.round((el.completed_courses / courseCount) * 100)
          : 0,
      count: el.completed_courses,
    });
  }
  return chartFormattedData;
};

type CourseRundownChartProps = {
  data: DataType;
  isMonthly: boolean;
};

const CourseRundownChart = ({ data, isMonthly }: CourseRundownChartProps) => {
  const brandColor = "#2c77f4";
  const shape2Color = "#afb4d4";
  const shape3Color = "#646c9a";

  const chartData = {
    datasets: [
      {
        fill: true,
        data: formatData(data, isMonthly),
        backgroundColor: function (context) {
          const ctx = context.chart.ctx;
          const gradient = isMonthly
            ? ctx.createLinearGradient(0, 0, 0, 225)
            : ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "rgba(128, 173, 248, 1)");
          gradient.addColorStop(1, "rgba(128, 173, 248, 0)");
          return gradient;
        },
        pointBackgroundColor: color(brandColor).alpha(0.6).rgbString(),
        borderColor: shape3Color,
        tension: 0.1,
        spanGaps: true,
        cubicInterpolationMode: "monotone",
        barPercentage: 0.7,
        pointRadius: 4,
        pointHitRadius: 20,
        pointHoverRadius: 10,
        pointBorderColor: "white",
        borderWidth: 1,
        categoryPercentage: 0.35,
      },
    ],
  };

  const chartConfig = {
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        grid: {
          color: shape2Color,
          offset: false,
          drawTicks: false,
          borderDash: [3, 4],
          zeroLineWidth: 1,
          zeroLineColor: shape2Color,
          zeroLineBorderDash: [3, 4],
        },
        ticks: {
          stepSize: 5,
          display: true,
          fontColor: shape3Color,
          fontSize: 13,
          padding: 10,
          callback: function (value) {
            return value + " %";
          },
        },
      },
      x: {
        grid: { display: false },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          footer: (ctx) => ctx[0]?.raw?.count + " courses completed",
          label: (ctx) => ctx.formattedValue + "%",
        },
      },
    },
  };

  return (
    <Line
      data={chartData as ChartData<"line", any[], unknown>}
      options={chartConfig}
    />
  );
};

type CourseRundownProps = {
  className?: string;
  changeRundownChartMode: (event: React.MouseEvent, value: boolean) => void;
  data: any;
  error: any;
  isPending: boolean;
  isMonthly: boolean;
};

export const CourseRundown = ({
  changeRundownChartMode,
  data,
  error,
  isPending,
  isMonthly,
}: CourseRundownProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={t("Percentage of completed courses")}
        action={
          <HeaderBox>
            <ToggleButtonGroup
              id="isMonthlyCheckbox"
              title={t("Weekly/Monthly toggle")}
              onChange={changeRundownChartMode}
              exclusive
              value={isMonthly}
            >
              <ToggleButton value={false}>{t("Weekly")}</ToggleButton>
              <ToggleButton value={true}>{t("Monthly")}</ToggleButton>
            </ToggleButtonGroup>
          </HeaderBox>
        }
      />
      <CardContent>
        <Box display="flex" justifyContent="center" alignItems="center">
          <BackendSuspense
            data={data}
            error={error}
            isPending={isPending}
            hasData={data?.course_completion_rundown?.length > 0}
          >
            <CourseRundownChart data={data} isMonthly={isMonthly} />
          </BackendSuspense>
        </Box>
      </CardContent>
    </Card>
  );
};
