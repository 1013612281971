import { z } from "zod";

export const UserPreferencesSchema = z.object({
  simulation_dashboard_period: z.string().default("P6M"),
  banners_dashboard_period: z.string().default("P7D"),
});
export type UserPreferences = z.infer<typeof UserPreferencesSchema>;

export const DefautUserPreferences: UserPreferences =
  UserPreferencesSchema.parse({});
