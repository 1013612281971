import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@mui/material";

import { Spinner } from "@/components/Spinner";
import { OrderBy } from "@/components/sort/SelectOrderByComponent";
import DataPerUsersTable from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/DataPerUsersTable";
import { downloadAsCSV } from "@/utils/downloadFile";
import { extractErrorMessage } from "@/utils/misc";
import { sentryCaptureException } from "@/utils/sentry";

function usersUrl(
  dateFrom,
  dateTo,
  department,
  sort,
  page,
  per_page,
  active_only,
  search = null,
  csv = false,
) {
  let endpointUserUrl = "users";
  let urlParams = new URLSearchParams();
  urlParams.append("date_from", dateFrom.toISOString());
  urlParams.append("date_to", dateTo.toISOString());
  urlParams.append("page_number", String(Number(page) + 1)); // datatable count page starting with 0 but django Paginator starts with 1.
  urlParams.append("per_page", String(per_page));
  urlParams.append("sort", sort ?? "lastname");
  urlParams.append("only_has_sent_messages", "true");
  urlParams.append("active_only", String(active_only));
  if (department != null && department !== "") {
    urlParams.append("filter__departments", department);
  }
  if (search != null && search !== "") {
    urlParams.append("search", search);
  }
  if (csv === true) {
    urlParams.append("csv", "true");
  }
  if (urlParams.toString() !== "") {
    endpointUserUrl = `${endpointUserUrl}?${urlParams.toString()}`;
  }
  return endpointUserUrl;
}

export default function UsersTable({
  dateFrom,
  dateTo,
  department,
  fetchActiveUsersOnly,
}) {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState({ users: [], total_count: 0 });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("lastname");
  const [searchTerm, setSearchTerm] = useState("");

  const onDownload = () => {
    getAccessTokenSilently()
      .then((accessToken) => {
        return axios.get(
          `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/${usersUrl(
            dateFrom,
            dateTo,
            department,
            sortingColumn,
            null,
            10,
            fetchActiveUsersOnly,
            null,
            true,
          )}`,
          { headers: { Authorization: `Bearer ${accessToken}` } },
        );
      })
      .then((response) => downloadAsCSV(response.data, "users.csv"))
      .catch((e) => {
        sentryCaptureException(e);
        snackbar.showMessage(extractErrorMessage(e));
      });
    return false;
  };

  const fetchUsersData = useCallback(
    async (url) => {
      try {
        setError(null);
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently();
        const resource = await axios.get(
          `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/${url}`,
          { headers: { Authorization: `Bearer ${accessToken}` } },
        );
        setIsLoading(false);
        setData(resource.data ? resource.data : undefined);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently],
  );

  const setOrderBy = useCallback(
    (listOrderBy: OrderBy[]) => {
      const orderByFormatted: string[] = [];
      for (const orderItem of listOrderBy) {
        if (orderItem.label) {
          orderByFormatted.push(
            (orderItem.order === "asc" ? "" : "-") + orderItem.label,
          );
        }
      }
      if (orderByFormatted.length === 0) {
        orderByFormatted.push("lastname");
      }
      setSortingColumn(orderByFormatted.join(","));
    },
    [setSortingColumn],
  );

  // fetch data upfront
  useEffect(() => {
    if (dateFrom && dateTo) {
      fetchUsersData(
        usersUrl(
          dateFrom,
          dateTo,
          department,
          sortingColumn,
          page,
          rowsPerPage,
          fetchActiveUsersOnly,
          searchTerm,
        ),
      );
    }
  }, [
    page,
    rowsPerPage,
    dateFrom,
    dateTo,
    department,
    sortingColumn,
    fetchUsersData,
    searchTerm,
    fetchActiveUsersOnly,
  ]);

  if (error) return <div>{t("An error occurred")}</div>;
  return (
    <>
      <Card>
        <CardHeader
          title={t("Data per user")}
          action={
            <Button variant={"outlined"} onClick={onDownload}>
              {t("Export users")}
            </Button>
          }
        />
        <CardContent>
          {isLoading && !data?.users && <Spinner />}

          <Box height={"20px"}>
            {isLoading && data.users && <LinearProgress />}
          </Box>

          {data.users && (
            <DataPerUsersTable
              dataPerUsers={data.users}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              totalCount={data.total_count}
              rowsPerPage={rowsPerPage}
              setSearchTerm={setSearchTerm}
              setOrderBy={setOrderBy}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
}
