import React from "react";

import ExtensionStatusChip from "@/pages/browser-defender/components/ExtensionStatusChip";
import { ExtensionStatusType } from "@/pages/browser-defender/components/extentionStatus";

export function ExtensionStatus({ data }) {
  let counters = {
    active: 0,
    inactive: 0,
    partially_configured: 0,
    disabled: 0,
  };

  data.forEach((item) => {
    if (item.days_since_last_seen < 30) {
      counters[item.extension_status] += 1;
    } else {
      counters["inactive"] += 1;
    }
  });

  let status: ExtensionStatusType = "unknown";
  if (counters["active"] > 0) {
    status = "active";
  } else if (counters["partially_configured"] > 0) {
    status = "partially_configured";
  } else if (counters["disabled"] > 0) {
    status = "disabled";
  } else if (counters["inactive"] > 0) {
    status = "inactive";
  }

  return <ExtensionStatusChip status={status} />;
}
