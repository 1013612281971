import React from "react";

export function DocBlock(props: {
  doc: { title: string; description: string; link: string };
}) {
  return (
    <div>
      <p>{props.doc.description}</p>
      <iframe
        title={props.doc.title}
        src={props.doc.link}
        width="100%"
        height="600px"
      ></iframe>
    </div>
  );
}
