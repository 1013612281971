import { debounce } from "lodash/index.js";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export function HeadersExtra({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      {children}
    </Box>
  );
}

export function HeaderFilterField({ setPage, setSearchTerm }) {
  const { t } = useTranslation();

  const updateSearchItemAndPage = useCallback(
    (value) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setPage, setSearchTerm],
  );

  // memoize the debounce call with useMemo
  const debouncedUpdate = useMemo(() => {
    return debounce(updateSearchItemAndPage, 1000);
  }, [updateSearchItemAndPage]);

  const onChange = (e) => {
    const value = e.target.value;
    debouncedUpdate(value);
  };

  return (
    <TextField
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
      size={"small"}
      label={t("Search")}
      onKeyDown={onChange}
    />
  );
}

export default function CustomTable({
  headers,
  HeadersExtra,
  rows,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) {
  const { t } = useTranslation();
  return (
    <Box>
      {HeadersExtra}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
          {headers && (
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell align={header.alignment} key={header.label}>
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        labelRowsPerPage={t("Rows per page") + " :"}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={(_, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Box>
  );
}
