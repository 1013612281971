import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  Popover,
  TextField,
} from "@mui/material";

import { ThreatFilterParams } from "@/pages/threats/service/types";

type FilterToolsProps = {
  value: ThreatFilterParams;
  onChange: (value: ThreatFilterParams) => void;
};

export const FilterTools = (props: FilterToolsProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bannerLevel, setBannerLevel] = useState(props.value.bannerLevel);
  const [hasAttachments, setHasAttachments] = useState(
    props.value.hasAttachments,
  );

  const selectedBannerLevel = useMemo(() => {
    switch (bannerLevel) {
      case "info":
      case null:
        return t("All");
      case "none":
        return t("No banner");
      case "suspicious":
        return t("Yellow");
      case "very-suspicious":
        return t("Red");
    }
  }, [bannerLevel, t]);

  const selectedAttachments = useMemo(() => {
    switch (hasAttachments) {
      case null:
        return t("All");
      case true:
        return t("Yes");
      case false:
        return t("No");
    }
  }, [hasAttachments, t]);

  const applyFilter = () => {
    props.onChange({ bannerLevel, hasAttachments });
  };

  const openComponent = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    setAnchorEl(event?.currentTarget);
    setIsModalOpen(true);
  };

  const closeComponent = () => {
    setAnchorEl(null);
    setIsModalOpen(false);
    applyFilter();
  };

  return (
    <>
      <Button
        startIcon={<FilterListIcon />}
        endIcon={isModalOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        size="uppercase"
        variant="outlined"
        color="darkBlue"
        onClick={openComponent}
      >
        {t("Filter")}
      </Button>
      <Popover
        open={isModalOpen}
        anchorEl={anchorEl}
        onClose={closeComponent}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Card sx={{ width: "175px", paddingBottom: 0 }} elevation={1}>
          <CardContent sx={{ padding: 0 }}>
            <List dense>
              <ListItem>
                <Autocomplete
                  sx={{ width: "150px" }}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField {...params} label={t("Banner Level")} />
                  )}
                  value={selectedBannerLevel}
                  onChange={(_, value) => {
                    switch (value) {
                      case t("All"):
                        setBannerLevel(null);
                        break;
                      case t("No banner"):
                        setBannerLevel("none");
                        break;
                      case t("Yellow"):
                        setBannerLevel("suspicious");
                        break;
                      case t("Red"):
                        setBannerLevel("very-suspicious");
                        break;
                    }
                  }}
                  options={[t("All"), t("No banner"), t("Yellow"), t("Red")]}
                />
              </ListItem>
              <ListItem>
                <Autocomplete
                  sx={{ width: "150px" }}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField {...params} label={t("Has attachments")} />
                  )}
                  value={selectedAttachments}
                  onChange={(_, value) => {
                    switch (value) {
                      case t("All"):
                        setHasAttachments(null);
                        break;
                      case t("Yes"):
                        setHasAttachments(true);
                        break;
                      case t("No"):
                        setHasAttachments(false);
                        break;
                    }
                  }}
                  options={[t("All"), t("Yes"), t("No")]}
                />
              </ListItem>
            </List>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <Button variant="contained" size="small" onClick={closeComponent}>
              {t("Apply")}
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};
