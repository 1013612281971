import React from "react";

import { Card, CardContent, CardHeader } from "@mui/material";

import { Spinner } from "@/components/Spinner";

export default function GenericCard({
  title,
  action = null,
  isLoading = false,
  children,
  padding = "auto",
}) {
  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={title} action={action} />
      <CardContent style={{ padding: padding }}>
        {isLoading && <Spinner />}

        {!isLoading && children}
      </CardContent>
    </Card>
  );
}
