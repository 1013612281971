import React, { RefObject, useContext } from "react";
import { useTranslation } from "react-i18next";

import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { DateRangePickerContext } from "./DateRangePickerContext";

type DateRangePickerSelectorProps = {
  rangeTextFieldRef: RefObject<HTMLElement>;
};

export const DateRangePickerSelector = ({
  rangeTextFieldRef,
}: DateRangePickerSelectorProps) => {
  const { t } = useTranslation();
  const { currentPeriod, updateCurrentPeriod, periods } = useContext(
    DateRangePickerContext,
  );

  const onChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value === "Custom") {
      rangeTextFieldRef.current?.click();
      return;
    }
    updateCurrentPeriod(e.target.value);
  };

  return (
    <Select
      value={currentPeriod}
      onChange={onChange}
      size="small"
      style={{
        height: "25px",
        width: "100px",
      }}
      sx={{
        "& .MuiInputBase-input": {
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: "10px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
      }}
    >
      {periods.map(({ period }) => (
        <MenuItem key={period} value={period}>
          {period}
        </MenuItem>
      ))}
      <MenuItem key="Custom" value={"Custom"}>
        {t("Custom")}
      </MenuItem>
    </Select>
  );
};
