import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Typography } from "@mui/material";

import { ButtonSpinner } from "@/components/Spinner";
import { sentryCaptureException } from "@/utils/sentry";

export default function PhishingDetailsFooter({ event_id, close }) {
  const [sending, setSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();

  async function sendReport() {
    setSending(true);
    try {
      const accessToken = await getAccessTokenSilently();
      await axios.post(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/report/phishing/false_alert`,
        {
          event_id: event_id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      setErrorMessage(t("An error occurred"));
      setSending(false);
      sentryCaptureException(error);
      return;
    }

    setErrorMessage("");
    setSending(false);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <Button variant="outlined" onClick={close}>
          {t("Close")}
        </Button>
        <Button
          variant="contained"
          onClick={sendReport}
          startIcon={sending ? <ButtonSpinner /> : null}
          disabled={sending}
        >
          {t("Report false alert")}
        </Button>
      </Box>
      {errorMessage && (
        <Typography
          style={{
            fontStyle: "italic",
            color: "#F6285F",
            fontSize: "0.7rem",
            display: "block",
            textAlign: "right",
            paddingRight: "1rem",
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
}
