import React from "react";

import { createSvgIcon } from "@mui/material";

export const WarningIcon = createSvgIcon(
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m 13.414145,1.4417381 c -0.582,-0.25878 -1.2464,-0.25878 -1.8283,0 -0.4495,0.19985 -0.7415,0.55189 -0.9447,0.8387299 -0.1997,0.28203 -0.4136,0.65151 -0.6488003,1.05785 L 0.69180364,19.402947 c -0.2361499,0.407801 -0.4507499,0.7784 -0.5962599,1.0929 -0.14788,0.3197 -0.30831,0.749301 -0.25718,1.2398 0.066162,0.6346 0.39865,1.2112 0.9146999,1.5865 0.39880996,0.2899 0.85104996,0.3664 1.20177996,0.3986 0.34509,0.0316 0.77333,0.0316 1.24458,0.0316 H 21.800545 c 0.4712,0 0.8995,0 1.2446,-0.0316 0.3507,-0.0322 0.8029,-0.1087 1.2018,-0.3986 0.516,-0.3753 0.8485,-0.951899 0.9147,-1.5865 0.0511,-0.4905 -0.1093,-0.9201 -0.2572,-1.2398 -0.1455,-0.3145 -0.3601,-0.685099 -0.5962,-1.0929 l -9.3007,-16.0646689 c -0.2351,-0.40632 -0.449,-0.77579 -0.6488,-1.05781 -0.2031,-0.2868399 -0.4951,-0.63888 -0.9446,-0.83873 z m 0.2109,8.24811 c 0,-0.62135 -0.5037,-1.12503 -1.125,-1.12503 -0.6214,0 -1.125,0.503679 -1.125,1.12503 v 4.4999999 c 0,0.6213 0.5036,1.125 1.125,1.125 0.6213,0 1.125,-0.5037 1.125,-1.125 z m -1.125,7.8749999 c -0.6214,0 -1.125,0.5036 -1.125,1.125 0,0.6213 0.5036,1.125 1.125,1.125 h 0.0112 c 0.6213,0 1.125,-0.5037 1.125,-1.125 0,-0.6214 -0.5037,-1.125 -1.125,-1.125 z"
      fill="#feb534"
      id="path66"
    />
  </svg>,
  "WarningIcon",
);
