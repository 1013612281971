import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export function useStopReminders() {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const stopReminders = async (setLoading, closeConfirmation, refetchState) => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    try {
      await axios
        .post(
          import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/reminders/stop",
          {},
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(() => {
          enqueueSnackbar(t("Automatic courses deactivated"), {
            variant: "success",
          });
        });
    } catch (error) {
      enqueueSnackbar(t("Failed to deactivate automatic courses"), {
        variant: "error",
      });
    }
    refetchState();
    setLoading(false);
    closeConfirmation();
  };
  return { stopReminders };
}
