import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, Tooltip } from "@mui/material";

export default function PermissionsChip({ risk, colors, size = "small" }) {
  const { t } = useTranslation();

  const labels = {
    high: t("Intrusive"),
    medium: t("Moderately instrusive"),
    low: t("Minimally intrusive"),
  };
  const danger_level = risk.risk_details.some(
    (element) => element.level === "high",
  )
    ? "high"
    : risk.risk_details.some((element) => element.level === "medium")
      ? "medium"
      : "low";

  const formatColor = Object.keys(colors).includes(danger_level)
    ? colors[danger_level]
    : null;

  return (
    <>
      {formatColor && (
        <Tooltip title={labels[danger_level]}>
          <Chip
            size={size}
            sx={{
              color: `${formatColor.textColor}`,
              bgcolor: `${formatColor.bgColor}`,
              borderColor: `${formatColor.textColor}`,
              padding: "0 6px",
              width: "auto",
            }}
            variant="outlined"
            label={labels[danger_level]}
          />
        </Tooltip>
      )}
    </>
  );
}
