import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

import { DeleteConfirmationDialog } from "@/components/modals/DeleteConfirmationDialog";
import { downloadEmailMessages } from "@/utils/exports";

export const ResetStats = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const title = t("Erase simulation data");
  const dialogContent = t(
    "Clicking on “Confirm” will erase all data related to Phishing Simulation. You will lose all data related to previous simulations sent. Once clicked, you won’t be able to download previous simulations raw data. This action is non-reversible. Please click the 'Download all simulation data' button bellow to backup your data before proceeding.",
  );

  async function resetAllStatsForCompany(): Promise<void> {
    try {
      setButtonLoading(true);
      await axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION
        }/company/simulation/reset`,
        {},
      );
      snackbar.showMessage(t("Data erased."));
      setOpenDialog(false);
    } catch (e) {
      snackbar.showMessage(t("An error occurred"));
    }
    setButtonLoading(false);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Typography variant="h6">{title}</Typography>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <Typography>
            {t(
              "Clicking on this button will erase all data related to Phishing Simulation. This action is non-reversible.",
            )}
          </Typography>
          <Button
            variant="contained"
            color={"error"}
            sx={{ marginTop: "1rem" }}
            onClick={() => setOpenDialog(true)}
            disabled={buttonLoading}
          >
            {t("Erase all simulation data")}
          </Button>
        </Box>
      </CardContent>

      <DeleteConfirmationDialog
        isShown={openDialog}
        onConfirm={resetAllStatsForCompany}
        onConfirmText={t("Confirm")}
        onCancel={() => setOpenDialog(false)}
        onCancelText={t("Cancel")}
        title={title}
        content={dialogContent}
        extraContent={
          <Button
            variant="contained"
            sx={{ marginTop: "1rem" }}
            onClick={() => {
              // Export from 1970-01-01 to now
              const dateFrom = new Date(0);
              const dateTo = new Date();

              downloadEmailMessages(
                getAccessTokenSilently,
                dateFrom,
                dateTo,
                null,
              );
            }}
            disabled={buttonLoading}
          >
            {t("Download all simulation data")}
          </Button>
        }
      />
    </Card>
  );
};
