import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export function useCompanySimulationStatus() {
  const [isSimulationEnabled, setIsSimulationEnabled] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    const token = await getAccessTokenSilently();

    try {
      setLoading(true);
      const response = await axios.get(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
          "/company/simulation_status",
        { headers: { Authorization: `Bearer ${token}` } },
      );
      setIsSimulationEnabled(response.data.automatic_simulation_delivery);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetch();
  }, [fetch]);
  return {
    isSimulationEnabled,
    error,
    loading,
    refetch: fetch,
  };
}
