import React from "react";
import { useTranslation } from "react-i18next";

import { Spinner } from "@/components/Spinner";

type BackendSuspenseProps = {
  data?: any;
  error: any;
  isPending: boolean;
  hasData: boolean;
  children: React.ReactNode;
};

export const BackendSuspense = (props: BackendSuspenseProps) => {
  const { t } = useTranslation();
  const { data, error, isPending } = { ...props };

  if (isPending) {
    return <Spinner />;
  } else if (error) {
    return <div>{t("An error occurred")}</div>;
  } else if (data?.length === 0 || (props.hasData && !props.hasData)) {
    return <div>{t("No data available")}</div>;
  }

  return props.children;
};
