import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Box, Stack, Typography } from "@mui/material";

import StickyHeader from "@/components/StickyHeader";
import { ActivateBrowserDefender } from "@/pages/browser-defender/components/ActivateBrowserDefender";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import TabPanelHeader from "@/pages/browser-defender/components/TabPanelHeader";
import { PasswordMisuseDemo } from "@/pages/browser-defender/demo/passwordMisuse/PasswordMisuse";
import ButtonWithDropdown from "@/pages/browser-defender/passwordMisuse/ButtonWithDropdown";
import DataTable from "@/pages/browser-defender/passwordMisuse/DataTable";
import { useUserContext } from "@/utils/contexts/UserContext";
import { sentryCaptureException } from "@/utils/sentry";

export default function PasswordMisusePage() {
  const info_user = useUserContext();

  if (info_user.email === "demo@acme.com") {
    return <PasswordMisuseDemo />;
  }

  return <PasswordMisuseLog />;
}

function PasswordMisuseLog() {
  const { t } = useTranslation();

  const [activeFeature, setActiveFeature] = useState(true);
  const [buttonStatus, setLabelSelectionStatus] = useState({
    archive: false,
    label: false,
    selectedRows: [],
  });
  const [errorMessage, setErrorMessage] = useState(""); // add back alert
  const [currentTab, setCurrentTab] = useState("unarchived");
  const [loading, setLoading] = useState(true);
  const [logsData, setLogsData] = useState({
    values: [],
    filteredValues: [],
  });

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchLogsData = useCallback(
    async (currentTab) => {
      try {
        const accessToken = await getAccessTokenSilently();
        const url = `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/actionable_logs/password_misuse/${currentTab}`;

        let resource;
        try {
          resource = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } catch (e) {
          if (e.response.status === 400) {
            setActiveFeature(false);
            return;
          }
        }

        setLogsData({
          values: resource.data,
          filteredValues: resource.data,
        });
        setLoading(false);
      } catch (e) {
        sentryCaptureException(e);
      }
    },
    [getAccessTokenSilently],
  );

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    setLoading(true);

    fetchLogsData(currentTab);
  }, [fetchLogsData, setLoading, info_user, currentTab]);

  async function setDomainType(label, action) {
    const rows = logsData.filteredValues.filter(
      (item) => buttonStatus.selectedRows.indexOf(item.id) > -1,
    );

    let resource;
    try {
      const accessToken = await getAccessTokenSilently();
      resource = await axios.post(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/password_misuse_domains/${currentTab}/label`,
        {
          event_ids: rows.map((item) => item.id),
          company_domain_label: label,
          additional_action: action,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      setErrorMessage(t("An error occurred"));
      sentryCaptureException(error);
    }

    setLogsData({
      values: resource.data,
      filteredValues: resource.data,
    });

    setLoading(false);
  }

  async function handleAlerts(label, action) {
    setLoading(true);

    const rows = logsData.filteredValues.filter(
      (item) => buttonStatus.selectedRows.indexOf(item.id) > -1,
    );

    let resource;
    try {
      const accessToken = await getAccessTokenSilently();
      resource = await axios.post(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/password_misuse_alerts/${label}`,

        {
          event_ids: rows.map((item) => item.id),
          additional_action: action,
        },

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      setErrorMessage(t("An error occurred"));
      sentryCaptureException(error);
    }

    setLogsData({
      values: resource.data,
      filteredValues: resource.data,
    });

    setLoading(false);
  }

  function selectionCounts() {
    const selectedRows = logsData.filteredValues.filter(
      (item) => buttonStatus.selectedRows.indexOf(item.id) > -1,
    );

    const selectedDomains = selectedRows.map((item) => item.domain);
    const selectedUsers = selectedRows.map((item) => item.email);

    const similarByDomain = logsData.filteredValues.filter((item) =>
      selectedDomains.includes(item.domain),
    );
    const similarByDomainAndUser = logsData.filteredValues.filter(
      (item) =>
        selectedDomains.includes(item.domain) &&
        selectedUsers.includes(item.email),
    );

    return {
      selectedRows: selectedRows.length,
      similarByDomain: similarByDomain.length,
      similarByDomainAndUser: similarByDomainAndUser.length,
    };
  }

  if (!activeFeature) {
    return (
      <div style={{ position: "relative", top: 0, left: 0 }}>
        <div
          style={{ position: "absolute", left: "0%", right: "0%", top: "0%" }}
        >
          <ActivateBrowserDefender />
        </div>
      </div>
    );
  }

  return (
    <Box>
      <StickyHeader>
        <Stack direction="row" gap={3} justifyContent="end" alignItems="center">
          <Typography
            variant="h2"
            style={{
              marginRight: "auto",
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {t("Password Alerts")}
          </Typography>
          <ButtonWithDropdown
            archivePossible={buttonStatus.archive}
            labelActionPossible={buttonStatus.label}
            labelDomains={setDomainType}
            handleAlerts={handleAlerts}
            currentTab={currentTab}
            selectionCounts={selectionCounts()}
          />
        </Stack>
      </StickyHeader>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage("")}>
          {errorMessage}
        </Alert>
      )}
      <TabPanelHeader
        tabsHeaders={[
          {
            label: t("Unarchived"),
            key: "unarchived",
          },
          { label: t("Archived"), key: "archived" },
        ]}
        tabsContents={["unarchived", "archived"].map((item, index) => {
          return (
            <GenericCard title={""} isLoading={loading} key={index}>
              <DataTable
                data={logsData}
                setData={setLogsData}
                setLabelSelectionStatus={setLabelSelectionStatus}
              />
            </GenericCard>
          );
        })}
        setCurrentTab={setCurrentTab}
      />
    </Box>
  );
}
