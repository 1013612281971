import React, { PropsWithChildren, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Paper } from "@mui/material";

import { SetupChecklistContext } from "./SetupChecklist";

type ActiveStepPaneProps = {
  clickNextDisabled?: boolean;
  onClickNext?: (next: () => void) => void;
} & PropsWithChildren;

const ActiveStepPane = ({
  children,
  clickNextDisabled = false,
  onClickNext = undefined,
}: ActiveStepPaneProps) => {
  const { activeIndex, prev, next, steps } = useContext(SetupChecklistContext);
  const { t } = useTranslation();

  const activeStep = useMemo(() => steps[activeIndex], [activeIndex, steps]);

  return (
    <Paper sx={{ p: "2.5rem" }}>
      {children}
      <Box marginTop="3rem" display="flex" justifyContent="space-between">
        <Button variant="outlined" disabled={activeIndex === 0} onClick={prev}>
          {t("Previous")}
        </Button>
        {activeIndex < steps.length - 1 ? (
          <Button
            variant="outlined"
            disabled={clickNextDisabled}
            onClick={onClickNext ? () => onClickNext(next) : next}
          >
            {activeStep.mandatory ? t("Next") : t("Skip")}
          </Button>
        ) : null}
      </Box>
    </Paper>
  );
};

export default ActiveStepPane;
