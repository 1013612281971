import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import InfoIcon from "@mui/icons-material/Info";
import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";

import {
  AudienceSelectionDepartment,
  AudienceSelectionMode,
  AudienceSelectionUser,
} from "@/components/AudienceSelector";
import { FullPageSpinner } from "@/components/Spinner";
import StickyHeader from "@/components/StickyHeader";
import { TemplateCatalogContextType } from "@/pages/templates/TemplateCatalogContextProvider";
import { TemplateType } from "@/pages/templates/templateUtils";
import { SimulationAccess } from "@/types/user";
import { useSimulationResource } from "@/utils/ResourceGet";
import { useUserContext } from "@/utils/contexts/UserContext";

import {
  AuditStep,
  StepDefine,
  StepPreview,
  StepValidated,
} from "./AuditSteps";

export interface AuditData {
  start_date: Dayjs | null;
  end_date: Dayjs | null;
  simulations_per_user: number;
}

export interface PreviewData {
  recipient: string;
  template_title: string;
  tag: string;
  language: string;
  payload: string;
  sending_date: string;
}

const Audit: React.FC = () => {
  const { t } = useTranslation();
  const user = useUserContext();
  const simulationAccess = user.current_company?.simulation_access;
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [step, setStep] = useState<AuditStep>(AuditStep.DEFINE);
  const [auditData, setAuditData] = useState<AuditData>({
    start_date: null,
    end_date: null,
    simulations_per_user: 1,
  });
  const [previewData, setPreviewData] = useState<PreviewData[]>([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [audienceSelectionMode, setAudienceSelectionMode] =
    useState<AudienceSelectionMode>("users");
  const [selectedUsers, setSelectedUsers] = useState<AudienceSelectionUser[]>(
    [],
  );
  const [selectedDepartments, setSelectedDepartments] = useState<
    AudienceSelectionDepartment[]
  >([]);

  const [userList] = useSimulationResource("users/basic_infos", []);

  const fetchUsers = () => {
    return;
  };

  const fetchTrialRunData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    let response;
    try {
      setLoading(true);
      response = await axios.get(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/trial-run`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const data = response.data;

      if (response.status === 404) {
        setStep(AuditStep.DEFINE);
      } else {
        setAuditData({
          start_date: dayjs(data.start_date),
          end_date: dayjs(data.end_date),
          simulations_per_user: data.simulations_per_user,
        });
        setPreviewData(data.preview_data || []);

        if (data.preview_mode) {
          setStep(AuditStep.PREVIEW);
        } else if (dayjs(data.start_date).isSameOrBefore(dayjs(), "day")) {
          navigate("/phishing-simulation/dashboard");
        } else {
          setStep(AuditStep.ALREADY_VALIDATED);
        }
      }
    } catch (error) {
      if (response.status !== 404) {
        setError("Failed to fetch trial run data. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, navigate]);

  useEffect(() => {
    fetchTrialRunData();
  }, [fetchTrialRunData]);

  const handleDateChange =
    (field: "start_date" | "end_date") => (date: Dayjs | null) => {
      setAuditData((prev) => ({ ...prev, [field]: date }));
    };

  const selectTemplate = (
    prevState: TemplateCatalogContextType,
    template: TemplateType,
  ) => {
    let newState = { ...prevState };
    if (prevState.selectedTemplates.includes(template)) {
      newState.selectedTemplates = prevState.selectedTemplates.filter((x) => {
        return x !== template;
      });
    } else {
      newState.selectedTemplates.push(template);
    }
    return newState;
  };
  const [isTemplateSelectionOpen, setIsTemplateSelectionOpen] = useState(false);
  const [templateCatalogContext, dispatchSelectTemplate] = useReducer(
    selectTemplate,
    {
      isInSelectionMode: true,
      selectedTemplates: [],
      selectTemplate: (template: TemplateType) =>
        dispatchSelectTemplate(template),
    },
  );

  const handleSimulationsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = parseInt(event.target.value, 10);
    setAuditData((prev) => ({
      ...prev,
      simulations_per_user: isNaN(value) ? 1 : value,
    }));
  };

  const handleCancel = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/trial-run/delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      await fetchTrialRunData();
      setError(null);
    } catch (error) {
      setError("Failed to cancel trial run.");
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewAudit = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/trial-run/preview`,
        {
          start_date: auditData.start_date?.format("YYYY-MM-DD"),
          end_date: auditData.end_date?.format("YYYY-MM-DD"),
          simulations_per_user: auditData.simulations_per_user,
          templates: templateCatalogContext.selectedTemplates.map(
            (template) => template.name,
          ),
          target_user_addresses: selectedUsers.map(
            (option: any) => option.user.email,
          ),
          target_department_ids: selectedDepartments.map(
            (option: any) => option.department.id,
          ),
          target_audience: audienceSelectionMode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      setPreviewData(response.data.preview_data);
      setStep(AuditStep.PREVIEW);
    } catch (error) {
      setError("Failed to create trial run preview.");
    } finally {
      setLoading(false);
    }
  };

  const handleValidateAudit = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/trial-run/validate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 6000);
      setStep(AuditStep.NEWLY_VALIDATED);
    } catch (error) {
      setError("Failed to schedule trial run.");
    } finally {
      setLoading(false);
    }
  };

  const handleViewResults = () => {
    const today = dayjs();
    if (auditData.start_date && auditData.start_date.isAfter(today, "day")) {
      // If start_date is after today, set the step to ALREADY_VALIDATED
      setStep(AuditStep.ALREADY_VALIDATED);
    } else {
      // If start_date is today or earlier, navigate to the dashboard
      navigate("/phishing-simulation/dashboard");
    }
  };

  const isDateRangeValid = useMemo(() => {
    if (auditData.start_date && auditData.end_date) {
      return auditData.end_date.unix() >= auditData.start_date.unix();
    }
    return true;
  }, [auditData.start_date, auditData.end_date]);

  const isStartDateValid = useMemo(() => {
    if (auditData.start_date) {
      return auditData.start_date.isAfter(dayjs(), "day");
    }
    return true;
  }, [auditData.start_date]);

  const isFormValid = useMemo(() => {
    return (
      auditData.start_date !== null &&
      auditData.end_date !== null &&
      auditData.simulations_per_user > 0 &&
      isDateRangeValid &&
      isStartDateValid
    );
  }, [auditData, isDateRangeValid, isStartDateValid]);

  const renderAuditParams = () => {
    if (step !== AuditStep.DEFINE) {
      const totalSimulations = previewData.length;
      const uniqueUsers = new Set(previewData.map((data) => data.recipient))
        .size;

      return (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {step === AuditStep.PREVIEW && (
            <>
              <Typography>
                <strong>{t("Preview")}:</strong>{" "}
                {t(
                  "{{totalSimulations}} simulations to {{uniqueUsers}} users",
                  {
                    totalSimulations: totalSimulations,
                    uniqueUsers: uniqueUsers,
                  },
                )}
              </Typography>
              <Typography>|</Typography>
            </>
          )}
          <Typography>
            <strong>{t("Start Date")}:</strong>{" "}
            {auditData.start_date?.format("YYYY-MM-DD")}
          </Typography>
          <Typography>
            <strong>{t("End Date")}:</strong>{" "}
            {auditData.end_date?.format("YYYY-MM-DD")}
          </Typography>
          <Tooltip
            title={t(
              "If the preview contains less simulations per user ('simulation rate') than you would like, please assign more tags in Phishing Simulation > Company Scan > Template attribution (note that some templates aren't available in trial mode).",
            )}
          >
            <Typography>
              <strong>{t("Simulations per user")}:</strong>{" "}
              {auditData.simulations_per_user}
              <InfoIcon sx={{ cursor: "pointer", fontSize: "medium", ml: 1 }} />
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {showConfetti && <Confetti recycle={false} />}
      <StickyHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">{t("Audit")}</Typography>
          {renderAuditParams()}
        </Box>
      </StickyHeader>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            {simulationAccess !== SimulationAccess.TRIAL_MODE ? (
              <Typography variant="h6" align="center">
                {t(
                  "You are not in trial mode, and thus cannot access the audit page.",
                )}
              </Typography>
            ) : loading ? (
              <FullPageSpinner />
            ) : (
              <>
                {error && (
                  <Typography color="error" align="center" gutterBottom>
                    {error}
                  </Typography>
                )}
                {step === AuditStep.DEFINE && (
                  <StepDefine
                    auditData={auditData}
                    handleDateChange={handleDateChange}
                    handleSimulationsChange={handleSimulationsChange}
                    handlePreviewAudit={handlePreviewAudit}
                    isStartDateValid={isStartDateValid}
                    isDateRangeValid={isDateRangeValid}
                    isFormValid={isFormValid}
                    isTemplateSelectionOpen={isTemplateSelectionOpen}
                    setIsTemplateSelectionOpen={setIsTemplateSelectionOpen}
                    templateCatalogContext={templateCatalogContext}
                    audienceSelectionMode={audienceSelectionMode}
                    setAudienceSelectionMode={setAudienceSelectionMode}
                    setSelectedUsers={setSelectedUsers}
                    setSelectedDepartments={setSelectedDepartments}
                    userList={userList}
                    fetchUsers={fetchUsers}
                  />
                )}
                {step === AuditStep.PREVIEW && (
                  <StepPreview
                    previewData={previewData}
                    handleCancel={handleCancel}
                    handleValidateAudit={handleValidateAudit}
                  />
                )}
                {(step === AuditStep.NEWLY_VALIDATED ||
                  step === AuditStep.ALREADY_VALIDATED) && (
                  <StepValidated
                    step={step}
                    auditData={auditData}
                    handleCancel={handleCancel}
                    handleViewResults={handleViewResults}
                  />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Audit;
