import React from "react";

import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { Tooltip, useTheme } from "@mui/material";

import { FeedbackLG } from "@/pages/threats/service/domain";

type ThreatFeedbackCommentIconProps = {
  feedback: FeedbackLG;
};

export const ThreatFeedbackCommentIcon = ({
  feedback,
}: ThreatFeedbackCommentIconProps) => {
  const theme = useTheme();

  if (!feedback?.comment) {
    return null;
  }

  return (
    <Tooltip
      title={feedback.comment}
      placement="right-end"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-7, -20],
              },
            },
          ],
        },
      }}
    >
      <ChatOutlinedIcon htmlColor={theme.palette.info.main} />
    </Tooltip>
  );
};
