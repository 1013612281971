import React from "react";

import { SvgIcon } from "@mui/material";

export const DownloadComplete = ({
  fontSize = 12,
  color = "neutral",
}: {
  fontSize?: string | number;
  color?: "neutral" | "primary";
}) => {
  return (
    <SvgIcon
      viewBox="0 0 33 33"
      fill="none"
      sx={{ fontSize: fontSize }}
      color={color}
    >
      <path
        fill="none"
        d="M27.5 17.1875V9.35C27.5 7.03978 27.5 5.88468 27.0504 5.00229C26.6549 4.22612 26.0239 3.59508 25.2477 3.1996C24.3653 2.75 23.2102 2.75 20.9 2.75H12.1C9.78978 2.75 8.63468 2.75 7.75229 3.1996C6.97612 3.59508 6.34508 4.22612 5.9496 5.00229C5.5 5.88468 5.5 7.03978 5.5 9.35V23.65C5.5 25.9602 5.5 27.1153 5.9496 27.9977C6.34508 28.7739 6.97612 29.4049 7.75229 29.8004C8.63468 30.25 9.78975 30.25 12.0999 30.25H16.5M19.9375 26.125L22.6875 28.875L28.875 22.6875"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
