import React from "react";

import { useBackend } from "@/utils/misc";

export const STARS_PER_LEVEL = 5;

export const useUserStats = ({ userId = null }) => {
  const url = `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/${
    userId ? `user/${userId}` : "current_user"
  }/stats`;
  const { data, error, isPending } = useBackend(url);

  const [successRate, setSuccessRate] = React.useState(0);
  const [received, setReceived] = React.useState(0);
  const [reported, setReported] = React.useState(0);
  const [stars, setStars] = React.useState(0);

  const [level, setLevel] = React.useState(0);
  const [starsToNextLevel, setStarsToNextLevel] = React.useState(0);

  React.useEffect(() => {
    if (data == null) return;

    setSuccessRate(data["success_rate"]);
    setReceived(data["received"]);
    setReported(data["reported"]);
    setStars(data["stars"]);

    setLevel(Math.ceil(data["stars"] / STARS_PER_LEVEL));
    setStarsToNextLevel(STARS_PER_LEVEL - (data["stars"] % STARS_PER_LEVEL));
  }, [data]);

  return {
    isPending,
    error,
    successRate,
    received,
    reported,
    stars,
    level,
    starsToNextLevel,
    starsPerLevel: STARS_PER_LEVEL,
  };
};
