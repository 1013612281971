import React, { useContext } from "react";

import { TemplateType } from "@/pages/templates/templateUtils";

export type TemplateCatalogContextType = {
  isInSelectionMode: boolean;
  selectedTemplates: Array<TemplateType>;
  selectTemplate: (template: TemplateType) => void;
};

const DefaultTemplateCatalogContext: TemplateCatalogContextType = {
  isInSelectionMode: false,
  selectedTemplates: [],
  selectTemplate: (TemplateType) => {
    return;
  },
};

const TemplateCatalogContext = React.createContext<TemplateCatalogContextType>(
  DefaultTemplateCatalogContext,
);
export function TemplateCatalogContextProvider({
  children,
  context,
}: {
  children: JSX.Element;
  context: TemplateCatalogContextType;
}) {
  return (
    <TemplateCatalogContext.Provider value={context}>
      {children}
    </TemplateCatalogContext.Provider>
  );
}

export function useTemplateCatalogContext() {
  return useContext(TemplateCatalogContext);
}
