import React from "react";

import { SvgIcon } from "@mui/material";

export const ShieldIcon = ({
  fontSize = 12,
  color = "neutral",
}: {
  fontSize?: string | number;
  color?: "neutral" | "primary";
}) => {
  return (
    <SvgIcon sx={{ fontSize: fontSize }} color={color}>
      <svg viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 2.4375V28.5625M24.3333 15.5C24.3333 22.2491 16.7485 27.1578 13.9888 28.7205C13.6751 28.8981 13.5183 28.9868 13.297 29.0329C13.1253 29.0687 12.8747 29.0687 12.7029 29.0329C12.4816 28.9868 12.3248 28.8981 12.0111 28.7205C9.2514 27.1578 1.66663 22.2491 1.66663 15.5V8.9242C1.66663 7.82487 1.66663 7.27521 1.85187 6.80272C2.01551 6.38532 2.28143 6.01288 2.62664 5.71761C3.01741 5.38336 3.54767 5.19036 4.6082 4.80436L12.2041 2.03968C12.4986 1.93248 12.6459 1.87888 12.7974 1.85764C12.9317 1.83879 13.0682 1.83879 13.2026 1.85764C13.3541 1.87888 13.5013 1.93248 13.7958 2.03968L21.3917 4.80436C22.4522 5.19036 22.9825 5.38336 23.3733 5.71761C23.7185 6.01288 23.9844 6.38532 24.148 6.80272C24.3333 7.27521 24.3333 7.82487 24.3333 8.9242V15.5Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
