import React, { ReactElement } from "react";

import { Box, useTheme } from "@mui/material";

import { AttachmentsIcon } from "@/components/icons/AttachmentsIcon";
import { ClickRate } from "@/components/icons/ClickRate";
import { CredentialsIcon } from "@/components/icons/CredentialsIcon";
import { DownloadsIcon } from "@/components/icons/DownloadsIcon";
import { PermissionsIcon } from "@/components/icons/PermissionsIcon";

import { TemplateTypeEnum } from "./templateUtils";

const TemplateTypeIcon = ({ type }: { type: TemplateTypeEnum }) => {
  const theme = useTheme();
  if (type === TemplateTypeEnum.ALL) {
    return <></>;
  }

  let icon: ReactElement;
  switch (type) {
    case TemplateTypeEnum.CREDENTIALS:
      icon = <CredentialsIcon />;
      break;
    case TemplateTypeEnum.ATTACHMENT:
      icon = <AttachmentsIcon />;
      break;
    case TemplateTypeEnum.PERMISSION:
      icon = <PermissionsIcon />;
      break;
    case TemplateTypeEnum.DOWNLOAD:
      icon = <DownloadsIcon />;
      break;
    case TemplateTypeEnum.IMPERSONATION:
      icon = <ClickRate color="neutral" />;
      break;
    case TemplateTypeEnum.CLICK:
      icon = <ClickRate color="neutral" />;
      break;
  }
  return (
    <Box
      sx={{
        border: "1.03px solid",
        borderColor: theme.palette.divider,
        borderRadius: "25px",
        px: ".75rem",
        py: ".1rem",
      }}
    >
      {icon}
    </Box>
  );
};

export default TemplateTypeIcon;
