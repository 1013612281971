import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export const EmailSent = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      color="primary"
      {...props}
    >
      <path
        opacity="0.4"
        d="M28 4L14 18"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M4.55387 14.326C3.76008 14.0173 3.36318 13.8629 3.24731 13.6405C3.14686 13.4477 3.14672 13.2181 3.24695 13.0251C3.36256 12.8026 3.75928 12.6478 4.55271 12.3382L27.0697 3.55103C27.7859 3.27152 28.1441 3.13177 28.3729 3.20822C28.5716 3.27461 28.7276 3.43056 28.794 3.6293C28.8704 3.85813 28.7307 4.21625 28.4512 4.93249L19.664 27.4495C19.3544 28.2429 19.1996 28.6396 18.9771 28.7552C18.7841 28.8555 18.5545 28.8553 18.3617 28.7549C18.1393 28.639 17.9849 28.2421 17.6762 27.4483L14.1721 18.4377C14.1094 18.2765 14.0781 18.196 14.0297 18.1281C13.9868 18.068 13.9342 18.0154 13.8741 17.9725C13.8062 17.9241 13.7257 17.8928 13.5645 17.8301L4.55387 14.326Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
