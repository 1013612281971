import { useSnackbar } from "material-ui-snackbar-provider";
import React from "react";
import { useTranslation } from "react-i18next";

import CloudDownloadButton from "@/components/buttons/cloudDownloadButton";
import { useLazyThreatEmlQuery } from "@/pages/threats/api";
import { downloadFile } from "@/utils/downloadFile";

const DownloadEmlButton = ({ threat }) => {
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [triggerFetchEml] = useLazyThreatEmlQuery();

  const handleClick = async () => {
    triggerFetchEml(threat.id, true).then((eml) => {
      if (!eml?.data?.payload) {
        snackbar.showMessage(
          t("An error occurred while fetching EML. Please try again later."),
        );
        return;
      }
      const decoded = atob(eml.data.payload);
      const blob = new Blob([decoded], { type: "text/plain" });
      downloadFile(blob, "message.eml");
    });
  };

  return (
    <CloudDownloadButton
      title={t("Download EML")}
      onClick={() => handleClick()}
    />
  );
};

export default DownloadEmlButton;
