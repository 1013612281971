import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";

import { ButtonSpinner } from "@/components/Spinner";

const ROUTE: string = `${
  import.meta.env.VITE_APP_ENDPOINT_LEARNING
}/company/freechat`;

const FreechatPreferences = (): React.JSX.Element => {
  const [isFreechatEnabled, setIsFreechatEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  useEffect(
    function getFreechatPreferences() {
      axios
        .get(ROUTE)
        .then((response) => {
          setIsFreechatEnabled(response.data.enabled);
          setIsLoading(false);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [setIsLoading, setIsError, setIsFreechatEnabled],
  );

  const changeFreechatEnabled = () => {
    setIsLoading(true);
    axios
      .post(ROUTE, {
        enabled: !isFreechatEnabled,
      })
      .then(() => {
        setIsFreechatEnabled((state) => !state);
        snackbar.showMessage("Freechat preferences successfully updated");
      })
      .catch(() => {
        snackbar.showMessage(
          "There was an error while modifying freechat preferences",
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{t("AI-powered free chat")}</Typography>
            <Tooltip
              title={t(
                `Mantra has implemented rigorous measures to ensure the chatbot provides reliable information, though it may occasionally contain errors or inconsistencies.`,
              )}
              sx={{ marginLeft: "15px" }}
            >
              <InfoIcon />
            </Tooltip>
            <Box sx={{ paddingLeft: ".5em" }}>
              {!isError ? (
                <Switch
                  size="small"
                  checked={isFreechatEnabled}
                  onChange={changeFreechatEnabled}
                  disabled={isLoading || isError}
                />
              ) : (
                ""
              )}
              {isLoading ? <ButtonSpinner /> : ""}
              {isError ? (
                <Typography variant="helper_text" color="error">
                  {t("Unable to fetch freechat preferences")}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Stack spacing={2}>
          <Box>
            <Typography>
              {t(
                "When activated, employees will be able to discuss with an intelligent chatbot when they are not following a course.",
              )}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FreechatPreferences;
