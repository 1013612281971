import clsx from "clsx";
import React from "react";

import LayoutStyle from "@/components/layout/Layout.module.css";
import Menu from "@/components/menu/Menu";

export const Layout = function ({
  children,
  disableMenu = false,
  disablePadding = false,
}) {
  return (
    <div className={LayoutStyle.container}>
      {!disableMenu && (
        <div className={LayoutStyle.menu}>
          <Menu />
        </div>
      )}
      <div
        className={clsx(
          LayoutStyle.mainContent,
          !disablePadding && LayoutStyle.mainContentPadding,
        )}
      >
        {children}
      </div>
    </div>
  );
};
