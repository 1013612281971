import React from "react";

import SortComponent from "@/components/sort/SortComponent";

export default function TableSort({ setOrderBy, columns, defaultOrder = [] }) {
  return (
    <SortComponent
      setOrderBy={(listOrderBy) => {
        let orderByFormatted = [];
        for (const orderItem of listOrderBy) {
          if (orderItem.label) {
            orderByFormatted.push({
              column: orderItem.label,
              direction: orderItem.order === "asc" ? 1 : -1,
            });
          }
        }

        setOrderBy(
          orderByFormatted.length > 0 ? orderByFormatted : defaultOrder,
        );
      }}
      orderChoices={columns}
    />
  );
}
