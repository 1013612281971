import React from "react";
import { useTranslation } from "react-i18next";

import { FullPageSpinner } from "@/components/Spinner";
import DataSafesListTable from "@/pages/banners/preferences/DataSafesListTable";
import TabPanelHeader from "@/pages/banners/preferences/TabPanelHeader";
import { useCompanySafeSendersQuery } from "@/pages/banners/remote-apis/bannerUsersApi";
import { ContactType } from "@/pages/banners/remote-apis/contact_types";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { useUserContext } from "@/utils/contexts/UserContext";

export function BannerPreferencesDashboard() {
  const { t } = useTranslation();

  const user = useUserContext();

  const { data, isError, isLoading } = useCompanySafeSendersQuery({
    companyId: user.current_company.id,
  });

  if (isError) {
    return <div>{t("An error occurred")}</div>;
  }

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <GenericCard title={t("Safe lists")} isLoading={isLoading}>
        <TabPanelHeader
          tabsHeaders={[
            {
              label: t("Safe domains"),
              key: "domains",
              helper: t(
                "No banners will be displayed on emails coming from senders or domains on this list",
              ),
            },
            {
              label: t("Safe senders"),
              key: "email_addresses",
              helper: t(
                "No banners will be displayed on emails coming from senders or domains on this list",
              ),
            },
          ]}
          tabsContents={[
            <DataSafesListTable
              data={data.safeDomains}
              contactType={ContactType.domain}
              key={"domains"}
            />,
            <DataSafesListTable
              data={data.safeSenders}
              contactType={ContactType.email_address}
              key={"email_addresses"}
            />,
          ]}
        />
      </GenericCard>
    </>
  );
}
