import React from "react";

import { alpha } from "@mui/material";
import { Chip } from "@mui/material";

export function LevelCell({ value }) {
  let color = "#666";
  if (value === "Beginner") {
    color = "#EE97A7";
  } else if (value === "Intermediate") {
    color = "#4CA2ED";
  } else if (value === "Expert") {
    color = "#1dc9b7";
  }

  if (!value) {
    return <></>;
  }
  return (
    <Chip
      variant="outlined"
      size={"small"}
      sx={{
        backgroundColor: alpha(color, 0.1),
        borderColor: color,
        color: color,
        fontWeight: "bold",
      }}
      label={value}
    />
  );
}
