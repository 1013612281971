import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import { useBackend } from "@/utils/misc";

export function User({ userId }) {
  const { t } = useTranslation();

  const { data, error, isPending } = useBackend(
    `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/${
      userId ? `user/${userId}` : "current_user"
    }/info`,
  );

  let fullname;
  if (isPending || !data) fullname = "";
  if (error) fullname = t("An error occurred");
  if (data) {
    if (data?.length === 0) {
      fullname = "";
    } else {
      fullname = data.firstname + " " + data.lastname;
    }
  }
  return (
    <Typography component={"span"} sx={{ fontSize: 20, color: "black" }}>
      {fullname}
    </Typography>
  );
}
