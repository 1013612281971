import { T } from "@/components/LocalizedString";
import { SetupStep } from "@/components/stepper/Stepper";

import {
  SimulationSetupChecklistStatus,
  StepperStateKey,
} from "./SetupChecklistTypes";

export class SetupStepper {
  readonly steps: SetupStep[];

  constructor(state: SimulationSetupChecklistStatus) {
    const defaultStepperState: SetupStep[] = [];
    defaultStepperState[StepperStateKey.MAILING_PERMISSIONS] = {
      label: T("Mailing Permissions"),
      status: "uncompleted",
      mandatory: true,
    };
    defaultStepperState[StepperStateKey.PROVISIONING_PERMISSIONS] = {
      label: T("Provisioning Permissions"),
      status: "uncompleted",
      mandatory: true,
    };
    defaultStepperState[StepperStateKey.PROVISION_ONE_USER] = {
      label: T("Provision at least one other user"),
      status: "uncompleted",
      mandatory: true,
    };
    defaultStepperState[StepperStateKey.REPORT_BUTTON_DEPLOYMENT] = {
      label: T("Report Button Deployment"),
      status: "uncompleted",
      mandatory: false,
    };
    defaultStepperState[StepperStateKey.SIMULATION_DELIVERY_TEST] = {
      label: T("Delivery Test"),
      status: "uncompleted",
      mandatory: true,
    };
    defaultStepperState[StepperStateKey.COMPANY_SCAN] = {
      label: T("Company Scan"),
      status: "uncompleted",
      mandatory: false,
    };
    defaultStepperState[StepperStateKey.MAP_GROUPS_DEPARTMENTS] = {
      label: T("Map groups to departments"),
      status: "uncompleted",
      mandatory: false,
    };
    defaultStepperState[StepperStateKey.ATTRIBUTE_TEMPLATES_DEPARTMENTS] = {
      label: T("Attribute Saas to departments"),
      status: "uncompleted",
      mandatory: true,
    };

    this.steps = defaultStepperState;
    this.initStepper(state);
  }

  initStepper(state: SimulationSetupChecklistStatus) {
    const keys = Object.values(StepperStateKey).filter(
      (key) => !isNaN(Number(key)),
    );
    keys.forEach((key) => {
      stepsInitializer[key](state, this.steps[key]);
    });
  }
}

const stepsInitializer: ((
  checklistSliceState: SimulationSetupChecklistStatus,
  currentStepState: SetupStep,
) => void)[] = [];
stepsInitializer[StepperStateKey.MAILING_PERMISSIONS] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.has_mail_permissions) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};
stepsInitializer[StepperStateKey.PROVISIONING_PERMISSIONS] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.has_provisioning_permissions) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};
stepsInitializer[StepperStateKey.PROVISION_ONE_USER] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.has_at_least_one_non_admin_user) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "warning";
  }
};
stepsInitializer[StepperStateKey.REPORT_BUTTON_DEPLOYMENT] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.has_report_button_deployment) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "warning";
  }
};
stepsInitializer[StepperStateKey.SIMULATION_DELIVERY_TEST] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.has_confirmed_all_delivery_tests) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};
stepsInitializer[StepperStateKey.COMPANY_SCAN] = (
  checklistSliceState,
  currentStepState,
) => {
  const companyScan = checklistSliceState.company_scan;
  if (
    companyScan.has_key_people &&
    companyScan.has_email_signature &&
    companyScan.has_key_customers
  ) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "warning";
  }
};
stepsInitializer[StepperStateKey.MAP_GROUPS_DEPARTMENTS] = (
  checklistSliceState,
  currentStepState,
) => {
  if (checklistSliceState.has_mapped_groups_to_departments) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "warning";
  }
};
stepsInitializer[StepperStateKey.ATTRIBUTE_TEMPLATES_DEPARTMENTS] = (
  checklistSliceState,
  currentStepState,
) => {
  switch (checklistSliceState.assign_templates_to_departments) {
    case "no-tags":
      currentStepState.status = "error";
      break;
    case "default-tags":
      currentStepState.status = "warning";
      break;
    case "user-assigned-tags":
      currentStepState.status = "succeeded";
      break;
  }
};
