import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  Button,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Switch,
} from "@mui/material";

import {
  useDisengageCompanyMutation,
  useEngageOrDisengageMutation,
  useUsersQuery,
} from "@/pages/banners/remote-apis/bannerUsersApi";
import { UsersBannersListTable } from "@/pages/banners/users/UsersBannersListTable";
import { useUserContext } from "@/utils/contexts/UserContext";

const TopButtonsRow = styled.div`
  display: flex;
  flex-direction: row-reverse;

  margin-top: 10px;
  margin-bottom: 8px;
`;

const BottomButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const POLLING_VALUE = 5000;

export function UsersBannersListContainer() {
  const { current_company } = useUserContext();
  const { t } = useTranslation();
  const [pollingInterval, setPollingInterval] = useState(POLLING_VALUE);
  const {
    data: users,
    isLoading,
    isFetching,
  } = useUsersQuery(null, {
    pollingInterval: pollingInterval,
  });
  const [engageOrDisengage] = useEngageOrDisengageMutation();
  const [disengageAllCompany, { isLoading: disengageCompanyIsLoading }] =
    useDisengageCompanyMutation();

  const disableAllActions =
    !current_company.banners_permission_granted || disengageCompanyIsLoading;

  const [showEngagedUsersOnly, setShowEngagedUsersOnly] = useState(false);

  useEffect(() => {
    if (isFetching) return;

    if (
      !users.some(
        (e) =>
          e.banners_engagement_status === "engage_started" ||
          e.banners_engagement_status === "disengage_started",
      )
    ) {
      setPollingInterval(0);
    } else {
      setPollingInterval(POLLING_VALUE);
    }
  }, [setPollingInterval, users, isFetching]);

  const handleActiveUserSwitch = (event) => {
    setShowEngagedUsersOnly(event.target.checked);
  };

  const disableCompany = () => {
    const confirmation = window.confirm(
      t("This action deactivate banners for all users, are you sure ?"),
    );
    if (confirmation) disengageAllCompany();
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  const displayedUsers = users.filter((user) => {
    if (showEngagedUsersOnly) {
      return (
        user.banners_engagement_status === "engaged" ||
        user.banners_engagement_status === "disengage_started"
      );
    }
    return true;
  });

  return (
    <>
      <TopButtonsRow>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={showEngagedUsersOnly}
                onChange={handleActiveUserSwitch}
              />
            }
            label={t("Show only users with banner activated")}
          />
        </FormGroup>
      </TopButtonsRow>
      <UsersBannersListTable
        users={displayedUsers}
        handleEngageOrDisengageUser={engageOrDisengage}
        deactivateAllUserActions={disableAllActions}
      />
      <BottomButtonsRow>
        <Button
          disabled={disableAllActions}
          variant="contained"
          color="warning"
          onClick={disableCompany}
        >
          {t("Deactivate Banners")}
        </Button>
      </BottomButtonsRow>
    </>
  );
}
