import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import ExtensionRisksList from "@/pages/browser-defender/extensionGovernance/ExtensionRisksList";
import PermissionsChip from "@/pages/browser-defender/extensionGovernance/PermissionsChip";
import UsersTable from "@/pages/browser-defender/extensionGovernance/UsersTable";

const riskColors = {
  high: {
    bgColor: "#FEE9EF8C",
    textColor: "#F6285F",
    icon: <WarningAmberIcon color={"#F6285F"} />,
  },
  medium: {
    bgColor: "#FEF5ED8C",
    textColor: "#F2994A",
    icon: <ErrorOutlineOutlinedIcon color={"#F2994A"} />,
  },
  low: {
    bgColor: "#1dc9b71a",
    textColor: "#1dc9b7",
    icon: <CheckCircleOutlineOutlinedIcon color={"##1dc9b7"} />,
  },
};

function ExtensionCardHeader({ row }) {
  const { t } = useTranslation();

  return (
    <Box>
      <Box>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                sx={{ bgcolor: "#EDEBEB" }}
                style={{
                  color: "rgb(6, 17, 35)",
                  fontWeight: "bold",
                  width: 40,
                  height: 40,
                }}
              >
                {row.name.charAt(0).toUpperCase()}
              </Avatar>
              <Typography
                style={{
                  fontWeight: "bold",
                  display: "inline",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                {row.name}
              </Typography>
              {row.homepage_url && (
                <Tooltip title={t("Visit homepage")}>
                  <Link
                    to={row.homepage_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <OpenInNewIcon sx={{ color: "#5867dd" }} />
                  </Link>
                </Tooltip>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ paddingTop: "1rem" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs>
            <Typography style={{ color: "#7F8995" }}>
              {row.description}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default function ExtensionDetailsCard({ row }) {
  const [data, setData] = useState(row);
  const { t } = useTranslation();

  const riskMessages = data.risk_details.map(({ explanation }) => explanation);
  const filteredRiskMessages = data.risk_details.filter(
    ({ explanation }, index) => !riskMessages.includes(explanation, index + 1),
  );

  useEffect(() => {
    setData(row);
    document.getElementById("scrolling_extension_details").scrollTo(0, 0);
  }, [row]);

  return (
    <Card>
      <CardHeader title={<ExtensionCardHeader row={data} />} />
      <Divider />
      <CardContent
        style={{
          display: "flex",
          flexFlow: "column",
          paddingTop: "1rem",
          height: "70vh",
          overflow: "scroll",
        }}
        id="scrolling_extension_details"
      >
        <Box sx={{ marginBottom: "2rem", marginTop: "2rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginBottom: "2rem",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                flexShrink: 0,
                marginRight: "1rem",
              }}
            >
              {t("Permissions")}
            </Typography>

            <PermissionsChip risk={data} colors={riskColors} />
          </Box>

          <Box>
            <Typography style={{ marginBottom: "1rem" }}>
              {filteredRiskMessages.length > 0
                ? t("This extension requires the following permissions")
                : data.permissions.length > 0
                  ? t(
                      "This extension requires minimally intrusive permissions only.",
                    )
                  : t("This extension requires no permissions.")}
            </Typography>
            <ExtensionRisksList risks={filteredRiskMessages} />
          </Box>
        </Box>
        <Divider />
        <Box sx={{ marginBottom: "2rem", marginTop: "2rem" }}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "1.1rem",
              marginBottom: "2rem",
            }}
          >
            {t("User details")}
          </Typography>

          <UsersTable users={data.users} riskColors={riskColors} />
        </Box>
      </CardContent>
    </Card>
  );
}
