import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Box, Button, Typography } from "@mui/material";

import { selectSetupChecklistState } from "../SetupChecklistSlice";

const MapGroupsToDepartments: React.FC = () => {
  const { t } = useTranslation();

  const { has_mapped_groups_to_departments: hasMappedGroupsToDepartments } =
    useSelector(selectSetupChecklistState);

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        {t("Match your groups with departments created in Mantra.")}
      </Typography>
      <Typography>
        {t(
          "You need to ensure that a sufficient amount of users are assigned to departments, in order to dispatch simulations correctly",
        )}
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link to="/phishing-simulation/company#groups-departments">
          <Button variant="contained">{t("Map groups to departments")}</Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          {hasMappedGroupsToDepartments ? (
            <>
              <CheckCircleOutlinedIcon color="success" />
              <Typography variant="body_small" color="success.main">
                {t("It works!")}
              </Typography>
            </>
          ) : (
            <>
              <CancelOutlinedIcon color="error" />
              <Typography color="error">
                {t("You need to map more groups")}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MapGroupsToDepartments;
