import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { FullPageSpinner } from "@/components/Spinner";
import { GrantPermissionComponent } from "@/pages/awareness/setup/Teams/GrantPermissionComponent";
import { RadioFormControl } from "@/pages/awareness/setup/Teams/RadioFormControl";
import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import { StyledTypo } from "@/pages/awareness/setup/Teams/StyledTypo";
import {
  useDeployCompanyWideMutation,
  useDeployDepartmentsMutation,
  useDeployUsersMutation,
  useInstallationQuery,
} from "@/pages/awareness/setup/Teams/teamsInstallationApi";
import { AWARENESS_SCRIBEHOW_TROUBLESHOOTING_TEAMS } from "@/utils/constants";
import { useUserContext } from "@/utils/contexts/UserContext";

export const Teams = () => {
  const POLLING_VALUE = 5000;
  const snackbar = useSnackbar();
  const [pollingInterval, setPollingInterval] = useState(POLLING_VALUE);
  const [deploymentType, setDeploymentType] = useState("company");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deployedDepartments, setDeployedDepartments] = useState([]);
  const user = useUserContext();
  const [deployBotConsent, setDeployBotConsent] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [botName, setBotName] = useState("");
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const { data: installation, isLoading } = useInstallationQuery(
    user?.base_company?.id,
    {
      skip: !user?.base_company?.id,
      pollingInterval: pollingInterval,
    },
  );
  const [deployCompanyWide, { isLoading: companyWideLoading }] =
    useDeployCompanyWideMutation();
  const [deployOnUsers, { isLoading: userLoading }] = useDeployUsersMutation();
  const [deployDepartments, { isLoading: departmentLoading }] =
    useDeployDepartmentsMutation();

  useEffect(() => {
    async function f() {
      if (installation && !initialized) {
        setSelectedUsers(installation.users_to_deploy);
        setDeployedDepartments(installation.deployed_departments);
        setDeployBotConsent(installation.deploy_bot_consent);
        setDeploymentType(installation.mode);
        setBotName(installation.bot_name);
        setInitialized(true);
      }
      // if we're deploying users and they're all deployed, stop polling
      if (
        installation &&
        installation.mode === "users" &&
        installation.users_to_deploy.every((user) =>
          installation.users_deployed.includes(user.id),
        )
      ) {
        setPollingInterval(0);
      }
    }

    f();
  }, [installation, user, initialized, selectedUsers]);

  const onClickDeploy = () => {
    if (
      installation.users_deployed.length > 0 &&
      ["users", "departments"].includes(deploymentType)
    ) {
      setShowConfirmationPopup(true);
    } else {
      deployUsers();
    }
  };

  const deployUsers = async () => {
    if (deploymentType === "company") {
      deployCompanyWide(user?.base_company?.id);
    } else if (deploymentType === "users") {
      const usersID = selectedUsers.map((user) => user.id);
      deployOnUsers({ company_id: user?.base_company?.id, user_ids: usersID });
    } else if (deploymentType === "departments") {
      const departmentsID = deployedDepartments.map(
        (department) => department.id,
      );
      deployDepartments({
        company_id: user?.base_company?.id,
        department_ids: departmentsID,
      });
    }
    setPollingInterval(POLLING_VALUE);
    snackbar.showMessage(
      t("{{botName}} is currently being installed.", { botName: botName }),
    );
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <Card>
      <CardHeader variant={"h2"} title={t("Microsoft Teams Configuration")} />
      <Divider sx={{ marginBottom: "1.5rem" }} />
      <CardContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box sx={{ width: "600px" }}>
          <GrantPermissionComponent
            nonce={installation.nonce}
            deployBotConsent={deployBotConsent}
          />

          <StyledTypo variant={"h2"}>{t("Deployment Type")}</StyledTypo>
          <RadioFormControl
            deploymentType={deploymentType}
            setDeploymentType={setDeploymentType}
            setDeployedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            actuallyDeployedUsers={installation.users_deployed}
            setDeployedDepartments={setDeployedDepartments}
            deployedDepartments={deployedDepartments}
          />
          <StyledBox>
            <Typography color={"text.secondary"} sx={{ paddingTop: "1rem" }}>
              {t(
                "{{botName}} is currently deployed on {{nbBotsDeployed}} users",
                {
                  botName: botName,
                  nbBotsDeployed: installation.users_deployed.length,
                },
              )}
            </Typography>
          </StyledBox>

          <StyledBox sx={{ justifyContent: "flex-end" }}>
            {!(companyWideLoading || departmentLoading || userLoading) && (
              <Button
                variant={"contained"}
                disabled={!deployBotConsent}
                sx={{ marginTop: "2rem" }}
                onClick={onClickDeploy}
              >
                {t("Deploy bot to user")}
              </Button>
            )}
          </StyledBox>
          <Trans i18nKey="TeamsTroubleshootingLinkText">
            If {{ botName }} is not deploying for some users,
            <Link
              target="_blank"
              to={AWARENESS_SCRIBEHOW_TROUBLESHOOTING_TEAMS}
            >
              {" "}
              follow this guide
            </Link>
            .
          </Trans>
        </Box>
      </CardContent>
      <Dialog open={showConfirmationPopup}>
        <DialogTitle>{t("Warning")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              "{{botName}} will be deployed only on those selected {{entity}}.",
              {
                botName: botName,
                entity:
                  deploymentType === "users"
                    ? t("Users").toLowerCase()
                    : t("Departments").toLowerCase(),
              },
            )}{" "}
            {t(
              "All other users that have had the bot installed will have it removed.",
            )}
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setShowConfirmationPopup(false)}>
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setShowConfirmationPopup(false);
                deployUsers();
              }}
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
