import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useReducer } from "react";

import { ColumnSort } from "@/pages/threats/service/domain";
import {
  ThreatFilterParams,
  ThreatSourceParams,
} from "@/pages/threats/service/types";

const threatParameterLocalState = createSlice({
  name: "threatParams",
  initialState: {
    archived: false,
    searchTerm: undefined,
    sort: [],
    source: null,
    bannerLevel: null,
    hasAttachments: null,
  },
  reducers: {
    performSearch: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    filter: (state, action: PayloadAction<ThreatFilterParams>) => {
      state.bannerLevel = action.payload.bannerLevel;
      state.hasAttachments = action.payload.hasAttachments;
    },
    sort: (state, action: PayloadAction<ColumnSort[]>) => {
      state.sort = action.payload;
    },
    source: (state, action: PayloadAction<ThreatSourceParams>) => {
      state.source = action.payload.source;
      state.archived = action.payload.archived;
    },
  },
});

export const useListOptions = (disableThreatsInBanners: boolean) => {
  const [listOptions, dispatch] = useReducer(
    threatParameterLocalState.reducer,
    {
      archived: false,
      searchTerm: undefined,
      sort: [],
      source: disableThreatsInBanners ? "user" : null,
      bannerLevel: null,
      hasAttachments: null,
    },
  );
  const { performSearch, sort, source, filter } =
    threatParameterLocalState.actions;

  return {
    options: listOptions,

    search: (term: string) => {
      dispatch(performSearch(term));
    },
    onFilterChange: (f: ThreatFilterParams) => {
      dispatch(filter(f));
    },
    onSortChange: (s: ColumnSort[]) => {
      dispatch(sort(s));
    },
    onTabChange: (tab: ThreatSourceParams) => {
      dispatch(source(tab));
    },
  };
};
