import React, { PropsWithChildren } from "react";

import { Stack, Typography } from "@mui/material";

import StickyHeader from "@/components/StickyHeader";

type TitleBarProps = {
  title: string;
  height?: string;
};

export const TitleBar = ({
  title,
  children,
}: PropsWithChildren<TitleBarProps>) => {
  return (
    <StickyHeader>
      <Stack direction="row" gap={3} justifyContent="end" alignItems="center">
        <Typography
          variant="h2"
          style={{
            marginRight: "auto",
            maxHeight: "2rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>
        {children}
      </Stack>
    </StickyHeader>
  );
};
