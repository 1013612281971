import React from "react";

import { ComingSoon } from "@/pages/banners/ComingSoon";
import { useUserContext } from "@/utils/contexts/UserContext";

type BannersLayoutType = {
  children?: React.ReactNode;
};

const BannersLayout: React.FC<BannersLayoutType> = ({
  children,
}: BannersLayoutType) => {
  const { current_company } = useUserContext();

  if (!current_company.banners_activated) {
    return <ComingSoon />;
  } else {
    return <>{children}</>;
  }
};

export default BannersLayout;
