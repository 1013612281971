import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from "@mui/material";

import { useSimulationResource } from "@/utils/ResourceGet";

const ENDPOINT = "company/send_onboarding_new_users_status";
const TRY_ONBOARDING_ENDPOINT = "onboarding_email_to_user";
export const SendOnboardings = () => {
  const snackbar = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const [{ enabled: optionEnabled }, readError] = useSimulationResource(
    ENDPOINT,
    false,
  );

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(optionEnabled);
  }, [optionEnabled]);

  async function onChange(): Promise<void> {
    const initialCheckValue = !!checked;
    const targetCheckValue = !checked;
    try {
      setChecked(targetCheckValue);
      await axios.put(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/${ENDPOINT}`,
        { enabled: targetCheckValue },
      );
      snackbar.showMessage(
        `New users will ${
          targetCheckValue === true ? "" : "not"
        } receive onboarding emails.`,
      );
    } catch {
      snackbar.showMessage("Could not update value.");
      setChecked(initialCheckValue);
    }
  }

  async function sendExampleOnboardingEmail(): Promise<void> {
    try {
      setButtonLoading(true);
      await axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION
        }/${TRY_ONBOARDING_ENDPOINT}`,
      );
      snackbar.showMessage("Email sent.");
    } catch (e) {
      snackbar.showMessage("Error: email could not be sent.");
    }
    setButtonLoading(false);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Typography variant="h6">
              {t("Send onboarding emails to new users")}
            </Typography>
            <Box sx={{ paddingLeft: ".5em" }}>
              <Switch
                size="small"
                checked={checked}
                onChange={onChange}
                disabled={readError}
              />
            </Box>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <Typography>
            {t(
              "When activated, new employees will receive an email that explains how Mantra Phishing Simulations work.",
            )}
          </Typography>
          {readError !== null && (
            <Typography style={{ color: "red" }}>
              {t("Could not retrieve data.")}
            </Typography>
          )}
          <Button
            variant="contained"
            sx={{ marginTop: "1rem" }}
            onClick={sendExampleOnboardingEmail}
            disabled={buttonLoading}
          >
            {t("Send example onboarding email to myself")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
