import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import { Alert, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { T } from "@/components/LocalizedString";
import { ActivateBrowserDefender } from "@/pages/browser-defender/components/ActivateBrowserDefender";
import { AdvancedSettings } from "@/pages/browser-defender/setup/AdvancedSettings";
import { BrowserDefenderExtensionInformation } from "@/pages/browser-defender/setup/BrowserDefenderExtensionInformation";
import { CompanySettings } from "@/pages/browser-defender/setup/CompanySettings";
import DownloadsPolicies from "@/pages/browser-defender/setup/DownloadsPolicies";
import { GroupsSettings } from "@/pages/browser-defender/setup/GroupsSettings";
import { useUserContext } from "@/utils/contexts/UserContext";
import { sentryCaptureException } from "@/utils/sentry";

const statusColors = {
  off: (
    <Tooltip title={T("Disabled")}>
      <IconButton>
        <ClearIcon sx={{ color: "#F6285F" }} />
      </IconButton>
    </Tooltip>
  ),
  on: (
    <Tooltip title={T("Enabled")}>
      <IconButton>
        <CheckIcon sx={{ color: "#65c58b" }} />
      </IconButton>
    </Tooltip>
  ),
  flexible: (
    <Tooltip title={T("Flexible")}>
      <IconButton>
        <LockOpenOutlinedIcon sx={{ color: "#65c58b" }} />
      </IconButton>
    </Tooltip>
  ),
  strict: (
    <Tooltip title="Strict">
      <IconButton>
        <LockIcon sx={{ color: "#65c58b" }} />
      </IconButton>
    </Tooltip>
  ),
  monitor: (
    <Tooltip title={T("Monitor")}>
      <IconButton>
        <PreviewIcon sx={{ color: "#65c58b" }} />
      </IconButton>
    </Tooltip>
  ),
};

type CompanySettingsAPI = {
  email_alerts_active?: boolean;
  send_monthly_digest?: boolean;
  manual_password_setup?: boolean;
  llm_based_detection?: boolean;
  extension_generation?: string;
};

export function Preferences() {
  const [activeFeature, setActiveFeature] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const [companySettings, setCompanySettings] = useState<{
    data: CompanySettingsAPI;
    loading: boolean;
  }>({
    data: {},
    loading: true,
  });

  const [groupSettings, setGroupSettings] = useState({
    data: [],
    groups: [],
    loading: true,
  });

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchCompanySettings = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = `${
        import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
      }/settings/company`;

      try {
        const resource = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setCompanySettings({
          data: resource.data,
          loading: false,
        });
      } catch (e) {
        if (e.response.status === 400) {
          setCompanySettings({
            data: {},
            loading: false,
          });
          setActiveFeature(false);
          return;
        }
      }
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  const fetchGroupSettings = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = `${
        import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
      }/settings/groups`;

      try {
        const resource = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setGroupSettings({
          data: resource.data.configuration,
          groups: resource.data.groups_list,
          loading: false,
        });
      } catch (e) {
        if (e.response.status === 400) {
          setGroupSettings({
            data: [],
            groups: [],
            loading: false,
          });
          setActiveFeature(false);
          return;
        }
      }
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchCompanySettings();
    fetchGroupSettings();
  }, [fetchCompanySettings, fetchGroupSettings, info_user]);

  async function saveCompanySettings(payload) {
    let resource;
    try {
      const accessToken = await getAccessTokenSilently();
      resource = await axios.post(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/settings/company`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      setErrorMessage(t("An error occurred while saving your configuration!"));
      sentryCaptureException(error);
    }

    setCompanySettings({
      data: resource.data,
      loading: false,
    });
  }

  async function makeGroupSettingsUpdateCall(payload) {
    let resource;
    try {
      const accessToken = await getAccessTokenSilently();
      resource = await axios.post(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/settings/company_group`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      setErrorMessage(t("An error occurred while saving your configuration!"));
      sentryCaptureException(error);
    }

    setGroupSettings({
      data: resource.data.configuration,
      groups: resource.data.groups_list,
      loading: false,
    });
  }

  async function saveGroupSettings(group_id, group_name, payload) {
    if (!payload.downloads_protection_policy_id) {
      payload.downloads_protection_policy_id = null;
    }
    makeGroupSettingsUpdateCall({
      ...payload,
      group_id: group_id,
      group_name: group_name,
    });
  }

  async function addGroupSettings(payload) {
    const selected_group = groupSettings.groups.filter(
      (item) => item.group_id === parseInt(payload.group_id),
    );
    makeGroupSettingsUpdateCall({
      ...payload,
      group_name: selected_group[0].group_name,
    });
  }

  async function deleteGroupSettings(group_id) {
    try {
      const accessToken = await getAccessTokenSilently();
      const resource = await axios.delete(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/settings/company_group/${group_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      setGroupSettings({
        data: resource.data.configuration,
        groups: resource.data.groups_list,
        loading: false,
      });
    } catch (error) {
      setErrorMessage("An error occurred while deleting your configuration!");
      sentryCaptureException(error);
    }
  }

  async function saveAdvancedSettings(key, value) {
    const data = {
      email_alerts_active: companySettings.data.email_alerts_active,
      send_monthly_digest: companySettings.data.send_monthly_digest,
      manual_password_setup: companySettings.data.manual_password_setup,
      llm_based_detection: companySettings.data.llm_based_detection,
      [key]: value,
    };

    try {
      const accessToken = await getAccessTokenSilently();
      const resource = await axios.post(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/settings/company/advanced`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      setCompanySettings({
        data: resource.data,
        loading: false,
      });
    } catch (error) {
      setErrorMessage(t("An error occurred while saving your configuration!"));
      sentryCaptureException(error);
      return;
    }
  }

  if (!activeFeature) {
    return (
      <div style={{ position: "relative", top: 0, left: 0 }}>
        <div
          style={{ position: "absolute", left: "0%", right: "0%", top: "0%" }}
        >
          <ActivateBrowserDefender />
        </div>
      </div>
    );
  }

  return (
    <>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage("")}>
          {errorMessage}
        </Alert>
      )}
      <Grid container alignItems="stretch">
        <Grid item xs={12}>
          <BrowserDefenderExtensionInformation config={companySettings.data} />
        </Grid>
        <Grid item xs={12}>
          <CompanySettings
            companySettings={companySettings}
            saveCompanySettings={saveCompanySettings}
            statusColors={statusColors}
          />
        </Grid>
        <Grid item xs={12}>
          <GroupsSettings
            groupSettings={groupSettings}
            addGroupSettings={addGroupSettings}
            saveGroupSettings={saveGroupSettings}
            deleteGroupSettings={deleteGroupSettings}
            statusColors={statusColors}
          />
        </Grid>
        <Grid item xs={12}>
          <DownloadsPolicies />
        </Grid>
        <Grid item xs={12}>
          <AdvancedSettings
            settings={companySettings}
            saveAdvancedSettings={saveAdvancedSettings}
          />
        </Grid>
      </Grid>
    </>
  );
}
