import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface MultipleSelectCheckmarksProps {
  items: string[];
  tag?: string | null;
  selectedItems: string[];
  handleChange: (event: SelectChangeEvent<string[]>) => void;
}

const MultipleSelectCheckmarks = ({
  items,
  tag,
  selectedItems,
  handleChange,
}: MultipleSelectCheckmarksProps): JSX.Element => {
  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
        {tag && <InputLabel>{tag}</InputLabel>}
        <Select
          multiple
          value={selectedItems}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {items.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedItems.includes(item)} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelectCheckmarks;
