import React, { useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import CollapsibleTable from "@/pages/browser-defender/components/CollapsibleTable";
import GenericCard from "@/pages/browser-defender/components/GenericCard";

const headers = [
  { label: "Version", alignment: "left" as const },
  { label: "Browser", alignment: "left" as const },
  { label: "Platform", alignment: "left" as const },
  { label: "Latest heartbeat", alignment: "left" as const },
  { label: "Status", alignment: "center" as const },
  { label: "ID", alignment: "center" as const },
];

const sortingColumns = [
  { label: "Version", name: "version" },
  { label: "Browser", name: "browser" },
  { label: "Platform", name: "platform" },
  { label: "Latest heartbeat", name: "latest_heartbeat" },
  { label: "Status", name: "status" },
];

export default function ExtensionsTableDemo() {
  const [data, setData] = useState([
    {
      extension_uuid: "2d281612-9398-4e37-82fa-0ed07d20877e",
      latest_heartbeat: "2023-08-06 09:34",
      days_since_last_seen: 1,
      version: "1.0.0",
      extension_status: "active",
      extension_status_label: "Protected",
      browser: "Google Chrome",
      browser_version: "120.0.6099.216",
      platform: "Windows",
      platform_version: "13.0.1",
    },
    {
      extension_uuid: "d0ad65b3-770c-45d1-9149-81cfd31b606a",
      latest_heartbeat: "2023-08-06 09:36",
      days_since_last_seen: 1,
      version: "1.0.0",
      extension_status: "active",
      extension_status_label: "Protected",
      browser: "Microsoft Edge",
      browser_version: "110.1.60.12",
      platform: "Windows",
      platform_version: "13.0.1",
    },
    {
      extension_uuid: "6e829163-5f70-4521-9ea9-b4c0ab97004d",
      latest_heartbeat: "17-10-2023 07:38",
      days_since_last_seen: 156.0,
      version: "3.1.2",
      extension_status: "inactive",
      extension_status_label: "Last seen +30 days ago",
      browser: "Microsoft Edge",
      browser_version: "100.3.40.11",
      platform: "Windows",
      platform_version: "13.0.1",
      is_hidden: true,
    },
  ]);

  async function handleVisibility(visibility, extension_uuid) {
    const newData = data.map((item, index) => {
      if (item.extension_uuid === extension_uuid) {
        return { ...item, is_hidden: !visibility };
      }
      return item;
    });

    setData(newData);
  }

  function setExtensionAsHidden(extension_uuid) {
    handleVisibility(false, extension_uuid);
  }

  function setExtensionAsVisible(extension_uuid) {
    handleVisibility(true, extension_uuid);
  }

  return (
    <GenericCard title={"Deployments"}>
      <CollapsibleTable
        headers={headers}
        sortingColumns={sortingColumns}
        setSorting={() => {}}
        setSearchTerm={() => {}}
        defaultOrder={[{ column: "version", direction: -1 }]}
        rows={data.map((row, index) => {
          return (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                align={headers[0].alignment}
              >
                {row.version}
              </TableCell>
              <TableCell align={headers[1].alignment}>
                {row.browser} [{row.browser_version}]
              </TableCell>
              <TableCell align={headers[2].alignment}>
                {row.platform} [{row.platform_version}]
              </TableCell>
              <TableCell align={headers[3].alignment}>
                {row.latest_heartbeat}
              </TableCell>
              <TableCell align={headers[4].alignment}>
                {row.extension_status_label}
              </TableCell>
              <TableCell align={headers[5].alignment}>
                {row.extension_uuid}
              </TableCell>
              <TableCell align="center">
                <Tooltip
                  title={
                    row.is_hidden
                      ? "Show extension in user details"
                      : "Hide extension from user details"
                  }
                >
                  <IconButton
                    onClick={() =>
                      row.is_hidden
                        ? setExtensionAsVisible(row.extension_uuid)
                        : setExtensionAsHidden(row.extension_uuid)
                    }
                  >
                    {row.is_hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      />
    </GenericCard>
  );
}
