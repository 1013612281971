import React from "react";

import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBox = styled(Box)`
  background-color: #f9f9f9;
  margin-right: 5px;
  width: max-content;
  height: Hug (24px);
  padding: 2px 8px 2px 10px;
  border-radius: 4px;
  border: 1px;
  gap: 3px;
`;

export default function EventsCounter({
  data,
  icon,
  iconFirst = false,
  tooltipValue = "",
}) {
  let style;
  if (iconFirst) {
    style = { paddingLeft: "5px" };
  } else {
    style = { paddingRight: "5px" };
  }
  return (
    <Tooltip
      title={tooltipValue}
      disableHoverListener={tooltipValue.length === 0}
      arrow
    >
      <StyledBox>
        <Stack direction="row" alignItems="center" gap="1px">
          {iconFirst && icon}

          <Typography sx={style} variant="body_small" color="text.secondary">
            {data}
          </Typography>
          {!iconFirst && icon}
        </Stack>
      </StyledBox>
    </Tooltip>
  );
}
