import React from "react";

import IconButton from "../IconButton";

const EditIconButton = ({ title, onClick, size = 16 }) => {
  return (
    <IconButton
      title={title && title.charAt(0).toUpperCase() + title.slice(1)}
      onClick={onClick}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.7071 2.29271C20.212 0.797615 17.788 0.797613 16.2929 2.2927L15.1314 3.45423C14.7354 3.85025 14.5374 4.04826 14.4632 4.27658C14.3979 4.47743 14.3979 4.69377 14.4632 4.89462C14.5374 5.12295 14.7354 5.32096 15.1314 5.71697L18.2829 8.86844C18.6789 9.26446 18.8769 9.46247 19.1052 9.53666C19.3061 9.60191 19.5224 9.60192 19.7233 9.53666C19.9516 9.46247 20.1496 9.26446 20.5456 8.86844L21.7071 7.70692C23.2022 6.21183 23.2022 3.7878 21.7071 2.29271Z"
          fill="rgba(0,0,0,.54)"
        />
        <path
          d="M16.8687 12.5454C17.2647 12.1494 17.4627 11.9514 17.5369 11.723C17.6021 11.5222 17.6021 11.3059 17.5369 11.105C17.4627 10.8767 17.2647 10.6787 16.8687 10.2827L13.7172 7.13118C13.3212 6.73517 13.1232 6.53716 12.8948 6.46297C12.694 6.39771 12.4776 6.39771 12.2768 6.46297C12.0485 6.53715 11.8505 6.73516 11.4544 7.13118L3.03673 15.5488C2.79144 15.7937 2.57507 16.0097 2.40582 16.2664C2.25701 16.4921 2.13938 16.7368 2.05613 16.994C1.96144 17.2865 1.92792 17.5904 1.88992 17.9349L1.50612 21.3894C1.47258 21.6913 1.57809 21.9921 1.7929 22.2069C2.00771 22.4217 2.30851 22.5272 2.61044 22.4937L6.06494 22.1099C6.40945 22.0719 6.71335 22.0384 7.00584 21.9437C7.26301 21.8604 7.50774 21.7428 7.7334 21.594C7.99008 21.4247 8.2061 21.2084 8.451 20.9631L16.8687 12.5454Z"
          fill="rgba(0,0,0,.54)"
        />
      </svg>
    </IconButton>
  );
};

export default EditIconButton;
