import { createApi } from "@reduxjs/toolkit/query/react";

import {
  Permission,
  UserWithPermissions,
} from "@/pages/general-settings/permissions-attribution/permissions";
import { simulationBaseQuery } from "@/utils/rtkQuery";

export const permissionsApi = createApi({
  reducerPath: "permissionsApi",
  baseQuery: simulationBaseQuery(),
  endpoints: (builder) => ({
    fetchAdminsWithPermissions: builder.query<Array<UserWithPermissions>, void>(
      {
        query: () => "users/admins",
        transformResponse: (response: Array<object>) =>
          response.map((e) => ({
            id: e["id"],
            firstname: e["firstname"],
            lastname: e["lastname"],
            email: e["email"],
            permissions: e["permissions"]
              .map(Permission.fromCode)
              .filter((p: Permission) => p != null),
          })),
      },
    ),
    updateAdminPermissions: builder.mutation<void, UserWithPermissions>({
      query: (user) => ({
        url: `user/${user.id}/permissions`,
        method: "PUT",
        body: { permissions: user.permissions.map((p) => p.code) },
      }),
    }),
    addAdmin: builder.mutation<void, number>({
      query: (id) => ({
        url: `users/admins/${id}`,
        method: "POST",
      }),
    }),
    removeAdmin: builder.mutation<void, number>({
      query: (id) => ({
        url: `users/admins/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});
