import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box, Paper, Stack, Typography } from "@mui/material";

import { ServiceAccount } from "@/pages/general-settings/GSuite/ServiceAccount";
import { GroupProvision } from "@/pages/general-settings/GroupProvision";
import { EnableCheckbox } from "@/pages/general-settings/commonUserProvisioning/EnableCheckbox";
import { ExcludedUsers } from "@/pages/general-settings/commonUserProvisioning/ExcludedUsers";
import { ManualSync } from "@/pages/general-settings/commonUserProvisioning/ManualSync";

export function GSuiteSync() {
  const { t } = useTranslation();

  return (
    <Paper sx={{ padding: "2rem" }}>
      <Stack useFlexGap sx={{ gap: "4rem" }}>
        <Box>
          <Typography sx={{ color: "black", mb: "1rem" }}>
            <Trans i18nKey="configureGoogleWorkspaceLink">
              Please follow
              <Link
                to="https://www.notion.so/mantrams/User-Provisioning-4e417c72f3074344b1a90c1fc991551e"
                target="_blank"
                rel="noopener noreferrer"
              >
                this procedure
              </Link>
              to configure your Google Workspace account.
            </Trans>
          </Typography>
          <Typography variant="h2" sx={{ mb: "1rem" }}>
            {t("Service account status")}
          </Typography>
          <ServiceAccount />
        </Box>
        <Box>
          <Typography variant="h2" sx={{ mb: "1rem" }}>
            {t("Excluded users (optional)")}
          </Typography>
          <ExcludedUsers />
        </Box>
        <Box>
          <Typography variant="h2" sx={{ mb: "1rem" }}>
            {t("Provisioned groups (optional)")}
          </Typography>
          <GroupProvision />
        </Box>
        <Stack>
          <Typography variant="h2" sx={{ mb: "1rem" }}>
            {t("Provisioning")}
          </Typography>
          <EnableCheckbox />
          <ManualSync />
        </Stack>
      </Stack>
    </Paper>
  );
}
