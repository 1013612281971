import React, { PropsWithChildren, ReactNode } from "react";
import { Provider } from "react-redux";

import store from "@/store";

const ReduxProvider = (props: PropsWithChildren): ReactNode => {
  return <Provider store={store}>{props.children}</Provider>;
};

export default ReduxProvider;
