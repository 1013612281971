import React from "react";
import { useTranslation } from "react-i18next";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { useBackend } from "@/utils/misc";

export default function DepartmentSelector({ value, onChange }) {
  const { t } = useTranslation();

  let endpoint_url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/departments_from_user";

  const { data, error, isPending } = useBackend(endpoint_url);

  const handleChange = (event) => onChange(event.target.value);

  if (isPending || !data) {
    return <div>{t("Loading")}...</div>;
  }
  if (error) {
    return <div>{t("An error occurred")}</div>;
  }
  if (data?.length === 0) {
    return <div>{t("No data available")}</div>;
  }

  const menuItems = data.map((name) => (
    <MenuItem value={name} key={name}>
      {name}
    </MenuItem>
  ));

  return (
    <div>
      <FormControl
        sx={{
          width: "150px",
          background: value === "" ? "" : "#ebefff",
        }}
      >
        <InputLabel size="small" htmlFor="department-select">
          {t("Department")}
        </InputLabel>
        <Select
          label={t("Department")}
          id="department-select"
          size="small"
          value={value}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>{t("None")}</em>
          </MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
}
