import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import Duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import RelativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(Duration);
dayjs.extend(RelativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
