import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UserWithPermissions } from "@/pages/general-settings/permissions-attribution/permissions";

const initialState: Array<UserWithPermissions> = [];

export const permissionsSlice = createSlice({
  name: "usersWithPermissions",
  initialState,
  reducers: {
    setAllPermissions: (
      state,
      action: PayloadAction<Array<UserWithPermissions>>,
    ) => {
      return action.payload;
    },
    addUserWithPermissions: (
      state,
      action: PayloadAction<UserWithPermissions>,
    ) => {
      state.push(action.payload);
    },
    changePermissions: (state, action: PayloadAction<UserWithPermissions>) => {
      const index = state.map((u) => u.email).indexOf(action.payload.email);
      state[index] = action.payload;
    },
    removePermissions: (state, action: PayloadAction<UserWithPermissions>) => {
      const index = state.map((u) => u.email).indexOf(action.payload.email);
      state.splice(index, 1);
    },
  },
});

export const {
  setAllPermissions,
  addUserWithPermissions,
  changePermissions,
  removePermissions,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;
