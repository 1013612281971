import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/store";

import { ActiveStatusEnum, SaasType, TemplateTypeEnum } from "./templateUtils";

type TemplateStoreState = {
  page: number;
  templatesPerPage: number;
  count: number;
  search: string | null;
  saasSearch: SaasType | null;
  templateTypeSearch: TemplateTypeEnum;
  activeStatus: ActiveStatusEnum | null;
  bestPerformersOnly: boolean;
};

const templateSlice = createSlice({
  name: "template",
  initialState: {
    page: 1,
    templatesPerPage: 25,
    count: 0,
    search: "",
    saasSearch: null,
    templateTypeSearch: TemplateTypeEnum.ALL,
    activeStatus: ActiveStatusEnum.ACTIVE,
    bestPerformersOnly: false,
  } as TemplateStoreState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload.page;
    },
    setTemplatesPerPage: (state, action) => {
      state.templatesPerPage = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload.count;
    },
    setSearch: (state, action) => {
      state.search = action.payload.search;
      state.page = 1;
    },
    setSaasSearch: (state, action) => {
      state.saasSearch = action.payload.saas;
      state.page = 1;
    },
    setTemplateTypeSearch: (state, action) => {
      state.templateTypeSearch = action.payload;
      state.page = 1;
    },
    setActiveStatus: (state, action) => {
      state.activeStatus = action.payload;
      state.page = 1;
    },
    setBestPerformersOnly: (state, action) => {
      state.bestPerformersOnly = action.payload;
      state.page = 1;
    },
  },
});

export const {
  setPage,
  setTemplatesPerPage,
  setCount,
  setSearch,
  setSaasSearch,
  setTemplateTypeSearch,
  setActiveStatus,
  setBestPerformersOnly,
} = templateSlice.actions;

export const selectPage = (state: RootState) => {
  return state.template.page;
};

export const selectTemplatesPerPage = (state: RootState) => {
  return state.template.templatesPerPage;
};

export const selectCount = (state: RootState) => {
  return state.template.count;
};

export const selectSearch = (state: RootState) => {
  return state.template.search;
};

export const selectSaasSearch = (state: RootState) => {
  return state.template.saasSearch;
};

export const selectTemplateTypeSearch = (state: RootState) => {
  return state.template.templateTypeSearch;
};

export const selectActiveStatus = (state: RootState) => {
  return state.template.activeStatus;
};

export const selectBestPerformersOnly = (state: RootState) => {
  return state.template.bestPerformersOnly;
};

export default templateSlice.reducer;
