import React, { ReactNode } from "react";

import { Box, Typography } from "@mui/material";

export function GeneralInfoLine({
  label,
  text = "",
  value = null,
}: {
  label: ReactNode;
  text?: ReactNode;
  value?: ReactNode;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        p: "0.5rem",
      }}
    >
      <Typography sx={{ fontWeight: "bold", width: "50%" }}>
        {label}:
      </Typography>
      {text && (
        <Typography
          sx={{ display: "inline-block", textAlign: "right", width: "50%" }}
        >
          {text}
        </Typography>
      )}
      {value}
    </Box>
  );
}
