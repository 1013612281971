import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";

export interface OrderBy {
  id: number;
  column: Column | null;
  label: string;
  order: "asc" | "desc";
}

export type Column = {
  name: string;
  label: ReactNode;
};

type SelectOrderByComponentProps = {
  index: number;
  orderChoice: Column[];
  handleOrderChange: (
    index: number,
    event: SelectChangeEvent<"asc" | "desc">,
  ) => void;
  handleOrderLabelChange: (index: number, value) => void;
  orderItem: OrderBy;
  handleRemoveItem: (index: number) => void;
};

export default function SelectOrderByComponent({
  index,
  orderChoice,
  handleOrderChange,
  handleOrderLabelChange,
  orderItem,
  handleRemoveItem,
}: SelectOrderByComponentProps) {
  const { t } = useTranslation();

  return (
    <ListItem key={index}>
      <Stack direction="row" spacing={2}>
        <Autocomplete
          sx={{ width: "150px" }}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label={t("Select a column")} />
          )}
          value={orderItem.column}
          onChange={(_, value) => handleOrderLabelChange(index, value)}
          options={orderChoice}
        />
        <FormControl>
          <InputLabel id={"order-select"}>{t("Order")}</InputLabel>
          <Select
            size="small"
            labelId="order-select"
            label={t("Order")}
            value={orderItem.order}
            onChange={(e) => handleOrderChange(index, e)}
          >
            <MenuItem value="asc">{t("Ascending")}</MenuItem>
            <MenuItem value="desc">{t("Descending")}</MenuItem>
          </Select>
        </FormControl>

        <IconButton aria-label="delete" onClick={() => handleRemoveItem(index)}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </ListItem>
  );
}
