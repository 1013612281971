import React, { PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { enUS, frFR } from "@mui/material/locale";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";

// eslint-disable-next-line no-restricted-imports
import { theme } from "@/theme";

const localeThemeLanguage = (language: string) => {
  switch (language) {
    case "fr":
      return frFR;
    case "en":
    default:
      return enUS;
  }
};

const ThemeProvider = (props: PropsWithChildren): ReactNode => {
  const { i18n } = useTranslation();
  const localeTheme = createTheme(theme, localeThemeLanguage(i18n.language));
  return (
    <MuiThemeProvider theme={localeTheme}>{props.children}</MuiThemeProvider>
  );
};

export default ThemeProvider;
