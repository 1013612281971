import dayjs from "dayjs";
import React, { useState } from "react";

import { Divider, Typography } from "@mui/material";
import { DateCalendar, DateView } from "@mui/x-date-pickers";

import { MANTRA_CREATION_DATE } from "@/utils/constants";

type DateCalendarPanelProps = {
  title: string;
  value: dayjs.Dayjs;
  onChange: (v: dayjs.Dayjs) => void;
};

export const DateCalendarPanel = (props: DateCalendarPanelProps) => {
  const [view, setView] = useState<DateView>("day");

  const onYearChange = (year: dayjs.Dayjs) => {
    if (year.year() === props.value.year()) {
      return;
    }
    setView("day");
  };

  return (
    <>
      <Typography my={2} variant="h6">
        {props.title}
      </Typography>
      <Divider flexItem />
      <DateCalendar
        fixedWeekNumber={6}
        views={["day", "month", "year"]}
        view={view}
        onViewChange={(view) => setView(view)}
        onYearChange={onYearChange}
        value={props.value}
        onChange={props.onChange}
        minDate={MANTRA_CREATION_DATE}
        maxDate={dayjs()}
      />
    </>
  );
};
