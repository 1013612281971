import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Box, Button, Typography } from "@mui/material";

import { useUserContext } from "@/utils/contexts/UserContext";

import { selectAwarenessSetupChecklistState } from "../../SetupChecklistSlice";

const AssignCourse: React.FC = () => {
  const { current_company } = useUserContext();
  const { oneCourseAssigned } = useSelector(selectAwarenessSetupChecklistState);
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">{t("Assign you first course")}</Typography>
      <Box mb="1rem">
        {t("Assign your first course to the employees of")}{" "}
        {current_company.name} {t("to start their awareness training.")}
      </Box>
      <Box display="flex" alignItems="center" gap="1.5rem">
        <Link to="/awareness/courses">
          <Button variant="contained">{t("Assign a course")}</Button>
        </Link>
        <Box display="flex" alignItems="center" gap="0.5rem">
          {oneCourseAssigned ? (
            <>
              <CheckCircleOutlinedIcon color="success" />
              <Typography variant="body_extrasmall" color="success.main">
                {t("Completed")}
              </Typography>
            </>
          ) : (
            <>
              <CancelOutlinedIcon color="error" />
              <Typography variant="body_extrasmall" color="error">
                {t("Not Completed")}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AssignCourse;
