import React from "react";

import { SvgIcon } from "@mui/material";

export default function (props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="5.23824" cy="5.23812" r="3.33333" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.23809 0C2.34517 0 0 2.34517 0 5.23809C0 8.13101 2.34517 10.4762 5.23809 10.4762C8.13101 10.4762 10.4762 8.13101 10.4762 5.23809C10.4762 2.34517 8.13101 0 5.23809 0ZM7.00338 3.47281C7.18935 3.65877 7.18935 3.96028 7.00338 4.14624L5.91153 5.23809L7.00338 6.32995C7.18935 6.51591 7.18935 6.81742 7.00338 7.00338C6.81742 7.18935 6.51591 7.18935 6.32995 7.00338L5.23809 5.91153L4.14624 7.00338C3.96028 7.18935 3.65877 7.18935 3.47281 7.00338C3.28684 6.81742 3.28684 6.51591 3.47281 6.32995L4.56466 5.23809L3.47281 4.14624C3.28684 3.96028 3.28684 3.65877 3.47281 3.47281C3.65877 3.28684 3.96028 3.28684 4.14624 3.47281L5.23809 4.56466L6.32995 3.47281C6.51591 3.28684 6.81742 3.28684 7.00338 3.47281Z"
          fill="#FF0000"
        />
      </svg>
    </SvgIcon>
  );
}
