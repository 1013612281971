import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useSelector } from "react-redux";

import { BACKEND_URL } from "@/pages/banners/remote-apis/config";
import { selectAuth0Token } from "@/utils/contexts/AuthSlice";

export function useSendEmail() {
  const [isSendError, setIsSendError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const token = useSelector(selectAuth0Token);

  const sendEmails = async ({ templateNames, userEmails }) => {
    try {
      setIsSending(true);
      const url = new URL(`${BACKEND_URL}/banners/users/send_template_emails`);
      await axios.post(
        url.href,
        { templates: templateNames, email_addresses: userEmails },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setIsSendError(false);
      setIsSending(false);
      enqueueSnackbar("The emails have been sent.", { variant: "success" });
    } catch (error) {
      setIsSendError(true);
      setIsSending(false);
      enqueueSnackbar("The emails couldn't be sent.", { variant: "error" });
    }
  };

  return { isSendError, isSending, sendEmails };
}
