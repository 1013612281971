import React from "react";
import { useTranslation } from "react-i18next";

import EditIcon from "@mui/icons-material/Edit";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { Spinner } from "@/components/Spinner";
import CustomDialog from "@/pages/browser-defender/components/CustomDialog";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import CompanyConfigurationForm from "@/pages/browser-defender/setup/CompanyConfigurationForm";
import CondensedTableCell from "@/pages/browser-defender/styles/CondensedTableCell";

export function CompanySettings({
  companySettings,
  saveCompanySettings,
  statusColors,
}) {
  const { t } = useTranslation();

  return (
    <GenericCard title={t("Company level settings")}>
      {companySettings.loading && <Spinner />}
      {!companySettings.loading && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">{t("Password Protection")}</TableCell>
                <TableCell align="center">
                  {t("Downloads Protection")}
                </TableCell>
                <TableCell align="center">{t("Browsing Protection")}</TableCell>
                <TableCell align="center">
                  {t("User can disable extension")}
                </TableCell>
                <TableCell align="center">
                  {t("Authorized login domain(s)")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companySettings.data && (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <CondensedTableCell>
                    <CustomDialog
                      callToAction={<EditIcon />}
                      callToActionText={t("Edit company settings")}
                      actionButtonText={t("Save configuration")}
                      actionButtonCallback={saveCompanySettings}
                      dialogTitle={t("Company level settings")}
                    >
                      <CompanyConfigurationForm record={companySettings.data} />
                    </CustomDialog>
                  </CondensedTableCell>
                  <CondensedTableCell align="center">
                    {statusColors[companySettings.data.password_protection]}
                  </CondensedTableCell>
                  <CondensedTableCell align="center">
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {statusColors[companySettings.data.downloads_protection]}

                      {companySettings.data.downloads_protection_policy ? (
                        companySettings.data.downloads_protection_policy.name
                      ) : (
                        <Typography
                          style={{
                            fontStyle: "italic",
                            color: "gray",
                            fontSize: "0.8rem",
                          }}
                        >
                          {t("(Mantra Default)")}
                        </Typography>
                      )}
                    </Stack>
                  </CondensedTableCell>
                  <CondensedTableCell align="center">
                    {statusColors[companySettings.data.browsing_protection]}
                  </CondensedTableCell>
                  <CondensedTableCell align="center">
                    {companySettings.data.user_can_disable_extension
                      ? statusColors["on"]
                      : statusColors["off"]}
                  </CondensedTableCell>
                  <CondensedTableCell align="center">
                    {companySettings.data.safe_domains &&
                      companySettings.data.safe_domains.join(", ")}
                  </CondensedTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </GenericCard>
  );
}
