import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { ChecklistPane } from "@/components/stepper/ChecklistPane";
import { useStatusQuery } from "@/pages/banners/setup/BannerSetupChecklist";

type SafeListsSetupProps = { title: ReactNode };

export const SafeListsSetup: React.FC<SafeListsSetupProps> = ({
  title,
}: SafeListsSetupProps) => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useStatusQuery();

  if (isError) return <>{t("Error")}</>;

  if (isLoading) return <>{t("Loading")}</>;
  return (
    <ChecklistPane title={title}>
      <Typography>
        {t(
          "Declare to Mantra the senders and domains on which you don't want banners to be displayed.",
        )}
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link to="/banners/setup#preferences">
          <Button variant="contained">{t("Safe Lists")}</Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          {data.have_safe_lists ? (
            <CheckCircleOutlinedIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          )}
        </Box>
      </Box>
    </ChecklistPane>
  );
};
