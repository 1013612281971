import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

type ConsentGrantDocumentationLinkProps = {
  documentation_url: string;
};
const ConsentGrantDocumentationLink = ({
  documentation_url,
}: ConsentGrantDocumentationLinkProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography>
        {t("Follow the documentation")}:{" "}
        <Link to={documentation_url} target="_blank" rel="noopener noreferrer">
          <Button variant="contained">{t("How to grant Consent")}</Button>
        </Link>
      </Typography>
    </Box>
  );
};

export default ConsentGrantDocumentationLink;
