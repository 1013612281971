import React from "react";

import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon color="primary">
      <svg
        width="27"
        height="24"
        viewBox="0 0 27 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.12 1.57823C11.6306 1.36064 11.072 1.36064 10.5826 1.57823C10.2047 1.74628 9.9592 2.04229 9.78837 2.28348C9.6204 2.52062 9.44057 2.83129 9.24279 3.17296L1.42251 16.6807C1.22395 17.0236 1.04351 17.3353 0.921157 17.5997C0.796813 17.8685 0.661917 18.2298 0.704909 18.6422C0.760541 19.1758 1.04011 19.6607 1.47403 19.9762C1.80936 20.22 2.18963 20.2843 2.48453 20.3113C2.7747 20.3379 3.13478 20.3379 3.53103 20.3379H19.1716C19.5678 20.3379 19.9279 20.3379 20.2181 20.3113C20.513 20.2843 20.8933 20.22 21.2286 19.9762C21.6625 19.6607 21.9421 19.1758 21.9977 18.6422C22.0407 18.2298 21.9058 17.8685 21.7815 17.5997C21.6591 17.3353 21.4787 17.0237 21.2802 16.6808L13.4598 3.17293C13.2621 2.83127 13.0822 2.52061 12.9143 2.28348C12.7434 2.04229 12.4979 1.74628 12.12 1.57823ZM12.2973 8.51356C12.2973 7.99113 11.8738 7.56761 11.3513 7.56761C10.8289 7.56761 10.4054 7.99113 10.4054 8.51356V12.2973C10.4054 12.8198 10.8289 13.2433 11.3513 13.2433C11.8738 13.2433 12.2973 12.8198 12.2973 12.2973V8.51356ZM11.3513 15.1352C10.8289 15.1352 10.4054 15.5587 10.4054 16.0811C10.4054 16.6036 10.8289 17.0271 11.3513 17.0271H11.3608C11.8832 17.0271 12.3067 16.6036 12.3067 16.0811C12.3067 15.5587 11.8832 15.1352 11.3608 15.1352H11.3513Z"
          fill="currentColor"
        />
        <circle cx="20.4323" cy="18.1619" r="5.67561" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4324 12.4862C17.2978 12.4862 14.7567 15.0273 14.7567 18.1619C14.7567 21.2965 17.2978 23.8376 20.4324 23.8376C23.567 23.8376 26.108 21.2965 26.108 18.1619C26.108 15.0273 23.567 12.4862 20.4324 12.4862ZM23.1191 16.9788C23.3206 16.7773 23.3206 16.4506 23.1191 16.2491C22.9176 16.0476 22.5909 16.0476 22.3894 16.2491L19.6584 18.9801L18.4753 17.797C18.2738 17.5955 17.9472 17.5955 17.7457 17.797C17.5442 17.9985 17.5442 18.3252 17.7457 18.5267L19.2936 20.0746C19.4951 20.2761 19.8218 20.2761 20.0233 20.0746L23.1191 16.9788Z"
          fill="#65C58B"
        />
      </svg>
    </SvgIcon>
  );
}
