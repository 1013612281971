import React from "react";

import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon color="primary">
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3513 19.8649L11.2567 19.7229C10.5996 18.7373 10.271 18.2445 9.83697 17.8877C9.45269 17.5719 9.0099 17.3349 8.53393 17.1904C7.9963 17.0271 7.40401 17.0271 6.21941 17.0271H4.9189C3.85934 17.0271 3.32956 17.0271 2.92487 16.8209C2.56888 16.6395 2.27946 16.3501 2.09808 15.9941C1.89188 15.5894 1.89188 15.0596 1.89188 14.0001V5.86492C1.89188 4.80536 1.89188 4.27558 2.09808 3.87088C2.27946 3.5149 2.56888 3.22548 2.92487 3.04409C3.32956 2.83789 3.85934 2.83789 4.9189 2.83789H5.29728C7.4164 2.83789 8.47596 2.83789 9.28535 3.2503C9.99732 3.61306 10.5762 4.19191 10.9389 4.90387C11.3513 5.71327 11.3513 6.77283 11.3513 8.89194M11.3513 19.8649V8.89194M11.3513 19.8649L11.446 19.7229C12.1031 18.7373 12.4316 18.2445 12.8657 17.8877C13.25 17.5719 13.6928 17.3349 14.1687 17.1904C14.7064 17.0271 15.2987 17.0271 16.4833 17.0271H17.7838C18.8433 17.0271 19.3731 17.0271 19.7778 16.8209C20.1338 16.6395 20.4232 16.3501 20.6046 15.9941C20.8108 15.5894 20.8108 15.0596 20.8108 14.0001V5.86492C20.8108 4.80536 20.8108 4.27558 20.6046 3.87088C20.4232 3.5149 20.1338 3.22548 19.7778 3.04409C19.3731 2.83789 18.8433 2.83789 17.7838 2.83789H17.4054C15.2863 2.83789 14.2267 2.83789 13.4173 3.2503C12.7054 3.61306 12.1265 4.19191 11.7637 4.90387C11.3513 5.71327 11.3513 6.77283 11.3513 8.89194"
          stroke="currentColor"
          strokeWidth="1.89189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
