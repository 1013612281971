import React from "react";

import { Box, Chip, Tooltip } from "@mui/material";

import {
  ExtensionStatusColors,
  ExtensionStatusType,
} from "@/pages/browser-defender/components/extentionStatus";

export default function ExtensionStatusChip({
  status,
}: {
  status: ExtensionStatusType;
}) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Tooltip title={ExtensionStatusColors[status].label}>
        <Chip
          label={ExtensionStatusColors[status].label}
          variant={"outlined"}
          style={{
            color: ExtensionStatusColors[status].color,
            backgroundColor: ExtensionStatusColors[status].backgroundColor,
            borderColor: ExtensionStatusColors[status].color,
          }}
        />
      </Tooltip>
    </Box>
  );
}
