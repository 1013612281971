import React from "react";
import { useTranslation } from "react-i18next";

import DownloadButton from "@/components/buttons/DownloadButton";
import { downloadThreatCSV } from "@/pages/threats/service/csvReport";
import { Threat } from "@/pages/threats/service/types";

type CSVExportButtonProps = {
  getAllThreats: () => Promise<Threat[]>;
};

const CSVExportButton = ({ getAllThreats }: CSVExportButtonProps) => {
  const { t } = useTranslation();

  const download = async () => {
    const allThreats = await getAllThreats();
    downloadThreatCSV(allThreats);
  };

  return <DownloadButton title={t("Export to CSV")} onClick={download} />;
};

export default CSVExportButton;
