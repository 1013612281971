import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Grid } from "@mui/material";

import { FullPageSpinner } from "@/components/Spinner";
import { LearningLanguage } from "@/pages/my-progress/LearningLanguage";
import { UserActivity } from "@/pages/my-progress/activity/UserActivity";
import { Badges } from "@/pages/my-progress/badges/Badges";
import { Leaderboard } from "@/pages/my-progress/leaderboard/Leaderboard";
import { YourStats } from "@/pages/my-progress/stats/YourStats";
import { useBackend } from "@/utils/misc";

export function MyProgress() {
  const { t } = useTranslation();

  let endpoint_url = import.meta.env.VITE_APP_ENDPOINT_SIMULATION;
  const { data, error, isPending } = useBackend(
    endpoint_url + "/current_user/activity",
  );

  if (isPending || !data) {
    return <FullPageSpinner />;
  }
  if (error) {
    return <Box>{t("An error occurred")}</Box>;
  }

  if (data) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={4}>
              <YourStats userId={undefined} />
            </Grid>
            <Grid item xs={4}>
              <Leaderboard />
            </Grid>
            <Grid item xs={4}>
              <LearningLanguage />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={6}>
              <Badges />
            </Grid>
            <Grid item xs={6}>
              <UserActivity data={data} admin={undefined} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
