import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { List, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";

import ActionChip from "@/pages/browser-defender/components/ActionChip";
import CollapsibleTable from "@/pages/browser-defender/components/CollapsibleTable";
import StyledTableRow from "@/pages/browser-defender/styles/StyledTableRow";

const filterColumns = [
  "file_name",
  "email",
  "domain",
  "user_action",
  "browser",
];

const actionColors = {
  cancel: { bgColor: "rgba(255, 175, 44, 0.25)", textColor: "#BE7A0C" },
  download: { bgColor: "rgba(255, 175, 44, 0.5)", textColor: "#BE7A0C" },
  default: { bgColor: "rgba(255, 175, 44, 0.25)", textColor: "#B50A3E" },
};

const IssuesDetails = ({ data }) => {
  return (
    data && (
      <List sx={{ listStyleType: "disc", ml: "2rem" }}>
        {data.map((issue) => (
          <IssueDetails key={issue} data={issue} />
        ))}
      </List>
    )
  );
};

const IssueDetails = ({ data }) => {
  const { t } = useTranslation();

  const mapping = {
    file_type: t("File type not allowed by policy"),
    file_size: t("File size exceeds policy limit"),
    source_domain: t("Source domain not allowed by policy"),
  };
  return (
    <ListItem sx={{ display: "list-item", p: "0 0.5rem 0 0" }}>
      {mapping[data]}
    </ListItem>
  );
};

export default function DataTable({ data, setData }) {
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const headers = [
    { label: t("Time (UTC)"), alignment: "left" as const },
    { label: t("Email"), alignment: "left" as const },
    { label: t("Domain"), alignment: "left" as const },
    { label: t("File Name"), alignment: "left" as const },
    { label: t("File Size"), alignment: "left" as const },
    { label: t("Policy"), alignment: "center" as const },
    { label: t("Browser"), alignment: "center" as const },
    { label: t("User action"), alignment: "center" as const },
  ];

  const sortingColumns = [
    { label: t("Email"), name: "email" },
    { label: t("Domain"), name: "domain" },
    { label: t("File Name"), name: "file_name" },
    { label: t("Last Name"), name: "lastname" },
    { label: t("User action"), name: "user_action" },
    { label: t("Browser"), name: "browser" },
  ];

  const actionMapping = {
    cancel: t("Cancelled"),
    download: t("Downloaded"),
  };

  useEffect(() => {
    let filteredValues = data.values;

    if (searchTerm) {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          let value;

          try {
            value = row[attribute].toLowerCase();
          } catch {
            value = row[attribute];
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    function parseDates(stringDate) {
      let [dateComp, timeComp] = stringDate.split(" ");
      let [day, month, year] = dateComp.split("-");
      let [hours, minutes] = timeComp.split(":");
      return new Date(+year, +month - 1, +day, +hours, +minutes);
    }

    if (sorting) {
      filteredValues.sort((p1, p2) => {
        let condition = 1;
        for (let sortRule of sorting) {
          if (sortRule.column === "event_date") {
            const parsedDate1 = parseDates(p1[sortRule.column]);
            const parsedDate2 = parseDates(p2[sortRule.column]);

            condition *=
              parsedDate1 < parsedDate2
                ? 1 * sortRule.direction
                : parsedDate1 > parsedDate2
                  ? -1 * sortRule.direction
                  : 0;
            continue;
          }
          condition *=
            p1[sortRule.column] < p2[sortRule.column]
              ? -1 * sortRule.direction
              : p1[sortRule.column] > p2[sortRule.column]
                ? 1 * sortRule.direction
                : 0;
        }
        return condition;
      });
    }

    setData({
      values: data.values,
      filteredValues: filteredValues,
      loading: false,
    });
  }, [searchTerm, sorting, data.values, setData]);

  return (
    <CollapsibleTable
      headers={headers}
      sortingColumns={sortingColumns}
      setSorting={setSorting}
      setSearchTerm={setSearchTerm}
      defaultOrder={[{ column: "event_date", direction: 1 }]}
      rows={data.filteredValues.map((row, index) => {
        return (
          <StyledTableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row" align={headers[0].alignment}>
              {row.event_date}
            </TableCell>
            <TableCell align={headers[1].alignment}>
              <Link to="#">{row.email}</Link>
            </TableCell>
            <TableCell align={headers[2].alignment}>{row.domain}</TableCell>
            <TableCell align={headers[3].alignment}>{row.file_name}</TableCell>
            <TableCell align={headers[4].alignment}>{row.file_size}</TableCell>
            <TableCell align={headers[5].alignment}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tooltip
                  title={
                    row.reason_for_block &&
                    Object.keys(row.reason_for_block).length > 0 ? (
                      <IssuesDetails data={row.reason_for_block.issues} />
                    ) : (
                      t("File type not allowed by policy")
                    )
                  }
                >
                  <Typography
                    style={{ textDecoration: "underline", cursor: "help" }}
                  >
                    {row.reason_for_block &&
                    Object.keys(row.reason_for_block).length > 0
                      ? row.reason_for_block.policy
                      : t("Mantra Default")}
                  </Typography>
                </Tooltip>
                <Link to="/browser-defender/downloads-policies">
                  <ReadMoreIcon />
                </Link>
              </Stack>
            </TableCell>
            <TableCell align={headers[6].alignment}>{row.browser}</TableCell>
            <TableCell align={headers[7].alignment}>
              <ActionChip
                data={row}
                actionMapping={actionMapping}
                colors={actionColors}
              />
            </TableCell>
          </StyledTableRow>
        );
      })}
    />
  );
}
