import React, { useState } from "react";

import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import GppBadIcon from "@mui/icons-material/GppBad";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import RadarIcon from "@mui/icons-material/Radar";
import SearchIcon from "@mui/icons-material/Search";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import StickyHeader from "@/components/StickyHeader";
import Tabs, {
  CurrentTabComponent,
  TabsContextProvider,
} from "@/components/controls/Tabs";
import { BasicStatCard } from "@/components/dashboard/BasicStatCard";
import { StyledTableHeaderRow } from "@/pages/browser-defender/components/CollapsibleTable";
import { useUserContext } from "@/utils/contexts/UserContext";

const statsData = [
  {
    title: "Total Saas detected",
    count: 27,
    icon: <RadarIcon sx={{ color: "#7567FE", fontSize: 32 }} />,
  },
  {
    title: "Saas to review",
    count: 0,
    icon: <NewReleasesIcon sx={{ color: "#7567FE", fontSize: 32 }} />,
  },
  {
    title: "Approved Saas",
    count: 17,
    icon: <VerifiedUserIcon sx={{ color: "#7567FE", fontSize: 32 }} />,
  },
  {
    title: "Blocked Saas",
    count: 10,
    icon: <GppBadIcon sx={{ color: "#7567FE", fontSize: 32 }} />,
  },
];

const tableData = [
  {
    id: 1,
    name: "Dashlane",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 31,
    alternative: null,
  },
  {
    id: 2,
    name: "Trello",
    status: "Blocked",
    criticality: "Low",
    weeklyUsers: 0,
    alternative: "notion.so",
  },
  {
    id: 3,
    name: "Google Sheets",
    status: "Allowed",
    criticality: "Low",
    weeklyUsers: 25,
    alternative: null,
  },
  {
    id: 4,
    name: "Google Meet",
    status: "Allowed",
    criticality: "Low",
    weeklyUsers: 25,
    alternative: null,
  },
  {
    id: 5,
    name: "WeTransfer",
    status: "Blocked",
    criticality: "High",
    weeklyUsers: 0,
    alternative: "drive.google.com",
  },
  {
    id: 6,
    name: "Notion",
    status: "Allowed",
    criticality: "Low",
    weeklyUsers: 25,
    alternative: null,
  },
  {
    id: 7,
    name: "Zoom",
    status: "Allowed",
    criticality: "Low",
    weeklyUsers: 25,
    alternative: null,
  },
  {
    id: 8,
    name: "AirTable",
    status: "Blocked",
    criticality: "High",
    weeklyUsers: 0,
    alternative: "notion.so",
  },
  {
    id: 9,
    name: "HubSpot",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 31,
    alternative: null,
  },
  {
    id: 10,
    name: "Aircall",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 31,
    alternative: null,
  },
  {
    id: 11,
    name: "Yousign",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 31,
    alternative: null,
  },
  {
    id: 12,
    name: "Slack",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 28,
    alternative: null,
  },
  {
    id: 13,
    name: "Dropbox",
    status: "Blocked",
    criticality: "Low",
    weeklyUsers: 0,
    alternative: "drive.google.com",
  },
  {
    id: 14,
    name: "Monday.com",
    status: "Blocked",
    criticality: "Low",
    weeklyUsers: 0,
    alternative: "notion.so",
  },
  {
    id: 15,
    name: "LastPass",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 30,
    alternative: null,
  },
  {
    id: 16,
    name: "Asana",
    status: "Blocked",
    criticality: "Low",
    weeklyUsers: 0,
    alternative: "notion.so",
  },
  {
    id: 17,
    name: "Microsoft Teams",
    status: "Allowed",
    criticality: "Low",
    weeklyUsers: 22,
    alternative: null,
  },
  {
    id: 18,
    name: "DocuSign",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 29,
    alternative: null,
  },
  {
    id: 19,
    name: "Box",
    status: "Blocked",
    criticality: "Low",
    weeklyUsers: 0,
    alternative: "drive.google.com",
  },
  {
    id: 20,
    name: "Zendesk",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 27,
    alternative: null,
  },
  {
    id: 21,
    name: "Calendly",
    status: "Allowed",
    criticality: "Low",
    weeklyUsers: 24,
    alternative: null,
  },
  {
    id: 22,
    name: "Jira",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 26,
    alternative: null,
  },
  {
    id: 23,
    name: "Evernote",
    status: "Blocked",
    criticality: "Low",
    weeklyUsers: 0,
    alternative: "notion.so",
  },
  {
    id: 24,
    name: "RingCentral",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 28,
    alternative: null,
  },
  {
    id: 25,
    name: "Basecamp",
    status: "Blocked",
    criticality: "Low",
    weeklyUsers: 0,
    alternative: "notion.so",
  },
  {
    id: 26,
    name: "Freshdesk",
    status: "Allowed",
    criticality: "High",
    weeklyUsers: 25,
    alternative: null,
  },
  {
    id: 27,
    name: "PCloud",
    status: "Blocked",
    criticality: "Low",
    weeklyUsers: 0,
    alternative: "drive.google.com",
  },
];

export function SaasGovernance() {
  const [selectedRows, setSelectedRows] = useState([]);

  const StatusChip = ({ status }) => (
    <Chip
      label={status}
      variant="outlined"
      size="small"
      sx={{
        backgroundColor:
          status === "Allowed"
            ? "rgba(52, 211, 153, 0.1)"
            : "rgba(248, 113, 113, 0.1)",
        color:
          status === "Allowed" ? "rgb(52, 211, 153)" : "rgb(248, 113, 113)",
        borderColor:
          status === "Allowed" ? "rgb(52, 211, 153)" : "rgb(248, 113, 113)",
        borderRadius: "4px",
      }}
    />
  );

  const CriticalityChip = ({ level }) => (
    <Chip
      label={level}
      variant="outlined"
      size="small"
      sx={{
        backgroundColor:
          level === "High"
            ? "rgba(250, 204, 21, 0.1)"
            : "rgba(129, 140, 248, 0.1)",
        color: level === "High" ? "rgb(250, 170, 21)" : "rgb(129, 140, 248)",
        borderColor:
          level === "High" ? "rgb(250, 170, 21)" : "rgb(129, 140, 248)",
        borderRadius: "4px",
      }}
    />
  );

  const TableComponent = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    return (
      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <StyledTableHeaderRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.length === tableData.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedRows(tableData.map((row) => row.id));
                      } else {
                        setSelectedRows([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{"Service"}</TableCell>
                <TableCell align="center">{"Authorization"}</TableCell>
                <TableCell align="center">{"General criticality"}</TableCell>
                <TableCell align="center">{"Weekly users"}</TableCell>
                <TableCell align="center">{"Approved alternative"}</TableCell>
                <TableCell align="center"></TableCell>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    selected={selectedRows.includes(row.id)}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.includes(row.id)}
                        onClick={() => {
                          setSelectedRows((prev) =>
                            prev.includes(row.id)
                              ? prev.filter((id) => id !== row.id)
                              : [...prev, row.id],
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Box
                          sx={{
                            width: 32,
                            height: 32,
                            bgcolor: "grey.200",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {row.name[0]}
                        </Box>
                        <Typography>{row.name}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <StatusChip status={row.status} />
                    </TableCell>
                    <TableCell align="center">
                      <CriticalityChip level={row.criticality} />
                    </TableCell>
                    <TableCell align="center">{row.weeklyUsers}</TableCell>
                    <TableCell align="center">
                      {row.alternative && (
                        <Typography
                          color="primary"
                          sx={{ textDecoration: "underline" }}
                        >
                          {row.alternative}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        sx={{
                          color: "grey.500",
                          "&:hover": { backgroundColor: "grey.100" },
                        }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    );
  };

  const EmptyState = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        minHeight: "400px",
        gap: 2,
      }}
    >
      <TaskAltIcon sx={{ fontSize: 48, color: "primary.main" }} />
      <Typography variant="h5" color="text.secondary">
        {"All detected Saas are managed"}
      </Typography>
    </Box>
  );

  // Content wrapper with action bar
  const ContentWrapper = ({ children }) => (
    <Box
      sx={{
        display: "visible",
        flexDirection: "column",
        px: 2,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={4}
        sx={{ py: 1 }}
      >
        <Stack direction="row" spacing={1} alignItems="left">
          <TextField
            size="small"
            placeholder="Search"
            sx={{ width: "240px" }}
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ color: "action.active", mr: 1 }} />
              ),
            }}
          />

          <Button
            startIcon={<FilterListIcon />}
            size="uppercase"
            variant="outlined"
            onClick={() => {}}
          >
            {"Filter"}
          </Button>

          <Button
            startIcon={<ImportExportIcon />}
            size="uppercase"
            variant="outlined"
            onClick={() => {}}
          >
            {"Sort"}
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="right">
          <Button
            variant="outlined"
            startIcon={<BlockIcon />}
            size="small"
            color="error"
          >
            {"Block Saas"}
          </Button>
          <Button
            variant="outlined"
            startIcon={<CheckCircleIcon />}
            size="small"
            color="success"
          >
            {"Approve Saas"}
          </Button>
        </Stack>
      </Stack>
      <Box>{children}</Box>
    </Box>
  );

  const info_user = useUserContext();

  if (info_user.email !== "demo@acme.com") {
    return (
      <Typography>
        {"This feature might come in the future. Stay tuned!"}
      </Typography>
    );
  }

  return (
    <Box>
      <StickyHeader>
        <Typography
          variant="h2"
          style={{
            marginRight: "auto",
            maxHeight: "2rem",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {"Saas Governance"}
        </Typography>
      </StickyHeader>

      <Box sx={{ p: 1, height: "100%", overflow: "visible" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 2,
            mb: 4,
          }}
        >
          {statsData.map((stat, index) => (
            <BasicStatCard
              key={index}
              title={stat.title}
              data={stat.count}
              icon={stat.icon}
            />
          ))}
        </Box>

        <TabsContextProvider
          value={{
            tabs: [
              {
                anchor: "to-review",
                label: "SAAS TO REVIEW",
                component: (
                  <ContentWrapper key="empty">
                    <EmptyState />
                  </ContentWrapper>
                ),
              },
              {
                anchor: "managed",
                label: "MANAGED SAAS",
                component: (
                  <ContentWrapper key="table">
                    <TableComponent />
                  </ContentWrapper>
                ),
              },
            ],
          }}
        >
          <Tabs />
          <CurrentTabComponent />
        </TabsContextProvider>
      </Box>
    </Box>
  );
}
