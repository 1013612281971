import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

export default function CustomCampaignList({ listCampaigns }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  function handleButtonClick(params) {
    navigate({
      pathname: location.pathname + "/details",
      search: "?id=" + params.id,
    });
  }

  const columns: GridColDef[] = [
    { field: "campaign_name", headerName: t("Campaign Name"), flex: 1 },
    { field: "sent_date", headerName: t("Date"), flex: 0.2 },
    { field: "simulations_sent_count", headerName: t("Sent"), flex: 0.2 },
    {
      field: "simulations_opened_rate",
      headerName: t("Open Rate"),
      flex: 0.2,
    },
    {
      field: "simulations_clicked_rate",
      headerName: t("Click Rate"),
      flex: 0.2,
    },
    {
      field: "actions",
      width: 20,
      renderHeader: () => {
        return "";
      },
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleButtonClick(params)}>
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <DataGrid
      sx={{ height: "90%" }}
      rows={listCampaigns}
      columns={columns}
      disableColumnMenu
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 20,
          },
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
    />
  );
}
