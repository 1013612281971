import React from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import { Spinner } from "@/components/Spinner";
import { useDepartmentsQuery } from "@/learningApi";
import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";

export const CollapseDepartmentAutocomplete = ({
  openCollapse,
  deployedDepartments,
  setDeployedDepartments,
}) => {
  const { t } = useTranslation();
  const { data: departments, isLoading } = useDepartmentsQuery();

  const removeSelectedDepartment = (id) => {
    const tmp = deployedDepartments.filter(
      (department) => department.id !== id,
    );
    setDeployedDepartments(tmp);
  };

  const handleChange = (event, newValue) => {
    setDeployedDepartments(newValue);
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Collapse
      in={openCollapse}
      timeout="auto"
      sx={{ paddingLeft: "7rem", marginTop: "1rem" }}
      unmountOnExit
      key={"users_subset_collapsed"}
    >
      <Autocomplete
        size="small"
        multiple
        options={departments.filter(
          (dept) => !deployedDepartments.map((x) => x.id).includes(dept.id),
        )}
        getOptionLabel={(option) => option.name}
        value={deployedDepartments}
        freeSolo={true}
        loadingText={t("Loading") + "..."}
        onChange={handleChange}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            sx={{ width: "80%" }}
            {...params}
            label={t("Department")}
          />
        )}
      />
      {deployedDepartments.map((value, index) => (
        <StyledBox sx={{ justifyContent: "unset" }} key={index}>
          <IconButton
            onClick={() => {
              removeSelectedDepartment(value.id);
            }}
          >
            <CloseIcon sx={{ width: "0.8em" }} />
          </IconButton>
          <Typography
            sx={{
              px: "1.5rem",
              width: "60%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            color={"text.secondary"}
          >
            {value.name}
          </Typography>
        </StyledBox>
      ))}
    </Collapse>
  );
};
