import React from "react";
import { Link } from "react-router-dom";

import { Chip, Stack, Typography, alpha, useTheme } from "@mui/material";
import TableRow from "@mui/material/TableRow";

import { AttachmentsIcon } from "@/components/icons/AttachmentsIcon";
import { ClickRate } from "@/components/icons/ClickRate";
import { CredentialsIcon } from "@/components/icons/CredentialsIcon";
import { DownloadsIcon } from "@/components/icons/DownloadsIcon";
import { PermissionsIcon } from "@/components/icons/PermissionsIcon";
import {
  StyledBox,
  TableCellWithoutBorder,
} from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/DataPerUsersRow";

export default function DataPerUserCollapseRow({ emailMessage }) {
  const theme = useTheme();
  const getChipStatus = (status) => {
    const getStyles = () => {
      switch (status) {
        case "Sent":
          return {
            backgroundColor: "#9090901A",
            color: "#909090",
            borderColor: "#909090",
          };
        case "Reported":
          return {
            backgroundColor: "#65C58B1A",
            color: "#65C58B",
            borderColor: "#65C58B",
          };
        case "Opened":
          return {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          };
        case "Compromised":
          return {
            backgroundColor: "#F6285F1A",
            color: "#F6285F",
            borderColor: "#F6285F",
          };
        case "Clicked":
          return {
            backgroundColor: "#F2994A1A",
            color: "#F2994A",
            borderColor: "#F2994A",
          };

        default:
          return {
            backgroundColor: "#9090901A",
            color: "#909090",
            borderColor: "#909090",
          };
      }
    };

    return (
      <Chip
        label={status}
        size={"small"}
        variant={"outlined"}
        sx={getStyles()}
      />
    );
  };

  const EmailMessagesTypeIcon = ({ type }) => {
    const customStyle = { width: "15px", height: "15px", color: "#98A5B3" };
    switch (type) {
      case "Attachment":
        return <AttachmentsIcon sx={customStyle} />;
      case "Download":
        return <DownloadsIcon sx={customStyle} />;
      case "Permission":
        return <PermissionsIcon sx={customStyle} />;
      case "Credentials":
        return <CredentialsIcon sx={customStyle} />;
      default:
        return <ClickRate sx={customStyle} />;
    }
  };

  return (
    <TableRow>
      <TableCellWithoutBorder>
        <Typography variant={"body_small"}>{emailMessage.sent_date}</Typography>
      </TableCellWithoutBorder>
      <TableCellWithoutBorder>
        <Link
          to={`/dashboard/emailmessage?id=${emailMessage.id || emailMessage}`}
          style={{ color: "black" }}
        >
          <StyledBox
            sx={{
              border: "1px solid",
              borderColor: "#a7bef1",
              color: "#1662ff",
              backgroundColor: "#1662FF1A",
              maxWidth: "100%",
            }}
          >
            <Typography variant={"body_small"} noWrap>
              {emailMessage.template}
            </Typography>
          </StyledBox>
        </Link>
      </TableCellWithoutBorder>
      <TableCellWithoutBorder>
        <Stack direction="row" alignItems="center" gap="1px">
          <EmailMessagesTypeIcon type={emailMessage.type} />
          <Typography
            sx={{ paddingLeft: "5px" }}
            variant="body_small"
            color="text.secondary"
          >
            {emailMessage.type}
          </Typography>
        </Stack>
      </TableCellWithoutBorder>
      <TableCellWithoutBorder>
        {" "}
        {getChipStatus(emailMessage.email_status)}
      </TableCellWithoutBorder>
    </TableRow>
  );
}
