import { useSnackbar } from "material-ui-snackbar-provider";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Permission,
  UserWithPermissions,
} from "@/pages/general-settings/permissions-attribution/permissions";
import {
  addUserWithPermissions,
  changePermissions,
  removePermissions,
  setAllPermissions,
} from "@/pages/general-settings/permissions-attribution/permissionsSlice";
import { permissionsApi } from "@/pages/general-settings/permissions-attribution/remoteApis";
import { RootState } from "@/store";

export function usePermissionService() {
  const users = useSelector((state: RootState) => state.usersWithPermissions);
  const dispatch = useDispatch();

  const snackBar = useSnackbar();
  const showErrorMessage = () => snackBar.showMessage("Something went wrong");
  const showErrorMessagFromPayload = (error) => snackBar.showMessage(error);

  const { data: fetchData, isLoading: fetchIsLoading } =
    permissionsApi.useFetchAdminsWithPermissionsQuery();
  const [
    addAdminApi,
    { isLoading: createIsLoading, isSuccess: createIsSuccess },
  ] = permissionsApi.useAddAdminMutation();
  const [
    updatePermissionsApi,
    { isLoading: updateIsLoading, isSuccess: updateIsSuccess },
  ] = permissionsApi.useUpdateAdminPermissionsMutation();
  const [
    removeAdminApi,
    { isLoading: removeAdminIsLoading, isSuccess: removeAdminIsSuccess },
  ] = permissionsApi.useRemoveAdminMutation();

  useEffect(() => {
    if (users.length === 0) {
      dispatch(setAllPermissions(fetchData));
    }
  }, [fetchData, dispatch, users]);

  function updateUserPermissions(
    user: UserWithPermissions,
    newPermissions: Array<Permission>,
  ) {
    const newUser: UserWithPermissions = {
      id: user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      permissions: newPermissions,
    };

    updatePermissionsApi(newUser)
      .unwrap()
      .then(() => dispatch(changePermissions(newUser)))
      .catch(showErrorMessage);
  }

  function createUserWithPermissions(user: UserWithPermissions) {
    addAdminApi(user.id)
      .unwrap()
      .then(() => dispatch(addUserWithPermissions(user)))
      .catch((error) => showErrorMessagFromPayload(error.data.error));
  }

  async function removeUserWithPermissions(user: UserWithPermissions) {
    const userToRevoke: UserWithPermissions = {
      id: user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      permissions: [],
    };

    try {
      await updatePermissionsApi(userToRevoke).unwrap();
      await removeAdminApi(user.id).unwrap();
      dispatch(removePermissions(user));
    } catch (e) {
      showErrorMessage();
    }
  }

  return {
    users,
    fetchIsLoading,
    createUserWithPermissions,
    createIsLoading,
    createIsSuccess,
    updateUserPermissions,
    updateIsLoading,
    updateIsSuccess,
    removeUserWithPermissions,
    removeIsLoading: removeAdminIsLoading || updateIsLoading,
    removeIsSuccess: removeAdminIsSuccess || updateIsSuccess,
  };
}
