import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export const ClickRate = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fill="none"
        opacity="0.4"
        d="M11.9974 4.66797V2.66797M6.74494 6.74885L5.33073 5.33464M6.74494 17.3346L5.33073 18.7488M17.3307 6.74885L18.7449 5.33464M4.66406 12.0013H2.66406"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M27.7573 17.8317C28.4622 17.4522 28.8146 17.2624 28.9038 17.0341C28.9811 16.8359 28.9593 16.6127 28.845 16.4334C28.7133 16.2266 28.3307 16.1088 27.5656 15.8731L12.595 11.2616C11.97 11.0691 11.6575 10.9728 11.4465 11.0503C11.2626 11.1177 11.1177 11.2626 11.0503 11.4465C10.9728 11.6575 11.0691 11.97 11.2616 12.595L15.8731 27.5657C16.1088 28.3308 16.2266 28.7133 16.4333 28.845C16.6127 28.9593 16.8359 28.9812 17.034 28.9038C17.2623 28.8147 17.4521 28.4622 17.8317 27.7574L21.154 21.5874C21.2104 21.4827 21.2386 21.4303 21.2748 21.3845C21.307 21.3438 21.3438 21.307 21.3845 21.2748C21.4303 21.2386 21.4827 21.2104 21.5874 21.154L27.7573 17.8317Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
