import { T } from "@/components/LocalizedString";
import { MenuItemType } from "@/components/menu/MenuItem";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";
import { SimulationAccess } from "@/types/user";

const config: MenuItemType[] = [
  {
    title: T("Corporate"),
    pathname: "corporate",
    icon: "/media/icons/svg/menu/company.svg",
    permissions: [Permission.CORPORATE_ADMIN],
    submenu: [
      {
        title: T("Simulation Dashboard"),
        path: "/corporate/dashboard",
        pathname: "corporate-dashboard",
        permissions: [Permission.CORPORATE_ADMIN],
      },
      {
        title: T("Course Sharing"),
        path: "/corporate/course-sharing",
        pathname: "corporate-course-sharing",
        permissions: [Permission.CORPORATE_ADMIN],
      },
    ],
  },
  {
    title: T("Phishing Simulation"),
    pathname: "phishing-simulation",
    icon: "/media/icons/svg/menu/target.svg",
    permissions: [
      Permission.SIMULATION_DASHBOARD,
      Permission.SIMULATION_CUSTOM_CAMPAIGN,
      Permission.SIMULATION_SETUP,
      Permission.SETTINGS_COMPANY_SCAN,
    ],
    submenu: [
      {
        title: T("Dashboard"),
        pathname: "phishing-simulation-dashboard",
        path: "/phishing-simulation/dashboard",
        relatedPaths: [/^\/dashboard\/emailmessage$/],
        permissions: [Permission.SIMULATION_DASHBOARD],
      },
      {
        title: T("Company Scan"),
        path: "/phishing-simulation/company",
        pathname: "phishing-simulation-company",
        permissions: [Permission.SETTINGS_COMPANY_SCAN],
      },
      {
        title: T("Template Catalog"),
        path: "/phishing-simulation/templates",
        pathname: "phishing-simulation-templates",
        permissions: [Permission.SIMULATION_TEMPLATE_CATALOG],
      },
      {
        title: T("Custom Campaign"),
        pathname: "custom-campaign",
        path: "/phishing-simulation/custom-campaign",
        permissions: [Permission.SIMULATION_CUSTOM_CAMPAIGN],
        isVisible: ({ current_company }) => {
          return (
            current_company?.simulation_access === SimulationAccess.FULL_ACCESS
          );
        },
      },
      {
        title: T("Audit"),
        pathname: "audit",
        path: "/phishing-simulation/audit",
        permissions: [Permission.SIMULATION_AUDIT],
        isVisible: ({ current_company }) => {
          return (
            current_company?.simulation_access === SimulationAccess.TRIAL_MODE
          );
        },
      },
      {
        title: T("Setup"),
        pathname: "phishing-simulation-setup",
        path: "/phishing-simulation/setup",
        permissions: [Permission.SIMULATION_SETUP],
      },
    ],
  },
  {
    title: T("Cyber Awareness"),
    pathname: "awareness",
    icon: "/media/icons/svg/menu/book-open-01.svg",
    permissions: [
      Permission.AWARENESS_DASHBOARD,
      Permission.AWARENESS_SETUP,
      Permission.AWARENESS_COURSE_CATALOG_ADMIN,
      Permission.AWARENESS_COURSE_CATALOG_USER,
    ],

    submenu: [
      {
        title: T("Dashboard"),
        path: "/awareness/dashboard",
        pathname: "awareness-dashboard",
        permissions: [Permission.AWARENESS_DASHBOARD],
      },
      {
        title: T("Courses"),
        path: "/awareness/courses",
        pathname: "awareness-courses",
        relatedPaths: [/^\/webchat(\/\d+)?$/, /\/awareness\/courses\/\d+/],
        permissions: [
          Permission.AWARENESS_COURSE_CATALOG_USER,
          Permission.AWARENESS_COURSE_CATALOG_ADMIN,
        ],
      },
      {
        title: T("Users"),
        path: "/awareness/users",
        pathname: "awareness-users-config",
        permissions: [Permission.AWARENESS_MANAGE_USERS],
      },
      {
        title: T("Push"),
        path: "/awareness/push",
        pathname: "awareness-push",
        isVisible: ({ email }) => {
          return email === "demo@acme.com";
        },
      },
      {
        title: T("Setup"),
        path: "/awareness/setup",
        pathname: "awareness-setup",
        permissions: [Permission.AWARENESS_SETUP],
      },
    ],
  },
  {
    title: T("Smart Banners"),
    pathname: "banners",
    icon: "/media/icons/svg/menu/shield-tick.svg",
    permissions: [Permission.BANNERS],
    submenu: [
      {
        title: T("Dashboard"),
        pathname: "banners-dashboard",
        path: "/banners/dashboard",
      },
      {
        title: T("Users"),
        pathname: "banners-users",
        path: "/banners/users",
      },
      {
        title: T("Setup"),
        pathname: "banners-setup",
        path: "/banners/setup",
      },
    ],
  },
  {
    title: T("Browser Defender"),
    icon: "/media/icons/svg/menu/globe.svg",
    pathname: "browser-defender",
    permissions: [Permission.BROWSER_DEFENDER],
    submenu: [
      {
        title: T("Dashboard"),
        path: "/browser-defender/dashboard",
        pathname: "browser-defender-dashboard",
      },
      {
        title: T("Password Alerts"),
        path: "/browser-defender/password-misuse",
        pathname: "browser-defender-password-misuse",
      },
      {
        title: T("Dangerous Downloads"),
        path: "/browser-defender/dangerous-downloads",
        pathname: "browser-defender-dangerous-downloads",
      },
      {
        title: T("Dangerous Browsing"),
        path: "/browser-defender/dangerous-browsing",
        pathname: "browser-defender-dangerous-browsing",
      },
      {
        title: T("Weak passwords"),
        path: "/browser-defender/weak-passwords",
        pathname: "browser-defender-weak-passwords",
        isVisible: ({ email }) => {
          return email === "demo@acme.com";
        },
      },
      {
        title: T("Extension Governance"),
        path: "/browser-defender/extension-governance",
        pathname: "browser-defender-extension-governance",
      },
      {
        title: T("Saas Governance"),
        path: "/browser-defender/saas-governance",
        pathname: "browser-defender-saas-governance",
        isVisible: ({ email }) => {
          return email === "demo@acme.com";
        },
      },
      {
        title: T("Users"),
        path: "/browser-defender/users",
        pathname: "browser-defender-users",
      },
      {
        title: T("Setup"),
        path: "/browser-defender/setup",
        pathname: "browser-defender-setup",
      },
    ],
  },
  {
    title: T("Threats"),
    pathname: "threats",
    icon: "/media/icons/svg/menu/alert-triangle.svg",
    path: "/threats",
    permissions: [Permission.THREATS],
  },
  {
    title: T("My Progress"),
    pathname: "my-progress",
    icon: "/media/icons/svg/menu/speedometer-02.svg",
    path: "/my-progress",
    permissions: [Permission.MY_PROGRESS],
  },
  {
    title: T("General Settings"),
    pathname: "general-settings",
    icon: "/media/icons/svg/menu/settings-02.svg",
    path: "/general-settings",
    relatedPaths: [/^\/users\/import$/, /^\/users\/\d+$/],
    permissions: [Permission.SETTINGS_GENERAL],
  },
  {
    title: T("Support"),
    pathname: "support",
    icon: "/media/icons/svg/menu/question-mark.svg",
    path: "/support",
    permissions: [Permission.SUPPORT],
  },
];

export default config;
