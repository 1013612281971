import {
  DefautUserPreferences,
  UserPreferences,
  UserPreferencesSchema,
} from "@/utils/userPreferences";

export const loadUserPreferences = (): UserPreferences => {
  try {
    return UserPreferencesSchema.parse(
      JSON.parse(localStorage.getItem("user-preferences")),
    );
  } catch {
    return DefautUserPreferences;
  }
};
