import React, { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

import { ChecklistPane } from "@/components/stepper/ChecklistPane";
import {
  useAcknowledgeTestSetupMutation,
  useSendDeploymentTestMutation,
  useStatusQuery,
} from "@/pages/banners/setup/BannerSetupChecklist";
import { useUserContext } from "@/utils/contexts/UserContext";

type DeploymentTestProps = { title: ReactNode };

export const DeploymentTest: React.FC<DeploymentTestProps> = ({
  title,
}: DeploymentTestProps) => {
  const { t } = useTranslation();
  const { email } = useUserContext();

  const {
    data,
    isLoading: isStatusLoading,
    isError: isStatusError,
    isFetching: isStatusFetching,
  } = useStatusQuery();

  const [sendDeploymentTest, { isLoading, isSuccess, isError, error }] =
    useSendDeploymentTestMutation();
  const [acknowledgeTestSetup, { isLoading: isAckLoading }] =
    useAcknowledgeTestSetupMutation();

  if (isStatusError) return <>{t("Error")}</>;

  if (isStatusLoading) return <>{t("Loading")}</>;

  const checkbox = data.deployment_test_done;
  const atLeastOneUserEngaged = data.engaged_users > 0;

  return (
    <ChecklistPane title={title}>
      <Typography>
        {t("In order to test the deployment, engage at least one user.")}
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link to="/banners/users">
          <Button variant="contained">{t("Engage one User")}</Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          {atLeastOneUserEngaged ? (
            <CheckCircleOutlinedIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          )}
        </Box>
      </Box>
      <Typography>
        <Trans i18nKey="DeploymentTestBanners">
          Click on the button below to check deployment of Smart Banners. You
          should receive an email with a green banner in your inbox. If you
          don&apos;t receive it,
          <a href="mailto:team@mantra.ms">contact us</a>.
        </Trans>
      </Typography>

      <Box display="flex" alignItems="center" gap="2.5rem">
        <Button
          variant="contained"
          onClick={() => {
            sendDeploymentTest();
          }}
          disabled={isLoading}
        >
          {t("Test deployment")}
        </Button>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontStyle: "italic",
            display: "inline",
          }}
        >
          <Trans i18nKey="DeployementTestBanners">
            You must be engaged to receive Smart Banners (in
            <Link to="/banners/users">Users tab</Link>
            ).
          </Trans>
        </Typography>
      </Box>
      {isSuccess && (
        <div style={{ color: "green" }}>
          {t("Email successfully sent to")} {email}.
        </div>
      )}
      {isError && <div style={{ color: "red" }}>{JSON.stringify(error)}</div>}
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkbox}
              disabled={isAckLoading || isStatusFetching}
              onChange={(e) => {
                acknowledgeTestSetup(e.target.checked);
              }}
            />
          }
          label={t(
            "I confirm I have received an email with a green banner in my inbox.",
          )}
        />
      </FormGroup>
    </ChecklistPane>
  );
};
