import axios, { AxiosResponse } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Stack, Typography } from "@mui/material";

import InfoTooltip from "@/components/InfoTooltip";
import { T } from "@/components/LocalizedString";
import { ButtonSpinner } from "@/components/Spinner";
import ListItemsFormField from "@/components/forms/ListItemsFormField";
import { validateEmail } from "@/utils/misc";

const ROUTE = `${
  import.meta.env.VITE_APP_ENDPOINT_SIMULATION
}/users/sync/excluded`;

const EXCLUDED_TOOLTIP_CONTENT = T(
  "You can enter emails one by one here. You may also paste a list of emails separated by a comma or line breaks. They will all be added to the list.",
);

type ExcludedUser = {
  id: number;
  email: string;
};

const useExcluded = () => {
  const [users, setUsers] = useState<ExcludedUser[]>([]);
  const [isLoadingItems, setIsLoadingItems] = useState<boolean>(false);
  const [isErrorItems, setIsErrorItems] = useState<boolean>(false);
  const { t } = useTranslation();

  const addUser = async (email: string) => {
    const response = await axios.post(ROUTE, { email: email });
    setUsers((users) => {
      const usersCopy = [...users];
      usersCopy.push({
        id: response.data.id,
        email: email,
      });
      return usersCopy;
    });
  };

  const bulkAddUsers = async (emails: string[]) => {
    const emailsHashset = new Set(emails);
    const userEmailsHashset = new Set(users.map((user) => user.email));
    // @ts-ignore
    const filteredEmails = emailsHashset.difference(userEmailsHashset);

    if (filteredEmails.size === 0) {
      throw new Error(t("no new email entered"));
    }

    const newUsers = await Promise.all(
      filteredEmails
        .values()
        .map((email: string) => axios.post(ROUTE, { email: email })),
    );

    setUsers((users) => {
      const usersCopy = [...users];
      usersCopy.push(
        ...newUsers.map((value: AxiosResponse<{ id: number }>) => ({
          id: value.data.id,
          email: JSON.parse(value.config.data).email,
        })),
      );
      return usersCopy;
    });
  };

  /*
   * Optimistic deletion of a user
   */
  const deleteUser = async (key: number) => {
    const indexUserToDelete = users.findIndex(
      (user) => user.id === Number(key),
    );
    const userToDelete = { ...users.find((user) => user.id === key) };
    setUsers((users) => {
      return users.filter((user) => user.id !== key);
    });
    try {
      await axios.delete(`${ROUTE}/${key}`);
    } catch (e) {
      setUsers((users) => {
        const usersCopy = [...users];
        usersCopy.splice(indexUserToDelete, 0, userToDelete);
        return usersCopy;
      });
      throw e;
    }
  };

  const modifyUser = async (email: string, key: string) => {
    await axios.patch(`${ROUTE}/${key}`, { email: email });
    setUsers((users) => {
      const usersCopy = [...users];
      const user = usersCopy.find((user) => Number(key) === user.id);
      user.email = email;
      return usersCopy;
    });
  };

  useEffect(
    function initialLoadUsers() {
      setIsLoadingItems(true);
      axios
        .get(ROUTE)
        .then((response) => {
          setUsers(response.data?.excluded);
        })
        .catch(() => {
          setIsErrorItems(true);
        })
        .finally(() => {
          setIsLoadingItems(false);
        });
    },
    [setUsers],
  );

  const validate = (value: string, key: number) =>
    users.find((user) => {
      if (user.id === key) {
        return false;
      }
      return user.email === value;
    }) == null && validateEmail(value);

  return {
    users,
    isLoadingItems,
    isErrorItems,
    addUser,
    bulkAddUsers,
    deleteUser,
    modifyUser,
    validate,
  };
};

export function ExcludedUsers() {
  const {
    isLoadingItems,
    isErrorItems,
    users,
    addUser,
    deleteUser,
    modifyUser,
    bulkAddUsers,
    validate,
  } = useExcluded();
  const { t } = useTranslation();

  const items = useMemo(() => {
    return users.map((user) => ({
      value: user.email,
      key: user.id,
    }));
  }, [users]);

  if (isLoadingItems) {
    return <ButtonSpinner sx={{ marginTop: "1rem" }} />;
  }

  if (isErrorItems) {
    return (
      <Typography color="error" marginTop="1rem">
        {t("Error: unable to fetch excluded users")}
      </Typography>
    );
  }

  return (
    <Stack gap="1rem">
      {users.length > 0 ? (
        <Box>
          {t("Number of excluded users :")} <b>{users.length}</b>
        </Box>
      ) : (
        ""
      )}
      <ListItemsFormField
        newItemPlaceholder="user@email.com"
        items={items}
        addItem={addUser}
        bulkAddItems={bulkAddUsers}
        deleteItem={deleteUser}
        setItem={modifyUser}
        validate={validate}
        itemErrorMessage={t(
          "Error while trying to exclude a user, you may try again later",
        )}
        itemSuccessMessage={t("Successfully uploaded")}
        editErrorMessage={t(
          "Unable to modify the email, verify that the email is valid",
        )}
        bulkAddErrorMessage={t(
          "Unable to add emails, verify that those are valid emails",
        )}
        bulkSomeDuplicatedEntriesMessage={(validEntries: number) => {
          t("{{validEntries}} email addresses were added", {
            validEntries: validEntries,
          });
        }}
        tooltipPlaceholder={<InfoTooltip content={EXCLUDED_TOOLTIP_CONTENT} />}
      />
    </Stack>
  );
}
