import * as React from "react";
import { useTranslation } from "react-i18next";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ReportIcon from "@mui/icons-material/Report";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

import PermissionsChip from "@/pages/browser-defender/extensionGovernance/PermissionsChip";

const riskColors = {
  high: {
    bgColor: "#FEE9EF8C",
    textColor: "#F6285F",
    icon: <WarningAmberIcon color={"#F6285F"} />,
  },
  medium: {
    bgColor: "#FEF5ED8C",
    textColor: "#F2994A",
    icon: <ErrorOutlineOutlinedIcon color={"#F2994A"} />,
  },
  low: {
    bgColor: "#1dc9b71A",
    textColor: "#1dc9b7",
    icon: <CheckCircleOutlineOutlinedIcon color={"#1dc9b7"} />,
  },
};

function ExtensionTableRow({ row, onSelect, isSelected }) {
  return (
    <TableRow
      style={{
        cursor: "pointer",
        color: "rgb(6, 17, 35)",
        background: isSelected ? "#D9D9FF" : "#fff",
      }}
      onClick={() => onSelect(row)}
    >
      <TableCell>
        <Box
          style={{ display: "flex", alignItems: "center", paddingLeft: "1rem" }}
        >
          <Avatar
            sx={{ bgcolor: "#EDEBEB" }}
            style={{
              color: "rgb(6, 17, 35)",
              fontWeight: "bold",
              width: 40,
              height: 40,
            }}
          >
            {row.name.charAt(0).toUpperCase()}
          </Avatar>
          <Typography
            style={{
              fontWeight: "500",
              width: "100%",
              display: "inline",
              marginLeft: "1rem",
            }}
            noWrap
          >
            {row.potentially_dangerous && (
              <Tooltip title="Mantra suggests this extension might be dangerous">
                <ReportIcon
                  style={{ color: "#F6285F", marginRight: "0.5rem" }}
                />
              </Tooltip>
            )}
            {row.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="center">
        {
          row.users.filter(
            (obj) => obj.uninstalled === false && obj.enabled === true,
          ).length
        }
      </TableCell>
      <TableCell align="center">
        <PermissionsChip risk={row} colors={riskColors} />
      </TableCell>
    </TableRow>
  );
}

export default function ExtensionsTable({
  values,
  setCurrentExtension,
  selectedExtension,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const { t } = useTranslation();

  const handleSelect = (extension) => {
    setCurrentExtension(extension);
  };

  React.useEffect(() => {
    setPage(0);
  }, [values]);

  return (
    <TableContainer>
      <Table stickyHeader style={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow style={{ color: "rgb(6, 17, 35)" }}>
            <TableCell
              align={"left"}
              style={{ minWidth: "200", width: "50%", paddingLeft: "3rem" }}
            >
              {t("Name")}
            </TableCell>
            <TableCell align={"center"}>{t("Users")}</TableCell>
            <TableCell align={"center"}>{t("Permissions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? values.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : values
          ).map((row) => (
            <ExtensionTableRow
              key={row.internal_id}
              row={row}
              onSelect={handleSelect}
              isSelected={selectedExtension?.internal_id === row.internal_id}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[4, 8, 16, 32]}
        labelRowsPerPage={t("Rows per page") + " :"}
        component="div"
        count={values.length}
        rowsPerPage={rowsPerPage}
        page={values.length <= rowsPerPage ? 0 : page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={(_, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </TableContainer>
  );
}
