import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FormControlLabel, FormGroup, Switch } from "@mui/material";

import { ImportButton } from "@/pages/general-settings/UsersManagement/csvImport/ImportButton";
import { ExportButton } from "@/pages/general-settings/UsersManagement/export/ExportButton";
import { UserList } from "@/pages/general-settings/UsersManagement/usersPanel/UserList";
import { useUserContext } from "@/utils/contexts/UserContext";

const UserOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 8px;

  & > .buttons {
    display: flex;
    gap: 10px;
  }

    > label {
      margin-bottom: 0;
    }

    .custom-control-label {
      cursor: pointer;
    }
  }
`;

export function Users() {
  const [activeUsersOnly, setActiveUsersOnly] = useState(true);
  const { t } = useTranslation();

  return (
    <div>
      <UserOptions>
        {useUserContext().current_company.provider === "CSV" && (
          <div className="buttons">
            <ImportButton />
            <ExportButton />
          </div>
        )}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={activeUsersOnly}
                onChange={(event) => setActiveUsersOnly(event.target.checked)}
              />
            }
            label={t("Show active users only")}
          />
        </FormGroup>
      </UserOptions>
      <div className="row">
        <div className="col-md-12 no-right-padding no-left-padding">
          <UserList activeUsersOnly={activeUsersOnly} />
        </div>
      </div>
    </div>
  );
}
