import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { ConfirmationDialog } from "@/components/modals/ConfirmationDialog";

export type ThreatDeleteInfo = {
  id: number;
  subject: string;
  reportDate: Date;
};

type ThreatDeleteConfirmationDialogProps = {
  onDelete: () => void;
  onCancel: () => void;
  threats: ThreatDeleteInfo[];
};

const ThreatDeleteConfirmationDialog = ({
  onDelete,
  onCancel,
  threats,
}: ThreatDeleteConfirmationDialogProps) => {
  const { t } = useTranslation();
  return (
    <ConfirmationDialog
      isShown={threats.length > 0}
      onConfirm={onDelete}
      isLoading={false}
      disabled={false}
      onConfirmText="Delete"
      onCancel={onCancel}
      onCancelText="Cancel"
      title={
        threats.length > 1 ? (
          <span>
            <Trans i18nKey="ThreatDeleteConfirmationDialogText">
              Do you really want to delete those <b>{threats.length}</b>{" "}
              threats?
            </Trans>
          </span>
        ) : (
          <div>{t("Do you really want to delete this threat ?")}</div>
        )
      }
    >
      <Box
        sx={{
          overflowY: "scroll",
          maxHeight: "100px",
          maxWidth: "500px",
        }}
      >
        {threats.map((el) => (
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            key={el.subject + el.reportDate.toISOString() + el.id}
          >
            <Typography
              color="primary"
              sx={{ fontWeight: 500, maxWidth: "225px" }}
              noWrap
            >
              {el.subject}
            </Typography>
            <Box sx={{ fontWeight: "400" }}>
              {el.reportDate.toLocaleTimeString(undefined, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}
            </Box>
          </Box>
        ))}
      </Box>
    </ConfirmationDialog>
  );
};

export default ThreatDeleteConfirmationDialog;
