import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { downloadAsCSV } from "@/utils/downloadFile";

/**
 * Download users in CSV
 */
export const useDownloadUsers = () => {
  const { getAccessTokenSilently } = useAuth0();

  const url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/users/export/csv";

  const startDownload = async () => {
    const accessToken = await getAccessTokenSilently();

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      })
      .then((response) => downloadAsCSV(response.data, "users.csv"));
  };

  return { startDownload };
};
