import React from "react";

import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon color="primary">
      <svg
        width="23"
        height="21"
        viewBox="0 0 23 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.75C0 1.67892 1.67892 0 3.75 0H9.25C11.3211 0 13 1.67892 13 3.75V6.25H14.25C16.3211 6.25 18 7.92892 18 10V16.25C18 17.2165 17.2165 18 16.25 18H1.74999C0.783507 18 0 17.2165 0 16.25V3.75ZM5.16152 5.65968C5.14946 5.66002 5.1373 5.66017 5.12501 5.66017C3.79474 5.66017 3.79166 3.66917 5.11575 3.65993H8.09357L8.10395 3.66036L8.125 3.66017C8.625 3.66017 9.12498 3.99349 9.12498 4.66015C9.12498 5.36145 8.57171 5.6939 8.04715 5.65747H5.20878C5.19322 5.6585 5.17746 5.65922 5.16152 5.65968ZM8.09357 6.65993H5.11575C4.61883 6.66401 4.12498 6.99761 4.12498 7.66015C4.12498 8.36143 4.67827 8.69388 5.20283 8.65746H8.04123C8.06855 8.65925 8.09647 8.66017 8.125 8.66017C9.45832 8.66017 9.45832 6.66016 8.125 6.66016C8.11443 6.66016 8.10395 6.65967 8.09357 6.65993ZM14.625 8.13477C15.9584 8.13477 15.9584 10.1348 14.625 10.1348C13.2916 10.1348 13.2916 8.13477 14.625 8.13477ZM5.20878 11.6575C5.18147 11.6592 5.15355 11.6601 5.12501 11.6601C3.79474 11.6601 3.79166 9.66916 5.11575 9.65992H8.09357C8.10406 9.65962 8.11451 9.66015 8.125 9.66015C8.625 9.66015 9.12498 9.99349 9.12498 10.6602C9.12498 11.3615 8.57158 11.694 8.047 11.6575H5.20878ZM15.625 12.1347C15.625 11.4681 15.125 11.1348 14.625 11.1348C14.125 11.1348 13.625 11.4681 13.625 12.1347C13.625 13.4681 15.625 13.4681 15.625 12.1347ZM14.625 14.1347C15.9584 14.1347 15.9584 16.1348 14.625 16.1348C13.2916 16.1348 13.2916 14.1347 14.625 14.1347ZM7.75002 14.5H5.25002C5.11192 14.5 5 14.6119 5 14.75V16.5H8V14.75C8 14.6119 7.88807 14.5 7.75002 14.5Z"
          fill="currentColor"
        />
        <circle cx="16.6756" cy="14.6756" r="5.67561" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6756 8.99987C13.541 8.99987 11 11.541 11 14.6755C11 17.8101 13.541 20.3512 16.6756 20.3512C19.8102 20.3512 22.3513 17.8101 22.3513 14.6755C22.3513 11.541 19.8102 8.99987 16.6756 8.99987ZM19.3623 13.4925C19.5638 13.291 19.5638 12.9643 19.3623 12.7628C19.1608 12.5613 18.8341 12.5613 18.6327 12.7628L15.9017 15.4938L14.7186 14.3107C14.5171 14.1092 14.1904 14.1092 13.9889 14.3107C13.7874 14.5122 13.7874 14.8389 13.9889 15.0404L15.5368 16.5883C15.7383 16.7898 16.065 16.7898 16.2665 16.5883L19.3623 13.4925Z"
          fill="#65C58B"
        />
      </svg>
    </SvgIcon>
  );
}
