import { createApi } from "@reduxjs/toolkit/query/react";

import { simulationBaseQuery } from "@/utils/rtkQuery";

export type FirstSimulationDate = {
  nb_months_since: number;
  first_simulation_date: string;
};

export const userApi = createApi({
  reducerPath: "user-api",
  baseQuery: simulationBaseQuery(),
  endpoints: (builder) => ({
    firstSimulation: builder.query<FirstSimulationDate, void>({
      query: () => ({ url: "/company/first_simulation_date" }),
    }),
  }),
});

export const { useFirstSimulationQuery } = userApi;
