import { createApi } from "@reduxjs/toolkit/query/react";

import { simulationBaseQuery } from "@/utils/rtkQuery";

interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  departments: string[];
}

interface FetchUsersResponse {
  users: User[];
  total_count: number;
  total_pages: number;
}

export type UserLevelsResponse = {
  Beginner?: number;
  Intermediate?: number;
  Expert?: number;
};

export type UserLevelsRequest = {
  department: string | null;
  active_only: boolean;
};

export const usersApi = createApi({
  reducerPath: "usersApi",
  refetchOnMountOrArgChange: true,
  baseQuery: simulationBaseQuery(),
  endpoints: (builder) => ({
    fetchGeneralSettingsUsers: builder.query<
      FetchUsersResponse,
      {
        startIndex: number;
        pageSize: number;
        sort?: string;
        searchValue?: string;
        departmentId?: string;
        activeOnly?: boolean;
      }
    >({
      query: (queryData) => ({
        url: `/users`,
        method: "GET",
        params: {
          page_number:
            Math.floor(queryData.startIndex / queryData.pageSize) + 1,
          per_page: queryData.pageSize,
          sort: queryData.sort || "lastname",
          active_only: queryData.activeOnly,
          email_details: false,
          only_has_sent_messages: false,
          search: queryData.searchValue,
        },
      }),
    }),
    usersSimulationLevels: builder.query<UserLevelsResponse, UserLevelsRequest>(
      {
        query: (params) => ({
          url: "/users/levels",
          method: "GET",
          params: params?.department
            ? params
            : { active_only: params.active_only },
        }),
      },
    ),
  }),
});

export const {
  useFetchGeneralSettingsUsersQuery,
  useUsersSimulationLevelsQuery,
} = usersApi;
