import React, { useState } from "react";

import { Checkbox } from "@mui/material";
import { CheckboxProps } from "@mui/material/Checkbox/Checkbox";

export type SelectionListType = {
  isSelected: (objectId: number) => boolean;
  addToSelection: (objectId: number) => void;
  removeFromSelection: (objectId: number) => void;
  resetSelection: () => void;
  selectedIds: number[];
};

export const useSelectionList: () => SelectionListType = () => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  return {
    isSelected: (objectId) => selectedIds.includes(objectId),
    resetSelection: () => setSelectedIds([]),
    addToSelection: (objectId) =>
      setSelectedIds((prevState) => [...new Set(prevState).add(objectId)]),
    removeFromSelection: (objectId) =>
      setSelectedIds((prevState) => {
        const s = new Set(prevState);
        s.delete(objectId);
        return [...s];
      }),
    selectedIds: selectedIds,
  };
};

type SelectionCheckboxProps = {
  objectId: number;
  selectionList: SelectionListType;
} & CheckboxProps;

export const SelectionCheckbox = ({
  objectId,
  selectionList,
  ...props
}: SelectionCheckboxProps) => {
  return (
    <Checkbox
      checked={selectionList.isSelected(objectId)}
      onClick={(e) => {
        const checked = (e.target as HTMLInputElement).checked;
        if (checked) {
          selectionList.addToSelection(objectId);
        } else {
          selectionList.removeFromSelection(objectId);
        }
        e.stopPropagation();
      }}
      {...props}
    />
  );
};
