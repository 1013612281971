import React from "react";
import { useTranslation } from "react-i18next";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Table,
  TableBody,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import DownloadsPolicyForm from "@/pages/browser-defender/setup/DownloadsPolicyForm";
import CondensedTableCell from "@/pages/browser-defender/styles/CondensedTableCell";

const MantraPolicyRow = ({ availableMediaTypes, policy }) => {
  const { t } = useTranslation();

  const mediaTypeLabel = (mediaType) => {
    // t("Executables & scripting")
    // t("Archive & packaging")
    // t("Documents & data")
    // t("Media")
    // t("System & configuration")
    // t("Security & cryptography")
    // t("Potentially dangerous or malicious")
    // t("Miscellaneous")
    return t(mediaType.label);
  };

  return (
    <TableRow>
      <CondensedTableCell style={{ width: "14%" }}>
        <DownloadsPolicyForm
          availableMediaTypes={availableMediaTypes}
          actionButtonCallback={null}
          callToAction={<VisibilityIcon />}
          callToActionText=""
          dialogTitle={t("View policy")}
          record={policy}
          canEdit={false}
        />
      </CondensedTableCell>
      <CondensedTableCell style={{ textAlign: "left" }}>
        <Tooltip
          title={
            <Box>
              {policy.media_types.length > 0 && (
                <Box>
                  <Typography>{t("Blocked media types")}</Typography>
                  {policy.media_types.map((mediaType) => (
                    <Typography sx={{ ml: "1rem" }} key={mediaType.key}>
                      {mediaTypeLabel(mediaType)}:{" "}
                      {mediaType.media_types.length}
                    </Typography>
                  ))}
                </Box>
              )}

              <Typography>
                {t("Max file size")}: {policy.max_size_allowed} KB
              </Typography>
              <Typography>
                {t("Blocked download sources")}: {policy.blocked_sources.length}
              </Typography>
            </Box>
          }
        >
          <Typography
            placement="right-start"
            style={{ fontWeight: "bold", display: "inline-block" }}
          >
            {policy.name}
          </Typography>
        </Tooltip>
      </CondensedTableCell>
    </TableRow>
  );
};

const DownloadsPolicyRow = ({
  availableMediaTypes,
  actionButtonCallback,
  deleteButtonCallback,
  policy,
}) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <CondensedTableCell style={{ width: "14%" }}>
        <DownloadsPolicyForm
          availableMediaTypes={availableMediaTypes}
          actionButtonCallback={actionButtonCallback}
          callToAction={<VisibilityIcon />}
          callToActionText=""
          dialogTitle={t("View policy")}
          record={policy}
          canEdit={false}
        />
        <DownloadsPolicyForm
          availableMediaTypes={availableMediaTypes}
          actionButtonCallback={actionButtonCallback}
          callToAction={<EditIcon />}
          callToActionText={t("Edit policy")}
          dialogTitle="Edit policy"
          record={policy}
        />
        <Tooltip
          style={{
            display: "inline-block",
            paddingLeft: 0,
          }}
          title={t("Delete policy")}
        >
          <DeleteIcon
            style={{ color: "#fd397a", cursor: "pointer" }}
            onClick={() => deleteButtonCallback(policy.id)}
          />
        </Tooltip>
      </CondensedTableCell>
      <CondensedTableCell style={{ textAlign: "left" }}>
        <Tooltip
          title={
            <Box>
              {policy.media_types.length > 0 && (
                <Box>
                  <Typography>{t("Blocked media types")}</Typography>
                  {policy.media_types.map((mediaType) => (
                    <Typography sx={{ ml: "1rem" }} key={mediaType.key}>
                      {mediaType.label}: {mediaType.media_types.length}
                    </Typography>
                  ))}
                </Box>
              )}

              <Typography>
                {t("Max file size")}: {policy.max_size_allowed} KB
              </Typography>
              <Typography>
                {t("Blocked download sources")}: {policy.blocked_sources.length}
              </Typography>
            </Box>
          }
        >
          <Typography
            placement="right-start"
            style={{ fontWeight: "bold", display: "inline-block" }}
          >
            {policy.name}
          </Typography>
        </Tooltip>
      </CondensedTableCell>
    </TableRow>
  );
};

const DownloadsPolicyTable = ({
  availableMediaTypes,
  actionButtonCallback,
  deleteButtonCallback,
  downloadsPolicies,
}) => {
  return (
    <Table size="small">
      <TableBody>
        {downloadsPolicies.map((policy) =>
          policy.id === 0 ? (
            <MantraPolicyRow
              key={policy.id}
              availableMediaTypes={availableMediaTypes}
              policy={policy}
            />
          ) : (
            <DownloadsPolicyRow
              key={policy.id}
              availableMediaTypes={availableMediaTypes}
              actionButtonCallback={actionButtonCallback}
              deleteButtonCallback={deleteButtonCallback}
              policy={policy}
            />
          ),
        )}
      </TableBody>
    </Table>
  );
};

export default DownloadsPolicyTable;
