import React from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import { useDeleteCompanySafeSendersMutation } from "@/pages/banners/remote-apis/bannerUsersApi";
import { useUserContext } from "@/utils/contexts/UserContext";

export default function DeleteSafeItemsConfirmationDialog({
  target,
  confirmationDialogOpen,
  setConfirmationDialogOpen,
}) {
  const { t } = useTranslation();
  const user = useUserContext();
  const [deleteCompanySafeSenders] = useDeleteCompanySafeSendersMutation();

  async function handleConfirm() {
    setConfirmationDialogOpen(false);
    await deleteCompanySafeSenders({
      companyId: user.current_company.id,
      items: [target.item],
      type: target.type,
    });
  }

  return (
    <Dialog open={confirmationDialogOpen} maxWidth={"xs"} fullWidth>
      <DialogTitle>{t("Delete item")}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography>
            <strong>
              {t("You’re about to delete the item from the allowlist.")}
            </strong>
          </Typography>
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button onClick={() => setConfirmationDialogOpen(false)}>
            {t("Cancel")}
          </Button>
          <Box>
            <Button
              disableElevation
              onClick={handleConfirm}
              variant={"contained"}
            >
              {t("Confirm")}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
