import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, IconButton } from "@mui/material";

import { EmailTableComponent } from "@/pages/phishing-simulation/setup/EmailTableComponent";

export function WhitelistingTestLog() {
  const [showDetails, setShowDetails] = useState(false);
  const [emails, setEmailsList] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const tableDisplayOptions = {
    download: false,
    filter: false,
    print: false,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    customToolbar: () => {
      return (
        <IconButton onClick={whitelisting_emails_call}>
          <RefreshIcon />
        </IconButton>
      );
    },
  };

  const whitelisting_emails_call = async () => {
    const accessToken = await getAccessTokenSilently();
    const endpointPath = `${
      import.meta.env.VITE_APP_ENDPOINT_SIMULATION
    }/emails/whitelisting_test_history`;
    const result = await axios.get(endpointPath, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    setEmailsList(result.data.emails);
  };

  function toggleDisplay() {
    if (shouldFetch) {
      whitelisting_emails_call();
    }
    setShouldFetch(false);
    setShowDetails(!showDetails);
  }

  return (
    <div>
      <Button variant="contained" onClick={toggleDisplay} sx={{ mt: 2, mb: 2 }}>
        {showDetails ? t("Hide sent emails log") : t("Show sent emails log")}
      </Button>
      <Box sx={{ display: showDetails ? "block" : "none" }}>
        <EmailTableComponent
          data={emails}
          tableDisplayOptions={tableDisplayOptions}
          customHeaders={{ send_date: t("Sending date") }}
        />
      </Box>
    </div>
  );
}
