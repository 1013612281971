import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import StickyHeader from "@/components/StickyHeader";
import Tabs, {
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { BannerPreferencesDashboard } from "@/pages/banners/preferences/BannerPreferencesDashboard";
import { BannersSetupChecklist } from "@/pages/banners/setup/BannersSetupChecklist";

const SetupInner: React.FC = () => {
  const { currentTabComponent } = useTabsContext();
  const { t } = useTranslation();

  return (
    <>
      <StickyHeader height="120px">
        <Typography variant="h2">{t("Banners Setup")}</Typography>
        <Tabs />
      </StickyHeader>
      {currentTabComponent}
    </>
  );
};

export const Setup: React.FC = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      anchor: "setup-checklist",
      label: t("Setup Checklist"),
      component: <BannersSetupChecklist />,
    },
    {
      anchor: "preferences",
      label: t("Preferences"),
      component: <BannerPreferencesDashboard />,
    },
  ];

  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <SetupInner />
    </TabsContextProvider>
  );
};
