import React from "react";

import { Spinner } from "./Spinner";

export default function SplashScreen() {
  return (
    <div id="splash-screen">
      <img
        src="/media/logos/logo_mantra_loading_screen_small.png"
        alt="Mantra logo"
        width="300"
        height="52"
      />
      <Spinner />
    </div>
  );
}
