import React, { useContext, useRef } from "react";
import { useState } from "react";

import { Stack } from "@mui/material";

import { DateRange, Period, PeriodDateRange } from "./DateRange";
import DateRangePickerContextProvider, {
  DateRangePickerContext,
} from "./DateRangePickerContext";
import { DateRangePickerSelector } from "./DateRangeSelector";
import DateRangeTextField from "./DateRangeTextField";
import RangeCalendarPopover from "./RangeCalendarPopover";

type DateRangePickerProps = {
  periods: PeriodDateRange[];
  onChange: (newPeriod: Period, newDateRange: DateRange) => void;
};

const DateRangePickerInternal = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(ref.current);
  const { currentDateRange, updateCurrentDateRange } = useContext(
    DateRangePickerContext,
  );

  return (
    <Stack direction="row" alignItems="baseline">
      <DateRangePickerSelector rangeTextFieldRef={ref} />
      <DateRangeTextField
        ref={ref}
        onClick={() => setAnchorEl(ref.current)}
        value={currentDateRange}
      />
      <RangeCalendarPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        dateRange={currentDateRange}
        updateDateRange={(v) => updateCurrentDateRange(v)}
      />
    </Stack>
  );
};

const DateRangePicker = ({
  children,
  ...props
}: React.PropsWithChildren & DateRangePickerProps) => {
  const { periods, onChange } = props;
  return (
    <DateRangePickerContextProvider periods={periods} onChange={onChange}>
      <DateRangePickerInternal />
    </DateRangePickerContextProvider>
  );
};

export default DateRangePicker;
