import { createApi } from "@reduxjs/toolkit/query/react";

import { simulationBaseQuery } from "@/utils/rtkQuery";

import { ActiveStatusEnum, SaasType, TemplateTypeEnum } from "./templateUtils";

export const templateApi = createApi({
  reducerPath: "templateApi",
  refetchOnMountOrArgChange: true,
  baseQuery: simulationBaseQuery(),
  endpoints: (builder) => ({
    fetchTemplateList: builder.query<
      any,
      {
        page: number;
        templatesPerPage: number;
        search: string;
        saas: SaasType | null;
        type: string;
        activation_status: ActiveStatusEnum;
        best_performers: boolean;
      }
    >({
      query: ({
        page,
        templatesPerPage,
        search,
        saas,
        type,
        activation_status,
        best_performers,
      }) => {
        const params = new URLSearchParams();
        params.append("page", String(page));
        params.append("templates_per_page", String(templatesPerPage));
        if (search !== "" && search != null) {
          params.append("search", search);
        }
        if (saas != null && saas.name != null && saas.name !== "") {
          params.append("saas", saas?.name);
        }
        if (type !== TemplateTypeEnum.ALL) {
          params.append("template_type", type);
        }
        if (activation_status !== ActiveStatusEnum.ALL) {
          params.append("activation_status", activation_status);
        }
        params.append("best_performers", String(best_performers));
        return `templates/list?${params.toString()}`;
      },
    }),
    fetchTemplateContent: builder.query({
      query: (payload: { name: string; language: string }) =>
        `templates/get/?name=${payload.name}&language_code=${payload.language}`,
    }),
    fetchRawTemplateContent: builder.mutation({
      query: (payload: { id: number }) => ({
        url: `templates/id/${payload.id}`,
        method: "GET",
      }),
    }),
    fetchSaasList: builder.query({
      query: (payload: void) => `saas_with_templates`,
    }),
    checkDenylistStatus: builder.query({
      query: (templateName: string) => ({
        // here and elsewhere we should use encodeURIComponent if template name isn't just ascii
        url: `templates/denylist/get?template_name=${templateName}`,
        method: "GET",
      }),
    }),
    modifyTemplateDenylist: builder.mutation({
      query: ({ templateName, state }) => ({
        url: `templates/denylist/${state}`,
        method: "POST",
        body: { template_name: templateName },
      }),
    }),
  }),
});

export const {
  useFetchTemplateListQuery,
  useFetchRawTemplateContentMutation,
  useFetchTemplateContentQuery,
  useFetchSaasListQuery,
  useCheckDenylistStatusQuery,
  useModifyTemplateDenylistMutation,
} = templateApi;
