import React from "react";
import { useTranslation } from "react-i18next";

import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import { BackendSuspense } from "./BackendSuspense";

export const CourseCompletedCard = ({ data, error, isPending }) => {
  const completedCourses =
    data?.course_completion_rundown.slice(-1)[0]?.completed_courses;
  const courseCount = data?.course_count;
  const width =
    courseCount && courseCount > 0
      ? Math.round((completedCourses / courseCount) * 100)
      : 0;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        title={t("Courses Completed")}
        avatar={<SchoolOutlinedIcon fontSize="large" color="primary" />}
      />
      <CardContent>
        <BackendSuspense
          hasData={courseCount > 0}
          error={error}
          isPending={isPending}
        >
          <div style={{ backgroundColor: theme.palette.divider }}>
            <div
              style={{
                width: width + "%",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "25px" }}
              >
                <Typography color="white">{width} %</Typography>
              </Box>
            </div>
          </div>
          <Stack direction="row">
            <div>0</div>
            {completedCourses > 0 && completedCourses < courseCount ? (
              <Box display="flex" justifyContent="end" width={`${width}%`}>
                <div>{completedCourses}</div>
              </Box>
            ) : (
              ""
            )}
            <Box marginLeft="auto">{courseCount}</Box>
          </Stack>
        </BackendSuspense>
      </CardContent>
    </Card>
  );
};
