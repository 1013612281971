import React from "react";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import { T } from "@/components/LocalizedString";

export const passwordMisuseDomainLabelButtonOptions = [
  {
    key: "reuse" as const,
    value: {
      title: T("Credentials reuse"),
      description: T("Keep triggering alerts for domain(s) and ..."),
      icon: <ReportProblemIcon sx={{ color: "#F19949" }} />,
    },
  },
  {
    key: "safe" as const,
    value: {
      title: T("Authorized domain"),
      description: T("Stop triggering alerts for domain(s) and ..."),
      icon: <CheckCircleIcon sx={{ color: "#35D9C0" }} />,
    },
  },
  {
    key: "blocked" as const,
    value: {
      title: T("Blocked domain"),
      description: T("Block browsing on domain(s) and ..."),
      icon: <ReportIcon sx={{ color: "#F6285F" }} />,
    },
  },
];

export const mantraDomainLabelOptions = [
  {
    key: "potential_phishing",
    value: {
      title: T("Phishing attack"),
      description: "",
      icon: <AutoAwesomeIcon sx={{ color: "#F6285F" }} />,
    },
  },
  {
    key: "reuse",
    value: {
      title: T("Credentials reuse"),
      description: "",
      icon: <AutoStoriesIcon sx={{ color: "#030ffc" }} />,
    },
  },
];
