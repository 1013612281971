import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Card, CardContent, CardHeader, useTheme } from "@mui/material";

import { BackendSuspense } from "@/pages/awareness/dashboard/BackendSuspense";
import { BarPart } from "@/pages/awareness/dashboard/BarPart";
import RestartDropOffConfirmationDialog from "@/pages/awareness/progress/RestartDropOffConfirmationDialog";
import SplitButton from "@/pages/awareness/progress/SplitButton";
import { HeaderBox } from "@/utils/styled-components";

type UserRepartitionStatsData = {
  user_count: number;
  user_dropoff_count: number;
  user_dropoff_rate: number;
  user_not_set_up_count: number;
  user_not_set_up_rate: number;
  user_not_started_count: number;
  user_not_started_rate: number;
  user_ongoing_count: number;
  user_ongoing_rate: number;
};

type UserRepartitionStatsProps = {
  data: UserRepartitionStatsData;
  isPending: boolean;
  error: any | null;
};

export const UserRepartitionStats = ({
  data,
  isPending,
  error,
}: UserRepartitionStatsProps) => {
  const theme = useTheme();

  const onTrackColor = theme.palette.status.awareness.onTrack;
  const dropOffColor = theme.palette.status.awareness.dropOff;
  const notStartedColor = theme.palette.status.awareness.notStarted;
  const notSetupColor = theme.palette.status.awareness.notSetup;
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [target, setTarget] = useState("drop_off");
  const onClickRestart = (mode: string) => {
    setConfirmationDialogOpen(true);
    setTarget(mode);
  };
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={t("Current user repartition")}
        action={
          <HeaderBox>
            <SplitButton onClickRestart={onClickRestart} />
          </HeaderBox>
        }
      />
      <CardContent>
        <RestartDropOffConfirmationDialog
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          target={target}
          confirmationDialogOpen={confirmationDialogOpen}
        />
        <Box display="flex" justifyContent="center" alignItems="center">
          <BackendSuspense
            data={data}
            error={error}
            isPending={isPending}
            hasData={data?.user_count !== 0}
          >
            <Box width="100%" display="flex">
              {data?.user_ongoing_count > 0 && (
                <BarPart
                  width={Math.round(data.user_ongoing_rate * 100)}
                  userCount={data.user_ongoing_count}
                  label={t("On Track")}
                  color={onTrackColor}
                  tooltip={t(
                    "Completed course or interacted with a course less than a month ago",
                  )}
                />
              )}
              {data?.user_dropoff_count > 0 && (
                <BarPart
                  width={Math.round(data.user_dropoff_rate * 100)}
                  userCount={data.user_dropoff_count}
                  label={t("Late")}
                  color={dropOffColor}
                  tooltip={t("Interacted with a course more than a month ago")}
                />
              )}
              {data?.user_not_started_count > 0 && (
                <BarPart
                  width={Math.round(data.user_not_started_rate * 100)}
                  userCount={data.user_not_started_count}
                  label={t("Not Started")}
                  color={notStartedColor}
                  tooltip={t("Never interacted with any course")}
                />
              )}
              {data?.user_not_set_up_count > 0 && (
                <BarPart
                  width={Math.round(data.user_not_set_up_rate * 100)}
                  userCount={data.user_not_set_up_count}
                  label={t("Not Set Up")}
                  color={notSetupColor}
                  tooltip={t("Can’t receive any course")}
                />
              )}
              {data?.user_ongoing_count <= 0 &&
              data?.user_dropoff_count <= 0 &&
              data?.user_not_started_count <= 0 &&
              data?.user_not_set_up_count <= 0
                ? t("no data available")
                : ""}
            </Box>
          </BackendSuspense>
        </Box>
      </CardContent>
    </Card>
  );
};
