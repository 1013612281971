import React from "react";
import { useTranslation } from "react-i18next";

import { Alert, Stack, Typography } from "@mui/material";

import { ExtensionGenerationBadge } from "@/pages/browser-defender/components/ExtensionGenerationBadge";
import GenericCard from "@/pages/browser-defender/components/GenericCard";

interface BrowserDefenderExtensionInformationProps {
  config: {
    extension_generation?: string;
  };
}

export function BrowserDefenderExtensionInformation({
  config,
}: BrowserDefenderExtensionInformationProps) {
  const { t } = useTranslation();

  return (
    <GenericCard title={t("Browser Defender extension information")}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography color="textSecondary">
            {t("Extension generation: ")}
          </Typography>
          <ExtensionGenerationBadge generation={config.extension_generation} />
        </Stack>
        {config.extension_generation === "legacy" && (
          <Alert severity="info">
            {t("Monitoring mode requires newer version")}
            <br />
            {t("Please contact dev-extension to upgrade")}
          </Alert>
        )}
      </Stack>
    </GenericCard>
  );
}
