import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";

import { useGetLocalizedCourseInteractionsQuery } from "@/api";
import { BigSpinner } from "@/components/Spinner";

import { Interaction } from "./Interaction";
import {
  addInteraction,
  selectCurrentLocalizedCourse,
  selectEditMode,
  selectHasNotSavedInteractions,
  selectInteractions,
} from "./courseSlice";

export function Interactions() {
  const editMode = useSelector(selectEditMode);
  // TODO use listener or something like this instead of
  // fetching with the hook (cause performance issues on)
  // huge number of interaction (cause many rerenders apparently)
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  const { isFetching, isError } = useGetLocalizedCourseInteractionsQuery(
    localizedCourse?.id,
  );
  const interactions = useSelector(selectInteractions);
  const dispatch = useDispatch();
  const hasNotSavedInteractions = useSelector(selectHasNotSavedInteractions);
  const { t } = useTranslation();

  const onAddInteraction = (index: null | number) => {
    dispatch(
      addInteraction({
        previousIndex: index,
      }),
    );
  };

  return (
    <>
      {isFetching ? <BigSpinner /> : ""}
      {isError ? <div>interaction: an error occured...</div> : ""}
      {!isFetching && !isError ? (
        <Box>
          {editMode ? (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ marginBottom: "1rem" }}
              disabled={hasNotSavedInteractions}
              onClick={() => onAddInteraction(null)}
            >
              {t("Insert interaction")}
            </Button>
          ) : (
            ""
          )}
          {(interactions ?? []).map((interaction, index) => (
            <Box key={interaction.keyId}>
              <Interaction index={index} interaction={interaction} />
              {editMode ? (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{ marginBottom: "1rem" }}
                  disabled={hasNotSavedInteractions}
                  onClick={() => onAddInteraction(index)}
                >
                  {t("Insert interaction")}
                </Button>
              ) : (
                ""
              )}
            </Box>
          ))}
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
